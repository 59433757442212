import moment from 'moment-timezone';

export const formatDate = (raw) => {
  return moment(raw).format('YYYY-MM-DD');
};

export const extractOwnerChangeDate = (rawString) => {
  const dateInfo = rawString.split(' ')[0];
  return dateInfo;
  // const dateInfo = rawString.split(' ')[0].split('/');
  // console.log('dateInfo: ', dateInfo);

  // console.log(
  //   'dateInfo: ',
  //   new Date(
  //     parseFloat(dateInfo[2]),
  //     parseFloat(dateInfo[1]),
  //     parseFloat(dateInfo[0]),
  //   ),
  // );
  // return new Date(
  //   parseFloat(dateInfo[2]),
  //   parseFloat(dateInfo[1]),
  //   parseFloat(dateInfo[0]),
  // );
};
