import { AssetTransferConstants as constants } from '../../_constants/transaction/';
import { assetTransferService as service } from '../../_services/transaction/asset.transfer.service';
import { alertActions } from './../alert.action';

export const assetTransferAction = {
  insertItem,
  updateItem,
  deleteItem,
  getItem,
  getAllItems,
  reset,
};

function request(t) {
  return { type: t };
}
function success(t, res) {
  return { type: t, data: res };
}
function failure(t, error) {
  return { type: t, error };
}
function reset(t) {
  return { type: t };
}

function insertItem(data) {
  return async (dispatch) => {
    dispatch(request(constants.INSERT_REQUEST));
    try {
      const res = await service.insertItem(data);
      dispatch(success(constants.INSERT_SUCCESS, res));
      dispatch(reset(constants.RESET));
      window.history.back();
      return res;
    } catch (error) {
      dispatch(failure(constants.INSERT_FAILURE, error));
      dispatch(alertActions.error(error.toString()));
      return error;
    }
  };
}

function updateItem(data) {
  return async (dispatch) => {
    dispatch(request(constants.UPDATE_REQUEST));
    try {
      const res = await service.updateItem(data);
      dispatch(success(constants.UPDATE_SUCCESS, res));
      dispatch(reset(constants.RESET));
      return res;
    } catch (error) {
      dispatch(failure(constants.UPDATE_FAILURE, error));
      dispatch(alertActions.error(error.toString()));
      return error;
    }
  };
}

function deleteItem(data) {
  return async (dispatch) => {
    dispatch(request(constants.DELETE_REQUEST));
    try {
      const res = await service.deleteItem(data);
      dispatch(success(constants.DELETE_SUCCESS, res));
      dispatch(reset(constants.RESET));
      return res;
    } catch (error) {
      dispatch(failure(constants.DELETE_FAILURE, error));
      dispatch(alertActions.error(error.toString()));
      return error;
    }
  };
}

function getItem(code) {
  return async (dispatch) => {
    dispatch(request(constants.GET_REQUEST));
    try {
      const res = await service.getItem(code);
      dispatch(success(constants.GET_SUCCESS, res));
      dispatch(reset(constants.RESET));
      return res;
    } catch (error) {
      dispatch(failure(constants.GET_FAILURE, error));
      dispatch(alertActions.error(error.toString()));
      return error;
    }
  };
}

function getAllItems() {
  return async (dispatch) => {
    dispatch(request(constants.GETALL_REQUEST));
    try {
      const res = await service.getAllItem();
      dispatch(success(constants.GETALL_SUCCESS, res));
      dispatch(reset(constants.RESET));
      return res;
    } catch (error) {
      dispatch(failure(constants.GETALL_FAILURE, error));
      dispatch(alertActions.error(error.toString()));
      return error;
    }
  };
}
