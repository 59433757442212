import { assetConstants as constants } from '../_constants';

const initialState = {
  request: false,
  reset: false,
  insertRequest: false,
  insertSuccess: false,
  insertFailure: false,

  updateSuccess: false,
  updateFailure: false,

  deleteSuccess: false,
  deleteFailure: false,

  getSuccess: false,
  getFailure: false,

  getAllSuccess: false,
  getAllFailure: false,

  insertImageSuccess: false,
  insertImageFailure: false,

  updateImageSuccess: false,
  updateImageFailure: false,

  deleteImageSuccess: false,
  deleteImageFailure: false,

  getImageSuccess: false,
  getImageFailure: false,

};
export function assetOperation(state = initialState, action) {
  switch (action.type) {
    case constants.INSERT_REQUEST:
      return {
        ...state,
        request: true,
        insertSuccess: false,
        insertFailure: false,
      };
    case constants.INSERT_SUCCESS:
      return {
        ...state,
        insertSuccess: true,
        request: false,
        data: action.data,
      };
    case constants.INSERT_FAILURE:
      return {
        ...state,
        insertFailure: true,
        request: false,
        error: action.error,
      };

    case constants.UPDATE_REQUEST:
      return {
        ...state,
        request: true,
        updateSuccess: false,
        updateFailure: false,
      };
    case constants.UPDATE_SUCCESS:
      return {
        ...state,
        request: false,
        updateSuccess: true,
        updateFailure: false,
        data: action.data,
      };
    case constants.UPDATE_FAILURE:
      return {
        ...state,
        request: false,
        updateSuccess: false,
        updateFailure: true,
        error: action.error,
      };

    case constants.DELETE_REQUEST:
      return { ...state, request: true };
    case constants.DELETE_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        request: false,
        data: action.data,
      };
    case constants.DELETE_FAILURE:
      return {
        ...state,
        deleteFailure: true,
        request: false,
        error: action.error,
      };

    case constants.GET_REQUEST:
      return {
        ...state,
        request: true,
        getSuccess: false,
        getFailure: false,
      };
    case constants.GET_SUCCESS:
      return {
        ...state,
        request: false,
        getSuccess: true,
        getFailure: false,
        data: action.data,
      };
    case constants.GET_FAILURE:
      return {
        ...state,
        request: false,
        getSuccess: false,
        getFailure: true,
        error: action.error,
      };

    case constants.GETALL_REQUEST:
      return {
        ...state,
        request: true,
        getAllSuccess: false,
        getAllFailure: false,
      };
    case constants.GETALL_SUCCESS:
      return {
        ...state,
        request: false,
        getAllSuccess: true,
        getAllFailure: false,
        data: action.data,
      };
    case constants.GETALL_FAILURE:
      return {
        ...state,
        request: false,
        getAllSuccess: false,
        getAllFailure: true,
        error: action.error,
      };


    case constants.INSERT_IMAGE_REQUEST:
      return {
        ...state,
        request: true,
        insertImageSuccess: false,
        insertImageFailure: false,
      };
    case constants.INSERT_IMAGE_SUCCESS:
      return {
        ...state,
        request: false,
        insertImageSuccess: true,
        insertImageFailure: false,
        data: action.data,
      };
    case constants.INSERT_IMAGE_FAILURE:
      return {
        ...state,
        request: false,
        insertImageSuccess: false,
        insertImageFailure: true,
        error: action.error,
      };

    case constants.UPDATE_IMAGE_REQUEST:
      return {
        ...state,
        request: true,
        updateImageSuccess: false,
        updateImageFailure: false,
      };
    case constants.UPDATE_IMAGE_SUCCESS:
      return {
        ...state,
        request: false,
        updateImageSuccess: true,
        updateImageFailure: false,
        data: action.data,
      };
    case constants.UPDATE_IMAGE_FAILURE:
      return {
        ...state,
        updateImageRequest: false,
        updateImageSuccess: false,
        updateImageFailure: true,
        error: action.error,
      };


    case constants.DELETE_IMAGE_REQUEST:
      return {
        ...state,
        request: true,
        deleteImageSuccess: false,
        deleteImageFailure: false,
      };
    case constants.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        request: false,
        deleteImageSuccess: true,
        deleteImageFailure: false,
        data: action.data,
      };
    case constants.DELETE_IMAGE_FAILURE:
      return {
        ...state,
        request: false,
        deleteImageSuccess: false,
        deleteImageFailure: true,
        error: action.error,
      };


    case constants.GET_IMAGE_REQUEST:
      return {
        ...state,
        request: true,
        getImageSuccess: false,
        getImageFailure: false,
      };
    case constants.GET_IMAGE_SUCCESS:
      return {
        ...state,
        request: false,
        getImageSuccess: true,
        getImageFailure: false,
        data: action.data,
      };
    case constants.GET_IMAGE_FAILURE:
      return {
        ...state,
        request: false,
        getImageSuccess: false,
        getImageFailure: true,
        error: action.error,
      };
    case constants.RESET:
      return {
        ...initialState,
      }

    default:
      return { ...initialState }; // if return state, insertRequest, insertSuccess, etc., will remain the same value
  }
}
