export const warrantyConstants = {
    API_INSERT: '/api/asset/warranty/insert',
    API_UPDATE: '/api/asset/warranty/update',
    API_DELETE: '/api/site/location/zone/delete',
    API_GET: '/api/site/location/zone/',
    API_GETALL: '/api/site/location/zone/getall',

    INSERT_REQUEST: 'WARRANTY_INSERT_REQUEST',
    INSERT_SUCCESS: 'WARRANTY_INSERT_SUCCESS',
    INSERT_FAILURE: 'WARRANTY_INSERT_FAILURE',

    UPDATE_REQUEST: 'WARRANTY_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'WARRANTY_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'WARRANTY_UPDATE_FAILURE',

    DELETE_REQUEST: 'WARRANTY_DELETE_REQUEST',
    DELETE_SUCCESS: 'WARRANTY_DELETE_SUCCESS',
    DELETE_FAILURE: 'WARRANTY_DELETE_FAILURE',

    GET_REQUEST: 'WARRANTY_GET_REQUEST',
    GET_SUCCESS: 'WARRANTY_GET_SUCCESS',
    GET_FAILURE: 'WARRANTY_GET_FAILURE',

    GETALL_REQUEST: 'WARRANTY_GETALL_REQUEST',
    GETALL_SUCCESS: 'WARRANTY_GETALL_SUCCESS',
    GETALL_FAILURE: 'WARRANTY_GETALL_FAILURE',

    RESET: 'WARRANTY_RESET',
    SERVICE: 'WARRANTY_SERVICE',
};
