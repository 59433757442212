import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';
import { ListingDatatable } from '../../_fragments';
import Form from './Form';
import { assetClassSchema as schema } from '../../_validationSchema';
import { assetClassAction as actions } from '../../_action/assetClass.action';
import { assetClass_model as model } from '../../_model';
import { assetClassConstants as constants } from '../../_constants';

import { useSelector } from 'react-redux';

const AssetClass = (props) => {
  const { t } = props;

  const header = { name: 'AssetClass', icon: 'cubes' };
  const reducer = useSelector((state) => state.assetClassOperation);
  const [item, setItem] = React.useState({});

  const filenameXML = 'asset-class';
  const menuCode = 'M_ASSET_CLASS';

  const columns = [
    { title: t('code'), field: 'Code' },
    { title: t('description'), field: 'Description' },
  ];
  let useService = () => {
    return [schema, actions, model, constants];
  };
  const [open, setOpen] = useState(false);
  const [selectedCode, setSelected] = useState('');
  const [type, setFormType] = useState('');
  const [keyTrigger, setTrigger] = useState(false);
  useEffect(() => {
    if (open === false) {
      setFormType('');
      setTrigger(!keyTrigger);
    }
  }, [open]);
  useEffect(() => {
    if (reducer.getSuccess) {
      setItem(reducer.data.data.info);
    }
  }, [reducer]);

  return (
    <>
      <Form
        type={type}
        header={header}
        code={selectedCode}
        open={open}
        setClose={() => setOpen(false)}
        useService={useService}
        reducer={reducer}
        item={item}
      />
      <ListingDatatable
        key={keyTrigger}
        header={header}
        columns={columns}
        filenameXML={filenameXML}
        setSelected={setSelected}
        setFormOpen={setOpen}
        setFormType={setFormType}
        menuCode={menuCode}
      />
    </>
  );
};

export default translate(AssetClass);
