import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Title } from '../../_fragments';
import SaveIcon from '@material-ui/icons/Save';
import EmailIcon from '@material-ui/icons/Email';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Icon, Header } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../_action/alert.action';

import {
  Paper,
  FormControl,
  FormLabel,
  FormHelperText,
  Grid,
  Button,
  FormControlLabel,
  Switch,
  Box,
  Card,
  Typography,
  Slide,
  TextField,
} from '@material-ui/core';
import { smtpService } from '../../_services/smtp.service';
import { smtpSchema as schema } from '../../_validationSchema';
import {
  BlurCircularRounded,
  ContactSupportOutlined,
} from '@material-ui/icons';
import { useHistory } from 'react-router';

const limit = 20;
export default function EmailConfig(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state.alert);
  const history = useHistory();

  // state
  const [state, setState] = React.useState({
    code: '',
    host: '',
    port: '',
    username: '',
    password: '',
    bcc: [], // list
    email: '', // textfield
    error: null,
    errorMsg: null,
    errorList: {
      code: null,
      host: null,
      port: null,
      username: null,
      password: null,
      bcc: null,
    },
  });
  // header
  const [title, setTitle] = React.useState({
    iconaction: 'add',
    content: 'Asset',
    description: 'Create',
  });
  // slide direction
  const [direction, setDirection] = React.useState({
    slide: 'left',
    in: true,
    timeout: 650,
  });
  // submit
  const onSubmit = async (e) => {
    var bcc = convertEmailListToString(state.bcc);
    console.log(state);

    var errorList = state.errorList;
    var isError = false;
    if (state.host === '') {
      errorList.host = 'host is missing';
      isError = true;
    }
    if (state.port === '') {
      errorList.port = 'port is missing';
      isError = true;
    }
    if (state.username === '') {
      errorList.username = 'username is missing';
      isError = true;
    }
    if (state.password === '') {
      errorList.password = 'password is missing';
      isError = true;
    }
    if (isError) {
      setState({ ...state, errorList: errorList });
      return;
    }

    var params = {
      code: state.code,
      host: state.host,
      port: state.port,
      username: state.username,
      password: state.password,
      bcc: bcc,
    };
    try {
      if (state.code === '') {
        smtpService.insertItem(params).then((res) => {
          console.log('insert: ', res);
          dispatch(alertActions.success('Config Saved'));
          getData();
        });
      } else {
        smtpService
          .updateItem(params)
          .then((res) => {
            console.log('update: ', res);
            dispatch(alertActions.success('Config Saved'));
            getData();
          })
          .catch((err) => {
            console.log('update error: ', err);
          });
      }
    } catch (err) {
      console.log('err: ', err);
      dispatch(alertActions.error('Error'));
    }
  };
  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };
  const isInList = (email) => {
    return state.bcc.includes(email);
  };
  const convertEmailListToString = (list) => {
    var stringed = '';
    list.map((item) => {
      stringed = stringed + item + ',';
    });
    return stringed;
  };
  const isValid = (email) => {
    var error = null;

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (error) {
      setState({ ...state, errorList: { ...state.errorList, bcc: error } });

      return false;
    }

    return true;
  };
  const handleDelete = (toBeRemoved) => {
    setState({
      ...state,
      bcc: state.bcc.filter((email) => email !== toBeRemoved),
    });
  };
  const handleKeyDown = (e) => {
    if (['Enter', 'Tab', ','].includes(e.key)) {
      e.preventDefault();

      var email = state.email.trim();

      if (email && isValid(email)) {
        setState({
          ...state,
          bcc: [...state.bcc, email],
          email: '',
        });
      }
    }
  };
  const handlePaste = (e) => {
    e.preventDefault();

    var paste = e.clipboardData.getData('text');
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !isInList(email));
      // if(toBeAdded.length >= limit)
      //   toBeAdded = toBeAdded.slice(0,limit);
      setState({ ...state, bcc: [...state.bcc, ...toBeAdded] });
    }
  };
  const handleChange = (e, data) => {
    const value = e.target.value;
    if (e.target.type === 'checkbox') {
      setState({ ...state, [e.target.name]: data });
    } else if (e.target.type === 'date') {
      setState({ ...state, [e.target.name]: value });
    } else {
      setState({
        ...state,
        [e.target.name]: value,
        errorList: { ...state.errorList, [e.target.name]: null },
      });
    }
  };
  const getData = () => {
    smtpService
      .getAllItem()
      .then((res) => {
        var result = res.data.results[0];
        var bcc = result.bcc;

        if (bcc != null && bcc.length >= 1) {
          console.log('strBCC: ', bcc);
          bcc = bcc.split(',');
          bcc = bcc.slice(0, bcc.length - 1);
          console.log('splitted: ', bcc);
        }
        setState({
          ...state,
          code: result.code,
          host: result.host,
          port: result.port,
          username: result.username,
          password: result.password,
          bcc: bcc,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // get item
  };
  React.useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      // get all item

      getData();
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <>
      <Slide
        timeout={direction.timeout}
        direction={direction.slide}
        in={direction.in}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <form noValidate autoComplete="off">
            {props.isView === true && 'This is view form'}
            <Grid item xs={12} md={12} lg={12} className={classes.container}>
              <Card variant="outlined" className={classes.card}>
                <Header dividing>
                  <Typography variant="h6" color="textPrimary">
                    <Icon name="mail" />
                    E-mail Notification Configuration
                  </Typography>
                </Header>
                <Grid
                  container
                  justify="left"
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  {/* SMTPHost */}
                  <Grid item xs={12} md={12} xl={12}>
                    <FormControl margin="dense" fullWidth required>
                      <FormLabel component="legend">SMTPHost</FormLabel>
                      <TextField
                        name="host"
                        size="small"
                        placeholder="SMTPHost"
                        value={state.host}
                        onChange={handleChange}
                        variant="outlined"
                        required
                        error={state.errorList.host}
                      />
                      {state.errorList.host && (
                        <FormHelperText error>
                          {state.errorList.host}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* SMTPPort */}
                  <Grid item xs={12} md={12} xl={12}>
                    <FormControl margin="dense" fullWidth required>
                      <FormLabel component="legend">SMTPPort</FormLabel>
                      <TextField
                        name="port"
                        size="small"
                        placeholder="SMTPPort"
                        value={state.port}
                        onChange={handleChange}
                        variant="outlined"
                        required
                        error={state.errorList.port}
                      />
                      {state.errorList.port && (
                        <FormHelperText error>
                          {state.errorList.port}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* SMTPPortUsername */}
                  <Grid item xs={12} md={12} xl={12}>
                    <FormControl margin="dense" fullWidth required>
                      <FormLabel component="legend">SMTPUsername</FormLabel>
                      <TextField
                        name="username"
                        size="small"
                        placeholder="SMTPUsernmae"
                        value={state.username}
                        onChange={handleChange}
                        variant="outlined"
                        required
                        error={state.errorList.username}
                      />
                      {state.errorList.username && (
                        <FormHelperText error>
                          {state.errorList.username}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* SMTP Password */}
                  <Grid item xs={12} md={12} xl={12}>
                    <FormControl margin="dense" fullWidth required>
                      <FormLabel component="legend">SMTPPassword</FormLabel>
                      <TextField
                        name="password"
                        size="small"
                        placeholder="SMTPPassword"
                        value={state.password}
                        onChange={handleChange}
                        variant="outlined"
                        type="password"
                        required
                        error={state.errorList.password}
                      />
                      {state.errorList.password && (
                        <FormHelperText error>
                          {state.errorList.password}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* bcc */}

                  <Grid item xs={6} md={6} xl={6}>
                    {state.bcc.map((email) => (
                      <div key={email} className={classes.tagItem}>
                        {email}

                        <button
                          type="button"
                          className={classes.rmEmailBtn}
                          onClick={() => handleDelete(email)}
                        >
                          &times;
                        </button>
                      </div>
                    ))}

                    <FormControl margin="dense" fullWidth>
                      {/* <FormLabel component="legend">BCC ({state.bcc.length} / {limit})</FormLabel> */}
                      <FormLabel component="legend">BCC</FormLabel>
                      <TextField
                        name="email"
                        size="small"
                        placeholder="Type or paste email addresses and press `Enter`"
                        value={state.email}
                        onChange={handleChange}
                        variant="outlined"
                        error={state.errorList.bcc}
                        InputProps={{
                          onKeyDown: handleKeyDown,
                          onPaste: handlePaste,
                          endAdornment: (
                            <button
                              type="button"
                              className={classes.rmEmailBtn}
                              onClick={() =>
                                setState({ ...state, bcc: [], email: '' })
                              }
                            >
                              <RefreshIcon />
                            </button>
                          ),
                        }}
                        // disabled={state.bcc.length >= limit}
                      />
                      {state.errorList.bcc && (
                        <FormHelperText error>
                          {state.errorList.bcc}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12} xl={12}>
                    <Button
                      variant="outlined"
                      size="large"
                      color="default"
                      onClick={onSubmit}
                      className={classes.btnSave}
                      startIcon={<SaveIcon />}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </form>
        </div>
      </Slide>
    </>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  btnSave: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
  btnBack: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  container: { padding: '0px !important' },
  group: { padding: theme.spacing(1), margin: theme.spacing(0) },
  card: { padding: theme.spacing(2), margin: theme.spacing(1) },
  tagItem: {
    backgroundColor: '#d4d5d6',
    display: 'inline-block',
    fontSize: 14,
    borderRadius: 30,
    height: 30,
    padding: 3,
    paddingLeft: 5,
    paddingRight: 5,
    display: 'inline-flex',
    alignItems: 'center',
    margin: 1,
  },
  rmEmailBtn: {
    backgroundColor: 'white',
    width: 22,
    height: 22,
    borderRadius: 50,
    border: 'none',
    cursor: 'pointer',
    font: 'inherit',
    marginLeft: 5,
    fontWeight: 'bold',
    padding: 0,
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hasError: {
    borderColor: 'tomato',
  },
}));
