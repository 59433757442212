export const ASSET_ROOT_ROUTE = '/asset';

export const ASSET_UPDATE_ROUTE = `/asset/update/id`;
export const ASSET_VIEW_ROUTE = `/asset/view/id`;
export const ASSET_DELETE_ROUTE = `/asset/delete/id`;
export const ASSET_INSERT_ROUTE = '/asset/insert';

export const ASSET_CONDITION_ROOT_ROUTE = '/asset/condition';
export const ASSET_CONDITION_UPDATE_ROUTE = '/asset/condition/update';
export const ASSET_CONDITION_INSERT_ROUTE = '/asset/condition/insert';

export const ASSET_GROUP_ROOT_ROUTE = '/asset/group';
export const ASSET_GROUP_UPDATE_ROUTE = '/asset/group/update';
export const ASSET_GROUP_INSERT_ROUTE = '/asset/group/insert';

export const ASSET_MODEL_ROOT_ROUTE = '/asset/model';
export const ASSET_MODEL_UPDATE_ROUTE = '/asset/model/update';
export const ASSET_MODEL_INSERT_ROUTE = '/asset/model/insert';

export const ASSET_BRAND_ROOT_ROUTE = '/asset/brand';
export const ASSET_BRAND_UPDATE_ROUTE = '/asset/brand/update';
export const ASSET_BRAND_INSERT_ROUTE = '/asset/brand/insert';

export const ASSET_SUBCLASS_ROOT_ROUTE = '/asset/subclass';
export const ASSET_SUBCLASS_UPDATE_ROUTE = '/asset/subclass/update';
export const ASSET_SUBCLASS_INSERT_ROUTE = '/asset/subclass/insert';

export const ASSET_CLASS_ROOT_ROUTE = '/asset/class';
export const ASSET_CLASS_UPDATE_ROUTE = '/asset/class/update';
export const ASSET_CLASS_INSERT_ROUTE = '/asset/class/insert';

export const ASSET_SUPPLIER_ROOT_ROUTE = '/asset/supplier';
export const ASSET_SUPPLIER_UPDATE_ROUTE = '/asset/supplier/update';
export const ASSET_SUPPLIER_INSERT_ROUTE = '/asset/supplier/insert';

export const BUSINESS_AREA_ROOT_ROUTE = '/business_area';
export const BUSINESS_AREA_UPDATE_ROUTE = '/business_area/update';
export const BUSINESS_AREA_INSERT_ROUTE = '/business_area/insert';

export const ROLE_ROOT_ROUTE = '/role';
export const USER_ROOT_ROUTE = '/user';
export const PERMISSION_ROOT_ROUTE = '/permission';

export const ZONE_ROOT_ROUTE = '/zone';
export const SITE_ROOT_ROUTE = '/site';
export const LOCATION_ROOT_ROUTE = '/location';

export const COST_CENTER_ROUTE = '/cost_center';

export const CUSTOMER_ROUTE = '/customer';
export const CUSTOMER_ROUTE_INSERT = '/customer/insert';
export const CUSTOMER_ROUTE_UPDATE = '/customer/update';

export const ASSET_ACCOUNT_ROUTE = '/asset/account';
export const ASSET_ACCOUNT_UPDATE_ROUTE = '/asset/account/update';
export const ASSET_ACCOUNT_INSERT_ROUTE = '/asset/account/insert';

export const ASSET_TYPE_ROUTE = '/asset/type';
export const ASSET_TYPE_UPDATE_ROUTE = '/asset/type/update';
export const ASSET_TYPE_INSERT_ROUTE = '/asset/type/insert';

export const REASON_ROOT_ROUTE = '/reason';
export const REASON_UPDATE_ROUTE = '/reason/update';
export const REASON_INSERT_ROUTE = '/reason/insert';

export const REASONTYPE_ROOT_ROUTE = '/reasontype';
export const REASONTYPE_UPDATE_ROUTE = '/reasontype/update';
export const REASONTYPE_INSERT_ROUTE = '/reasontype/insert';
//*Inventory*//
const rootInventory = `/inventory`;

export const INVENTORY_STOCK_RECEIPT = `${rootInventory}/stockreceipt`;
export const INVENTORY_STOCK_RECEIPT_INSERT = `${rootInventory}/stockreceipt/insert`;
export const INVENTORY_STOCK_RECEIPT_UPDATE = `${rootInventory}/stockreceipt/update`;

export const INVENTORY_STOCK_TRANSFER = `${rootInventory}/stocktransfer`;
export const INVENTORY_STOCK_TRANSFER_INSERT = `${rootInventory}/stocktransfer/insert`;
export const INVENTORY_STOCK_TRANSFER_UPDATE = `${rootInventory}/stocktransfer/update`;

export const INVENTORY_STOCK_ADJUSTMENT = `${rootInventory}/stockadjustment`;
export const INVENTORY_STOCK_ADJUSTMENT_INSERT = `${rootInventory}/stockadjustment/insert`;
export const INVENTORY_STOCK_ADJUSTMENT_UPDATE = `${rootInventory}/stockadjustment/update`;

export const INVENTORY_STOCK_ISSUE = `${rootInventory}/stockissue`;
export const INVENTORY_STOCK_ISSUE_INSERT = `${rootInventory}/stockissue/insert`;
export const INVENTORY_STOCK_ISSUE_UPDATE = `${rootInventory}/stockissue/update`;

export const INVENTORY_REQUESITION = `${rootInventory}/requesition`;
export const INVENTORY_REQUESITION_INSERT = `${rootInventory}/requesition/insert`;
export const IINVENTORY_REQUESITION_UPDATE = `${rootInventory}/requesition/update`;

export const INVENTORY_BORROW = `${rootInventory}/borrow`;
export const INVENTORY_BORROW_INSERT = `${rootInventory}/borrow/insert`;
export const INVENTORY_BORROW_UPDATE = `${rootInventory}/borrow/update`;

export const INVENTORY_RETURN = `${rootInventory}/return`;
export const INVENTORY_RETURN_INSERT = `${rootInventory}/return/insert`;
export const INVENTORY_RETURN_UPDATE = `${rootInventory}/return/update`;

export const INVENTORY_OWNER_TRANSFER = `${rootInventory}/ownertransfer`;
export const INVENTORY_OWNER_TRANSFER_INSERT = `${rootInventory}/ownertransfer/insert`;
export const INVENTORY_OWNER_TRANSFER_UPDATE = `${rootInventory}/ownertransfer/update`;

export const INVENTORY_WRITEOFF = `${rootInventory}/writeoff`;
export const INVENTORY_OWRITEOFF_INSERT = `${rootInventory}/writeoff/insert`;
export const INVENTORY_WRITEOFF_UPDATE = `${rootInventory}/writeoff/update`;

export const INVENTORY_ASSET_CHECK = `${rootInventory}/assetcheck`;
export const INVENTORY_ASSET_CHECK_INSERT = `${rootInventory}/assetcheck/insert`;
export const INVENTORY_ASSET_CHECK_UPDATE = `${rootInventory}/assetcheck/update`;

export const INVENTORY_ASSET_CHECK_SMS = `${rootInventory}/sms1-assetcheck`;
export const INVENTORY_ASSET_CHECK_INSERT_SMS = `${rootInventory}/sms1-assetcheck/insert`;
export const INVENTORY_ASSET_CHECK_UPDATE_SMS = `${rootInventory}/sms1-assetcheck/update`;
//*setting*//
const rootSetting = `/setting`;
export const CODERUN = `${rootSetting}/coderun`;
export const CODERUN_INSERT = `${rootSetting}/coderun/insert`;
export const CODERUN_UPDATE = `${rootSetting}/coderun/update`;

// email
export const EMAIL_CONFIG = '/email/config';
export const EMAIL_HISTORY = '/email/history';

//*Reports*//
const rootReport = `/reports`;
export const TEST_REPORT = '/testReport';
export const ASSET_MASTER_REPORT = `${rootReport}/assetmaster`;
export const ASSET_BORROW_REPORT = `${rootReport}/borrow`;
export const ASSET_RETURN_REPORT = `${rootReport}/return`;
export const ASSET_COUNT_REPORT = `${rootReport}/assetcheck`;
export const ASSET_OWNERTRANSFER_REPORT = `${rootReport}/ownertransfer`;
export const ASSET_WRITEEOFF_REPORT = `${rootReport}/writeoff`;
export const ASST_TRANSFER_REPORT = `${rootReport}/transfer`;
