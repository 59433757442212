import { useCallback, useEffect, useState } from 'react';
import { formatDate } from '../_helpers/time';
import {
  assetService,
  assetClassService,
  assetSubClassService,
  assetGroupService,
  assetModelService,
  uomService,
  assetConditionService,
} from '../_services';
import useFetch from './useFetch';

export default function useAsset(
  { code, initial, form = null },
  dependencies = [],
) {
  const [loading, setLoading] = useState(true);

  const [asset, setAsset] = useState(initial);
  const [title, setTitle] = useState({
    iconaction: 'add',
    content: 'Asset',
    description: 'Create',
  });
  const extract = useCallback((res) => res.data.results, []);

  const [clssOp, clssOpLoading] = useFetch({
    fetchFromApi: () => assetClassService.getAllItem(),
    extract,
  });
  const [subClassOp, subClassOpLoading] = useFetch({
    fetchFromApi: () => assetSubClassService.getAllItem(),
    extract,
  });
  const [groupOp, groupOpLoading] = useFetch({
    fetchFromApi: () => assetGroupService.getAllItem(),
    extract,
  });
  const [modelOp, modelOpLoading] = useFetch({
    fetchFromApi: () => assetModelService.getAllItem(),
    extract,
  });
  const [uomOp, uomOpLoading] = useFetch({
    fetchFromApi: () => uomService.getAllItem(),
    extract,
  });
  const [conditionOp, conditionOpLoading] = useFetch({
    fetchFromApi: () => assetConditionService.getAllItem(),
    extract,
  });
  const makeSubmitFormat = useCallback(async () => {
    return {
      ...asset,
      unit: !!asset.unit ? asset.unit.code : '',
      condition: !!asset.condition ? asset.condition.code : '',
      cost: !!asset.cost ? asset.cost.code : '',
      brand: !!asset.brand ? asset.brand.code : '',
      clss: !!asset.clss ? asset.clss.code : '',
      subClass: !!asset.subClass ? asset.subClass.code : '',
      group: !!asset.group ? asset.group.code : '',
      model: !!asset.model ? asset.model.code : '',
      supplier: !!asset.supplier ? asset.supplier.code : '',
      dimensionUOM: !!asset.dimensionUOM ? asset.dimensionUOM.code : '',
      weightUOM: !!asset.weightUOM ? asset.weightUOM.code : '',
      periodUOM: !!asset.periodUOM
        ? asset.periodUOM.code
        : uomOp.find((u) => u.code === 'DAY').code,
      periodUOMl: !!asset.periodUOMl
        ? asset.periodUOMl.code
        : uomOp.find((u) => u.code === 'DAY').code,
      warrantyStart: !!asset.warrantyStart && formatDate(asset.warrantyStart),
      warrantyEnd: !!asset.warrantyEnd && formatDate(asset.warrantyEnd),
      licenseStart: !!asset.licenseStart && formatDate(asset.licenseStart),
      licenseEnd: !!asset.licenseEnd && formatDate(asset.licenseEnd),
      businessArea: '',
      location: '',
      zone: '',
    };
  }, [uomOp, asset]);

  const fetchData = useCallback(async () => {
    const res = await assetService.getItemInterfaced(code);

    setAsset({
      ...res,
      licenseStart: res.licenseStart === '' ? '' : formatDate(res.licenseStart),
      licenseEnd: res.licenseEnd === '' ? '' : formatDate(res.licenseEnd),
      warrantyStart:
        res.warrantyStart === '' ? '' : formatDate(res.warrantyStart),
      warrantyEnd: res.warrantyEnd === '' ? '' : formatDate(res.warrantyEnd),
    });
    setLoading(false);
  }, [code]);

  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled && !!code) {
      fetchData();
    }

    return () => {
      isCancelled = true;
    };
  }, [code, ...dependencies]);
  useEffect(() => {
    if (form === 'update_form') {
      setTitle({ iconaction: 'edit', content: 'Asset', description: 'update' });
    } else if (form === 'view_form') {
      setTitle({
        iconaction: 'sitemap',
        content: 'Asset',
        description: 'view',
      });
    } else {
      setLoading(false);
    }
  }, [form]);

  return {
    asset,
    setAsset,
    loading:
      loading &&
      uomOpLoading &&
      clssOpLoading &&
      groupOpLoading &&
      modelOpLoading &&
      subClassOpLoading &&
      conditionOpLoading,
    title,
    makeSubmitFormat,
    options: { clssOp, subClassOp, groupOp, modelOp, uomOp, conditionOp },
  };
}
