import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';

// import Modal from 'react-bootstrap/Modal';

const Alert = (props) => {
  return <MuiAlert {...props} />;
};

export default Alert;
