import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { translate } from "react-switch-lang";
// import { assetBrandAction } from "../../../_action/assetBrand.action";
// import FormContainer from "../../Container/FormContainer";
import { commonLabel } from "../../../labelConstant";
import {
  // Button,
  // FormControl,
  // LinearProgress,
  Grid,
  TextField,
} from "@material-ui/core";
// import Alert from "@material-ui/lab/Alert";
// import SaveIcon from "@material-ui/icons/Save";
// import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
// import { useAssetRefFormStyles } from "../../muiStyles";
import { makeStyles } from "@material-ui/core/styles";
import { Container, } from 'semantic-ui-react';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(0),
    padding: theme.spacing(1),
  },
  grid_item: {
    margin: theme.spacing(0),
    padding: theme.spacing(1),
  },
  title: {
    fontSize: "1.1rem",
    padding: theme.spacing(1),
  },
}));

const AssetInsert = (props) => {

  const classes = useStyles(props);
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  const { t } = props;
  return (
    <Container>
      <Grid container direction="row">
        {/* box1 */}
        <Grid item xs={12} md={4}>
          <div className="text-info">
            <span className={classes.title}>{t('assetInformation')}</span>
          </div>
          <Grid container direction="row">
            <Grid item xs={12} md={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="code"
                label={t("code")}
                variant="outlined"
                size="small"
                error={"code" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"code" in errors ? errors.code.message : ""}
                autoFocus
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid_item}>
              <TextField
                fullWidth
                name="barcode"
                label={t("barcode")}
                variant="outlined"
                size="small"
                error={"barcode" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"barcode" in errors ? errors.barcode.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid_item}>
              <TextField
                fullWidth
                name="product"
                label={t("product")} // "P/N"
                variant="outlined"
                size="small"
                error={"product" in errors ? true : false}
                defaultValue=""
                // inputRef={register({
                //   required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                // })}
                helperText={"product" in errors ? errors.product.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid_item}>
              <TextField
                fullWidth
                name="thainame"
                label={t("thaiName")}
                variant="outlined"
                size="small"
                error={"thainame" in errors ? true : false}
                defaultValue=""
                // inputRef={register({
                //   required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                // })}
                helperText={"thainame" in errors ? errors.thainame.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid_item}>
              <TextField
                fullWidth
                name="engname"
                label={t("engName")}
                variant="outlined"
                size="small"
                error={"engname" in errors ? true : false}
                defaultValue=""
                // inputRef={register({
                //   required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                // })}
                helperText={"engname" in errors ? errors.engname.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6} md={6} className={classes.grid_item}>
              <TextField
                fullWidth
                name="color"
                label={t("color")}
                variant="outlined"
                size="small"
                error={"color" in errors ? true : false}
                defaultValue=""
                // inputRef={register({
                //   required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                // })}
                helperText={"color" in errors ? errors.color.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6} md={6} className={classes.grid_item}>
              <TextField
                fullWidth
                name="isserialize"
                label={t("isSerialize")}
                variant="outlined"
                size="small"
                error={"isserialize" in errors ? true : false}
                defaultValue=""
                // inputRef={register({
                //   required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                // })}
                helperText={
                  "isserialize" in errors ? errors.isserialize.message : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <div className="text-info">
            <span className={classes.title}>{t('assetClassification')}</span>
          </div>
          <Grid container direction="row">
            <Grid item xs={12} md={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="brand"
                label={t("assetBrand")}
                variant="outlined"
                size="small"
                error={"brand" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={
                  "brand" in errors ? errors.brand.message : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="class"
                label={t("assetClass")}
                variant="outlined"
                size="small"
                error={"class" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={
                  "class" in errors ? errors.class.message : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="subclass"
                label={t('assetSubClass')}
                variant="outlined"
                size="small"
                error={"subclass" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={
                  "subclass" in errors ? errors.subclass.message : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="group"
                label={t("assetGroup")}
                variant="outlined"
                size="small"
                error={"Asset Group" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={
                  "group" in errors ? errors.group.message : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="model"
                label={t("assetModel")}
                variant="outlined"
                size="small"
                error={"model" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={
                  "model" in errors ? errors.model.message : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* box2         */}
        <Grid item xs={12} md={4}>
          <div className="text-info">
            <span className={classes.title}>{t('manufacturing')}</span>
          </div>
          <Grid container direction="row">
            <Grid item xs={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="supplier"
                label={t("assetSupplier")}
                variant="outlined"
                size="small"
                error={"supplier" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"supplier" in errors ? errors.supplier.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <div className="text-info">
            <span className={classes.title}>{t('dimension')}</span>
          </div>
          <Grid container direction="row">
            <Grid item md={4} xs={4} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="height"
                label={t("height")}
                variant="outlined"
                size="small"
                error={"height" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"height" in errors ? errors.height.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={4} xs={4} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="width"
                label={t("width")}
                variant="outlined"
                size="small"
                error={"width" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"width" in errors ? errors.width.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={4} xs={4} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="depth"
                label={t("depth")}
                variant="outlined"
                size="small"
                error={"depth" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"depth" in errors ? errors.depth.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={6} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="unit"
                label={t("unit")}
                variant="outlined"
                size="small"
                error={"unit" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"unit" in errors ? errors.unit.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={6} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="weight"
                label={t("weight")}
                variant="outlined"
                size="small"
                error={"weight" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"weight" in errors ? errors.weight.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <div className="text-info">
            <span className={classes.title}>{t('assetOwner')}</span>
          </div>
          <Grid container direction="row">
            <Grid item md={12} xs={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="owner"
                label={t("owner")}
                variant="outlined"
                size="small"
                error={"owner" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"owner" in errors ? errors.owner.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="company"
                label={t("company")}
                variant="outlined"
                size="small"
                error={"company" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"company" in errors ? errors.company.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="branch"
                label={t("branch")}
                variant="outlined"
                size="small"
                error={"branch" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"branch" in errors ? errors.branch.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="department"
                label={t("department")}
                variant="outlined"
                size="small"
                error={"department" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"department" in errors ? errors.department.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <div className="text-info">
            <span className={classes.title}>{t('location')}</span>
          </div>
          <Grid container direction="row">
            <Grid item md={12} xs={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="businessarea"
                label={t("businessArea")}
                variant="outlined"
                size="small"
                error={"businessarea" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"businessarea" in errors ? errors.businessarea.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="site"
                label={t("site")}
                variant="outlined"
                size="small"
                error={"site" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"site" in errors ? errors.site.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="location"
                label={t("location")}
                variant="outlined"
                size="small"
                error={"location" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"location" in errors ? errors.location.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} className={classes.grid_item}>
              <TextField
                required
                fullWidth
                name="zone"
                label={t("zone")}
                variant="outlined"
                size="small"
                error={"zone" in errors ? true : false}
                defaultValue=""
                inputRef={register({
                  required: t(commonLabel.YUP_REQUIRED_THIS_FIELD),
                })}
                helperText={"zone" in errors ? errors.zone.message : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

        </Grid>
        {/* image-box */}
        <Grid item xs={12} md={4}>
          <div className="text-info">
            <span className={classes.title}>{t('assetInformation')}</span>
          </div>

          <Grid container direction="row">
            <Grid item xs={12} className={classes.grid_item}>
              <img className={classes.root} src="../../imgs/300.png" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default translate(AssetInsert);
