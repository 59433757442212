import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from 'react-switch-lang';
import {
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Select from 'react-select';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { isMoment } from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { AssetCheckServices as service } from '../services/assetcheck.service';
import {
  getCurrentOwner,
  getCurrentOwnerType,
} from '../redux/actions/assetcheck.action';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  griditem: {
    padding: theme.spacing(1),
  },
  bg: {
    //background: 'linear-gradient(180deg, white 30%, grey 70%)',
    // background: '#bbdefb',
  },
  control: {
    paddingRight: theme.spacing(0.5),
  },
  input: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

const AssetCheckHeader = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, register, setValue } = props;
  const [values, setValues] = useState({
    code: '',
    date: moment().format('YYYY-MM-DD'),
    sourceOwner: '',
    sourceOwnerType: '',
    remark: '',
  });
  const initialOwnerOption = [
    {
      value: 1,
      label: 'Company',
    },
    {
      value: 2,
      label: 'Branch',
    },
    {
      value: 3,
      label: 'Department',
    },
    {
      value: 4,
      label: 'Customer',
    },
    {
      value: 5,
      label: 'Employee',
    },
  ];

  const [ownerTypeOption, setownerTypeOption] = useState(initialOwnerOption);
  const [onwersOption, setOnwersOption] = useState([]);
  const [onwerDescsOption, setOnwersDescOption] = useState([]);

  let loading = false;
  //*** Services */

  const ownersQuery = async (ownerType, inputValue) => {
    try {
      const res = await service.autoComplete({
        req_type: ownerType,
        req_search: inputValue,
        recordTotal: '30',
      });

      const resData = Object.keys(res).map(
        (key) =>
          new Object({
            value: res[key].value,
            label: res[key].label,
          }),
      );

      return resData;
    } catch (error) {
      console.log('error:', error);
    }
  };

  //** UserEffect */

  useEffect(() => {
    ownersQuery(values.sourceOwnerType.value, '').then((opts) => {
      setOnwersOption(opts);
    });
  }, [values['sourceOwnerType']]);

  useEffect(() => {
    dispatch(getCurrentOwner(values.sourceOwner));
    dispatch(getCurrentOwnerType(values.sourceOwnerType));
  }, [values['sourceOwner']]);

  //getUpdate data
  useEffect(() => {
    console.log('code', props.code);
    if (!!props.code) {
      loading = true;
      if (loading) {
        service
          .getItem(props.code)
          .then((res) => {
            loading = false;
            const result = res.data.result;
            // console.log('result:', result);

            props.setValue('sourceOwnerType', {
              value: res.data.result.ownerType,
              label: initialOwnerOption.find(
                (i) => i.value == res.data.result.ownerType,
              ).label,
            });

            props.setValue('sourceOwner', {
              value: res.data.result.owner,
              label: res.data.result.owner,
            });

            setValues({
              code: result.code,
              date: result.date,
              sourceOwner: result.owner,
              sourceOwnerType: result.ownerType,
              remark: result.remark,
            });
          })
          .catch((error) => {
            loading = false;
            window.alert('error', error.message);
          });
      }
    }
  }, []);

  //*** Handles ***//

  const handleChange = (event) => {
    //console.log(event.target.value);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = (event) => {
    if (event[0] != null) {
      setValues({
        ...values,
        [event[1].name]: { value: event[0].value, label: event[0].label },
      });
    } else {
      setValues({
        ...values,
        [event[1].name]: '',
      });
    }
  };

  return (
    <>
      <Grid justify="flex-start" container direction="row" spacing={1}>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.input} fullWidth margin="dense">
            <FormLabel component="legend">{t('Code')}</FormLabel>
            <TextField
              size="small"
              variant="outlined"
              name="code"
              value={values.code}
              inputRef={register()}
              onChange={handleChange}
              disabled
            />
            <FormHelperText>
              {props.errors.code?.type === 'required' &&
                'Your input is required'}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.input} fullWidth margin="dense">
            <FormLabel component="legend">{t('Date')}</FormLabel>
            <TextField
              size="small"
              type="date"
              variant="outlined"
              name="date"
              value={[moment(values.date).format('YYYY-MM-DD')]}
              inputRef={props.register({ required: true })}
              onChange={handleChange}
              disabled={props.readOnly}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormHelperText>
              {props.errors.date?.type === 'required' &&
                'Your input is required'}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            size="small"
            required={true}
            // error={!!props.errors.sourceOwnerType}
          >
            <FormLabel component="legend">{t('OnwerType')}</FormLabel>

            <Controller
              name="sourceOwnerType"
              as={<Select />}
              options={ownerTypeOption}
              defaultValue={values.sourceOwnerType}
              isClearable
              control={props.control}
              onChange={(value) => {
                setValue('sourceOwner', '');
                setValue('borrowby', '');
                handleSelectChange(value);
                return value[0];
              }}
              rules={{ required: 'sourceOwnerType is Required' }}
              isDisabled={props.readOnly}
            />
            <FormHelperText error>
              {!!props.errors.sourceOwnerType && (
                <>{props.errors.sourceOwnerType.message}</>
              )}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            size="small"
            required={true}
            // error={!!props.errors.sourceOwner}
          >
            <FormLabel component="legend">{t('Owner')}</FormLabel>
            <Controller
              as={<AsyncSelect />}
              options={onwersOption ? onwersOption : []}
              name="sourceOwner"
              isClearable
              isDisabled={props.readOnly}
              control={props.control}
              rules={{ required: 'Required' }}
              defaultOptions={onwersOption}
              loadOptions={(value) => {
                if (!values.sourceOwnerType) {
                  window.alert('please select source ownertype first');
                  return null;
                }
                return ownersQuery(values.sourceOwnerType.value, value);
              }}
              placeholder={
                values.sourceOwnerType.label
                  ? 'Search by ' + values.sourceOwnerType.label
                  : 'Select...'
              }
              onChange={(value) => {
                // console.log(value);
                handleSelectChange(value);
                return value[0];
              }}
            />
            <FormHelperText error>
              {!!props.errors.sourceOwner && (
                <>{props.errors.sourceOwner.message}</>
              )}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            error={!!props.errors.remark}
          >
            <FormLabel component="legend">{t('Remark')}</FormLabel>
            <TextField
              size="small"
              variant="outlined"
              name="remark"
              value={values.remark}
              inputRef={register}
              onChange={handleChange}
              disabled={props.readOnly}
            />
            <FormHelperText>
              {props.errors.remark?.type === 'required' &&
                'Your input is required'}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default translate(AssetCheckHeader);
