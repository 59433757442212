import React from 'react';

import { Typography } from '@material-ui/core';

const Label = ({ txt }) => (
  <Typography variant="h6" component="h6">
    {txt}
  </Typography>
);

export default Label;
