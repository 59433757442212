import React from 'react';
import { translate } from 'react-switch-lang';
import MaterialTable from 'material-table';
import { jQGridService, roleService } from '../../../_services';
import { tableIcons } from './tableIcons';

import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { formType } from '../../../_constants';
import palettle from '../../../theme/palette';
const HIstoryDatatable = (props) => {
  const { t, title, columns, menuCode, code } = props;
  const user = JSON.parse(localStorage.getItem('user'));
  const [actions, setActions] = React.useState([]);
  const role = user.roleinfo; // {code, description}

  React.useEffect(() => {
    let isCancelled = false;
    roleService.getRolePermissions(role.code).then((res) => {
      if (!isCancelled) {
        var menuPermission = res.data.results.find(
          (o) => o.menuCode === menuCode,
        );
      }
    });

    return () => {
      isCancelled = true;
    };
  }, []);
  return (
    <>
      <MaterialTable
        icons={tableIcons}
        // actions={actions}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters = query.filters;
            filters = filters.map((el) => {
              console.log('el: ', el);
              return {
                title: el.column.title,
                field: el.column.field,
                operator: el.operator,
                value: el.value,
              };
            });
            console.log('filters: ', filters);
            try {
              jQGridService
                .search(
                  props.filenameXML + '.xml',
                  query.page + 1,
                  query.pageSize,
                  code,
                )
                .then((res) => {
                  if (!!res.data) {
                    resolve({
                      data: res.data.rows,
                      page: res.data.page - 1,
                      totalCount: res.data.records,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: query.page,
                      totalCount: query.totalCount,
                    });
                  }
                });
            } catch (error) {
              console.log('error: ', error);
              resolve({
                data: [],
                page: query.page,
                totalCount: query.totalCount,
              });
            }
          })
        }
        options={{
          // filtering: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: palettle.background.default,
            color: palettle.primary.main,
          },

          pageSize: 10,
          pageSizeOptions: [5, 10, 100],
        }}
        title={title}
      />
    </>
  );
};

export default translate(HIstoryDatatable);
