export const uomConstants = {
  API_INSERT: '/api/uom/insert',
  API_UPDATE: '/api/uom/update',
  API_DELETE: '/api/uom/delete',
  API_GET: '/api/uom/',
  API_GETALL: '/api/uom/getall',

  INSERT_REQUEST: 'UOM_INSERT_REQUEST',
  INSERT_SUCCESS: 'UOM_INSERT_SUCCESS',
  INSERT_FAILURE: 'UOM_INSERT_FAILURE',

  UPDATE_REQUEST: 'UOM_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UOM_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UOM_UPDATE_FAILURE',

  DELETE_REQUEST: 'UOM_DELETE_REQUEST',
  DELETE_SUCCESS: 'UOM_DELETE_SUCCESS',
  DELETE_FAILURE: 'UOM_DELETE_FAILURE',

  GET_REQUEST: 'UOM_GET_REQUEST',
  GET_SUCCESS: 'UOM_GET_SUCCESS',
  GET_FAILURE: 'UOM_GET_FAILURE',

  GETALL_REQUEST: 'UOM_GETALL_REQUEST',
  GETALL_SUCCESS: 'UOM_GETALL_SUCCESS',
  GETALL_FAILURE: 'UOM_GETALL_FAILURE',

  RESET: 'UOM_RESET',
  SERVICE: 'UOM_SERVICE',
};
