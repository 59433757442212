export default (state = [], { type, payload, closing = false }) => {
  switch (type) {
    case 'SELECTED_ITEM':
      return { ...payload, closing: closing };
    case 'CLEAR_ITEM':
      return { state: [], payload: '' };
    default:
      return state;
  }
};
