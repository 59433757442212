import { CustomerConstants as constant } from '../_constants/customer.constants';
const initialState = {
  isSubmitting: false,
  isSuccess: false,
  message: '',
  data: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SUBMITTING':
      return { ...state, isSubmitting: true, isSuccess: false, message: '' };
    case 'SUCCESS':
      return {
        ...state,
        message: payload,
        isSubmitting: false,
        isSuccess: true,
      };
    case 'FAILED':
      return {
        ...state,
        message: payload,
        isSubmitting: false,
        isSuccess: false,
      };
    case 'GET_CUSTOMER':
      return { ...state, isSubmitting: false, isSuccess: true, data: payload };
    case 'CLEAR':
      return { ...state, ...initialState };
    default:
      return state;
  }
};
