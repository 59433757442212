import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { Segment, Container, Placeholder } from 'semantic-ui-react';
import { useAlert } from 'react-alert';
import { alertActions } from '../_action/alert.action';
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
} from 'react-switch-lang';
import en from '../language/en.json';
import th from '../language/th.json';

import { Login } from '../components/login';
import { history } from '../_helpers';
import { authHeader } from '../_helpers/auth-header';
import { PrivateRoute } from '../components';

import * as ROUTES from '../routes';
import { Asset } from '../components/Asset';
import Form from '../components/Asset/Form';

import { AssetModel } from '../components/AssetModel';
import { AssetBrand } from '../components/AssetBrand';
import { AssetClass } from '../components/AssetClass';
import { AssetCondition } from '../components/AssetCondition';
import { AssetGroup } from '../components/AssetGroup';
import { AssetSupplier } from '../components/AssetSupplier';
import { BusinessArea } from '../components/BusinessArea';
import { Reason } from '../components/Reason';
import { ReasonType } from '../components/ReasonType';

import { AssetSubClass } from '../components/AssetSubClass';
import { Role } from '../components/Role';
import { Permission } from '../components/Permission';
import { User } from '../components/User';
import { CostCenter } from '../components/CostCenter';
import { Customer } from '../components/Customer';
import {
  TestReport,
  AssetBorrowReport,
  AssetMasterReport,
  AssetReturnReport,
  AssetOwnerTransferReport,
  AssetWriteOffReport,
  AssetCountReport,
} from '../components/reports/';
import Zone from '../components/Zone';
import Site from '../components/Site';
import Location from '../components/Location';
import { AssetAccountType } from '../components/AssetAccountType';
import { AssetAccount } from '../components/AssetAccount';
import {
  Stockreceipt,
  StockReceiptInsert,
  StockReceiptUpdate,
} from '../components/Invenotry/stockreceipt';
import { Stocktransfer } from '../components/Invenotry/stocktransfer';
import { Stockissue } from '../components/Invenotry/stockissue';
import { Stockadjustment } from '../components/Invenotry/stockadjustment';
import coderun from '../components/setting/coderun';
import stocktransferinsert from '../components/Invenotry/stocktransfer/stocktransferinsert/stocktransferinsert';
import stocktransferedit from '../components/Invenotry/stocktransfer/stocktransferedit/stocktransferedit';
import { Assetrequesition } from '../components/Invenotry/assetrequesition';
import {
  Assetborrow,
  AssetBorrowForm,
  assetborrowedit,
} from '../components/Invenotry/assetborrow';
import {
  Assetownerchange,
  AssetOwnerChangeForm,
} from '../components/Invenotry/assetownerchange';
import {
  AssetReturn,
  assetreturnform,
} from '../components/Invenotry/assetreturn';
import { Assetwriteoff } from '../components/Invenotry/assetwriteoff';
import { fromPairs } from 'lodash';
import test from '../pages/test';
import assetWriteoffForm from '../components/Invenotry/assetwriteoff/components/asset.writeoff.form';
import RouteWithLayout from '../components/RouteWithLayout/RouteWithLayout';
import { Main as MainLayout } from '../layouts';
import Dashboard from '../components/dashboard';
import {
  AssetCheckForm,
  AssetCheckList,
} from '../components/Invenotry/assetcheck';
import {
  AssetCheckForm as AssetcheckFormSMS,
  AssetCheckList as AssetCheckListSMS,
} from '../components/Invenotry/assetchecksms1';
import FormTab from '../components/Asset/FormTab/FormTab';
import EmailConfig from '../components/EmailConfig';
import EmailHistory from '../components/EmailHistory';
import CustomerForm from '../components/Customer/Customer.form';
// Do this two lines only when setting up the application
setTranslations({ en, th });
setDefaultLanguage('en');
// If you want to remember selected language
setLanguageCookie();

function App() {
  // const alertState = useSelector((state) => state.alert);
  const [redirect, setRedirect] = React.useState(false);
  // const dispatch = useDispatch();
  // const alert = useAlert();

  // useEffect(() => {
  // if (alertState.success) {
  //   alert.success(alertState.message);
  //   dispatch(alertActions.clear(''));
  // } else if (alertState.error) {
  //   alert.error(alertState.message);
  //   dispatch(alertActions.clear(''));
  //   // setRedirect(true);
  //   // setTimeout(() => {
  //   //   window.location.replace('/login');
  //   // }, 1000);
  // }
  // }, [alertState]);

  // useEffect(() => {
  //   console.log('loginState: ', loginState);
  // }, [loginState]);
  const useLayout = MainLayout;

  return (
    <Router history={history}>
      {redirect && <Redirect from="*" to="/login" />}
      <Switch>
        <Route path="/login" component={Login} />
        <RouteWithLayout
          exact
          component={Dashboard}
          path="/dashboard"
          layout={useLayout}
        />

        <RouteWithLayout
          exact
          component={test}
          path="/test"
          layout={useLayout}
        />
        <RouteWithLayout
          exact
          component={Customer}
          path={ROUTES.CUSTOMER_ROUTE}
          layout={useLayout}
        />
        <RouteWithLayout
          exact
          component={CustomerForm}
          path={ROUTES.CUSTOMER_ROUTE_INSERT}
          layout={useLayout}
        />
        <RouteWithLayout
          exact
          component={CustomerForm}
          path={ROUTES.CUSTOMER_ROUTE_UPDATE + '/:id'}
          layout={useLayout}
        />

        <RouteWithLayout
          exact
          path="/"
          layout={useLayout}
          component={Dashboard}
        />
        <RouteWithLayout
          exact
          path={ROUTES.ASSET_MODEL_ROOT_ROUTE}
          component={AssetModel}
          layout={useLayout}
          menuCode="M_ASSET_MODEL"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.ASSET_BRAND_ROOT_ROUTE}
          component={AssetBrand}
          layout={useLayout}
          menuCode="M_ASSET_BRAND"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.ASSET_CLASS_ROOT_ROUTE}
          component={AssetClass}
          layout={useLayout}
          menuCode="M_ASSET_CLASS"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.ASSET_CONDITION_ROOT_ROUTE}
          component={AssetCondition}
          layout={useLayout}
          menuCode="M_ASSET_CON"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.ASSET_GROUP_ROOT_ROUTE}
          component={AssetGroup}
          layout={useLayout}
          menuCode="M_ASSET_GRP"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.ASSET_SUBCLASS_ROOT_ROUTE}
          component={AssetSubClass}
          layout={useLayout}
          menuCode="M_ASSET_SUBCLS"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.BUSINESS_AREA_ROOT_ROUTE}
          component={BusinessArea}
          layout={useLayout}
          menuCode="M_BUSI_AREA"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.REASON_ROOT_ROUTE}
          component={Reason}
          layout={useLayout}
          menuCode="M_REASON"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.REASONTYPE_ROOT_ROUTE}
          component={ReasonType}
          layout={useLayout}
          menuCode="M_REASON_TYPE"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.ASSET_SUPPLIER_ROOT_ROUTE}
          component={AssetSupplier}
          layout={useLayout}
          menuCode="M_ASSET_SUPP"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.ZONE_ROOT_ROUTE}
          component={Zone}
          layout={useLayout}
          menuCode="M_ZONE"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.SITE_ROOT_ROUTE}
          component={Site}
          layout={useLayout}
          menuCode="M_SITE"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.LOCATION_ROOT_ROUTE}
          component={Location}
          layout={useLayout}
          menuCode="M_LOCATION"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.ASSET_TYPE_ROUTE}
          component={AssetAccountType}
          layout={useLayout}
          menuCode="M_ASSET_TYPE"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.ASSET_ACCOUNT_ROUTE}
          component={AssetAccount}
          layout={useLayout}
          menuCode="M_ASSET_ACCOUNT"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.COST_CENTER_ROUTE}
          component={CostCenter}
          layout={useLayout}
          menuCode="M_COSTCENTER"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.ASSET_ROOT_ROUTE}
          component={Asset}
          layout={useLayout}
          menuCode="M_ASSET"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.ASSET_ROOT_ROUTE + '/insert'}
          component={FormTab}
          layout={useLayout}
          menuCode="M_ASSET"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.ASSET_ROOT_ROUTE + '/view'}
          component={FormTab}
          layout={useLayout}
          menuCode="M_ASSET"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.ASSET_ROOT_ROUTE + '/update'}
          component={FormTab}
          layout={useLayout}
          menuCode="M_ASSET"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.ROLE_ROOT_ROUTE}
          component={Role}
          layout={useLayout}
          menuCode="M_USER_ROLES"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.PERMISSION_ROOT_ROUTE}
          component={Permission}
          layout={useLayout}
          menuCode="M_USER_PERMISSION"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.USER_ROOT_ROUTE}
          component={User}
          layout={useLayout}
          menuCode="M_USER"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_STOCK_RECEIPT}
          component={Stockreceipt}
          layout={useLayout}
          menuCode="M_ASSET_RECEIPT"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_STOCK_RECEIPT_INSERT}
          component={StockReceiptInsert}
          layout={useLayout}
          menuCode="M_ASSET_RECEIPT"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_STOCK_RECEIPT_UPDATE + '/:id'}
          component={StockReceiptUpdate}
          layout={useLayout}
          menuCode="M_ASSET_RECEIPT"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_STOCK_ADJUSTMENT}
          component={Stockadjustment}
          layout={useLayout}
          menuCode="M_"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_STOCK_ADJUSTMENT_INSERT}
          component={StockReceiptInsert}
          layout={useLayout}
          menuCode="M_"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_STOCK_ADJUSTMENT_UPDATE + '/:id'}
          component={StockReceiptUpdate}
          layout={useLayout}
          menuCode="M_"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_STOCK_ISSUE}
          component={Stockissue}
          layout={useLayout}
          menuCode="M_ASSET_ISSUE"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_STOCK_TRANSFER}
          component={Stocktransfer}
          layout={useLayout}
          menuCode="M_ASSET_TRANSFER"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_STOCK_TRANSFER_INSERT}
          component={stocktransferinsert}
          layout={useLayout}
          menuCode="M_ASSET_TRANSFER"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_STOCK_TRANSFER_UPDATE + '/:id'}
          component={stocktransferedit}
          layout={useLayout}
          menuCode="M_ASSET_TRANSFER"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_REQUESITION}
          component={Assetrequesition}
          layout={useLayout}
          menuCode="M_"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_BORROW}
          component={Assetborrow}
          layout={useLayout}
          menuCode="M_ASSET_BORROW"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_BORROW_INSERT}
          component={AssetBorrowForm}
          layout={useLayout}
          menuCode="M_ASSET_BORROW"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_BORROW_UPDATE + '/:id'}
          component={AssetBorrowForm}
          layout={useLayout}
          menuCode="M_ASSET_BORROW"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_RETURN}
          component={AssetReturn}
          layout={useLayout}
          menuCode="M_ASSET_RETURN"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_RETURN_INSERT}
          component={assetreturnform}
          layout={useLayout}
          menuCode="M_ASSET_RETURN"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_RETURN_UPDATE + '/:id'}
          component={assetreturnform}
          layout={useLayout}
          menuCode="M_ASSET_RETURN"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_OWNER_TRANSFER}
          component={Assetownerchange}
          layout={useLayout}
          menuCode="M_ASSET_OWNERTRANSFER"
        />
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_OWNER_TRANSFER_INSERT}
          component={AssetOwnerChangeForm}
          layout={useLayout}
          menuCode="M_ASSET_OWNERTRANSFER"
        />
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_OWNER_TRANSFER_UPDATE + '/:id'}
          component={AssetOwnerChangeForm}
          layout={useLayout}
          menuCode="M_ASSET_OWNERTRANSFER"
        />

        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_WRITEOFF}
          component={Assetwriteoff}
          layout={useLayout}
          menuCode="M_ASSET_WRITEOFF"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_OWRITEOFF_INSERT}
          component={assetWriteoffForm}
          layout={useLayout}
          menuCode="M_ASSET_WRITEOFF"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_WRITEOFF_UPDATE + '/:id'}
          component={assetWriteoffForm}
          layout={useLayout}
          menuCode="M_ASSET_WRITEOFF"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_ASSET_CHECK}
          component={AssetCheckList}
          layout={useLayout}
          menuCode="M_ASSET_CHECK"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_ASSET_CHECK_INSERT}
          component={AssetCheckForm}
          layout={useLayout}
          menuCode="M_ASSET_CHECK"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_ASSET_CHECK_UPDATE + '/:id'}
          component={AssetCheckForm}
          menuCode="M_ASSET_CHECK"
          layout={useLayout}
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_ASSET_CHECK_SMS}
          component={AssetCheckListSMS}
          layout={useLayout}
          menuCode="M_ASSET_CHECK_SMS1"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.INVENTORY_ASSET_CHECK_UPDATE_SMS + '/:id'}
          component={AssetcheckFormSMS}
          menuCode="M_ASSET_CHECK_SMS1"
          layout={useLayout}
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.CODERUN}
          component={coderun}
          layout={useLayout}
          menuCode="M_"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.TEST_REPORT}
          component={TestReport}
          layout={useLayout}
          menuCode="M_"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.ASSET_MASTER_REPORT}
          component={AssetMasterReport}
          layout={useLayout}
          menuCode="M_"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.ASST_TRANSFER_REPORT}
          component={AssetOwnerTransferReport}
          layout={useLayout}
          menuCode="M_ASSET_OWNERTRANSFER_RP"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.ASSET_BORROW_REPORT}
          component={AssetBorrowReport}
          layout={useLayout}
          menuCode="M_"
        ></RouteWithLayout>

        <RouteWithLayout
          exact
          path={ROUTES.ASSET_RETURN_REPORT}
          component={AssetReturnReport}
          layout={useLayout}
          menuCode="M_"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.ASSET_COUNT_REPORT}
          component={AssetCountReport}
          layout={useLayout}
          menuCode="M_"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.EMAIL_CONFIG}
          component={EmailConfig}
          layout={useLayout}
          menuCode="M_EMAIL_CONFIG"
        ></RouteWithLayout>
        <RouteWithLayout
          exact
          path={ROUTES.EMAIL_HISTORY}
          component={EmailHistory}
          layout={useLayout}
          menuCode="M_EMAIL_HISTORY"
        ></RouteWithLayout>
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
}

export { App };
