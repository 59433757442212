export const assetAccountConstants = {
  API_INSERT: '/api/accountGFT/insert',
  API_UPDATE: '/api/accountGFT/update',
  API_DELETE: '/api/accountGFT/delete',
  API_GET: '/api/accountGFT/',
  API_GETALL: '/api/accountGFT/getall',

  INSERT_REQUEST: 'ASSET_ACCOUNT_INSERT_REQUEST',
  INSERT_SUCCESS: 'ASSET_ACCOUNT_INSERT_SUCCESS',
  INSERT_FAILURE: 'ASSET_ACCOUNT_INSERT_FAILURE',

  UPDATE_REQUEST: 'ASSET_ACCOUNT_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ASSET_ACCOUNT_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ASSET_ACCOUNT_UPDATE_FAILURE',

  DELETE_REQUEST: 'ASSET_ACCOUNT_DELETE_REQUEST',
  DELETE_SUCCESS: 'ASSET_ACCOUNT_DELETE_SUCCESS',
  DELETE_FAILURE: 'ASSET_ACCOUNT_DELETE_FAILURE',

  GET_REQUEST: 'ASSET_ACCOUNT_GET_REQUEST',
  GET_SUCCESS: 'ASSET_ACCOUNT_GET_SUCCESS',
  GET_FAILURE: 'ASSET_ACCOUNT_GET_FAILURE',

  GETALL_REQUEST: 'ASSET_ACCOUNT_GETALL_REQUEST',
  GETALL_SUCCESS: 'ASSET_ACCOUNT_GETALL_SUCCESS',
  GETALL_FAILURE: 'ASSET_ACCOUNT_GETALL_FAILURE',

  RESET: 'ASSET_ACCOUNT_RESET',
  SERVICE: 'ASSET_ACCOUNT_SERVICE',
};
