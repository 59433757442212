import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HistoryIcon from '@material-ui/icons/History';
import { AppBar } from '@material-ui/core';
import Form from '../Form';
// import Container from '@material-ui/core/Container';
import History from '../History';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // maxWidth: 500,
    backgroundColor: 'transparent',
  },
  tab: {
    backgroundColor: 'transparent',
  },
  container: {
    padding: theme.spacing(3),
    margin: theme.spacing(1),
  },
  appBar: {
    boxShadow: 'none',
  },
}));
export default function FormTab(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [history, setHistory] = React.useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    var state = props.location.state ? props.location.state : '';
    if (state.form === 'insert_form') {
      setHistory(false);
    } else setHistory(true);
  }, []);
  return (
    <>
      <Paper square className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            className={classes.tabs}
            aria-label="icon label tabs example"
          >
            <Tab icon={<AssignmentIcon />} selected label="Form" />
            {history && (
              <Tab
                icon={<HistoryIcon />}
                className={classes.tabs}
                label="History"
              />
            )}
          </Tabs>
        </AppBar>

        <div className={classes.container}>
          {value === 0 && <Form {...props} />}
          {value === 1 && <History {...props} />}
        </div>
      </Paper>
    </>
  );
}
