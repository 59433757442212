import React from 'react';


import {
    Card,
    Image,
    Icon,
} from 'semantic-ui-react';

export const ImageCard = ({ name, src, index }) => {


    return <>
        <Card raised>
            {/* <Image src={src} wrapped ui={false} size='mini' style={{ width: '100', height: '100 !important' }}

            /> */}
            <img src={src} height={200} />

            <Card.Content>
                {/* <Card.Header><Icon name='image' />no. {index + 1}</Card.Header> */}
                <Card.Meta>
                    <span>no. {index + 1}</span>
                </Card.Meta>
                <Card.Description>
                    {name}
                </Card.Description>
            </Card.Content>
            {/* <Card.Content extra>
                <Icon name='pin' /> {index + 1}
            </Card.Content> */}
        </Card>
    </>
}
