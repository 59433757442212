import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';
import MaterialTable, { MTableToolbar } from 'material-table';
import { jQGridService, roleService, assetService } from '../../../_services';
import { tableIcons } from './tableIcons';

import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import { formType } from '../../../_constants';
import palettle from '../../../theme/palette';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CloudUpload, Sync } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImportAlertModal from '../../_modal/ImportAlertModal/ImportAlertModal';
import useDebounce from '../../../hooks/useDebounce';

const StdDatatable = ({
  t,
  title,
  columns,
  setSelected,
  setFormType,
  menuCode,
  filenameXML,
}) => {
  const initAlert = {
    open: false,
    type: 'success',
    message: 'Init Massage',
  };
  const tableRef = React.createRef();
  const user = JSON.parse(localStorage.getItem('user'));
  const [actions, setActions] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState(initAlert);
  const [errorMessage, setErrorMessage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);
  const role = user.roleinfo; // {code, description}
  var addAction = {
    icon: 'add',
    tooltip: t('add'),
    isFreeAction: true,
    onClick: (e) => {
      setFormType(formType.INSERT);
    },
  };
  var viewAction = {
    icon: () => <VisibilityIcon />,
    tooltip: t('view'),
    onClick: (e, rowData) => {
      e.preventDefault();
      setSelected(rowData.Code);
      setFormType(formType.VIEW);
    },
  };
  var editAction = {
    icon: () => <EditIcon />,
    tooltip: t('edit'),
    onClick: (e, rowData) => {
      setSelected(rowData.Code);
      setFormType(formType.UPDATE);
    },
  };
  var deleteAction = {
    icon: () => <DeleteIcon />,
    tooltip: t('delete'),
    onClick: (e, rowData) => {
      e.preventDefault();
      const confirm = window.confirm(
        `you want to delete this document no. ${rowData.Code} ?`,
      );
      if (confirm) {
        setSelected(rowData.Code);
        setFormType(formType.DELETE);
      }
    },
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification({ open: false });
  };

  const onChangeInput = async (event) => {
    /* https://www.geeksforgeeks.org/file-uploading-in-react-js/ 
      https://bezkoder.com/react-hooks-file-upload/
      */

    setIsLoading(true);
    console.log('event => ', event);
    //CALL API:
    try {
      const result = await assetService.uploadAssetFile(event.target.files[0]);
      console.log('result: => ', result);
      if (result.status == 200) {
        setIsLoading(false);
        console.log('result: => ', result);
        setNotification({
          open: true,
          type: 'success',
          message: `Asset Master - Imported total: ${result.data.total} records`,
        });
        setImportSuccess(true);
      } else {
        setErrorMessage(result);
        setOpenModal(true);
        console.log('result-error: => ', result.data);
        setIsLoading(false);
        setNotification({
          open: true,
          type: 'error',
          message: `ERROR: ${
            result.status
          } - Asset master can't import to database :${'\n'} ${JSON.stringify(
            result.data?.rowcount,
          )} rows`,
        });
      }
    } catch (error) {
      console.log('catching... => ', error);
      setNotification({
        open: true,
        type: 'error',
        message: `Error: S${error}`,
      });
      setIsLoading(false);
    }
  };

  const syncToSmartMS = async (event) => {
    setIsLoading(true);
    //CALL API:
    try {
      const result = await assetService.syncToSmartMS();
      console.log('result: => ', result);
      if (result.status == 200) {
        setIsLoading(false);
        setNotification({
          open: true,
          type: 'success',
          message: `Sync to SmartSales total: ${result.data} records`,
        });
      }
    } catch (error) {
      console.log('catching... => ', error);
      setNotification({
        open: true,
        type: 'error',
        message: `Error: S${error.message}`,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      roleService.getRolePermissions(role.code).then((res) => {
        if (!isCancelled) {
          var menuPermission = res.data.results.find(
            (o) => o.menuCode === menuCode,
          );
          var actionList = [];
          if (menuPermission.new_permission) {
            actionList.push(addAction);
          }
          if (menuPermission.edit_permission) {
            actionList.push(editAction);
          }
          if (menuPermission.view_permission) {
            actionList.push(viewAction);
          }
          if (menuPermission.delete_permission) {
            actionList.push(deleteAction);
          }
          setActions(actionList);
        }
      });
    }

    return () => {
      isCancelled = true;
    };
  }, []);
  useEffect(() => {
    if (importSuccess === true) {
      console.log('refresh table');
      tableRef.current.onQueryChange();
      setImportSuccess(false);
    }
    return () => {};
  }, [importSuccess]);
  return (
    <>
      <MaterialTable
        icons={tableIcons}
        tableRef={tableRef}
        actions={actions}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters = query.filters;
            console.log('query: ', query.search);
            filters = filters.map((el) => {
              return {
                title: el.column.title,
                field: el.column.field,
                operator: el.operator,
                value: el.value,
              };
            });
            try {
              jQGridService
                .search(
                  filenameXML + '.xml',
                  query.page + 1,
                  query.pageSize,
                  query.search,
                )
                .then((res) => {
                  console.log(res);
                  if (!!res.data) {
                    resolve({
                      data: res.data.rows,
                      page: res.data.page - 1,
                      totalCount: res.data.records,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: query.page,
                      totalCount: query.totalCount,
                    });
                  }
                });
            } catch (error) {
              console.error('error: ', error);
              resolve({
                data: [],
                page: query.page,
                totalCount: query.totalCount,
              });
            }
          })
        }
        options={{
          // filtering: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: palettle.background.default,
            color: palettle.primary.main,
          },
          // debounceInterval: 500,
          pageSize: 10,
          pageSizeOptions: [5, 10, 100, 1000],
        }}
        title={<h1>{title}</h1>}
        components={{
          Toolbar: (props) => (
            //
            <div>
              <MTableToolbar {...props} />

              <div style={{ padding: 10, textAlign: 'end' }}>
                {isLoading && <CircularProgress size="1.5rem" />}
                {/* Button : Sync */}
                <Button
                  color="secondary"
                  variant="outlined"
                  startIcon={<Sync />}
                  component="span"
                  disabled={isLoading}
                  style={{ marginRight: 10, marginLeft: 10 }}
                  onClick={() => {
                    syncToSmartMS();
                  }}
                >
                  sync SMSales
                </Button>
                {/* Button : Import(.xlsx) */}
                <input
                  accept=".xlsx"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  onChange={onChangeInput}
                  multiple
                  type="file"
                />
                <label htmlFor="contained-button-file">
                  {/* {isLoading && <CircularProgress size="1.5rem" />} */}
                  <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<CloudUpload />}
                    component="span"
                    disabled={isLoading}
                  >
                    Import(.xlsx)
                  </Button>
                </label>
                <div>
                  <ImportAlertModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    errorMessage={errorMessage}
                  />
                  {/* {errorMessage && ( <Alert severity="error"> */}
                  {/* {errorMessage.data.error_data.map((item, index) => {
                        var msgs = item.errors;
                        return (
                          <>
                            <ul>
                              <li key={index}>
                                <h4
                                  style={{ color: 'white' }}
                                >{`code: ${item.code}`}</h4>
                              </li>
                              {msgs.map((err, idx) => {
                                const [key, value] = Object.entries(err)[0];
                                return (
                                  <li key={`${index}-${idx}`}>
                                    {`${key} : ${value}`}
                                  </li>
                                );
                              })}
                            </ul>
                            <hr style={{ borderColor: 'white' }}></hr>
                          </>
                        );
                      })} */}
                  {/* <ul style={{ textAlign: 'left' }}>
                        {errorMessage.data.error_data.map((item, index) => {
                          return (
                            <li
                              key={index}
                            >{`code: ${item.code} | error: ${item.errors}`}</li>
                          );
                        })}
                      </ul> */}
                  {/* </Alert> */}
                  {/* )} */}
                </div>
              </div>
            </div>
          ),
        }}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={notification.open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={notification.type}>
          <h3 style={{ color: 'white' }}>{notification.message}</h3>
        </Alert>
      </Snackbar>
    </>
  );
};

export default translate(StdDatatable);
