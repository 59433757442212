import React from 'react';
import { translate } from 'react-switch-lang';
import TransactionDatatable from '../../../_fragments/transactiondatatable/TransactionDatatableImport';
import { OwnerChangeServices as service } from './services/asset.ownerchange.service';
import { Label } from 'semantic-ui-react';
import { extractOwnerChangeDate } from '../../../_helpers/time';

const Assetownerchange = (props) => {
  const { t } = props;
  const header = {
    icon: 'cubes',
    content: 'Asset Owner Transfer',
    subHeader: 'Listing',
  };

  const insertBtn = {
    text: t('Add'),
    link: props.location.pathname + '/insert',
  };

  const actionPath = {
    add: `${props.location.pathname}/insert`,
    edit: `${props.location.pathname}/update`,
    delete: `${props.location.pathname}/delete`,
  };

  const headers = [
    { title: 'Code', field: 'Code' },
    {
      title: 'Date',
      field: 'Date',
      render: (rowData) => {
        const newDate = extractOwnerChangeDate(rowData.Date);
        return newDate;
      },
    },
    { title: 'SourceOwnerName', field: 'SourceOwnerName' },
    { title: 'DestinationOwnerName', field: 'DestinationOwnerName' },
    {
      title: 'Status',
      field: 'Status',
      render: (rowData) => (
        <Label color={rowData.Status == 'APPROVE' ? 'green' : 'grey'}>
          {rowData.Status}
        </Label>
      ),
    },
    { title: 'Create By', field: 'CreateBy' },
  ];

  const title = 'Asset Owner Change';
  const filenameXML = 'Asset-Owner-Change';
  const menuCode = 'M_ASSET_OWNERTRANSFER';
  return (
    <>
      <TransactionDatatable
        header={header}
        insertBtn={insertBtn}
        title={title}
        headers={headers}
        filenameXML={filenameXML}
        root={props.root}
        history={props.history}
        path={actionPath}
        service={service}
        menuCode={menuCode}
        disableOnApprove={true}
      />
    </>
  );
};

export default translate(Assetownerchange);
