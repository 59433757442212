import { assetBrandService } from '../assetBrand.service';
import { assetClassService } from '../assetClass.service';
import { assetConditionService } from '../assetCondition.service';
import { assetGroupService } from '../assetGroup.service';
import { assetModelService } from '../assetModel.service';
import { assetSupplierService } from '../assetSupplier.service';
import { assetSubClassService } from '../assetSubClass.service';
import { branchService } from '../branch.service';
import { businessAreaService } from '../businessArea.service';
import { companyService } from '../company.service';
import { locationService } from '../location.service';
import { ownerService } from '../owner.service';
import { siteService } from '../site.service';
import { userService } from '../user.service';
import { zoneService } from '../zone.service';
import { uomService } from '../uom.service';
import { costCenterService } from '../costCenter.service';
import { roleService } from '../role.service';
import { permissionService } from '../permission.service';

export const getRefs = async (obj) => {
  let new_obj = obj;

  let brand;
  if (obj.brand != '' && !!obj.brand) {
    brand = await assetBrandService.getItem(obj.brand);
    Promise.all([brand]).then((res) => {
      new_obj.brand = res[0].data.info;
    });
  }
  let clss;
  if (obj.clss != '' && !!obj.clss) {
    clss = await assetClassService.getItem(obj.clss);
    Promise.all([clss]).then((res) => {
      new_obj.clss = res[0].data.info;
    });
  }
  let subClass;
  if (obj.subClass != '' && !!obj.subClass) {
    subClass = await assetSubClassService.getItem(obj.subClass);
    Promise.all([subClass]).then((res) => {
      new_obj.subClass = res[0].data.info;
    });
  }
  let group;
  if (obj.group != '' && !!obj.group) {
    group = await assetGroupService.getItem(obj.group);
    Promise.all([group]).then((res) => {
      new_obj.group = res[0].data.info;
    });
  }
  let model;
  if (obj.model != '' && !!obj.model) {
    model = await assetModelService.getItem(obj.model);
    Promise.all([model]).then((res) => {
      new_obj.model = res[0].data.info;
    });
  }
  let supplier;
  if (obj.supplier != '' && !!obj.supplier) {
    supplier = await assetSupplierService.getItem(obj.supplier);
    Promise.all([supplier]).then((res) => {
      new_obj.supplier = res[0].data.info;
    });
  }
  let cost;
  if (obj.cost != '' && !!obj.cost) {
    cost = await costCenterService.getItem(obj.cost);
    Promise.all([cost]).then((res) => {
      new_obj.cost = res[0].data.info;
    });
  }
  let condition;
  if (obj.condition != '' && !!obj.condition) {
    condition = await assetConditionService.getItem(obj.condition);
    Promise.all([condition]).then((res) => {
      new_obj.condition = res[0].data.info;
    });
  }
  let businessArea;
  if (obj.businessArea != '' && !!obj.businessArea) {
    businessArea = await businessAreaService.getItem(obj.businessArea);
    Promise.all([businessArea]).then((res) => {
      new_obj.businessArea = res[0].data.info;
    });
  }

  let location;
  if (obj.location != '' && !!obj.location) {
    location = await locationService.getItem(obj.location);
    Promise.all([location]).then((res) => {
      new_obj.location = res[0].data.info;
    });
  }
  let zone;
  if (obj.zone != '' && !!obj.zone) {
    zone = await zoneService.getItem(obj.zone);
    Promise.all([zone]).then((res) => {
      new_obj.zone = res[0].data.info;
    });
  }
  //uoms
  let unit;
  if (obj.unit != '' && !!obj.unit) {
    unit = await uomService.getItem(obj.unit);
    Promise.all([unit]).then((res) => {
      new_obj.unit = res[0].data.info;
    });
  }

  let dimensionUOM;
  if (obj.dimensionUOM != '' && !!obj.dimensionUOM) {
    dimensionUOM = await uomService.getItem(obj.dimensionUOM);
    Promise.all([dimensionUOM]).then((res) => {
      new_obj.dimensionUOM = res[0].data.info;
    });
  }

  let weightUOM;
  if (obj.weightUOM != '' && !!obj.weightUOM) {
    weightUOM = await uomService.getItem(obj.weightUOM);
    Promise.all([weightUOM]).then((res) => {
      new_obj.weightUOM = res[0].data.info;
    });
  }

  let warrantyUnit;
  if (obj.periodUOM != '' && !!obj.periodUOM) {
    warrantyUnit = await uomService.getItem(obj.periodUOM);
    Promise.all([warrantyUnit]).then((res) => {
      new_obj.periodUOM = res[0].data.info;
    });
  }
  let licenseUnit;
  if (obj.periodUOMl != '' && !!obj.periodUOMl) {
    licenseUnit = await uomService.getItem(obj.periodUOMl);
    Promise.all([licenseUnit]).then((res) => {
      new_obj.periodUOMl = res[0].data.info;
    });
  }
  return new_obj;
};

export const getWarrantyPeriodUOM = async (obj) => {
  let WarrantyUnit;
  let periodUOM;

  console.log('warranty obj: ', obj);
  if (obj.WarrantyUnit != '' && !!obj.WarrantyUnit) {
    WarrantyUnit = await uomService.getItem(obj.WarrantyUnit);
    Promise.all([WarrantyUnit]).then((res) => {
      console.log('res warranty: ', res[0].data.info);
      periodUOM = res[0].data.info;
    });
  }
  console.log('periodUOM: ', periodUOM);
  return periodUOM;
};
