import React, { useState } from 'react';
import { translate } from 'react-switch-lang';
import { Typography } from '@material-ui/core';
import {
  Divider,
  Header,
  Icon,
  Grid,
  Button,
  Image,
  Table,
  Label,
} from 'semantic-ui-react';

import {
  InputText,
  InputNumber,
} from '../../../_fragments/formField/controllerFieldMUI';

const _InventoryAdditem = (props) => {
  const { t, control, errors } = props;
  const [items, setItems] = useState([]);
  const [adding, setAdding] = useState(false);

  const addItem = async () => {
    setAdding(true);
    setTimeout(() => {
      setItems([
        ...items,
        {
          inx: items.length,
          no: items.length + 1,
          image: 'image' + items.length,
          code: 'code' + items.length,
          description: 'description' + items.length,
          qty: 0 + items.length,
          unit: 'unit' + items.length,
          serialized: 'serialized' + items.length,
          sn: 'sn' + items.length,
        },
      ]);
      setAdding(false);
      console.log('Items: ', items);
    }, 500);
  };

  const onSubmitAddItem = async (e) => {
    e.preventDefault();
    await addItem();
  };

  const deleteItem = (inx, e) => {
    e.preventDefault();
    items.splice(inx, 1);
    setItems([...items]);
    console.log('Deleted: ', inx);
  };
  //console.log('add_item props: ', props.readOnly);
  return (
    <>
      <Header dividing>
        <Typography variant="h6" color="textPrimary">
          <Icon name="add" />
          {t('_additem')}
        </Typography>
      </Header>
      {!props.readOnly && (
        <Grid padded="horizontally">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={6} computer={3}>
              <InputText
                name="_code"
                control={props.control}
                label="_code"
                placeholder="_code"
                error={
                  !!props.errors.documentnumber && {
                    content: props.errors.documentnumber.message,
                  }
                }
                defaultValue=""
                content={props.errors.content}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={6} computer={4}>
              <InputText
                name="_assetname"
                control={props.control}
                label="_assetname"
                placeholder="_assetname"
                error={
                  !!props.errors.documentnumber && {
                    content: props.errors.documentnumber.message,
                  }
                }
                defaultValue=""
                content={props.errors.content}
              />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={6} computer={2}>
              <InputNumber
                name="_qty"
                control={control}
                label="_qty"
                placeholder="_qty"
                error={
                  !!props.errors.documentnumber && {
                    content: props.errors.documentnumber.message,
                  }
                }
                defaultValue=""
                content={props.errors.content}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={6} computer={2}>
              <InputText
                name="_unit"
                control={props.control}
                label="_unit"
                placeholder="_unit"
                error={
                  !!props.errors.documentnumber && {
                    content: props.errors.documentnumber.message,
                  }
                }
                defaultValue=""
                content={props.errors.content}
              />
            </Grid.Column>

            <Grid.Column
              verticalAlign="bottom"
              mobile={16}
              tablet={16}
              computer={3}
            ></Grid.Column>
            <Grid.Column
              textAlign="right"
              verticalAlign="bottom"
              mobile={16}
              tablet={16}
              computer={2}
            >
              <Button
                loading={adding}
                disabled={props.readOnly}
                onClick={(e) => {
                  onSubmitAddItem(e);
                }}
                color="blue"
                fluid
                content="Add Item"
                icon="add"
                labelPosition="left"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      <Table key="list" striped basic fixed singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>#No</Table.HeaderCell>
            <Table.HeaderCell width={1}>Image</Table.HeaderCell>
            <Table.HeaderCell>Code</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Qty</Table.HeaderCell>
            <Table.HeaderCell>Unit</Table.HeaderCell>
            <Table.HeaderCell>Serialized</Table.HeaderCell>
            <Table.HeaderCell>S/N</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items &&
            items.map((item) => (
              <Table.Row key={items.indexOf(item)}>
                <Table.Cell>
                  <Label>{items.indexOf(item) + 1}</Label>
                </Table.Cell>
                <Table.Cell>
                  <Image
                    src="https://react.semantic-ui.com/images/avatar/small/lena.png"
                    rounded
                    size="mini"
                  />
                </Table.Cell>
                <Table.Cell>{item.code}</Table.Cell>
                <Table.Cell>{item.description}</Table.Cell>
                <Table.Cell>{item.qty}</Table.Cell>
                <Table.Cell>{item.unit}</Table.Cell>
                <Table.Cell>{item.serialized}</Table.Cell>
                <Table.Cell>{item.sn}</Table.Cell>
                <Table.Cell>
                  <Button
                    color="grey"
                    size="mini"
                    // onClick={(e) => {
                    //   deleteItem(items.indexOf(item), e);
                    // }}
                    animated="vertical"
                  >
                    <Button.Content hidden>edit</Button.Content>
                    <Button.Content visible>
                      <Icon name="edit" />
                    </Button.Content>
                  </Button>

                  <Button
                    size="mini"
                    color="google plus"
                    onClick={(e) => {
                      deleteItem(items.indexOf(item), e);
                    }}
                    animated="vertical"
                  >
                    <Button.Content hidden>Delete</Button.Content>
                    <Button.Content visible>
                      <Icon name="delete" />
                    </Button.Content>
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Divider />
    </>
  );
};
export default translate(_InventoryAdditem);
