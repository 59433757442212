import React from 'react';
import { translate } from 'react-switch-lang';
import { InputSelect } from '../../../../_fragments/formField/controllerFieldMUI';
import { assetSupplierConstants as constants } from '../../../../_constants/assetSupplier.constants';
const Manufacturing = (props) => {
  const { t, errors, control, readOnly } = props;

  return (
    <>

      <InputSelect
        name="AssetSupplier"
        control={control}
        label="supplier"
        placeholder="supplier"
        error={
          !!errors.AssetSupplier && {
            content: errors.AssetSupplier.message,
          }
        }
        service={constants.SERVICE}
        readOnly={readOnly}
      />
    </>
  );
};

export default translate(Manufacturing);
