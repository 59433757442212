import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));
export const SaveButton = (props) => {
  const { name, onClick, register } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      <Button
        variant="contained"
        size="large"
        className={classes.button}
        startIcon={<SaveIcon />}
        color="primary"
        onClick={onClick}
        register={register}
        name={name}
      >
        Save
      </Button>
      <Button
        name={name}
        size="large"
        color="default"
        variant="contained"
        startIcon={<ArrowBackIcon />}
        register={register}
        onClick={(e) => {
          e.preventDefault();
          props.history.goBack();
        }}
      >
        Back
      </Button>
    </React.Fragment>
  );
};

export const ClearButton = (props) => {
  const { name } = props;
  return (
    <>
      {/* <Button
        icon
        size="small"
        labelPosition="left"
        color="grey"
        name={name}
        onClick={(e) => {
          e.preventDefault();
          props.history.goBack();
        }}
      >
        <Icon name="arrow left" />
        Back
      </Button> */}
    </>
  );
};
