import React, { useState } from 'react';
import { translate } from 'react-switch-lang';

import StdSubtitle from '../../../../_fragments/Title/StdSubtitle';

import BusinessAreaInput from '../../../../_fragments/locationForm/_businessArea';
import SiteInput from '../../../../_fragments/locationForm/_site';
import LocationInput from '../../../../_fragments/locationForm/_location';
import ZoneInput from '../../../../_fragments/locationForm/_zone';

const Location = (props) => {
  const { t, errors, control, readOnly } = props;
  const [site, setSite] = useState('');
  const [location, setLocation] = useState('');
  return (
    <>
      {/* <StdSubtitle content={t('location')} color='blue' /> */}

      <BusinessAreaInput
        name="BusinessArea"
        control={control}
        label="businessArea"
        placeholder="businessArea"
        error={
          !!errors.BusinessArea && {
            content: errors.BusinessArea.message,
          }
        }
        readOnly={readOnly}
        required
      />
      <SiteInput
        name="Site"
        control={control}
        label="site"
        placeholder="site"
        error={
          !!errors.Site && {
            content: errors.Site.message,
          }
        }
        // *******************************************
        onChange={([data, _]) => {
          if (!!data) setSite(data.value);
          else {
            setSite('');
            setLocation('');
          }
          return data;
        }}
        // ********************
        readOnly={readOnly}
        required
      />
      <LocationInput
        key={site || 'Site'}
        name="Location"
        control={control}
        label="location"
        placeholder="location"
        error={
          !!errors.Location && {
            content: errors.Location.message,
          }
        }
        // *******************************************
        onChange={([data, _]) => {
          if (!!data) setLocation(data.value);
          else setLocation('');
          return data;
        }}
        filter={(el) => {
          return el.site === site;
        }}
        // // ********************
        readOnly={readOnly}
        required
      />
      <ZoneInput
        key={location || 'Location'}
        name="Zone"
        control={control}
        label="zone"
        placeholder="zone"
        error={
          !!errors.Zone && {
            content: errors.Zone.message,
          }
        }
        // *******************************************
        filter={(el) => {
          return el.location === location;
        }}
        // // ********************
        readOnly={readOnly}
        required
      />
    </>
  );
};

export default translate(Location);
