import React, { useState, useEffect } from 'react';

import { userAction } from '../../_action/user.action';
import { useSelector, useDispatch } from 'react-redux';

import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

function Copyright() {
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="http//www.verismart.co.th">
          Verismart Technologies Co., Ltd.
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        {/* {process.env.REACT_APP_VERSION} */}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {process.env.REACT_APP_NAME} {' | '} {process.env.NODE_ENV}
        {' | '}
        {process.env.REACT_APP_VERSION}
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    //backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundImage: 'url(/imgs/login_image.jpg)',
    //backgroundColor: theme.palette.primary.main,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login() {
  const classes = useStyles();
  require('dotenv').config();
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const { username, password } = inputs;
  const loggingIn = useSelector((state) => state.authentication.loggingIn);
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();
  const alert = useSelector((state) => state.alert);
  const [redirect, setRedirect] = useState(false);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const submit = async (data) => {
    // console.log('inputs: ', inputs);
    // if (inputs.username && inputs.password) {
    //   dispatch(userAction.login(username, password));
    // }

    setSubmitted(true);
    // setRedirect(true);
    if (data.username && data.password) {
      dispatch(userAction.login(data.username, data.password));
    }
  };
  return (
    <div>
      {/* {redirect && <Redirect to="/" />} */}
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            {/* <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar> */}
            <img height={170} alt="Logo" src="/imgs/login_logo.png" />
            {/* <Typography component="h1" variant="h5">
              Fixed Asset Management
            </Typography> */}
            <form
              className={classes.form}
              name="form"
              onSubmit={handleSubmit(submit)}
            >
              {alert.message && (
                <div className="alert alert-default-danger">
                  {alert.message}
                </div>
              )}

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="username"
                label="User name"
                //value={username}
                onChange={handleChange}
                name="username"
                autoComplete="username"
                autoFocus
                inputRef={register({ required: true })}
              />
              {errors.username && (
                <div>
                  <span className="alert-danger">Username is required</span>
                </div>
              )}
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                //value={password}
                onChange={handleChange}
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={register({ required: true })}
              />

              {errors.password && (
                <div>
                  <span className="alert-danger"> Password is required</span>
                </div>
              )}
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                //onClick={submit}
              >
                {loggingIn && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Log in
              </Button>

              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
export { Login };
