import { ownerConstants as constants } from '../_constants';
import { authHeader } from '../_helpers/auth-header';
import {
  postRequest,
  getRequest,
  httpClient_Post_with_Params,
} from '../_helpers';

export const ownerService = {
  insertItem,
  updateItem,
  deleteItem,
  getItem,
  getAllItem,
  getOwnersAutocomplete,
};
async function insertItem(item) {
  return await postRequest(
    constants.API_INSERT,
    item,
    null,
    authHeader().Authorization,
  );
}

async function updateItem(item) {
  return await postRequest(
    constants.API_UPDATE,
    item,
    null,
    authHeader().Authorization,
  );
}

async function deleteItem(code) {
  return await (constants.API_DELETE, code, null, authHeader().Authorization);
}
async function getItem(code) {
  return await getRequest(
    constants.API_GET + code,
    null,
    authHeader().Authorization,
  );
}

async function getAllItem() {
  return await getRequest(
    constants.API_GETALL,
    null,
    authHeader().Authorization,
  );
}

async function getOwnersAutocomplete({
  req_type = -1,
  req_search = '',
  recordTotal = 0,
}) {
  //console.log('req_search params:', req_search);
  return await httpClient_Post_with_Params(
    constants.API_AUTOCOMPLETE_OWNER,
    { req_type, req_search, recordTotal },

    authHeader().Authorization,
  );
}
