import React from 'react';

const StockReceiptUpdate = () => {
  return (
    <>
      <p>StockReceiptUpdate</p>
    </>
  );
};

export default StockReceiptUpdate;
