import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Icon } from '@material-ui/core';
import { Profile, SidebarNav, UpgradePlan } from './components';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 250,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const classes = useStyles();
  const [pages, setPages] = useState([]);
  //let pages = [];
  useEffect(() => {
    const localStoregeData = localStorage.getItem('user');

    const userData = JSON.parse(localStoregeData);
    // const pages = userData.menus.map((data) => ({
    //   id: data.menuCode,
    //   title: data.name,
    //   href: '',
    //   icon: <Icon className={data.icon} />,
    //   submenus: data.children,
    // }));
    var pageList = [];
    // console.log(userData.menus);
    // if (userData.menus[0].view_permission) {
    //   pageList.push(userData.menus[0]);
    // }
    // if (userData.menus[1].view_permission) {
    //   pageList.push(userData.menus[1]);
    // }
    // pageList.push(userData.menus[2]); // user permission always on for testing purposes

    // if (userData.menus[3].view_permission) {
    //   pageList.push(userData.menus[3]);
    // }
    userData.menus.map((m) => {
      if (m.view_permission) pageList.push(m);
    });
    const pages = pageList.map((data) => ({
      id: data.menuCode,
      title: data.name,
      href: '',
      icon: data.icon,
      submenus: data.children,
    }));
    console.log('pages: ', pages);
    setPages(pages);
    // setPages([pages[0], pages[1], pages[2]]);
    // setPages(pages);
  }, []);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      {/* {console.log('pages: ', pages)} */}
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
        {/* <UpgradePlan /> */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
