import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from 'react-switch-lang';
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  FormHelperText,
  Paper,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { OwnerChangeServices as service } from './services/asset.ownerchange.service';
import {
  getCurrentOwner,
  getCurrentOwnerType,
} from '../assetcheck/redux/actions/assetcheck.action';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

function AssetOwnerChange_Header(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, register, setValue } = props;

  //selector
  const assetTransferStore = useSelector((state) => state.assetTable);

  const init = {
    code: '',
    date: moment().format('YYYY-MM-DD'),
    changeBy: '',
    sourceOwner: '',
    sourceAccount: '',
    sourceOwnerType: '',
    sourceCustomer: '',
    destCustomer: '',
    destOwner: '',
    destOwnerType: '',
    reason: '',
    remark: '',
  };
  const [values, setValues] = useState(init);

  const initialOwnerOption = [
    {
      value: 1,
      label: 'Not Define',
    },
    {
      value: 7,
      label: 'GFT',
    },
    {
      value: 8,
      label: 'MT',
    },
    {
      value: 9,
      label: 'Distributor',
    },
    {
      value: 10,
      label: 'Warehouse',
    },
    {
      value: 11,
      label: 'Follow Up',
    },
    {
      value: 12,
      label: 'Write Off',
    },
  ];
  const [ownerTypeOption, setownerTypeOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [onwersOption, setOnwersOption] = useState([]);
  const [fromCustomer, setFromCustomer] = useState([]);
  const [toCustomer, setToCustomer] = useState([]);
  const [onwerDescsOption, setOnwersDescOption] = useState([]);
  const [optionReason, setoptionReason] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [inputText, setInputText] = useState('');
  //*** Initial get values ****/
  useEffect(() => {
    service.getReason().then((res) => {
      res.data.results
        .filter((option) => option.reasonType == 'OWNERCHANGE')
        .map((item) => {
          setoptionReason((option) => [
            ...option,
            {
              value: item.value,
              label: item.label,
            },
          ]);
        });
    });

    service.getOwnersType().then((res) => {
      // console.log('res ', res.data.results);
      let data = res.data.results;
      data.map((item) => {
        setownerTypeOption((option) => [
          ...option,
          {
            value: item.code,
            label: item.description,
          },
        ]);
      });
    });
  }, []);

  //*** UPDATE GET VALUE */
  useEffect(() => {
    //check edit action

    if (!!props.code) {
      service
        .getItem(props.code)
        .then((res) => {
          setValues(res.data.result);
          console.log('res.data.result: ', res.data.result);
          if (!!res.data.result.reason)
            props.setValue('reason', {
              value: res.data.result.reason,
              label: res.data.result.reasonDesc,
            });

          //From-Acount Type
          props.setValue('sourceOwnerType', {
            value: res.data.result.sourceOwnerType,
            label: res.data.result.sourceOwnerType,
          });

          //From-account;
          props.setValue('sourceOwner', {
            value: res.data.result.sourceAccount,
            label: res.data.result.sourceAccountName,
          });

          //From-customer
          props.setValue('fromcustomer', {
            value: res.data.result.sourceOwner,
            label: res.data.result.sourceOwnerName,
          });

          props.setValue('destOwnerType', {
            value: res.data.result.destOwnerType,
            label: res.data.result.destOwnerType,
          });

          props.setValue('destOwner', {
            value: res.data.result.destinationAccount,
            label: res.data.result.destinationAccountName,
          });

          props.setValue('tocustomer', {
            value: res.data.result.changeBy,
            label: res.data.result.nameOfChangeBy,
          });
        })
        .catch((error) => {
          window.alert('error', JSON.stringify(error));
        });
    }
  }, []);

  useEffect(() => {
    ownersQuery(values.sourceOwnerType.value, '').then((opts) => {
      setOnwersOption(opts);
    });
  }, [values['sourceOwnerType']]);

  useEffect(() => {
    ownersQuery(values.destOwnerType.value, '').then((opts) => {
      setOnwersDescOption(opts);
    });
  }, [values['destOwnerType']]);

  useEffect(() => {
    if (assetTransferStore.is_readyonly) setIsReadOnly(true);
  }, [assetTransferStore]);

  useEffect(() => {
    // console.log('sorceOwner change ', values.sourceOwner.value);
    if (values.sourceOwner) {
      promoiseCustomerQuery('');
    }
  }, [values['sourceOwner']]);

  useEffect(() => {
    //  console.log('destOwner change ', values.destOwner);
    if (values.destOwner) {
      promoiseToCustomerQuery('');
    }
  }, [values['destOwner']]);
  //Owneroption loading
  const ownersQuery = async (ownerType, inputValue) => {
    try {
      //autocomplete dropdown
      const res = await service.getAccount({
        typeCode: ownerType,
        inputValue: inputValue,
        totalRecord: 30,
      });

      let data = [];

      res.data.results.map((item) => {
        data.push(
          new Object({
            value: item.code,
            label: item.description,
          }),
        );
      });

      return data;
    } catch (error) {
      console.log('error:', error);
      return [];
    }
  };

  const customerQuery = async (sourceOwner = '', inputValue = '') => {
    if (values.sourceOwner) {
      service
        .getCustomerAutocomplete({
          req_type: values.sourceOwner.value,
          req_search: inputValue,
          recordTotal: 30,
        })
        .then((items) => {
          if (!items) return setFromCustomer([]);
          let data = items;
          let arrayData = [];
          data.map((item) => {
            arrayData.push(new Object({ value: item.Code, label: item.Name }));
          });
          setFromCustomer(arrayData);

          return arrayData;
        });
    }
  };

  const customerToQuery = async (desOwner = '', inputValue = '') => {
    if (values.destOwner) {
      service
        .getCustomerAutocomplete({
          req_type: values.destOwner.value,
          req_search: inputValue,
          recordTotal: 30,
        })
        .then((items) => {
          if (!items) return setToCustomer([]);
          let data = items;
          let arrayData = [];
          data.map((item) => {
            arrayData.push(new Object({ value: item.Code, label: item.Name }));
          });
          //console.log('customer array ', arrayData);
          setToCustomer(arrayData);

          return arrayData;
        });
    }
  };

  const promoiseCustomerQuery = (inputValue = '') =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(customerQuery(values.sourceOwner.value, inputValue));
      }, 500);
    });

  const promoiseToCustomerQuery = (inputValue = '') =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(customerToQuery(values.destOwner.value, inputValue));
      }, 500);
    });
  const handleChange = (event) => {
    //console.log(event.target.value);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = (event) => {
    if (event[0] != null) {
      setValues({
        ...values,
        [event[1].name]: { value: event[0].value, label: event[0].label },
      });
    } else {
      setValues({
        ...values,
        [event[1].name]: '',
      });
    }
  };

  return (
    <>
      <Grid justify="center" container direction="row" spacing={1}>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.input} fullWidth margin="dense">
            <FormLabel component="legend">{t('Code')}</FormLabel>
            <TextField
              size="small"
              variant="outlined"
              name="code"
              value={values.code}
              inputRef={register()}
              onChange={handleChange}
              disabled
            />
            <FormHelperText>
              {props.errors.code?.type === 'required' &&
                'Your input is required'}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            required
            error={!!props.errors.dateValue}
          >
            <FormLabel component="legend">{t('Date')}</FormLabel>
            <TextField
              size="small"
              type="date"
              variant="outlined"
              name="date"
              value={[moment(values.date).format('YYYY-MM-DD')]}
              inputRef={props.register({ required: true })}
              onChange={handleChange}
              disabled={props.readOnly}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormHelperText>
              {props.errors.date?.type === 'required' &&
                'Your input is required'}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            size="small"
            required={true}
            error={!!props.errors.Reason}
          >
            <FormLabel component="legend">{t('Reason')}</FormLabel>
            <Controller
              name="reason"
              as={<Select />}
              options={optionReason}
              defaultValue={values.reason}
              isClearable
              control={props.control}
              rules={{ required: 'Reason is Required' }}
              isDisabled={props.readOnly}
            />
            <FormHelperText error>
              {!!props.errors.reason && <>{props.errors.reason.message}</>}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            error={!!props.errors.remark}
          >
            <FormLabel component="legend">{t('Remark')}</FormLabel>
            <TextField
              size="small"
              variant="outlined"
              name="remark"
              value={values.remark}
              inputRef={register}
              onChange={handleChange}
              disabled={props.readOnly}
            />
            <FormHelperText>
              {props.errors.remark?.type === 'required' &&
                'Your input is required'}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper variant="outlined" style={{ padding: 8 }}>
            <FormControl
              className={classes.input}
              fullWidth
              margin="dense"
              size="small"
              required={true}
              // error={!!props.errors.sourceOwnerType}
            >
              <FormLabel component="legend">{t('From')}</FormLabel>
              <FormLabel component="legend">{t('Account Type')}</FormLabel>
              <Controller
                name="sourceOwnerType"
                as={<Select />}
                options={ownerTypeOption}
                defaultValue={values.ownerTypeOption}
                getOptionLabel={(option) => `${option.value} - ${option.label}`}
                isClearable
                control={props.control}
                onChange={(value) => {
                  if (value[0] == null) {
                    setValue('sourceOwner', '');
                    setValue('fromcustomer', '');
                    setFromCustomer([]);
                  }
                  handleSelectChange(value);
                  return value[0];
                }}
                rules={{ required: 'Required' }}
                isDisabled={isReadOnly}
              />
              <FormHelperText error>
                {!!props.errors.sourceOwnerType && (
                  <>{props.errors.sourceOwnerType.message}</>
                )}
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.input}
              fullWidth
              margin="dense"
              size="small"
              required={true}
              // error={!!props.errors.sourceOwner}
            >
              <FormLabel component="legend">{t('Account')}</FormLabel>
              <Controller
                as={<Select />}
                options={onwersOption ? onwersOption : []}
                getOptionLabel={(option) => `${option.value} - ${option.label}`}
                name="sourceOwner"
                isClearable
                isDisabled={isReadOnly}
                control={props.control}
                rules={{ required: 'Required' }}
                // defaultOptions={values.account}
                loadOptions={(value) => {
                  //console.log('value ', value);
                  if (!values.sourceOwnerType) {
                    window.alert('please select source ownertype first');
                    return null;
                  }
                  return ownersQuery(values.sourceOwnerType.value, value);
                }}
                placeholder={
                  values.sourceOwnerType.label
                    ? 'Search by ' + values.sourceOwnerType.label
                    : 'Select...'
                }
                onChange={(value) => {
                  if (value[0] == null) {
                    setValue('fromcustomer', '');
                    setFromCustomer([]);
                  }

                  handleSelectChange(value);
                  return value[0];
                }}
              />
              <FormHelperText error>
                {!!props.errors.sourceOwner && (
                  <>{props.errors.sourceOwner.message}</>
                )}
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.input}
              fullWidth
              margin="dense"
              size="small"
              required={true}
              //error={!!props.errors.sourceOwner}
            >
              <FormLabel component="legend">{t('customer')}</FormLabel>

              <Controller
                as={<Select />}
                options={fromCustomer}
                getOptionLabel={(option) => `${option.value} - ${option.label}`}
                name="fromcustomer"
                isClearable
                isDisabled={isReadOnly}
                onInputChange={(value) => {
                  promoiseCustomerQuery(value);
                  return value;
                }}
                rules={{ required: 'Required' }}
                control={props.control}
                placeholder={
                  values.sourceOwner.label
                    ? 'Search by ' + values.sourceOwner.label
                    : 'Select...'
                }
              />
              {/* <Controller
                as={<Select />}
                name="fromcustomer"
                isClearable
                isDisabled={isReadOnly}
                control={props.control}
                rules={{ required: 'Required' }}
                options={fromCustomer}
                defaultOptions={fromCustomer}
                loadOptions={(value, callback) => {
                  if (!values.sourceOwner) {
                    window.alert('please select destination Borrow Type first');
                    return null;
                  }

                  customerQuery(values.sourceOwner.value, value);
                }}
                options={fromCustomer}
                placeholder={
                  values.sourceOwner.label
                    ? 'Search by ' + values.sourceOwner.label
                    : 'Select...'
                }
                onChange={(value) => {
                  // console.log(value);
                  handleSelectChange(value);
                  return value[0];
                }}
              /> */}
              <FormHelperText error>
                {!!props.errors.fromcustomer && (
                  <>{props.errors.fromcustomer.message}</>
                )}
              </FormHelperText>
            </FormControl>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper variant="outlined" style={{ padding: 8 }}>
            <FormControl
              className={classes.input}
              fullWidth
              margin="dense"
              size="small"
              required={true}
            >
              <FormLabel component="legend">{t('To')}</FormLabel>
              <FormLabel component="legend">{t('Account Type')}</FormLabel>
              <Controller
                name="destOwnerType"
                as={<Select />}
                options={ownerTypeOption}
                getOptionLabel={(option) => `${option.value} - ${option.label}`}
                defaultValue={values.destOwnerType}
                isClearable
                control={props.control}
                onChange={(value) => {
                  setValue('changeBy', '');
                  handleSelectChange(value);
                  return value[0];
                }}
                rules={{ required: 'destOwnerType is Required' }}
                isDisabled={isReadOnly}
              />
              <FormHelperText error>
                {!!props.errors.destOwnerType && (
                  <>{props.errors.destOwnerType.message}</>
                )}
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.input}
              fullWidth
              margin="dense"
              size="small"
              required={true}
            >
              <FormLabel component="legend">{t('Account')}</FormLabel>
              <Controller
                as={<Select />}
                options={onwerDescsOption ? onwerDescsOption : []}
                getOptionLabel={(option) => `${option.value} - ${option.label}`}
                name="destOwner"
                isClearable
                isDisabled={isReadOnly || props.readOnly}
                control={props.control}
                rules={{ required: 'Required' }}
                defaultOptions={onwerDescsOption}
                loadOptions={(value) => {
                  if (!values.destOwnerType) {
                    window.alert('please select destination Ownertype first');
                    return null;
                  }
                  return ownersQuery(values.destOwnerType.value, value);
                }}
                placeholder={
                  values.destOwnerType.label
                    ? 'Search by ' + values.destOwnerType.label
                    : 'Select...'
                }
                onChange={(value) => {
                  //console.log(value);
                  handleSelectChange(value);
                  return value[0];
                }}
              />

              <FormHelperText error>
                {!!props.errors.changeBy && (
                  <>{props.errors.changeBy.message}</>
                )}
              </FormHelperText>
            </FormControl>

            <FormControl
              className={classes.input}
              fullWidth
              margin="dense"
              size="small"
              required={true}
            >
              <FormLabel component="legend">{t('customer')}</FormLabel>

              <Controller
                name="tocustomer"
                as={<Select />}
                options={toCustomer}
                getOptionLabel={(option) => `${option.value} - ${option.label}`}
                isDisabled={isReadOnly}
                isClearable
                control={props.control}
                onInputChange={(value) => {
                  promoiseToCustomerQuery(value);
                  return value;
                }}
                rules={{ required: 'changeBy is Required' }}
              />
              <FormHelperText error>
                {!!props.errors.changeBy && (
                  <>{props.errors.changeBy.message}</>
                )}
              </FormHelperText>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}></Grid>
      </Grid>
    </>
  );
}

export default translate(AssetOwnerChange_Header);
