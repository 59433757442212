import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { compareAsc, format } from 'date-fns';
import { translate } from 'react-switch-lang';
import Select from 'react-select';
import { Header, Icon, Divider, Grid } from 'semantic-ui-react';
import {
  InputText,
  InputDate,
  InputTextSearch,
} from '../../../../../_fragments/formField/controllerFieldMUI';
import { Controller } from 'react-hook-form';
import {
  Typography,
  Paper,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { BorrowService as service } from '../../../../../_services/transaction/asset.borrow.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import AsyncSelect from 'react-select/async';
import { setDate } from 'date-fns/esm';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  bg: {
    //background: 'linear-gradient(180deg, white 30%, grey 70%)',
    // background: '#bbdefb',
  },
}));

const BorrowHeaderEdit = (props) => {
  const { t } = props;
  const classes = useStyles();
  const [optionReason, setoptionReason] = useState([]);
  const [ownerType, setownerTypeOption] = useState([
    {
      ownertype_code: '',
      ownertype_name: '',
    },
  ]);
  const [borrow, setBorrow] = useState({
    value: '1',
    label: 'default',
  });

  const [data, setdata] = useState([]);

  let loading = false;

  useEffect(() => {
    service.getReason().then((res) => {
      res.data.results.map((item) => {
        setoptionReason((option) => [
          ...option,
          {
            value: item.code,
            label: item.description,
          },
        ]);
      });
    });
  }, []);

  useEffect(() => {
    service
      .getItem(props.code)
      .then((res) => {
        // console.log('res.data.result', res.data.result);
        if (!!res.data.result.reason)
          props.setValue('Reason', {
            value: res.data.result.reason,
            label: res.data.result.reason,
          });
        props.setValue('OwnerType', res.data.result.ownerType);
        setdata(...data, res.data.result);
        service
          .autoComplete({
            req_type: res.data.result.ownerType,
            req_search: res.data.result.borrowBy,
            recordTotal: '1',
          })
          .then((option) => {
            const optMapp = new Object({
              value: option[0].value,
              label: option[0].label,
            });
            props.setValue('borrowby', optMapp);
          });
        // }
      })
      .catch((error) => {
        window.alert('error', error);
      });
  }, []);

  const optBorrowDefault = () => {
    service
      .autoComplete({
        req_type: ownerType.ownertype_code,
        req_search: '000003',
        recordTotal: '30',
      })
      .then((res) => {
        res = Object.keys(res).map(
          (key) =>
            new Object({
              value: res[key].value,
              label: res[key].label,
            }),
        );

        console.log(res);
        setBorrow({ ...borrow, res });
      });
  };

  const ownerOption = async (inputValue = '') => {
    try {
      loading = true;
      let res = await service.autoComplete({
        req_type: data.ownerType,
        req_search: inputValue,
        recordTotal: '30',
      });

      res = Object.keys(res).map(
        (key) =>
          new Object({
            value: res[key].value,
            label: res[key].label,
          }),
      );
      loading = false;
      // console.log('ownerOption ', res);
      return res.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase()),
      );
    } catch (error) {
      console.log('error:', error);
      loading = false;
    }
  };

  const promiseOwnerOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(ownerOption(inputValue));
      }, 200);
    });

  const ownerTypeonChange = (event) => {
    //console.log(event);

    props.setValue('borrowby', '');

    setdata({ ...data, ownerType: event });
    return event;
  };

  return (
    <>
      <Grid stackable>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Paper className={classes.root} elevation={1} variant="outlined">
              <div>
                <Header className={classes.bg} dividing>
                  <Typography variant="h6" color="textPrimary">
                    <Icon name="folder" />
                    {t('_asset_description')}
                  </Typography>
                </Header>

                <FormControl
                  margin="dense"
                  fullWidth
                  required
                  error={!!props.errors.code}
                >
                  <FormLabel component="legend">{t('Code')}</FormLabel>
                  <TextField
                    size="small"
                    variant="outlined"
                    name="code"
                    value={[data.code]}
                    inputRef={props.register({ required: true })}
                    onChange={(event) => {
                      setdata({ ...data, code: event.target.value });
                    }}
                    disabled
                  />
                  <FormHelperText>
                    {props.errors.code?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  margin="dense"
                  fullWidth
                  required
                  error={!!props.errors.dateValue}
                >
                  <FormLabel component="legend">{t('Date')}</FormLabel>
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    name="dateValue"
                    value={[moment(data.date).format('YYYY-MM-DD')]}
                    inputRef={props.register({ required: true })}
                    onChange={(event) => {
                      setdata({ ...data, date: event.target.value });
                    }}
                    disabled={props.readOnly}
                  />
                  <FormHelperText>
                    {props.errors.date?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  margin="dense"
                  fullWidth
                  required
                  error={!!props.errors.dateValue}
                >
                  <FormLabel component="legend">{t('status')}</FormLabel>
                  <TextField
                    size="small"
                    variant="outlined"
                    name="status"
                    value={[data.status]}
                    inputRef={props.register({ required: true })}
                    onChange={(event) => {
                      setdata({ ...data, status: event.target.value });
                    }}
                    disabled
                  />
                  <FormHelperText>
                    {props.errors.status?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  margin="dense"
                  fullWidth
                  size="small"
                  required
                  error={!!props.errors.Reason}
                >
                  <FormLabel component="legend">{t('Reason')}</FormLabel>
                  <Controller
                    as={Select}
                    options={optionReason}
                    name="Reason"
                    isClearable
                    control={props.control}
                    rules={{ required: 'Reason is Required' }}
                    onChange={([event, _]) => {
                      // console.log(event);
                      return event;
                    }}
                    isDisabled={props.readOnly}
                  />

                  <FormHelperText error>
                    {!!props.errors.Reason && (
                      <>{props.errors.Reason.message}</>
                    )}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  margin="dense"
                  fullWidth
                  error={!!props.errors.remark}
                >
                  <FormLabel component="legend">{t('Remark')}</FormLabel>
                  <TextField
                    size="small"
                    variant="outlined"
                    name="remark"
                    value={[data.remark]}
                    inputRef={props.register()}
                    onChange={(event) => {
                      setdata({ ...data, remark: event.target.value });
                    }}
                    disabled={props.readOnly}
                  />
                  <FormHelperText>
                    {props.errors.remark?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>
              </div>
            </Paper>
          </Grid.Column>
          <Grid.Column>
            <Paper className={classes.root} elevation={1} variant="outlined">
              <Header dividing>
                <Typography variant="h6" color="textPrimary">
                  <Icon name="user" />
                  {t('Owner')}
                </Typography>
              </Header>

              <FormControl name="ownerpart" margin="dense" size="small">
                {/* <Typography color="textPrimary">{t('Owner Type')}</Typography> */}
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend">Gender</FormLabel> */}

                  <Controller
                    control={props.control}
                    name="OwnerType"
                    onChange={([_, newValue]) => {
                      ownerTypeonChange(newValue);
                      return newValue;
                    }}
                    defaultValue=""
                    as={
                      <RadioGroup
                        row
                        aria-label="Owner Type"

                        //value={ownerType.ownertype_code}
                        //onChange={ownerTypeonChange}
                        //ref={props.register({ name: 'OwnerType' })}
                      >
                        <FormControlLabel
                          name="Company"
                          value="1"
                          control={<Radio />}
                          label="Company"
                          disabled={props.readOnly}
                        />

                        <FormControlLabel
                          name="Branch"
                          value="2"
                          control={<Radio />}
                          label="Branch"
                          disabled={props.readOnly}
                        />
                        <FormControlLabel
                          name="Department"
                          value="3"
                          control={<Radio />}
                          label="Department"
                          disabled={props.readOnly}
                        />
                        <FormControlLabel
                          value="4"
                          name="Customer"
                          control={<Radio />}
                          label="Customer"
                          disabled={props.readOnly}
                        />
                        <FormControlLabel
                          value="5"
                          name="Employee"
                          control={<Radio />}
                          label="Employee"
                          disabled={props.readOnly}
                        />
                      </RadioGroup>
                    }
                  ></Controller>
                </FormControl>
                <Controller
                  as={<AsyncSelect />}
                  name="borrowby"
                  isClearable
                  control={props.control}
                  rules={{ required: 'Required' }}
                  loadOptions={promiseOwnerOptions}
                  placeholder={'Search ... '}
                  isDisabled={props.readOnly}
                />
                <FormHelperText error>
                  {props.errors.borrowby && (
                    <>{props.errors.borrowby.message}</>
                  )}
                </FormHelperText>
              </FormControl>
            </Paper>
            <Paper className={classes.root} elevation={1} variant="outlined">
              <Header dividing>
                <Typography variant="h6" color="textPrimary">
                  <Icon name="user" />
                  {t('Borrow-Return Period')}
                </Typography>
              </Header>
              <section>
                <FormControl
                  margin="dense"
                  fullWidth
                  required
                  error={!!props.errors.borrowDate}
                >
                  <FormLabel component="legend">{t('borrowDate')}</FormLabel>
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    name="borrowDate"
                    value={[moment(data.borrowDate).format('YYYY-MM-DD')]}
                    inputRef={props.register({ required: true })}
                    onChange={(event) => {
                      setdata({ ...data, borrowDate: event.target.value });
                    }}
                    disabled={props.readOnly}
                  />
                  <FormHelperText>
                    {props.errors.borrowDate?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  margin="dense"
                  fullWidth
                  required
                  error={!!props.errors.returnDate}
                >
                  <FormLabel component="legend">{t('returnDate')}</FormLabel>
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    name="returnDate"
                    value={[moment(data.returnDate).format('YYYY-MM-DD')]}
                    inputRef={props.register({ required: true })}
                    onChange={(event) => {
                      setdata({ ...data, returnDate: event.target.value });
                    }}
                    disabled={props.readOnly}
                  />
                  <FormHelperText>
                    {props.errors.returnDate?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>
              </section>
            </Paper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
    </>
  );
};
export default translate(BorrowHeaderEdit);
