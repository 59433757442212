import { MenuItem, Select } from '@material-ui/core';
import React from 'react';

export default function SelectType(props) {
  const { columnDef, onFilterChanged, options } = props;

  return (
    <Select
      fullWidth
      defaultValue={''}
      onChange={(event) =>
        onFilterChanged(columnDef.tableData.id, event.target.value)
      }
    >
      {options.map((item) => {
        return (
          <MenuItem key={item.code} value={item.code}>
            {item.description}
          </MenuItem>
        );
      })}
    </Select>
  );
}
