import { roleConstants as constants } from '../_constants';
import { authHeader } from '../_helpers/auth-header';
import { postRequest, getRequest } from '../_helpers';

export const roleService = {
  insertItem,
  updateItem,
  deleteItem,
  getItem,
  getAllItem,
  getAllMenus,
  getRolePermissions,
  rolePermissionUpdate,
};
async function insertItem(item) {
  return await postRequest(
    constants.API_INSERT,
    item,
    null,
    authHeader().Authorization,
  );
}

async function updateItem(item) {
  return await postRequest(
    constants.API_UPDATE,
    item,
    null,
    authHeader().Authorization,
  );
}

async function deleteItem(code) {
  return await postRequest(
    constants.API_DELETE,
    code,
    null,
    authHeader().Authorization,
  );
}
async function getItem(code) {
  return await getRequest(
    constants.API_GET + code,
    null,
    authHeader().Authorization,
  );
}

async function getAllItem() {
  return await getRequest(
    constants.API_GETALL,
    null,
    authHeader().Authorization,
  );
}

async function getAllMenus() {
  return await getRequest(
    constants.API_GETALL_MENUS,
    null,
    authHeader().Authorization,
  );
}

async function getRolePermissions(code) {
  var url = constants.API_GET_PERMISSIONS;
  return await getRequest(
    url.replace('id', code),
    null,
    authHeader().Authorization,
  );
}

async function rolePermissionUpdate(item) {
  console.log('item: ', item);
  var url = constants.API_ROLE_PERMISSION_UPDATE;
  return await postRequest(url, item, null, authHeader().Authorization);
}
