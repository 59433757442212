import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Card, Grid, Message } from 'semantic-ui-react';

import { Title, SaveButton, ClearButton } from '../../../../_fragments';
import { default as _Header } from '../../_InventoryFlagments/_InventoryHeader';
import { default as _Location } from '../../_InventoryFlagments/_inventoryLocation';
import { default as _Additem } from '../../_InventoryFlagments/_InventoryAdditem';
import { default as _ItemList } from '../../_InventoryFlagments/_InventoryItemList';
import { default as _Footer } from '../../_InventoryFlagments/_InventoryFooter';

const StockReceiptInsert = (props) => {
  const { handleSubmit, errors, control } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [submitstatus, setsubmitstatus] = useState(false);
  const onSubmit = (data) => {
    console.log('data: ', data);
    setSubmitting(false);
    setsubmitstatus(true);
  };

  return (
    <>
      <Form
        {...submitstatus} //error, warning, success
        key="F0"
        onSubmit={submitting ? handleSubmit(onSubmit) : null}
        size="small"
        loading={submitting || props.loading}
        success={props.success}
      >
        <Card centered fluid>
          <Card.Content>
            <Card.Header>
              <Grid divided="vertically" padded>
                <Grid.Column computer={12} tablet={12} mobile={16}>
                  <Title
                    icon="warehouse"
                    iconaction="add"
                    content="Stock Receipt"
                    description="Create"
                    props={props}
                  />
                </Grid.Column>
                <Grid.Column
                  textAlign="right"
                  computer={4}
                  tablet={4}
                  mobile={16}
                >
                  {buttonAction(props.history)}
                </Grid.Column>
              </Grid>
            </Card.Header>
          </Card.Content>
          <Card.Content>
            <Card.Description>
              {submitstatus && (
                <Message
                  {...submitstatus}
                  header="Action Forbidden"
                  content="You can only sign up for an account once with a given e-mail address."
                />
              )}

              <_Header
                errors={errors}
                control={control}
                readOnly={props.readOnly}
              />
            </Card.Description>
            <Card.Description>
              <_Location
                control={control}
                errors={errors}
                readOnly={props.readOnly}
              />
            </Card.Description>
            <Card.Description>
              <_Additem
                control={control}
                errors={errors}
                readOnly={props.readOnly}
              />
            </Card.Description>
            <Card.Description>
              <_ItemList
                control={control}
                errors={errors}
                readOnly={props.readOnly}
              />
            </Card.Description>
            <Card.Description>
              <Grid divided="vertically" padded>
                <Grid.Column computer={12} tablet={12} mobile={16}>
                  <_Footer
                    control={control}
                    errors={errors}
                    readOnly={props.readOnly}
                  />
                </Grid.Column>
                <Grid.Column
                  textAlign="right"
                  computer={4}
                  tablet={4}
                  mobile={16}
                >
                  {buttonAction(props.history)}
                </Grid.Column>
              </Grid>
            </Card.Description>
          </Card.Content>
        </Card>
      </Form>
    </>
  );
};

export default StockReceiptInsert;

const buttonAction = (history) => {
  //console.log(history);
  return (
    <>
      <SaveButton>Save</SaveButton>
      <ClearButton history={history}>Back</ClearButton>
    </>
  );
};
//
