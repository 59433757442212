import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from 'react-switch-lang';
import { Grid, Button, Slide } from '@material-ui/core';
import { Divider } from 'semantic-ui-react';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { Title } from '../../../_fragments';
import {
  AssetCheckHeader as Header,
  AssetCheckDetail as Additem,
} from './components';
import { MyAlert as Alert } from '../../../_fragments/alert';
import { Save, Update, ResetItem } from './redux/actions/assetcheck.action';
import { AssetCheckServices as service } from './services/assetcheck.service';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  card: {
    // height: 100,
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
  },
  btnSave: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnArroved: {
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnBack: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
}));

let isReadOnly = false;
let isCreate = false;

const AssetCheckForm = (props) => {
  const { t, history } = props;
  const classes = useStyles();

  const [direction, setDirection] = useState({
    slide: 'left',
    in: true,
    timeout: 650,
  });
  const alert = useAlert();
  const {
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    getValues,
  } = useForm();

  const onsubmitAsync = async () => {
    // do something serivces
  };

  return (
    <>
      <Slide
        timeout={direction.timeout}
        direction={direction.slide}
        in={direction.in}
        mountOnEnter
        unmountOnExit
      >
        <form onSubmit={null} size="small" noValidate>
          <Grid container>
            <Grid item xs={12} sm={7}>
              <Title
                icon="cubes"
                iconaction="exchange"
                content="Asset Check"
                description="Description"
                props={props}
              />
            </Grid>
            <Grid item style={{ textAlign: 'end' }} xs={12} sm={5}>
              <Button
                className={classes.btnBack}
                name="backBtn"
                size="large"
                color="default"
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  setDirection({
                    ...direction,
                    slide: 'left',
                    in: false,
                  });
                  setTimeout(() => {
                    history.goBack();
                  }, direction.timeout);
                }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
          <Divider />

          <Header
            code={props.match.params.id}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            readOnly={isReadOnly}
          />
          {/* <Additem
            code={props.match.params.id}
            data={data}
            register={register}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            control={control}
            readOnly={isReadOnly}
          /> */}
        </form>
      </Slide>
    </>
  );
};

export default AssetCheckForm;
