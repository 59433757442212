export const WriteOffConstants = {
  API_INSERT: '/api/asset/writeoff/insert',
  API_UPDATE: '/api/asset/writeoff/update',
  API_DELETE: '/api/asset/writeoff/delete',
  API_APPROVE: '/api/asset/writeoff/apporve',
  API_GET: '/api/asset/writeoff/',
  API_GETALL: '/api/asset/writeoff/getall',
  API_GET_BY_ID: '/api/asset/writeoff/info?code=',

  API_GET_REASON: '/api/reason/getall',

  API_AUTOCOMPLETE: '/api/autocomplete',
  API_AUTOCOMPLETE_OWNER: '/api/autocomplete/owner',

  INSERT_REQUEST: 'WRITEOFF_INSERT_REQUEST',
  INSERT_SUCCESS: 'WRITEOFF_INSERT_SUCCESS',
  INSERT_FAILURE: 'RETURN_INSERT_FAILURE',

  UPDATE_REQUEST: 'WRITEOFF_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'WRITEOFF_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'WRITEOFF_UPDATE_FAILURE',

  DELETE_REQUEST: 'WRITEOFF_DELETE_REQUEST',
  DELETE_SUCCESS: 'WRITEOFF_DELETE_SUCCESS',
  DELETE_FAILURE: 'WRITEOFF_DELETE_FAILURE',

  GET_REQUEST: 'WRITEOFF_GET_REQUEST',
  GET_SUCCESS: 'WRITEOFF_GET_SUCCESS',
  GET_FAILURE: 'WRITEOFF_GET_FAILURE',

  GETALL_REQUEST: 'WRITEOFF_GETALL_REQUEST',
  GETALL_SUCCESS: 'WRITEOFF_GETALL_SUCCESS',
  GETALL_FAILURE: 'WRITEOFF_GETALL_FAILURE',

  RESET: 'WRITEOFF_RESET',
  SERVICE: 'WRITEOFF_SERVICE',
};
