import React, { useRef } from 'react';
import { translate } from 'react-switch-lang';
import { CustomerService as service } from '../../_services/customer.service';
import CustomerDatatableFilter from './CustomerDatatableFilter';

const Customer = (props) => {
  const { t } = props;
  const header = {
    icon: 'user',
    content: 'Customer',
    subHeader: 'Listing',
  };
  const tableRef = useRef();

  const insertBtn = {
    text: t('Add'),
    link: props.location.pathname + '/insert',
  };

  const actionPath = {
    add: `${props.location.pathname}/insert`,
    edit: `${props.location.pathname}/update`,
    delete: `${props.location.pathname}/delete`,
  };

  const headers = [
    // {
    //   title: 'Account Type',
    //   render: (rowData) => {
    //     if (rowData.Branch === null || rowData.Branch === '') return <>-</>;
    //     const acc = accounts.find((acc) => acc.code === rowData.Branch);

    //     const type = accountTypes.find(
    //       (type) => type.code === acc.locationTypeGft,
    //     );
    //     return <>{type.description}</>;
    //   },
    // },
    { title: 'Account Type', field: 'Account_Type' },
    { title: 'Account', field: 'Account' },
    { title: 'Code', field: 'Code' },
    { title: 'Name', field: 'Name' },
    { title: 'Active', field: 'Active' },
    { title: 'CreateDate', field: 'CreateDate' },
    { title: 'CreateBy', field: 'CreateBy' },
  ];

  const title = 'Customer';
  const filenameXML = 'Customer_Freezer';
  const menuCode = 'M_CUSTOMER';

  return (
    <>
      <CustomerDatatableFilter
        header={header}
        insertBtn={insertBtn}
        title={title}
        headers={headers}
        filenameXML={filenameXML}
        root={props.root}
        history={props.history}
        path={actionPath}
        service={service}
        menuCode={menuCode}
        tableRef={tableRef}
      />
    </>
  );
};

export default translate(Customer);
