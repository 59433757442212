import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from 'react-switch-lang';
import {
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  TextField,
  Grid,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Select from 'react-select';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { AssetCheckServices as service } from '../services/assetcheck.service';
import { AssetCheckDetail as Additem } from './index';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  griditem: {
    padding: theme.spacing(1),
  },
  bg: {
    //background: 'linear-gradient(180deg, white 30%, grey 70%)',
    // background: '#bbdefb',
  },
  control: {
    paddingRight: theme.spacing(0.5),
  },
  input: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));
const initialData = {
  assetCheckId: '',
  userId: '',
  date: '',
  remarks: '',
  number: '',
  createBy: '',
  active: true,
  customerCode: '',
  customerName: '',
  distributorId: '',
  distributorCode: '',
  distributorName: '',
  salesCode: '',
  firstName: '',
  lastName: '',
  user: '',
  details: [
    {
      active: '',
      asset: '',
      assetCheckDetailsId: '',
      assetCheckId: '',
      assetId: '',
      assetImage: '',
      assetState: '',
      assetStateId: '',
      assetType: '',
      barcode: '',
      brand: '',
      description: '',
      gpsLat: '',
      gpsLong: '',
      model: '',
      reason: '',
      remarks: '',
    },
  ],
};

const AssetCheckHeader = (props) => {
  const classes = useStyles();
  const { t, code } = props;
  const [itemData, setItemData] = useState(initialData);

  //*** Handles ***//

  useEffect(() => {
    if (code) {
      service.getItem(code).then((res) => {
        //  console.log('data=> ', res.data);
        setItemData(res.data);
      });
    }
  }, []);
  const handleChange = (event) => {
    //console.log(event.target.value);
  };

  return (
    <>
      <Grid
        justify="flex-start"
        container
        direction="row"
        spacing={1}
        className={classes.root}
      >
        <Grid item xs={12} md={3}>
          <FormControl className={classes.input} fullWidth margin="dense">
            <FormLabel component="legend">{t('Code')}</FormLabel>
            <TextField
              size="small"
              variant="outlined"
              name="code"
              value={itemData.number}
              onChange={handleChange}
              disabled
            />
            <FormHelperText>
              {props.errors.code?.type === 'required' &&
                'Your input is required'}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.input} fullWidth margin="dense">
            <FormLabel component="legend">{t('Date')}</FormLabel>
            <TextField
              size="small"
              type="date"
              variant="outlined"
              name="date"
              value={[moment(itemData.date).format('YYYY-MM-DD')]}
              onChange={handleChange}
              disabled
            />
            <FormHelperText>
              {props.errors.date?.type === 'required' &&
                'Your input is required'}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            size="small"
          >
            <FormLabel component="legend">{t('Distributor')}</FormLabel>
            <TextField
              size="small"
              variant="outlined"
              value={itemData.distributorCode + ' ' + itemData.distributorName}
              onChange={handleChange}
              disabled
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            size="small"
          >
            <FormLabel component="legend">{t('Customer')}</FormLabel>
            <TextField
              size="small"
              variant="outlined"
              value={itemData.customerCode + ' ' + itemData.customerName}
              onChange={handleChange}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            size="small"
          >
            <FormLabel component="legend">{t('By User')}</FormLabel>
            <TextField
              size="small"
              variant="outlined"
              value={
                itemData.salesCode +
                ' ' +
                itemData.firstName +
                ' ' +
                itemData.lastName
              }
              onChange={handleChange}
              disabled
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            size="small"
          >
            <FormLabel component="legend">{t('Remark')}</FormLabel>
            <TextField
              size="small"
              variant="outlined"
              value={itemData.remarks}
              onChange={handleChange}
              disabled
            />
          </FormControl>
        </Grid>
      </Grid>

      <Additem code={code} data={itemData} />
    </>
  );
};

export default translate(AssetCheckHeader);
