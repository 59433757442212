export const smtpConstants = {
    API_INSERT: '/api/smtp/insert',
    API_UPDATE: '/api/smtp/update',
    API_DELETE: '/api/smtp/delete',
    API_GET: '/api/smtp/',
    API_GETALL: '/api/smtp/getall',
  
    INSERT_REQUEST: 'SMTP_INSERT_REQUEST',
    INSERT_SUCCESS: 'SMTP_INSERT_SUCCESS',
    INSERT_FAILURE: 'SMTP_INSERT_FAILURE',
  
    UPDATE_REQUEST: 'SMTP_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SMTP_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SMTP_UPDATE_FAILURE',
  
    DELETE_REQUEST: 'SMTP_DELETE_REQUEST',
    DELETE_SUCCESS: 'SMTP_DELETE_SUCCESS',
    DELETE_FAILURE: 'SMTP_DELETE_FAILURE',
  
    GET_REQUEST: 'SMTP_GET_REQUEST',
    GET_SUCCESS: 'SMTP_GET_SUCCESS',
    GET_FAILURE: 'SMTP_GET_FAILURE',
  
    GETALL_REQUEST: 'SMTP_GETALL_REQUEST',
    GETALL_SUCCESS: 'SMTP_GETALL_SUCCESS',
    GETALL_FAILURE: 'SMTP_GETALL_FAILURE',
  
    RESET: 'SMTP_RESET',
    SERVICE: 'SMTP_SERVICE',
  };
  