import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';
import MaterialTable from 'material-table';
import { jQGridService } from '../../../_services';
import tableIcons from '@material-ui/icons/Tablet';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import { Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { selectedItem, clearItem } from './redux/actions/assetsearch.action';

const StdDatatable = (props) => {
  const { t, title } = props;
  const [selected, setSelected] = useState(null);
  const [closing, setclosing] = useState(false);
  const [owner, setOwner] = useState(null);
  const [ownerType, setOwnerType] = useState(null);
  const dispatch = useDispatch();

  //selector
  const storageAssetCheck = useSelector((state) => state.assetCheck);
  const storageAsset = useSelector((state) => state.assetSearch);

  const columns = [
    { title: t('code'), field: 'Code' },
    { title: t('serial'), field: 'Serial' },
    { title: t('description'), field: 'Description' },
    { title: t('brand'), field: 'AssetBrand' },
    { title: t('class'), field: 'AssetClass' },
    { title: t('subclass'), field: 'AssetSubClass' },
    { title: t('model'), field: 'AssetModel' },
  ];

  useEffect(() => {
    // console.log(storageAssetCheck.payload);
    try {
      setOwner(storageAssetCheck.payload.owner.value);
      setOwnerType(storageAssetCheck.payload.ownertype.value);
    } catch (error) {
      console.log(error);
    }
  }, [storageAssetCheck]);

  useEffect(() => {
    dispatch(selectedItem(selected, closing));
    return () => {
      //  dispatch(clearItem());
    };
  }, [selected]);

  return (
    <>
      <MaterialTable
        icons={<tableIcons />}
        actions={[
          {
            icon: () => <SearchIcon />,
            tooltip: t('Choose'),
            onClick: (e, rowData) => {
              e.preventDefault();
              setSelected(rowData);
              setclosing(true);
            },
          },
        ]}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            //console.log('query: ', query);
            try {
              jQGridService
                .searchAssetByOwner(
                  'Asset-By-Owner' + '.xml',
                  query.page + 1,
                  query.pageSize,
                  query.search,
                  owner ? owner : '',
                  ownerType ? ownerType : '',
                )
                .then((res) => {
                  if (!!res.data) {
                    resolve({
                      data: res.data.rows,
                      page: res.data.page - 1,
                      totalCount: res.data.records,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: query.page,
                      totalCount: query.totalCount,
                    });
                  }
                });
            } catch (error) {
              console.log('error: ', error);
              resolve({
                data: [],
                page: query.page,
                totalCount: query.totalCount,
              });
            }
          })
        }
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#e1f5fe',
            // background: 'linear-gradient(45deg, #b2dfdb 30%, #1976d2 90%)',
            color: 'Black',
          },
          // rowStyle: {
          //   background: 'linear-gradient(45deg, #b2dfdb 30%, #1976d2 90%)',

          // }
        }}
        title={title}
      />
    </>
  );
};

export default translate(StdDatatable);
