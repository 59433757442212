export const CustomerConstants = {
  API_INSERT: '/api/customer/insert',
  API_UPDATE: '/api/customer/update',
  API_DELETE: '/api/customer/delete',
  API_GETALL: '/api/customer/getall',
  API_GET_BY_ID: '/api/customer/',

  INSERT_REQUEST: 'INSERT_REQUEST',
  INSERT_SUCCESS: 'NSERT_SUCCESS',
  INSERT_FAILURE: 'NSERT_FAILURE',

  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_FAILURE',

  DELETE_REQUEST: 'DELETE_REQUEST',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILURE: 'DELETE_FAILURE',

  GET_REQUEST: 'GET_REQUEST',
  GET_SUCCESS: 'GET_SUCCESS',
  GET_FAILURE: 'GET_FAILURE',

  GETALL_REQUEST: 'GETALL_REQUEST',
  GETALL_SUCCESS: 'GETALL_SUCCESS',
  GETALL_FAILURE: 'GETALL_FAILURE',

  RESET: 'RESET',
  SERVICE: 'CUSTOMER_SERVICE',
};
