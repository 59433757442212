import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';
import { Controller } from 'react-hook-form';

import { ImageCard } from './ImageCard';
import {
    Header,
    Segment,
    Message,
    Divider,
    Icon,
    Button,
    Container, Grid,
    Card,
    Image,
    Label
} from 'semantic-ui-react';
import Alert from '@material-ui/lab/Alert';
import { assetService as service } from '../../../../_services/asset.service';



const PreviewImage = (props) => {
    const {
        t,
        readOnly,
        code,
        setUploadFiles
    } = props;
    const [urlList, setURL] = useState([]);
    const [currentFiles, setFiles] = useState([]);
    const [fileExceeded, setFileExceeded] = useState(false);
    const maxFiles = 6;

    useEffect(() => {

        setUploadFiles([]);
    }, []);
    useEffect(() => {
        if (!!code) {
            let assetCode = code

            service.getImage({ assetCode }).then(res => {
                console.log('image get response: ', res);
                // for (var pair of bodyFormData.entries()) {
                //     console.log(pair[0], ', ', pair[1]);
                //   }
            });
        }


    }, [code]);

    const onChange = (e) => {
        var files = e.target.files;
        console.log(files);
        if (files.length + currentFiles.length > maxFiles) {
            setFileExceeded(true);
        }
        else {
            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                setURL(urlList => [...urlList, URL.createObjectURL(file)]);
                setFiles(currentFiles => [...currentFiles, files[i]]);
            }
            setUploadFiles(files);
            setFileExceeded(false);
        }


    };
    const fileRef = React.createRef();
    return (
        <>
            <Segment >

                {fileExceeded &&
                    <Message negative>
                        <Message.Header>Maximum upload file exceeds!!</Message.Header>
                    </Message>
                }



                <Button
                    content="Choose File"
                    labelPosition="left"
                    icon="folder open"
                    color='blue'
                    onClick={(e) => { e.preventDefault(); fileRef.current.click() }}
                    disabled={readOnly}
                />
                <Button
                    content='Reset'
                    labelPosition="left"
                    icon='undo'
                    color='black'
                    onClick={(e) => { e.preventDefault(); setURL([]); setUploadFiles([]); setFiles([]); }}
                    disabled={readOnly}
                />


                <input
                    ref={fileRef}
                    type="file"
                    hidden
                    onChange={onChange}
                    multiple
                    accept="image/*"
                />
                <Header as='h2' attached='top'>
                    <Icon name='images' />
                    <Header.Content>
                        Preview Images
                    <Header.Subheader>
                            Maximum upload limit: 6
                    </Header.Subheader>
                    </Header.Content>
                </Header>
                <Segment attached='bottom' placeholder>
                    {(urlList.length === 0 && readOnly) &&
                        <Header icon>
                            <Icon name='ban' />
                                There are no images for this item
                            </Header>}
                    {(urlList.length === 0 && !readOnly) &&
                        <Header icon>
                            <Icon name='upload' />
                                Click <Label color='blue'>Choose File</Label> button to select image files and <Label color='black'>Reset</Label> button to remove all
                            </Header>}
                    <Grid columns={3} centered>
                        {urlList.map((url, index) => {

                            return <Grid.Column key={index} computer={5} tablet={16} mobile={16} >
                                <ImageCard src={url} index={index} name={currentFiles[index].name} />
                            </Grid.Column>
                        })}

                    </Grid>

                </Segment>

            </Segment>

        </>
    );
};

export default translate(PreviewImage);
