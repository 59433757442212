import React from 'react';

import ReactSelect from 'react-select';
import {
  Typography,
  TextField,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from '@material-ui/core';
export { InputSelect };
const InputSelect = ({
  value,
  name,
  onChange,
  options,
  label,
  isClearable,
}) => {
  return (
    <>
      <FormControl margin="dense" fullWidth size="small">
        <FormLabel component="legend">{label}</FormLabel>
        <ReactSelect
          defaultValue={value}
          options={options}
          name={name}
          onChange={onChange}
          isClearable={isClearable}
          placeholder={'Select...'}
        />
      </FormControl>
    </>
  );
};
