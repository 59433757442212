import React from "react";
import { Title } from "../../../_fragments";

const Stockissue = (props) => {
  return (
    <div>
      <Title
        icon="warehouse"
        iconaction="times rectangle"
        content="Stock Receipt"
        props={props}
      />
    </div>
  );
};

export default Stockissue;
