export default (state = [], { type, payload }) => {
  switch (type) {
    case 'GET_CURRENT_OWNER':
      return { payload };
    case 'GET_CURRETN_OWNER_TYPE':
      return { payload, ...payload };
    case 'CLEAR_ITEM':
      return {};
    default:
      return state;
  }
};
