import React, { useState } from 'react';
import { translate } from 'react-switch-lang';
import { Divider, Header, Icon, Grid } from 'semantic-ui-react';
import { Typography } from '@material-ui/core';
import SiteInput from '../../../_fragments/locationForm/_site';
import LocationInput from '../../../_fragments/locationForm/_location';
import ZoneInput from '../../../_fragments/locationForm/_zone';

const _InventoryLocation = (props) => {
  const { t } = props;
  const [site, setSite] = useState('');
  const [location, setLocation] = useState('');
  const [site2, setSite2] = useState('');
  const [location2, setLocation2] = useState('');
  return (
    <>
      <Header dividing>
        <Typography variant="h6" color="textPrimary">
          <Icon name="building" />
          {t('_location')}
        </Typography>
      </Header>

      <Grid stackable columns={2} padded>
        <Grid.Row>
          <Grid.Column>
            <Grid padded="horizontally">
              <Typography color="textPrimary" variant="body1">
                {t('_source')}
              </Typography>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={6} computer={5}>
                  <SiteInput
                    name="site"
                    control={props.control}
                    label="site"
                    placeholder="site"
                    error={
                      !!props.errors.site && {
                        content: props.errors.site.message,
                      }
                    }
                    // *******************************************
                    onChange={([data, _]) => {
                      if (!!data) setSite(data.value);
                      else {
                        setSite('');
                        setLocation('');
                      }
                      return data;
                    }}
                    // ********************
                    readOnly={props.readOnly}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={6} computer={5}>
                  <LocationInput
                    key={site || 'site'}
                    name="location"
                    control={props.control}
                    label="location"
                    placeholder="location"
                    error={
                      !!props.errors.locaiton && {
                        content: props.errors.locaiton.message,
                      }
                    }
                    // *******************************************
                    onChange={([data, _]) => {
                      if (!!data) setLocation(data.value);
                      else setLocation('');
                      return data;
                    }}
                    filter={(el) => {
                      return el.site === site;
                    }}
                    // // ********************
                    readOnly={props.readOnly}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={6} computer={6}>
                  <ZoneInput
                    key={location || 'location'}
                    name="sourceZone"
                    control={props.control}
                    label="zone"
                    placeholder="zone"
                    error={
                      !!props.errors.sourceZone && {
                        content: props.errors.sourceZone.message,
                      }
                    }
                    // *******************************************
                    filter={(el) => {
                      return el.location === location;
                    }}
                    // // ********************
                    readOnly={props.readOnly}
                    required
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column>
            <Grid padded="horizontally">
              <Typography color="textPrimary" variant="body1">
                {t('_Destination')}
              </Typography>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={6} computer={5}>
                  <SiteInput
                    name="site2"
                    control={props.control}
                    label="site2"
                    placeholder="site2"
                    error={
                      !!props.errors.site2 && {
                        content: props.errors.site2.message,
                      }
                    }
                    // *******************************************
                    onChange={([data, _]) => {
                      if (!!data) setSite2(data.value);
                      else {
                        setSite2('');
                        setLocation2('');
                      }
                      return data;
                    }}
                    // ********************
                    readOnly={props.readOnly}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={6} computer={5}>
                  <LocationInput
                    key={site2 || 'site2'}
                    name="location2"
                    control={props.control}
                    label="location2"
                    placeholder="location2"
                    error={
                      !!props.errors.locaiton2 && {
                        content: props.errors.locaiton2.message,
                      }
                    }
                    // *******************************************
                    onChange={([data, _]) => {
                      if (!!data) setLocation2(data.value);
                      else setLocation2('');
                      return data;
                    }}
                    filter={(el) => {
                      return el.site === site2;
                    }}
                    // // ********************
                    readOnly={props.readOnly}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={6} computer={6}>
                  <ZoneInput
                    key={location2 || 'location2'}
                    name="destinationZone"
                    control={props.control}
                    label="destinationZone"
                    placeholder="destinationZone"
                    error={
                      !!props.errors.destinationZone && {
                        content: props.errors.destinationZone.message,
                      }
                    }
                    // *******************************************
                    filter={(el) => {
                      return el.location === location2;
                    }}
                    // // ********************
                    readOnly={props.readOnly}
                    required
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider />
    </>
  );
};
export default translate(_InventoryLocation);
