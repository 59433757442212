export default (state = [], { type, payload, closing = false }) => {
  switch (type) {
    case 'SELECTED_OWNER':
      return { ...payload, closing: closing };
    case 'CLEAR_ITEM':
      return {};
    default:
      return state;
  }
};
