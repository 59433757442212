export const API_JQGRID = '/api/jqgrid/source';
export const API_JQGRID_PROXY = './api/jqgrid/source';

export const jQGRidConstants = {
  SEARCH: 'SEARCH_JQGRID',
  REQUEST: 'REQUEST_JQGRID',
  SUCCESS: 'SUCCESS_JQGRID',
  FAILURE: 'FAILURE_JQGRID',
  API: '/api/jqgrid/source',
  API_ADVANCE: 'api/jqgrid/advance/search',
  API_ASSET_ADV_SEARCH: '/api/asset/search/advance',

  SEARCH_AUTOCOMPLETE: 'SEARCH_AUTOCOMPLETE',
  REQUEST_AUTOCOMPLETE: 'REQUEST_AUTOCOMPLETE',
  SUCCESS_AUTOCOMPLETE: 'SUCCESS_AUTOCOMPLETE',
  FAILURE_AUTOCOMPLETE: 'FAILURE_AUTOCOMPLETE',
  API_AUTOCOMPLETE: '/api/autocomplete',

  RESET: 'JQGRID_RESET',
  SERVICE: 'JQGRID_SERVICE',
};

export const filenameXML = {
  asset: 'asset',
  asset_history: 'asset-owner-history',
  assetBrand: 'asset-brand',
  assetClass: 'asset-class',
  assetSubClass: 'asset-subclass',
  assetCondition: 'asset-condition',
  assetGroup: 'asset-group',
  assetModel: 'asset-model',
  assetSupplier: 'asset-supplier',
  businessArea: 'business-area',
  site: 'site',
  location: 'location',
  zone: 'zone',
  user: 'user',
  role: 'role',
  permission: 'permission',
};
