import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  // Button,
} from '@material-ui/core';
import palette from '../../../../theme/palette';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { dashboardService as service } from '../../../../_services/dashboard.service';
import { options } from './chart';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative',
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const LatestSales = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const init = {
    labels: [
      'PRINTER',
      'NOTEBOOK',
      'MOBILE PHONE',
      'COPY MACHINE',
      'COMPUTER',
      'CAMERA',
    ],
    datasets: [
      {
        label: 'Good',
        backgroundColor: palette.primary.main,
        data: [18, 5, 19, 27, 29, 19, 20],
      },
      {
        label: 'Damaged',
        backgroundColor: palette.error.main,
        data: [11, 20, 12, 29, 30, 25, 13],
      },
    ],
  };

  const [data, setData] = useState(init);
  useEffect(() => {
    service.AssetConditionByGroup().then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        // action={
        //   <Button size="small" variant="text">
        //     Last 7 days <ArrowDropDownIcon />
        //   </Button>
        // }
        title="Asset Condition By Groups"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar data={data} options={options} />
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        {/* <Button color="primary" size="small" variant="text">
          Overview <ArrowRightIcon />
        </Button> */}
      </CardActions>
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string,
};

export default LatestSales;
