import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
export default function AssetOwnerTransferReport() {
  const permissions = localStorage.getItem('user');
  const permissionsObj = JSON.parse(permissions);
  const [reportName, setReportName] = useState(null);
  const [isShowReport, setIsShowReport] = useState(false);
  const [errMsg, seterrMsg] = useState('');

  useEffect(() => {
    try {
      const menu = permissionsObj.menus[1];
      // console.log(menu);
      const data = menu.children.find(
        (i) => i.menuCode == 'M_ASSET_OWNERTRANSFER_RP',
      );
      //  console.log(data.url_report);
      setReportName(data ? data.url_report : '');
      setIsShowReport(true);
    } catch (error) {
      setIsShowReport(false);
      seterrMsg(error);
      setReportName(null);
    }
  }, []);

  return (
    <>
      {isShowReport ? (
        <Iframe
          url={reportName}
          width="100%"
          height={window.innerHeight}
          id="myId"
          className="myClassname"
          display="block"
          position="static"
        />
      ) : (
        <>
          <h1>No Found report</h1>
          <h3>{('Error: ', errMsg)}</h3>
        </>
      )}
    </>
  );
}
