import React from 'react';
import { translate } from 'react-switch-lang';
import { Header, Icon } from 'semantic-ui-react';
import { Typography } from '@material-ui/core';
import 'typeface-roboto';

function StdTitle(props) {
  const { t } = props;
  return (
    <>
      <Header>
        <Icon.Group size="big">
          <Icon name={props.icon} />
          <Icon corner name={props.iconaction} />
        </Icon.Group>
        <Header.Content>
          {props.content}
          {<Header.Subheader>{props.subHeader}</Header.Subheader>}
        </Header.Content>
      </Header>
    </>
  );
}

export default translate(StdTitle);
