import React from 'react';

import { Card, Button } from 'semantic-ui-react';

export const ImageCard = ({ name, src, index, deleteImage, readOnly }) => {
  const onDelete = (e) => {
    e.preventDefault();
    deleteImage(index);
  };
  return (
    <>
      <Card raised>
        {!!src && src !== '' && <img src={src} height={200} alt="asset" />}
        <Card.Content>
          <Card.Meta>
            <Button content="delete" onClick={onDelete} disabled={readOnly} />
          </Card.Meta>
          <Card.Description>{name}</Card.Description>
        </Card.Content>
      </Card>
    </>
  );
};
