import React from 'react';
import { translate } from 'react-switch-lang';
import { Header, Icon } from 'semantic-ui-react';
import 'typeface-roboto';

function StdSubtitle({ content, inverted, icon, color }) {
    return (
        <>
            <Header
                as="h3"
                inverted={inverted}
                icon={icon}
                color={(!!color) ? 'blue' : color}
                content={content}
                dividing

            />

        </>
    );
}

export default translate(StdSubtitle);
