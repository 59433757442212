export const siteConstants = {
  API_INSERT: '/api/site/insert',
  API_UPDATE: '/api/site/update',
  API_DELETE: '/api/site/delete',
  API_GET: '/api/site/',
  API_GETALL: '/api/site/getall',

  INSERT_REQUEST: 'SITE_INSERT_REQUEST',
  INSERT_SUCCESS: 'SITE_INSERT_SUCCESS',
  INSERT_FAILURE: 'SITE_INSERT_FAILURE',

  UPDATE_REQUEST: 'SITE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'SITE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'SITE_UPDATE_FAILURE',

  DELETE_REQUEST: 'SITE_DELETE_REQUEST',
  DELETE_SUCCESS: 'SITE_DELETE_SUCCESS',
  DELETE_FAILURE: 'SITE_DELETE_FAILURE',

  GET_REQUEST: 'SITE_GET_REQUEST',
  GET_SUCCESS: 'SITE_GET_SUCCESS',
  GET_FAILURE: 'SITE_GET_FAILURE',

  GETALL_REQUEST: 'SITE_GETALL_REQUEST',
  GETALL_SUCCESS: 'SITE_GETALL_SUCCESS',
  GETALL_FAILURE: 'SITE_GETALL_FAILURE',

  RESET: 'SITE_RESET',
  SERVICE: 'SITE_SERVICE',
};
