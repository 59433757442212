import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { translate } from 'react-switch-lang';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardActionArea,
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
  TextField,
  Button,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import AssetTable from '../../../_InventoryFlagments/assettable';
import SearchIcon from '@material-ui/icons/Search';
import StdDatatable from '../../../_InventoryFlagments/StdDatatable';
// import { clearItem , selectedItem} from '../../../_InventoryFlagments/redux/actions/assetsearch.action';
import {
  addItem,
  ResetItem,
  actionReadOnly,
} from '../components/redux/actions/borrow.additem.action';
import { BorrowService as service } from '../../../../../_services/transaction/asset.borrow.service';
import { assetService } from '../../../../../_services/asset.service';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  card: {
    // height: 100,
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
  },
  btnSave: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnBack: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
}));

function Borrowadditem(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, history, register, setValue } = props;

  //redux
  const storageAsset = useSelector((state) => state.assetSearch);

  //state
  const initValue = [
    {
      assetCode: null,
      assetDescription: null,
      serial: null,
      quanitity: null,
      remark2: null,
    },
  ];
  const [values, setValues] = useState([]);
  const [item, setItem] = useState(initValue);
  const [open, setOpen] = React.useState(false);
  const searchInput = useRef(null);
  const assetCodeInputFocus = useRef();

  let loading = false;
  //useEffects
  useEffect(() => {
    if (!!props.code) {
      loading = true;
      if (loading) {
        service
          .getItem(props.code)
          .then((res) => {
            loading = false;
            // console.log(res.data.result.assetBorrowDetail);
            setValues(...values, res.data.result.assetBorrowDetail);
            dispatch(addItem(res.data.result.assetBorrowDetail));
            dispatch(ResetItem);
          })
          .catch((error) => {
            loading = false;
            window.alert('error', error);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (storageAsset.closing) {
      setItem({
        assetCode: storageAsset.Code,
        assetDescription: storageAsset.Description,
        serial: storageAsset.Serial,
        quanitity: 1,
        remark: '',
      });

      setOpen(false);
    }
  }, [storageAsset]);

  //Events
  const handleChange = (event) => {
    // console.log(event.target.name, ' ', event.target.value);
    setItem({
      ...item,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnKeyDown = (event) => {
    if (!!item) {
      setItem([]);
    }
    assetService
      .getAssetItemBySerial(event.target.value)
      .then((res) => {
        try {
          console.log(res);
          if (res == null || res == '') {
            return window.alert('Not Found Serial. Please find again');
          }
          setItem({
            ...item,
            assetCode: res.data.results[0].Code,
            assetDescription: res.data.results[0].Description,
            serial: res.data.results[0].Serial,
            quanitity: '1',
            remarks: '',
          });
          searchInput.current.focus();
        } catch (error) {
          window.alert(`Error: ${JSON.stringify(error)}`);
          setItem([]);
          assetCodeInputFocus.current.focus();
        }
      })
      .catch((err) => {
        setItem([]);
        return window.alert('Not Found Serial. Please find again');
      });
  };

  const handleClickOpenPopup = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitItemAsync = async () => {
    if (item.assetCode != null) {
      setValues([item]);
      setItem(initValue);
      dispatch(ResetItem);
      assetCodeInputFocus.current.focus();
    } else {
      window.alert('Please find asset code first');
      assetCodeInputFocus.current.focus();
    }
  };

  return (
    <>
      <Card component="div" square>
        <CardHeader
          className={classes.cardHeader}
          title={
            <>
              <Grid container direction="row">
                <Grid item xs={12} sm={8}>
                  <Typography variant="h6">Items</Typography>
                </Grid>
                <Grid item style={{ textAlign: 'end' }} xs={12} sm={4}></Grid>
              </Grid>
            </>
          }
        ></CardHeader>
        <CardContent>
          <Grid justify="center" container direction="row" spacing={1}>
            {!props.readOnly && (
              <>
                <Grid item xs={12} md={4}>
                  <FormControl margin="dense" required fullWidth size="small">
                    <FormLabel component="legend">Asset Code</FormLabel>
                    <OutlinedInput
                      name="assetCode"
                      inputRef={assetCodeInputFocus}
                      type="text"
                      // inputRef={register()}
                      value={[item.assetCode]}
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Tab') {
                          //console.log(e.target.value);
                          handleOnKeyDown(e);
                          // write your functionality here
                        }
                      }}
                      // disabled={props.readOnly}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disabled={props.readOnly}
                            aria-label="search"
                            size="small"
                            onClick={() => {
                              handleClickOpenPopup();
                            }}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={0}
                    />
                    <FormHelperText>
                      {props.errors.assetCode?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl margin="dense" fullWidth size="small">
                    <FormLabel component="legend">assetDescription</FormLabel>
                    <TextField
                      name="assetDescription"
                      size="small"
                      variant="outlined"
                      value={[item.assetDescription]}
                      //inputRef={register()}
                      onChange={handleChange}
                      disabled
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl margin="dense" fullWidth size="small">
                    <FormLabel component="legend">Asset Serial</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="serial"
                      value={[item.serial]}
                      // inputRef={register()}
                      onChange={handleChange}
                      disabled
                    />
                    <FormHelperText>
                      {props.errors.serial?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControl margin="dense" fullWidth size="small">
                    <FormLabel component="legend">quanitity</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="quanitity"
                      type="number"
                      value={[item.quanitity]}
                      // inputRef={register()}
                      onChange={handleChange}
                      disabled
                    />
                    <FormHelperText>
                      {props.errors.quanitity?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl margin="dense" fullWidth size="small">
                    <FormLabel component="legend">Remark</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="remark"
                      value={[item.remark]}
                      //inputRef={register()}
                      onChange={handleChange}
                    />
                    <FormHelperText>
                      {props.errors.remark?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControl margin="dense" fullWidth size="small">
                    <FormLabel component="legend">Action</FormLabel>
                    <Button
                      fullWidth
                      ref={searchInput}
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        onSubmitItemAsync(e);
                        return e;
                      }}
                    >
                      Add
                    </Button>
                  </FormControl>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={12}>
              <AssetTable getItems={values} readOnly={props.readOnly} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActionArea>Action</CardActionArea>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Asset Master Search</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please find Asset Master as below:
          </DialogContentText>
          <div>
            <StdDatatable />
          </div>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleClose}>
            Search
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default translate(Borrowadditem);
