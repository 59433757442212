import { AssetTransferConstants as Constant } from '../../_constants/transaction';

const initialState = {};
export function assetTranserStorage(state = initialState, action) {
  switch (action.type) {
    case Constant.INSERT_REQUEST:
      return {
        ...state,
        insertRequest: true,
        insertSuccess: false,
        insertFailure: false,
      };
    case Constant.INSERT_SUCCESS:
      return {
        ...state,
        insertRequest: false,
        insertSuccess: true,
        insertFailure: false,
        data: action.data,
      };
    case Constant.INSERT_FAILURE:
      return {
        ...state,
        insertRequest: false,
        insertSuccess: false,
        insertFailure: true,
        error: action.error,
      };

    case Constant.UPDATE_REQUEST:
      return {
        ...state,
        updateRequest: true,
        updateSuccess: false,
        updateFailure: false,
      };
    case Constant.UPDATE_SUCCESS:
      return {
        ...state,
        updateRequest: false,
        updateSuccess: true,
        updateFailure: false,
        data: action.data,
      };
    case Constant.UPDATE_FAILURE:
      return {
        ...state,
        updateRequest: false,
        updateSuccess: false,
        updateFailure: true,
        error: action.error,
      };

    case Constant.DELETE_REQUEST:
      return {
        ...state,
        deleteRequest: true,
        deleteSuccess: false,
        deleteFailure: false,
      };
    case Constant.DELETE_SUCCESS:
      return {
        ...state,
        deleteRequest: false,
        deleteSuccess: true,
        deleteFailure: false,
        data: action.data,
      };
    case Constant.DELETE_FAILURE:
      return {
        ...state,
        deleteRequest: false,
        deleteSuccess: false,
        deleteFailure: true,
        error: action.error,
      };

    case Constant.GET_REQUEST:
      return {
        ...state,
        getRequest: true,
        getSuccess: false,
        getFailure: false,
      };
    case Constant.GET_SUCCESS:
      return {
        ...state,
        getRequest: false,
        getSuccess: true,
        getFailure: false,
        data: action.data,
      };
    case Constant.GET_FAILURE:
      return {
        ...state,
        getRequest: false,
        getSuccess: false,
        getFailure: true,
        error: action.error,
      };

    case Constant.GETALL_REQUEST:
      return {
        ...state,
        getAllRequest: true,
        getAllSuccess: false,
        getAllFailure: false,
      };
    case Constant.GETALL_SUCCESS:
      return {
        ...state,
        getAllRequest: false,
        getAllSuccess: true,
        getAllFailure: false,
        data: action.data,
      };
    case Constant.GETALL_FAILURE:
      return {
        ...state,
        getAllRequest: false,
        getAllSuccess: false,
        getAllFailure: true,
        error: action.error,
      };
    case Constant.RESET:
      return {};
    default:
      return {}; // if return state, insertRequest, insertSuccess, etc., will remain the same value
  }
}
