
import React from "react";
import { InputSelect, } from '../formField/controllerFieldMUI';
import {
    businessAreaConstants as constants,
} from "../../_constants";

const BusinessAreaInput = ({ name, control, label, placeholder, error, readOnly, required, onChange, filter }) => {

    return (
        <>
            <InputSelect
                name={name}
                control={control}
                label={label}
                placeholder={placeholder}
                error={error}
                onChange={onChange}
                filter={filter}
                service={constants.SERVICE}
                readOnly={readOnly}
                required={required}
            />
        </>
    );

}

export default BusinessAreaInput