export const assetModelConstants = {
  API_INSERT: '/api/class/subclass/group/model/insert',
  API_UPDATE: '/api/class/subclass/group/model/update',
  API_DELETE: '/api/class/subclass/group/model/delete',
  API_GET: '/api/class/subclass/group/model/',
  API_GETALL: '/api/class/subclass/group/model/getall',

  INSERT_REQUEST: 'ASSET_MODEL_INSERT_REQUEST',
  INSERT_SUCCESS: 'ASSET_MODEL_INSERT_SUCCESS',
  INSERT_FAILURE: 'ASSET_MODEL_INSERT_FAILURE',

  UPDATE_REQUEST: 'ASSET_MODEL_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ASSET_MODEL_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ASSET_MODEL_UPDATE_FAILURE',

  DELETE_REQUEST: 'ASSET_MODEL_DELETE_REQUEST',
  DELETE_SUCCESS: 'ASSET_MODEL_DELETE_SUCCESS',
  DELETE_FAILURE: 'ASSET_MODEL_DELETE_FAILURE',

  GET_REQUEST: 'ASSET_MODEL_GET_REQUEST',
  GET_SUCCESS: 'ASSET_MODEL_GET_SUCCESS',
  GET_FAILURE: 'ASSET_MODEL_GET_FAILURE',

  GETALL_REQUEST: 'ASSET_MODEL_GETALL_REQUEST',
  GETALL_SUCCESS: 'ASSET_MODEL_GETALL_SUCCESS',
  GETALL_FAILURE: 'ASSET_MODEL_GETALL_FAILURE',

  RESET: 'ASSET_MODEL_RESET',
  SERVICE: 'ASSET_MODEL_SERVICE',
};
