import React from 'react';
import { translate } from 'react-switch-lang';

import { Form, Divider, Header, Icon } from 'semantic-ui-react';

import {
  InputNumberUncontrolled,
  InputSelect,
} from '../../../../_fragments/formField/controllerFieldMUI';
// import StdSubtitle from '../../../../_fragments/Title/StdSubtitle';

import { uomConstants as constants } from '../../../../_constants';

const Dimension = (props) => {
  const { errors, control, readOnly } = props;

  return (
    <>
      {/* <StdSubtitle content={t('dimension')} color="blue" /> */}

      <Form.Group widths={2}>
        <InputNumberUncontrolled
          name="Height"
          control={control}
          label="height"
          placeholder="height"
          error={
            !!errors.Height && {
              content: errors.Height.message,
            }
          }
          readOnly={readOnly}
          required
        />

        <InputNumberUncontrolled
          name="Width"
          control={control}
          label="width"
          placeholder="width"
          error={
            !!errors.Width && {
              content: errors.Width.message,
            }
          }
          readOnly={readOnly}
        />
      </Form.Group>
      <Form.Group widths={2}>
        <InputNumberUncontrolled
          name="Depth"
          control={control}
          label="depth"
          placeholder="depth"
          error={
            !!errors.Depth && {
              content: errors.Depth.message,
            }
          }
          readOnly={readOnly}
        />

        <InputNumberUncontrolled
          name="Weight"
          control={control}
          label="weight"
          placeholder="weight"
          error={
            !!errors.Weight && {
              content: errors.Weight.message,
            }
          }
          readOnly={readOnly}
        />

        <Divider horizontal>
          <Header as="h4">
            <Icon name="cubes" />
            Division
          </Header>
        </Divider>
      </Form.Group>
      <InputSelect
        name="DimensionUomId"
        control={control}
        label="Dimension unit"
        placeholder="Dimension unit"
        error={
          !!errors.DimensionUomId && {
            content: errors.DimensionUomId.message,
          }
        }
        filter={(el) => {
          return el.uomGroup === 'Dimension';
        }}
        service={constants.SERVICE}
        readOnly={readOnly}
        required
      />
      <InputSelect
        name="WeightUomId"
        control={control}
        label="weight unit"
        placeholder="weight unit"
        error={
          !!errors.WeightUomId && {
            content: errors.WeightUomId.message,
          }
        }
        filter={(el) => {
          return el.uomGroup === 'Weight';
        }}
        service={constants.SERVICE}
        readOnly={readOnly}
      />
    </>
  );
};

export default translate(Dimension);
