import React from 'react';
import { translate } from 'react-switch-lang';

import { Divider, Header, Icon } from 'semantic-ui-react';
import {
  InputText,
  InputTextUncontrolled,
  InputSelect,
} from '../../../../_fragments/formField/controllerFieldMUI';
import { InputToggle } from '../../../../_fragments/formField/controllerField';

import { uomConstants, assetConditionConstants } from '../../../../_constants';
const Info = (props) => {
  const { t, errors, control, readOnly, isUpdate } = props;

  return (
    <>

      <InputTextUncontrolled
        name="Code"
        control={control}
        label="code"
        placeholder="code"
        error={
          !!errors.Code && {
            content: errors.Code.message,
          }
        }
        readOnly={readOnly || isUpdate}
      />


      <InputTextUncontrolled
        name="Barcode"
        control={control}
        label="barcode"
        placeholder="barcode"
        error={
          !!errors.Barcode && {
            content: errors.Barcode.message,
          }
        }
        readOnly={readOnly}
      />
      <InputTextUncontrolled
        name="ReferenceCode"
        control={control}
        label="referenceCode"
        placeholder="referenceCode"
        error={
          !!errors.ReferenceCode && {
            content: errors.ReferenceCode.message,
          }
        }
        readOnly={readOnly}
      />
      <InputTextUncontrolled
        name="Part"
        control={control}
        label="partNumber"
        placeholder="partNumber"
        error={
          !!errors.partNumber && {
            content: errors.partNumber.message,
          }
        }
        readOnly={readOnly}
      />
      <Divider horizontal>
        <Header as="h4">
          <Icon name="text width" />
          Division I
        </Header>
      </Divider>

      <InputTextUncontrolled
        name="Description"
        control={control}
        label="description"
        placeholder="description"
        error={
          !!errors.description && {
            content: errors.description.message,
          }
        }
        readOnly={readOnly}
      />
      <InputTextUncontrolled
        name="ShortDescription"
        control={control}
        label="shortDescription"
        placeholder="shortDescription"
        error={
          !!errors.shortDescription && {
            content: errors.shortDescription.message,
          }
        }
        readOnly={readOnly}
        required
      />
      <InputTextUncontrolled
        name="Parent"
        control={control}
        label="parent"
        placeholder="parent"
        error={
          !!errors.parent && {
            content: errors.parent.message,
          }
        }
        readOnly={readOnly}
        required
      />
      <InputTextUncontrolled
        name="Color"
        control={control}
        label="color"
        placeholder="color"
        error={
          !!errors.color && {
            content: errors.color.message,
          }
        }
        readOnly={readOnly}
      />
      <Divider horizontal>
        <Header as="h4">
          <Icon name="weight" />
          Division II
        </Header>
      </Divider>

      <InputSelect
        name="AssetUnit"
        control={control}
        label="assetUnit"
        placeholder="assetUnit"
        error={
          errors.assetUnit && {
            content: errors.assetUnit.message,
          }
        }
        filter={(el) => {
          return el.uomGroup === 'Asset';
        }}
        service={uomConstants.SERVICE}
        readOnly={readOnly}
      />
      <InputSelect
        name="AssetCondition"
        control={control}
        label="condition"
        placeholder="condition"
        error={
          errors.assetCondition && {
            content: errors.assetCondition.message,
          }
        }
        service={assetConditionConstants.SERVICE}
        readOnly={readOnly}
      />
      <InputToggle
        name="Serialized"
        control={control}
        label={'isSerialized'}
        readOnly={readOnly}
      />
      <InputToggle
        name="Erp"
        control={control}
        label={'ERP'}
        readOnly={readOnly}
      />
    </>
  );
};

export default translate(Info);
