import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from 'material-table';
import {
  assetAccountTypeService,
  jQGridService,
  roleService,
} from '../../_services';
import { tableIcons } from './tableIcons';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useAlert, positions } from 'react-alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import palettle from '../../theme/palette';

import SelectType from './DatatableComponents/SelectType';

const tableRef = React.createRef();

const CustomerDatatableFilter = (props) => {
  const alert = useAlert();
  const menuCode = props.menuCode;
  const user = JSON.parse(localStorage.getItem('user'));
  const [actions, setActions] = React.useState([]);
  const role = user.roleinfo; // {code, description}

  const [typeOptions, setTypeOptions] = useState([]);
  const headers = [
    {
      title: 'Account Type',
      field: 'Account_Type',
      filterComponent: (props) => (
        <SelectType {...props} options={typeOptions} />
      ),
    },
    { title: 'Account', field: 'Account', defaultFilter: '' },
    { title: 'Code', field: 'Code', defaultFilter: '' },
    { title: 'Name', field: 'Name', defaultFilter: '' },
    { title: 'Active', field: 'Active', filtering: false },
    { title: 'CreateDate', field: 'CreateDate', filtering: false },
    { title: 'CreateBy', field: 'CreateBy', filtering: false },
  ];

  var addAction = {
    icon: () => <AddIcon />,
    tooltip: 'Add new',
    isFreeAction: true,
    onClick: (e) => {
      e.preventDefault();
      props.history.push({
        pathname: `${props.path.add}`,
        state: { isReadOnly: false, action: 'create' },
      });
    },
  };
  var viewAction = {
    icon: () => <VisibilityIcon />,
    tooltip: 'View',
    onClick: (e, rowData) => {
      e.preventDefault();
      props.history.push({
        pathname: `${props.path.edit}/${
          rowData.Code || rowData.Asset_Check_Id
        }`,
        state: {
          isReadOnly: true,
          action: 'view',
          status: rowData.Status,
        },
      });
    },
  };
  var editAction = {
    icon: () => <EditIcon />,
    tooltip: 'edit',
    onClick: (e, rowData) => {
      e.preventDefault();
      props.history.push({
        pathname: `${props.path.edit}/${
          rowData.Code || rowData.Asset_Check_Id
        }`,
        state: {
          isReadOnly: false,
          action: 'edit',
          status: rowData.Status,
        },
      });
    },
  };
  var deleteAction = {
    icon: () => <DeleteIcon />,
    tooltip: 'Delete',
    onClick: (e, rowData) => {
      e.preventDefault();
      const confirm = window.confirm(
        `you want to delete this document no. ${rowData.Code} ?`,
      );

      if (confirm) {
        props.service
          .Delete(rowData.Code)
          .then((res) => {
            if (res === 'OK') {
              const options = {
                position: positions.BOTTOM_RIGHT,
                timeout: 2000,
              };
              alert.success(JSON.stringify(`Deleted Successfull`), options);
              tableRef.current && tableRef.current.onQueryChange();
            } else {
              //console.log(res);
              const options = {
                position: positions.BOTTOM_RIGHT,
                timeout: 2000,
              };
              alert.error(JSON.stringify(`Delete Not Successfull`), options);
              tableRef.current && tableRef.current.onQueryChange();
            }
          })
          .catch((err) => {
            const options = {
              position: positions.BOTTOM_RIGHT,
              type: 'error',
              timeout: 4000,
            };
            //alert.show(JSON.stringify(err), options);
          });
      }
    },
  };

  useEffect(() => {
    let isCancelled = false;
    roleService.getRolePermissions(role.code).then((res) => {
      if (!isCancelled) {
        var menuPermission = res.data.results.find(
          (o) => o.menuCode === menuCode,
        );

        //console.log(menuPermission);
        var actionList = [];
        if (menuPermission.new_permission) {
          actionList.push(addAction);
        }
        if (menuPermission.edit_permission) {
          actionList.push(editAction);
        }
        if (menuPermission.view_permission) {
          actionList.push(viewAction);
        }
        if (menuPermission.delete_permission) {
          actionList.push(deleteAction);
        }
        setActions(actionList);
      }
    });
    if (!isCancelled) {
      assetAccountTypeService.getAllItem().then((res) => {
        setTypeOptions(res.data.results);
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        tableRef={tableRef}
        actions={actions}
        columns={headers}
        data={(query) =>
          new Promise((resolve, reject) => {
            try {
              var query_filters = query.filters;
              var extracted_filters = {
                req_account: '',
                req_accountType: '',
                req_code: '',
                req_name: '',
              };
              if (query_filters.length === 0) {
                console.log('empty filter');
              } else {
                query_filters.forEach((fil) => {
                  if (fil.column.field === 'Account_Type') {
                    extracted_filters.req_accountType = fil.value;
                  } else if (fil.column.field === 'Account') {
                    extracted_filters.req_account = fil.value;
                  } else if (fil.column.field === 'Code') {
                    extracted_filters.req_code = fil.value;
                  } else if (fil.column.field === 'Name') {
                    extracted_filters.req_name = fil.value;
                  }
                });
              }
              jQGridService
                .search_customer_filter(
                  'Customer_Freezer.xml',
                  query.page + 1,
                  query.pageSize,
                  extracted_filters,
                )
                .then((res) => {
                  console.log('table res => ', res.data);
                  if (!!res.data) {
                    resolve({
                      data: res.data.rows,
                      page: res.data.page - 1,
                      totalCount: res.data.records,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: query.page,
                      totalCount: query.totalCount,
                    });
                  }
                });
            } catch (error) {
              console.error(`Err: ${error}`);
              return reject({
                data: [],
                page: 1,
                totalCount: 1,
              });
            }
          })
        }
        options={{
          filtering: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: palettle.background.default,
            color: palettle.primary.main,
          },

          search: false,
        }}
        title={props.title}
      />
    </>
  );
};

export default CustomerDatatableFilter;
