import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { translate } from 'react-switch-lang';
import {
  Header,
  Icon,
  Grid,
  Button,
  Table,
  Label,
  Input,
} from 'semantic-ui-react';
import {
  Typography,
  Paper,
  FormControl,
  FormLabel,
  TextField,
  FormHelperText,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputText,
  InputNumber,
  InputTextAutocomplete,
  InputTextSearch,
} from '../../../../../_fragments/formField/controllerFieldMUI';
import { useSelector, useDispatch } from 'react-redux';
import { addItem, DeleteItem } from '../redux/actions/writeoff.action';
import { clearItem } from '../../../_InventoryFlagments/redux/actions/assetsearch.action';
import { WriteOffService as service } from '../../../../../_services/transaction/asset.writeoff.service';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import StdDatatable from '../../../_InventoryFlagments/StdDatatable';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AssetTable from '../../../_InventoryFlagments/assettable';
import { assetService } from '../../../../../_services/asset.service';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(),
    margin: theme.spacing(1),
  },
  bg: {
    //background: 'linear-gradient(180deg, white 30%, grey 70%)',
    // background: '#bbdefb',
  },
}));

const WriteOffDetails = (props) => {
  const { t } = props;

  const [Adding, setAdding] = useState(false);
  const [AssetCode, setAssetCode] = useState('');
  const [Description, setDescription] = useState('');
  const [Serial, setSerial] = useState('');
  const [Quantity, setQuantity] = useState(1);
  const [returnQuanitity, setReturnQuanitity] = useState(1);
  const [Remark, setRemark] = useState('');
  const [itemIndex, setItemIndex] = useState(-1);
  const [submit, setsubmit] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const searchInput = useRef(null);
  const assetCodeInputFocus = useRef(null);

  /// Redux ///
  const dispatch = useDispatch();
  const storage = useSelector((state) => state.ownerSearch);
  const storageAsset = useSelector((state) => state.assetSearch);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);
  /// --- Redux  ----///
  let loading = false;
  ///--- Dialog ---///
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleOnKeyDown = (event) => {
    assetService.getAssetItemBySerial(event.target.value).then((res) => {
      // console.log(res.data.results[0]);
      try {
        setItems({
          ...items,
          assetCode: res.data.results[0].Code,
          assetDescription: res.data.results[0].Description,
          serial: res.data.results[0].Serial,
          quanitity: '1',
          remarks: '',
        });
        searchInput.current.focus();
      } catch (error) {
        window.alert('Not Found Serial. Please find again');
        setItems([]);
        assetCodeInputFocus.current.focus();
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  ///--- Dialog ---///

  useEffect(() => {
    if (!!props.code) {
      loading = true;
      if (loading) {
        service
          .getItem(props.code)
          .then((res) => {
            loading = false;
            //  console.log(res.data.result.assetWriteOffDetail);
            setItems(...items, res.data.result.assetWriteOffDetail);
            dispatch(addItem(res.data.result.assetWriteOffDetail));
          })
          .catch((error) => {
            loading = false;
            window.alert('error', error);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (storageAsset.closing) {
      setAssetCode(storageAsset.Code);
      setSerial(storageAsset.Serial);
      setDescription(storageAsset.Description);
      setRemark(storageAsset.Remark);
      setOpen(false);
      setError(false);
    }
  }, [storageAsset]);

  useEffect(() => {
    if (Adding) {
      setTimeout(() => {
        dispatch(addItem(items));
        setAdding(false);
        setAssetCode('');
        setSerial('');
        setDescription('');
        setQuantity(0);
        setReturnQuanitity(0);
        setRemark('');
      }, 300);
    }

    setsubmit(false);

    return () => {
      dispatch(clearItem());
      setsubmit(false);
    };
  }, [items, submit, Quantity]);

  //get borrow data reference document...
  useEffect(() => {
    if (storage.closing) {
      const datamap = storage.assetBorrowDetail.map((item) => {
        return {
          ...item,
          quanitity: item.quanitity,
          returnQuanitity: item.quanitity,
        };
      });
      setItems(datamap);
    }
  }, [storage]);

  const onSubmitAddItem = () => {
    setsubmit(true);
    setItems([
      {
        assetCode: AssetCode,
        assetDescription: Description,
        serial: Serial,
        quanitity: 1,
        remark: Remark,
      },
    ]);
  };

  return (
    <>
      <Paper elevation={1} variant="outlined">
        <Header dividing>
          <Typography variant="h6" color="textPrimary">
            <Icon name="add" />
            {t('Add Item')}
          </Typography>
        </Header>

        {!props.readOnly && (
          <div>
            <Grid padded="horizontally">
              <Grid.Row>
                <Grid.Column computer={4} tablet={16} mobile={16}>
                  <FormControl
                    margin="dense"
                    fullWidth
                    required={true}
                    error={null}
                    size="small"
                  >
                    <FormLabel component="legend">Asset</FormLabel>
                    <Input
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isEdit) {
                          setOpen(true);
                        }
                      }}
                      onChange={(e) => {
                        // console.log(e.target.value);
                      }}
                      error={error}
                      icon
                      size="small"
                      placeholder="Search..."
                      value={AssetCode}
                      ref={assetCodeInputFocus}
                    >
                      <input size="small" name="assetCode" />
                      <Icon name="search" />
                    </Input>
                  </FormControl>
                </Grid.Column>
                <Grid.Column computer={5} tablet={16} mobile={16}>
                  <FormControl fullWidth margin="dense" size="small">
                    <FormLabel component="legend">Description</FormLabel>
                    <Input
                      size="small"
                      readOnly
                      defaultValue={storageAsset.Description}
                      value={Description}
                    />
                  </FormControl>
                </Grid.Column>

                <Grid.Column computer={5} tablet={16} mobile={16}>
                  <FormControl margin="dense" fullWidth size="small">
                    <FormLabel component="legend">Serial</FormLabel>
                    <Input
                      size="small"
                      placeholder="Serial"
                      defaultValue={storageAsset.Serial}
                      value={Serial}
                      readOnly
                    >
                      <input placeholder="Serial" size="small" name="serial" />
                    </Input>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid.Column>
                <Grid.Column computer={2} tablet={16} mobile={16}>
                  <InputNumber
                    name="quanitity"
                    control={props.control}
                    label="Quanitity"
                    placeholder="input "
                    error={
                      !!props.errors.quanitity && {
                        content: props.errors.quanitity.message,
                      }
                    }
                    defaultValue={Quantity}
                    value={Quantity}
                    onChange={([e, _]) => {
                      setQuantity(e.target.value);
                      return e;
                    }}
                  />
                </Grid.Column>

                <Grid.Column computer={5} tablet={16} mobile={16}>
                  <InputText
                    name="remark"
                    control={props.control}
                    label="Remark"
                    placeholder="Remark"
                    error={
                      !!props.errors.code && {
                        content: props.errors.code.message,
                      }
                    }
                    defaultValue={Remark}
                    value={Remark}
                    onChange={([e]) => {
                      setRemark(e.target.value);
                      return e;
                    }}
                  />
                </Grid.Column>
                <Grid.Column computer={1} tablet={12} mobile={16}>
                  <FormControl margin="dense" fullWidth>
                    <FormLabel component="legend">Add</FormLabel>
                    <Link
                      ref={searchInput}
                      onClick={() => {
                        if (!!AssetCode) {
                          setAdding(true);
                          onSubmitAddItem();
                          setError(false);
                        } else {
                          setError(true);
                        }
                      }}
                    >
                      <AddBoxIcon style={{ fontSize: 42 }} color="primary" />
                    </Link>
                  </FormControl>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}
      </Paper>
      <AssetTable getItems={items} readOnly={props.readOnly} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Asset Master Search</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please find Asset Master as below:
          </DialogContentText>
          <div>
            <StdDatatable />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" onClick={handleClose}>
            Search
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default translate(WriteOffDetails);
