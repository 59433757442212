import React from 'react';
import { translate } from 'react-switch-lang';

import StdSubtitle from '../../../../_fragments/Title/StdSubtitle';
import { InputSelect } from '../../../../_fragments/formField/controllerFieldMUI';
import { costCenterConstants as constants } from '../../../../_constants';
const CostCenter = (props) => {
  const { t, errors, control, readOnly } = props;

  return (
    <>
      {/* <StdSubtitle content={t('costcenter')} color='blue' /> */}

      <InputSelect
        name="CostCenter"
        control={control}
        label="cost center"
        placeholder="cost center"
        error={
          !!errors.CostCenter && {
            content: errors.CostCenter.message,
          }
        }
        service={constants.SERVICE}
        readOnly={readOnly}
      />
    </>
  );
};

export default translate(CostCenter);
