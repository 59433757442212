import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { setLanguage, translate, getLanguage } from 'react-switch-lang';
import './header.css';
import { ListItem, ListItemText } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import { useAssetRefFormStyles } from '../muiStyles';
import { Flag } from 'semantic-ui-react';
// import Moment from 'react-moment';
// import moment from 'moment';
import moment from 'moment-timezone';
import {
  Dropdown,
  Button,
  Menu,
  Header as SemanticHeader,
} from 'semantic-ui-react';
import './header.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(4),
    // backgroundColor: "#e1f5fe",
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      color: 'black',
      background: 'linear-gradient(45deg, #e1f5fe 30%, #fafafa 90%)',
    },
  },
}));
function Header(props) {
  const { t } = props;
  const [time, setTime] = useState(moment().format('MMMM Do YYYY, h:mm:ss a'));
  const classes = useStyles();
  useEffect(() => {
    setInterval(() => {
      setTime(moment().format('MMMM Do YYYY, h:mm:ss a'));
    }, 1000);
  }, [time]);
  return (
    <>
      <Menu stackable>
        <Menu.Item>
          <SemanticHeader
            color="blue"
            block
            icon="idea"
            content="Fixed Asset"
          />
        </Menu.Item>


        <Menu.Menu position="right">
          <Menu.Item>
            <Button
              icon="unordered list"
              onClick={props.toggleSidebar}
              inverted
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </>
  );
}

// function Header(props) {
//   const { t } = props;
//   const [time, setTime] = useState(moment().format('MMMM Do YYYY, h:mm:ss a'));
//   // const [time, setTime] = useState(moment().tz('Asia/Bangkok').utc().format());
//   useEffect(() => {
//     setInterval(() => {
//       setTime(moment().format('MMMM Do YYYY, h:mm:ss a'));
//     }, 1000);
//   }, [time]);
//   return (

//     <div>
//       <nav className="main-header navbar navbar-expand navbar-white navbar-light">
//         <ul className="navbar-nav">
//           <li className="nav-item">
//           </li>
//         </ul>

//         <ul className="navbar-nav ml-auto">
//           <li className="nav-item">

//           </li>

//           <li className="nav-item">

//             <ListItem button>
//               <AccessTimeIcon />
//               <ListItemText primary={time} />
//             </ListItem>
//             {/* </span> */}
//           </li>

//           <li>
//             <span className="navbar-text">
//               <Dropdown
//                 button
//                 icon="world"
//                 value={getLanguage()}
//                 className="icon"
//                 labeled
//                 onChange={(_, data) => {
//                   setLanguage(data.value);
//                 }}
//                 selection
//                 options={[
//                   {
//                     key: 'en',
//                     text: (
//                       <span>
//                         <Flag name="us" />
//                         English
//                       </span>
//                     ),
//                     value: 'en',
//                   },
//                   {
//                     key: 'th',
//                     text: (
//                       <span>
//                         <Flag name="th" />
//                         Thai
//                       </span>
//                     ),
//                     value: 'th',
//                   },
//                 ]}
//               >

//               </Dropdown>
//             </span>
//           </li>
//           <li>
//             <span className="navbar-text">
//               <Link type="button" to="/login">
//                 <Button>{t('signOut')}</Button>
//               </Link>
//             </span>
//           </li>
//           <li>
//             <Button icon='menu' onClick={props.toggleSidebar} />
//           </li>
//         </ul>
//       </nav>
//     </div>
//   );
// }

export default translate(Header);
