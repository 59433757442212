import { alertConstants as constants } from '../_constants';

export function alert(state = {}, action) {
  switch (action.type) {
    case constants.SUCCESS:
      return {
        success: true,
        type: 'alert-sucess',
        message: action.message,
      };

    case constants.ERROR:
      return {
        error: true,
        type: 'alert-danger',
        message: action.message,
      };

    case constants.CLEAR:
      return {};
    default:
      return state;
  }
}
