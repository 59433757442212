import React from 'react';
import { translate } from 'react-switch-lang';

import { InputSelect } from '../../../../_fragments/formField/controllerFieldMUI';
import StdSubtitle from '../../../../_fragments/Title/StdSubtitle';

import { ownerConstants as owner_constants } from '../../../../_constants';

const Owner = (props) => {
  const { t, errors, control, readOnly } = props;
  return (
    <>

      {/* <InputSelect
        name="owner"
        control={control}
        label="owner"
        placeholder="owner"
        error={
          !!errors.owner && {
            content: errors.owner.message,
          }
        }
        service={owner_constants.SERVICE}
        readOnly={readOnly}
      /> */}

    </>
  );
};

export default translate(Owner);
