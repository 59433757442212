import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Card, Grid, Message, Form } from 'semantic-ui-react';
import { translate } from 'react-switch-lang';
import { Title, SaveButton, ClearButton } from '../../../../_fragments';
import { default as Header } from '../../_InventoryFlagments/_InventoryHeader';
import { default as Location } from '../../_InventoryFlagments/_inventoryLocation';
import { default as Additem } from '../../_InventoryFlagments/_InventoryAdditem';
// import { default as Footer } from '../../_InventoryFlagments/_InventoryFooter';

//****Redux & alert**** */
import { commonAlert } from '../../../../labelConstant';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { assetTransferAction as action } from '../../../../_action/transaction/asset.transfer.action';
//****Redux & alert**** */

const Stocktransferinsert = (props) => {
  const { t } = props;
  const { handleSubmit, errors, control, setValue } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [submitstatus, setsubmitstatus] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const storage = useSelector((state) => state.assetTranserStorage);

  const onSubmit = (data, e) => {
    e.preventDefault();
    setSubmitting(true);
    console.log('submitting....');

    const dataMappingApi = {
      ...data,
      sourceZone: data.sourceZone.value,
      destinationZone: data.destinationZone.value,
    };
    dispatch(action.insertItem(dataMappingApi));
  };

  React.useEffect(() => {
    if (storage.insertSuccess === true) {
      console.log('storage.insertSuccess: ', storage.insertSuccess);
      alert.success(t(commonAlert.ALERT_INSERT_SUCCESS));
      setSubmitting(false);
    } else if (storage.insertFailure === true) {
      console.log('storage.insertFailure: ', storage.insertFailure);
      setSubmitting(false);
      alert.error(t(commonAlert.ALERT_INVALID_INPUT));
    }
  }, [storage]);

  return (
    <>
      <Form
        key="formStockTransfer"
        onSubmit={handleSubmit(onSubmit)}
        // onSubmit={handleSubmit(onSubmit)}
        size="small"
        loading={submitting || props.loading}
        success={props.success}
      >
        <Card centered fluid>
          <Card.Content>
            <Card.Header>
              <Grid divided="vertically" padded>
                <Grid.Column computer={12} tablet={12} mobile={16}>
                  <Title
                    icon="cubes"
                    iconaction="exchange"
                    content="Asset Transfer"
                    description="Create"
                    props={props}
                  />
                </Grid.Column>
                <Grid.Column
                  textAlign="right"
                  computer={4}
                  tablet={4}
                  mobile={16}
                >
                  {buttonAction(props.history)}
                </Grid.Column>
              </Grid>
            </Card.Header>
          </Card.Content>
          <Card.Content>
            <Card.Description>
              {submitstatus && (
                <Message
                  {...submitstatus}
                  header="Action Forbidden"
                  content="You can only sign up for an account once with a given e-mail address."
                />
              )}

              <Header
                errors={errors}
                control={control}
                readOnly={props.readOnly}
              />
            </Card.Description>
            <Card.Description>
              <Location
                control={control}
                errors={errors}
                setValue={setValue}
                readOnly={props.readOnly}
              />
            </Card.Description>
            <Card.Description>
              <Additem
                control={control}
                errors={errors}
                readOnly={props.readOnly}
              />
            </Card.Description>
            {/* 
           
            <Card.Description>
              <Grid divided="vertically" columns={2} padded>
                <Grid.Column computer={12} tablet={12} mobile={16}>
                  <Footer
                    control={control}
                    errors={errors}
                    readOnly={props.readOnly}
                  />
                </Grid.Column>
                <Grid.Column
                  textAlign="right"
                  computer={4}
                  tablet={4}
                  mobile={16}
                >
                  {buttonAction(props.history)}
                </Grid.Column>
              </Grid>
            </Card.Description>
         
          */}
          </Card.Content>
        </Card>
      </Form>
    </>
  );
};

export default translate(Stocktransferinsert);

const buttonAction = (history, t) => {
  return (
    <>
      <SaveButton>SAVE</SaveButton>
      <ClearButton history={history}>Back</ClearButton>
    </>
  );
};
//
