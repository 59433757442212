export const assetAccountTypeConstants = {
  API_INSERT: '/api/typeGFT/insert',
  API_UPDATE: '/api/typeGFT/update',
  API_DELETE: '/api/typeGFT/delete',
  API_GET: '/api/typeGFT/',
  API_GETALL: '/api/typeGFT/getall',

  INSERT_REQUEST: 'ASSET_ACCOUNT_TYPE_INSERT_REQUEST',
  INSERT_SUCCESS: 'ASSET_ACCOUNT_TYPE_INSERT_SUCCESS',
  INSERT_FAILURE: 'ASSET_ACCOUNT_TYPE_INSERT_FAILURE',

  UPDATE_REQUEST: 'ASSET_ACCOUNT_TYPE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ASSET_ACCOUNT_TYPE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ASSET_ACCOUNT_TYPE_UPDATE_FAILURE',

  DELETE_REQUEST: 'ASSET_ACCOUNT_TYPE_DELETE_REQUEST',
  DELETE_SUCCESS: 'ASSET_ACCOUNT_TYPE_DELETE_SUCCESS',
  DELETE_FAILURE: 'ASSET_ACCOUNT_TYPE_DELETE_FAILURE',

  GET_REQUEST: 'ASSET_ACCOUNT_TYPE_GET_REQUEST',
  GET_SUCCESS: 'ASSET_ACCOUNT_TYPE_GET_SUCCESS',
  GET_FAILURE: 'ASSET_ACCOUNT_TYPE_GET_FAILURE',

  GETALL_REQUEST: 'ASSET_ACCOUNT_TYPE_GETALL_REQUEST',
  GETALL_SUCCESS: 'ASSET_ACCOUNT_TYPE_GETALL_SUCCESS',
  GETALL_FAILURE: 'ASSET_ACCOUNT_TYPE_GETALL_FAILURE',

  RESET: 'ASSET_ACCOUNT_TYPE_RESET',
  SERVICE: 'ASSET_ACCOUNT_TYPE_SERVICE',
};
