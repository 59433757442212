import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';
import MaterialTable from 'material-table';
import { jQGridService } from '../../../_services';
import { useSelector, useDispatch } from 'react-redux';
import { selectedOwner } from './redux/actions/ownersearch.action';
import { BorrowService as service } from '../../../_services/transaction/asset.borrow.service';

const BorrowDatatable = (props) => {
  const { t, title } = props;
  const [selected, setSelected] = useState(null);
  const [closing, setclosing] = useState(false);
  const dispatch = useDispatch();

  const columns = [
    { title: 'Code', field: 'Code' },
    { title: 'Date', field: 'Date' },
    { title: 'BorrowBy', field: 'BorrowBy' },
    { title: 'Status', field: 'Status' },
  ];

  const onClickBorrow = async (rowdata) => {
    const data = await service.getItem(rowdata.Code);
    const res = await data.data.result;
    dispatch(selectedOwner(res, true));
  };

  return (
    <>
      <MaterialTable
        title="Borrow Document list"
        icons={<tableIcons />}
        actions={[
          {
            icon: () => <h6>choose</h6>,
            tooltip: '',
            onClick: (e, rowData) => {
              e.preventDefault();
              onClickBorrow(rowData);
            },
          },
        ]}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            try {
              jQGridService
                .search(
                  'Asset-Borrow' + '.xml',
                  query.page + 1,
                  query.pageSize,
                  query.search,
                )
                .then((res) => {
                  if (!!res.data) {
                    resolve({
                      data: res.data.rows,
                      page: res.data.page - 1,
                      totalCount: res.data.records,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: query.page,
                      totalCount: query.totalCount,
                    });
                  }
                });
            } catch (error) {
              console.log('error: ', error);
              resolve({
                data: [],
                page: query.page,
                totalCount: query.totalCount,
              });
            }
          })
        }
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#e1f5fe',
            // background: 'linear-gradient(45deg, #b2dfdb 30%, #1976d2 90%)',
            color: 'Black',
          },
          // rowStyle: {
          //   background: 'linear-gradient(45deg, #b2dfdb 30%, #1976d2 90%)',

          // }
        }}
        title={title}
      />
    </>
  );
};

export default translate(BorrowDatatable);
