import * as yup from 'yup'; // for everything
export const schema = yup.object().shape({
  propertyPlate: yup
    .string()
    .min(2, 'code is too short')
    .required('code is required'),
  code: yup
    .string()
    .min(2, 'property plate is too short')
    .required('property plate is required'),

  des: yup
    .string()
    .min(2, 'description is too short')
    .required('description is required'),
});
