import React from 'react';
import { translate } from 'react-switch-lang';
import { StdTitle } from '../../_fragments';

import StdDatatable from './StdDatatable';
const ListingDatatable = (props) => {
  const {
    t,
    header,
    columns,
    filenameXML,
    setSelected,
    setFormOpen,
    setFormType,
    menuCode,
  } = props;
  return (
    <>
      <StdDatatable
        title={
          <StdTitle
            icon="list"
            content={header.name}
            subHeader={t('datatable')}
          />
        }
        columns={columns}
        filenameXML={filenameXML}
        setSelected={setSelected}
        setFormOpen={setFormOpen}
        setFormType={setFormType}
        menuCode={menuCode}
      />
    </>
  );
};

export default translate(ListingDatatable);
