export const alertConstants = {
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR',

  INSERT_SUCCESS: 'INSERT SUCCESS!!',
  INSERT_FALIURE: 'INSERT FAILURE!!',

  UPDATE_SUCCESS: 'UPDATE SUCCESS!!',
  UPDATE_FAILURE: 'UPDATE FAILURE!!',

  DELETE_SUCCESS: 'DELETE SUCCESS!!',
  DELETE_FAILURE: 'DELETE FAILURE!!',
};
