export { model as asset_model } from './asset.model.js';
export { model as assetBrand_model } from './assetBrand.model.js';
export { model as assetClass_model } from './assetClass.model.js';
export { model as assetCondition_model } from './assetCondition.model.js';
export { model as assetGroup_model } from './assetGroup.model.js';
export { model as assetModel_model } from './assetModel.model.js';
export { model as assetSubClass_model } from './assetSubClass.model.js';
export { model as assetSupplier_model } from './assetSupplier.model.js';
export { model as branch_model } from './branch.model.js';
export { model as businessArea_model } from './businessArea.model.js';
export { model as company_model } from './company.model.js';
export { model as costCenter_model } from './costCenter.model.js';
export { model as location_model } from './location.model.js';
export { model as owner_model } from './owner.model.js';
export { model as site_model } from './site.model.js';
export { model as uom_model } from './uom.model.js';
export { model as user_model } from './user.model.js';
export { model as zone_model } from './zone.model.js';
export { model as role_model } from './role.model';
export { model as permission_model } from './permission.model';
export { model as assetAccount_model } from './assetAccount.model';
export { model as assetAccountType_model } from './assetAccountType.model';
export { model as reason_model } from './reason.model';
