export const permissionConstants = {
  API_INSERT: '/api/permission/insert',
  API_UPDATE: '/api/permission/update',
  API_DELETE: '/api/permission/delete',
  API_GET: '/api/permission/',
  API_GETALL: '/api/permission/getall',

  INSERT_REQUEST: 'PERMISSION_INSERT_REQUEST',
  INSERT_SUCCESS: 'PERMISSION_INSERT_SUCCESS',
  INSERT_FAILURE: 'PERMISSION_INSERT_FAILURE',

  UPDATE_REQUEST: 'PERMISSION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PERMISSION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PERMISSION_UPDATE_FAILURE',

  DELETE_REQUEST: 'PERMISSION_DELETE_REQUEST',
  DELETE_SUCCESS: 'PERMISSION_DELETE_SUCCESS',
  DELETE_FAILURE: 'PERMISSION_DELETE_FAILURE',

  GET_REQUEST: 'PERMISSION_GET_REQUEST',
  GET_SUCCESS: 'PERMISSION_GET_SUCCESS',
  GET_FAILURE: 'PERMISSION_GET_FAILURE',

  GETALL_REQUEST: 'PERMISSION_GETALL_REQUEST',
  GETALL_SUCCESS: 'PERMISSION_GETALL_SUCCESS',
  GETALL_FAILURE: 'PERMISSION_GETALL_FAILURE',

  RESET: 'PERMISSION_RESET',
  SERVICE: 'PERMISSION_SERVICE',
};
