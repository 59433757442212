import React from 'react';
import { translate } from 'react-switch-lang';
import { TransactionDatatable } from '../../../_fragments/transactiondatatable';
import { BorrowService as service } from '../../../_services/transaction/asset.borrow.service';
import { Label } from 'semantic-ui-react';
// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';

import moment from 'moment';
// const useStyles = makeStyles((theme) => ({
//   fab: {
//     margin: theme.spacing(2),
//   },
//   absolute: {
//     position: 'absolute',
//     top: theme.spacing(2),
//     right: theme.spacing(3),
//   },
// }));
const Assetborrow = (props) => {
  // const classes = useStyles();
  const { t } = props;
  const header = {
    icon: 'cubes',
    content: 'Asset Borrow',
    subHeader: 'Listing',
  };

  const insertBtn = {
    text: t('Add'),
    link: props.location.pathname + '/insert',
  };

  const actionPath = {
    add: `${props.location.pathname}/insert`,
    edit: `${props.location.pathname}/update`,
    delete: `${props.location.pathname}/delete`,
  };

  const headers = [
    { title: 'Code', field: 'Code' },
    {
      title: 'Date',
      field: 'Data',
      render: (rowData) => moment(rowData).format('DD/MM/YYYY'),
    },
    { title: 'BorrowBy', field: 'BorrowBy' },
    // { title: 'Zone', field: 'Zone' },
    {
      title: 'Status',
      field: 'Status',
      render: (rowData) => (
        <Label color={rowData.Status == 'APPROVE' ? 'green' : 'grey'}>
          {rowData.Status}
        </Label>
      ),
    },
    { title: 'Create By', field: 'CreateBy' },
    // { title: 'Create Date', field: 'CreateDate', type: 'date' },
  ];

  const title = 'Asset Borrow';
  const filenameXML = 'Asset-Borrow';
  const menuCode = 'M_ASSET_BORROW';

  return (
    <>
      <TransactionDatatable
        header={header}
        insertBtn={insertBtn}
        title={title}
        headers={headers}
        filenameXML={filenameXML}
        root={props.root}
        history={props.history}
        path={actionPath}
        service={service}
        menuCode={menuCode}
      />
    </>
  );
};

export default translate(Assetborrow);
