import React from 'react';
// import { Link } from 'react-router-dom';
// import { Button, Table, Menu, Icon, Container } from 'semantic-ui-react';
import MaterialTable from 'material-table';
import { Transactionlisting } from '../../../_fragments/transactiondatatable/';

const Stockreceipt = (props) => {
  const title = 'Asset Receipt';
  const filenameXML = 'asset-receipt';
  const headers = [
    { title: 'Code', field: 'Code' },
    { title: 'Date', field: 'Date' },
    { title: 'Location', field: 'LocationDescription' },
    { title: 'Zone', field: 'ZoneDescription' },
    { title: 'Create By', field: 'CreateBy' },
    { title: 'Create Date', field: 'CreateDate', type: 'date' },
  ];

  console.log(props.location.pathname);
  return (
    <>
      <Transactionlisting
        root={props.location.pathname}
        history={props.history}
        headers={headers}
        title={title}
        filenameXML={filenameXML}
      />
      {/* {TableExampleStriped(props)} */}
    </>
  );
};

const fdata = [
  {
    no: '#0001',
    date: '10/5/2020',
    location: 'Stock A',
    createby: 'test1',
    createdate: '10/5/2020',
  },
  {
    no: '#0002',
    date: '10/5/2020',
    location: 'Stock B',
    createby: 'test1',
    createdate: '10/5/2020',
  },
];

const TableExampleStriped = (props) => (
  <MaterialTable
    name="stockre"
    title="Asset Receipt Listing"
    columns={[
      { title: 'No#', field: 'no' },
      { title: 'Date', field: 'Date' },
      { title: 'Destination', field: 'location' },
      { title: 'Create By', field: 'createby' },
      { title: 'Create Date', field: 'createdate', type: 'date' },
    ]}
    data={fdata}
    options={{
      selection: false,
      search: true,
      sorting: true,
      paging: true,
      actionsColumnIndex: -1,
      filtering: true,
      headerStyle: {
        backgroundColor: '#01579b',
        color: '#FFF',
      },
    }}
    actions={[
      {
        icon: 'add',
        tooltip: 'Add new',
        isFreeAction: true,
        onClick: (e) => {
          e.preventDefault();
          props.history.push('stockreceipt/insert');
        },
      },
      {
        icon: 'edit',
        tooltip: 'edit',
        onClick: (e, rowData) => alert('You will go edit: ' + rowData.no),
      },
      {
        icon: 'delete',
        tooltip: 'Delete',
        onClick: (e, rowData) =>
          window.confirm('You want to delete ' + rowData.no),
      },
    ]}
  />
);

export default Stockreceipt;
