export const reasonConstants = {
  API_INSERT: '/api/reason/insert',
  API_UPDATE: '/api/reason/update',
  API_DELETE: '/api/reason/delete',
  API_GET: '/api/reason/',
  API_GETALL: '/api/reason/getall',

  INSERT_REQUEST: 'REASON_INSERT_REQUEST',
  INSERT_SUCCESS: 'REASON_INSERT_SUCCESS',
  INSERT_FAILURE: 'REASON_INSERT_FAILURE',

  UPDATE_REQUEST: 'REASON_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'REASON_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'REASON_UPDATE_FAILURE',

  DELETE_REQUEST: 'REASON_DELETE_REQUEST',
  DELETE_SUCCESS: 'REASON_DELETE_SUCCESS',
  DELETE_FAILURE: 'REASON_DELETE_FAILURE',

  GET_REQUEST: 'REASON_GET_REQUEST',
  GET_SUCCESS: 'REASON_GET_SUCCESS',
  GET_FAILURE: 'REASON_GET_FAILURE',

  GETALL_REQUEST: 'REASON_GETALL_REQUEST',
  GETALL_SUCCESS: 'REASON_GETALL_SUCCESS',
  GETALL_FAILURE: 'REASON_GETALL_FAILURE',

  RESET: 'REASON_RESET',
  SERVICE: 'REASON_SERVICE',
};
