import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import palettle from '../../../../theme/palette';
import { translate } from 'react-switch-lang';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardActionArea,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  card: {
    // height: 100,
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
  },
  btnSave: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnBack: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
}));

const AssetCheckDetail = (props) => {
  const classes = useStyles();
  const { t, history } = props;
  const [itemData, setItemData] = useState([]);
  const [columns] = useState([
    {
      title: 'assetType',
      field: 'assetType',
    },
    {
      title: 'asset',
      field: 'asset',

      cellStyle: {
        width: '30%',
      },
    },
    {
      title: 'barcode',
      field: 'barcode',

      cellStyle: {
        width: '20%',
      },
    },
    {
      title: 'assetState',
      field: 'assetState',
    },

    // {
    //   title: 'assetImage',
    //   field: 'assetImage',
    //   render: (rowData) => (
    //     <img
    //       src={`http://s3-ap-southeast-1.amazonaws.com/sfa.frozen/assets/AI_3527_20201218_104839.jpg`}
    //       style={{ width: 60 }}
    //     />
    //   ),
    // },
    {
      title: 'remarks',
      field: 'remarks',
    },
  ]);

  return (
    <>
      <Card component="div">
        <CardContent>
          <Grid justify="center" container direction="row" spacing={0}>
            <Grid item xs={12} md={12}>
              <MaterialTable
                title="Asset Check List"
                columns={columns}
                data={props.data.details || {}}
                options={{
                  actionsColumnIndex: -1,
                  headerStyle: {
                    backgroundColor: palettle.background.default,
                    color: palettle.primary.main,
                  },
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActionArea>Action</CardActionArea>
      </Card>
    </>
  );
};

export default AssetCheckDetail;
