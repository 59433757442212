import React from 'react';
// import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import {
  // MuiThemeProvider,
  // createMuiTheme,
  Typography,
  // ThemeProvider,
} from '@material-ui/core';
// import { Button } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
//Fake Data://
const fdata = {
  status: '00',
  results: [
    {
      code: '070420-7WvmfjKAUlGKQdI41jcedZv',
      prefix: 'AS',
      yearValue: 2020,
      monthValue: 4,
      running: 3,
      length: 10,
      lastRunning: 'AS20200400002',
      createDate: '2020-04-07T19:06:43.147',
      createBy: 'aaa',
      modifyDate: '2020-04-07T19:09:07.597',
      modifyBy: '000001',
      active: true,
    },
    {
      code: '050320-NkPFI9JtIjcis1njjjVN7st',
      prefix: 'TEST',
      yearValue: 2020,
      monthValue: 3,
      running: 14,
      length: 5,
      lastRunning: 'TEST20200300013',
      createDate: '2020-03-05T13:53:58.187',
      createBy: '000001',
      modifyDate: '2020-03-05T14:08:29.673',
      modifyBy: '000001',
      active: true,
    },
    {
      code: '050320-C7jmrTdxxM4yjwqc7QWkvqL',
      prefix: 'ACK',
      yearValue: 2020,
      monthValue: 3,
      running: 4,
      length: 5,
      lastRunning: 'ACK20200300003',
      createDate: '2020-03-05T21:39:52.863',
      createBy: 'U001',
      modifyDate: '2020-03-05T21:40:30.57',
      modifyBy: 'U001',
      active: true,
    },
  ],
};

const TableExampleStriped = (props) => (
  <MaterialTable
    name="coderun"
    title="Code Run"
    columns={[
      { title: 'prefix', field: 'prefix' },
      { title: 'yearValue', field: 'yearValue', type: 'numeric' },
      { title: 'monthValue', field: 'monthValue', type: 'numeric' },
      { title: 'running', field: 'running', type: 'numeric' },
      { title: 'length', field: 'length', type: 'numeric' },
      { title: 'lastRunning', field: 'lastRunning' },
      { title: 'active', field: 'active', type: 'boolean' },
    ]}
    data={fdata.results}
    options={{
      selection: false,
      search: true,
      sorting: true,
      paging: true,
      actionsColumnIndex: -1,
      filtering: true,
    }}
    actions={[
      {
        icon: 'add',
        tooltip: 'Add new',
        isFreeAction: true,
        onClick: (e) => {
          e.preventDefault();
          alert('you need to create');
        },
      },
      {
        icon: 'edit',
        tooltip: 'edit',
        onClick: (e, rowData) => alert('You will go edit: ' + rowData.prefix),
      },
      {
        icon: 'delete',
        tooltip: 'Delete',
        onClick: (e, rowData) =>
          window.confirm('You want to delete ' + rowData.prefix),
      },
    ]}
  />
);

function coderun(props) {
  return (
    <>
      <Typography color="primary">Hello</Typography>
      <p color="primary">Text P</p>
      <Button color="primary">Primary</Button>
      {/* {TableExampleStriped(props)} */}
    </>
  );
}
export default coderun;
