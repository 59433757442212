import React from 'react';
import { translate } from 'react-switch-lang';
import { TransactionDatatable } from '../../../_fragments/transactiondatatable';
import { AssetCheckServices as service } from './services/assetcheck.service';
import { Label } from 'semantic-ui-react';
import moment from 'moment';

const AssetCheckList = (props) => {
  const { t } = props;
  const header = {
    icon: 'cubes',
    content: 'Asset Borrow',
    subHeader: 'Listing',
  };
  const insertBtn = {
    text: t('Add'),
    link: props.location.pathname + '/insert',
  };

  const actionPath = {
    add: `${props.location.pathname}/insert`,
    edit: `${props.location.pathname}/update`,
    delete: `${props.location.pathname}/delete`,
  };

  const headers = [
    { titile: 'id', field: 'Asset_Check_Id', hidden: true },
    { title: 'Distributor Code', field: 'Distributor_Id' },
    { title: 'Code', field: 'Number' },
    {
      title: 'Date',
      field: 'Data',
      render: (rowData) => moment(rowData).format('DD/MM/YYYY'),
    },
    {
      title: 'Customer',
      field: 'Customer_Code',
      render: (rowData) => rowData.Customer_Code + ' ' + rowData.Customer_Name,
    },

    { title: 'CheckBy', field: 'User' },
  ];

  const title = 'Asset Check';
  const filenameXML = 'SMS1_Asset-Check';
  const menuCode = 'M_ASSET_CHECK_SMS1';

  return (
    <>
      <TransactionDatatable
        header={header}
        insertBtn={insertBtn}
        title={title}
        headers={headers}
        filenameXML={filenameXML}
        root={props.root}
        history={props.history}
        path={actionPath}
        service={service}
        menuCode={menuCode}
      />
    </>
  );
};

export default translate(AssetCheckList);
