import { AssetTransferConstants } from '../../_constants/transaction';
import { authHeader } from '../../_helpers/auth-header';
import { postRequest, getRequest } from '../../_helpers';

export const assetTransferService = {
  insertItem,
  updateItem,
  deleteItem,
  getItem,
  getAllItem,
};

async function insertItem(item) {
  return await postRequest(
    AssetTransferConstants.API_ASSETTRANSFER_INSERT,
    item,
    null,
    authHeader().Authorization,
  );
}

async function updateItem(item) {
  return await postRequest(
    AssetTransferConstants.API_ASSETTRANSFER_UPDATE,
    item,
    null,
    authHeader().Authorization,
  );
}

async function deleteItem(code) {
  return await postRequest(
    AssetTransferConstants.API_ASSETTRANSFER_DELETE,
    code,
    null,
    authHeader().Authorization,
  );
}
async function getItem(code) {
  return await getRequest(
    AssetTransferConstants.API_ASSETTRANSFER_GET + code,
    null,
    authHeader().Authorization,
  );
}

async function getAllItem() {
  return await getRequest(
    AssetTransferConstants.API_ASSETTRANSFER_GETALL,
    null,
    authHeader().Authorization,
  );
}
