import { authHeader } from '../_helpers/auth-header';
import { DashboardConstants as constant } from '../_constants/dashboard.constants';
import { getRequest } from '../_helpers';

const AssetTotal = async () => {
  return await getRequest(
    constant.API_ASSET_SUMMARY,
    null,
    authHeader().Authorization,
  );
};

const AssetConditionByGroup = async () => {
  return await getRequest(
    constant.API_ASSET_CONDITION_BY_GROUP,
    null,
    authHeader().Authorization,
  );
};

export const dashboardService = {
  AssetTotal,
  AssetConditionByGroup,
};
