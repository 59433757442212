import React from 'react';
import { translate } from 'react-switch-lang';
import MaterialTable from 'material-table';
import { jQGridService, roleService } from '../../../_services';
import { tableIcons } from './tableIcons';

import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { formType } from '../../../_constants';
import palettle from '../../../theme/palette';
const StdDatatable = (props) => {
  const {
    t,
    title,
    columns,
    setSelected,
    setFormOpen,
    setFormType,
    menuCode,
  } = props;
  const user = JSON.parse(localStorage.getItem('user'));
  const [actions, setActions] = React.useState([]);
  const role = user.roleinfo;

  var addAction = {
    icon: 'add',
    tooltip: t('add'),
    isFreeAction: true,
    onClick: (e) => {
      setFormOpen(true);
      setFormType(formType.INSERT);
    },
  };
  var viewAction = {
    icon: () => <VisibilityIcon />,
    tooltip: t('view'),
    onClick: (e, rowData) => {
      e.preventDefault();
      setSelected(rowData.Code);
      setFormOpen(true);
      setFormType(formType.VIEW);
    },
  };
  var editAction = {
    icon: () => <EditIcon />,
    tooltip: t('edit'),
    onClick: (e, rowData) => {
      e.preventDefault();
      setSelected(rowData.Code);
      setFormOpen(true);
      setFormType(formType.UPDATE);
    },
  };
  var deleteAction = {
    icon: () => <DeleteIcon />,
    tooltip: t('delete'),
    onClick: (e, rowData) => {
      e.preventDefault();
      setSelected(rowData.Code);
      setFormOpen(true);
      setFormType(formType.DELETE);
    },
  };
  React.useEffect(() => {
    let isCancelled = false;
    roleService.getRolePermissions(role.code).then((res) => {
      //if (!!res) return null;
      if (!isCancelled) {
        var menuPermission = res.data.results.find(
          (o) => o.menuCode === menuCode,
        );

        var actionList = [];
        if (menuPermission.new_permission) {
          actionList.push(addAction);
        }
        if (menuPermission.edit_permission) {
          actionList.push(editAction);
        }
        if (menuPermission.view_permission) {
          actionList.push(viewAction);
        }
        if (menuPermission.delete_permission) {
          actionList.push(deleteAction);
        }
        setActions(actionList);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, []);
  return (
    <>
      <MaterialTable
        icons={tableIcons}
        actions={actions}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters = query.filters;
            filters = filters.map((el) => {
              console.log('el: ', el);
              return {
                title: el.column.title,
                field: el.column.field,
                operator: el.operator,
                value: el.value,
              };
            });
            console.log('filters: ', filters);
            try {
              jQGridService
                .search(
                  props.filenameXML + '.xml',
                  query.page + 1,
                  query.pageSize,
                  query.search,
                )
                .then((res) => {
                  if (!!res.data) {
                    console.log('list: ', res.data.rows);

                    resolve({
                      data: res.data.rows,
                      page: res.data.page - 1,
                      totalCount: res.data.records,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: query.page,
                      totalCount: query.totalCount,
                    });
                  }
                });
            } catch (error) {
              console.log('error: ', error);
              resolve({
                data: [],
                page: query.page,
                totalCount: query.totalCount,
              });
            }
          })
        }
        options={{
          // filtering: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: palettle.background.default,
            color: palettle.primary.main,
          },

          pageSize: 10,
          pageSizeOptions: [5, 10, 100],
        }}
        title={title}
      />
    </>
  );
};

export default translate(StdDatatable);
