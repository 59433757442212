import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, LinearProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { alertActions } from '../../_action/alert.action';

import {
  Budget,
  TotalUsers,
  TasksProgress,
  TotalProfit,
  UsersByDevice,
  LatestSales,
} from './components';

import { dashboardService as service } from '../../_services/dashboard.service';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    color: theme.palette.primary.main,
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const init = {
    assetTotal: null,
    goodCondition: null,
    damagedCondition: null,
    percentageOfAvaliable: null,
  };

  const [data, setData] = useState(init);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dispatch(alertActions.success('dashboard!!!'));
    //API Asset total, Good Condition, Damaged Condition, % Of Avaliable
    service
      .AssetTotal()
      .then((res) => {
        setData(res.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      })
      .catch((err) => {
        setData({});
        setIsLoading(false);
      });
  }, []);
  return (
    <div className={classes.root}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          <h1 className={classes.title}>Dashboard Summary</h1>
          <Grid container spacing={4}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Budget data={data} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalUsers data={data} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TasksProgress data={data} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalProfit data={data} />
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <LatestSales />
            </Grid>

            {/* <Grid item lg={4} md={6} xl={3} xs={12}>
           <UsersByDevice />
         </Grid> */}
          </Grid>
        </>
      )}
    </div>
  );
};

export default Dashboard;
