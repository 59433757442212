import { assetConstants as constants } from '../_constants';
import { authHeader } from '../_helpers/auth-header';
import {
  postRequest,
  getRequest,
  postRequestSync,
  getSyncAssetToSmartMS,
} from '../_helpers';
import uuid from 'react-uuid';
import { getRefs } from './refs/assetRef.service';
import moment from 'moment-timezone';
import axios from 'axios';
var fs = require('fs');

export const assetService = {
  insertItem,
  updateItem,
  deleteItem,
  getItem,
  getAllItem,
  insertImage,
  updateImage,
  deleteImage,
  getImage,
  getItemInterfaced,
  insertWarranty,
  getWarranty,
  getAssetItemBySerial,
  uploadAssetFile,
  uploadOwnerChangeTxn,
  syncToSmartMS,
  exists,
};
async function exists(code, serial) {
  const url = `${constants.API_EXISTS}${code}/${serial}`;

  const res = await getRequest(url, null, authHeader().Authorization);
  console.log('res: ', res);
  return res.data.exists;
}
async function insertItem(item) {
  var warranty = item.warrantyStart ? await insertWarranty(item) : null;
  var license = item.licenseStart ? await insertLicense(item) : null;

  return Promise.all([warranty, license]).then((values) => {
    var w = values[0];
    var l = values[1];
    return postRequest(
      constants.API_INSERT,
      toApi({
        ...item,
        warranty: w === null ? null : w.data.data.code,
        license: l === null ? null : l.data.data.code,
      }),
      null,
      authHeader().Authorization,
    );
  });
}

async function updateItem(item) {
  var warranty = null;
  var license = null;
  console.log('item: ', item);
  if (
    item.warrantyCode !== null &&
    item.warrantyCode !== undefined &&
    item.warrantyCode !== '' &&
    item.warrantyStart !== false
  ) {
    warranty = await updateWarranty(item);
  } else if (
    (item.warrantyCode === null || item.warrantyCode === undefined) &&
    item.warrantyStart !== false
  ) {
    warranty = await insertWarranty(item);
  }

  if (
    item.licenseCode !== null &&
    item.licenseCode !== undefined &&
    item.licenseCode !== '' &&
    item.licenseStart !== false
  ) {
    console.log('update license');

    license = await updateLicense(item);
  } else if (
    (item.licenseCode === null || item.licenseCode === undefined) &&
    item.licenseStart !== false
  ) {
    console.log('insert license');

    license = await insertLicense(item);
  }
  console.log('license result: ', license);
  console.log(
    'to api: ',
    toApi({
      ...item,
      warranty: warranty === null ? null : warranty.data.data.code,
      license: license === null ? null : license.data.data.code,
    }),
  );
  return postRequest(
    constants.API_UPDATE,
    toApi({
      ...item,
      warranty: warranty === null ? null : warranty.data.data.code,
      license: license === null ? null : license.data.data.code,
    }),
    null,
    authHeader().Authorization,
  );
  // return Promise.all([warranty, license]).then((values) => {
  //   var w = values[0];
  //   var l = values[1];
  //   return postRequest(
  //     constants.API_UPDATE,
  //     toApi({
  //       ...item,
  //       warranty: w === null ? null : w.data.data.code,
  //       license: l === null ? null : l.data.data.code,
  //     }),
  //     null,
  //     authHeader().Authorization,
  //   );
  // });
}

async function deleteItem(code) {
  return await postRequest(
    constants.API_DELETE,
    code,
    null,
    authHeader().Authorization,
  );
}
async function getItem(code) {
  return await getRequest(
    constants.API_GET + code,
    null,
    authHeader().Authorization,
  );
}

async function getAssetItemBySerial(serial) {
  return await getRequest(
    constants.API_SEARCH_SERIAL + serial,
    null,
    authHeader().Authorization,
  );
}

async function getItemInterfaced(code) {
  var res = await getRequest(
    constants.API_GET + code,
    null,
    authHeader().Authorization,
  );
  let warranty;
  console.log('raw res: ', res.data.info);
  let obj = toModel(res.data.info);

  if (res.data.info.Warranty !== '' && !!res.data.info.Warranty) {
    await getWarranty({ id: res.data.info.Code }).then((w) => {
      warranty = w.data.results[0];
    });
    obj.warrantyCode = warranty.Code;
    obj.warrantyStart = warranty.StartDate;
    obj.warrantyEnd = warranty.EndDate;
    obj.period = warranty.Period;
    obj.periodUOM = warranty.WarrantyUnit;
    obj.isNotify = warranty.IsNotify;
  }

  let license;
  if (res.data.info.License !== '' && !!res.data.info.License) {
    await getLicense({ id: res.data.info.Code }).then((l) => {
      license = l.data.results[0];
    });

    obj.licenseCode = license.Code;
    obj.licenseStart = license.StartDate;
    obj.licenseEnd = license.EndDate;
    obj.periodl = license.Period;
    obj.periodUOMl = license.LicenseUnit;
    obj.isNotifyl = license.IsNotify;
  }

  return getRefs(obj);
}

async function getAllItem() {
  return await getRequest(
    constants.API_GETALL,
    null,
    authHeader().Authorization,
  );
}

async function insertImage(position, code, file) {
  var bodyFormData = new FormData();
  var assetCode = code;

  bodyFormData.set('assetCode', code);
  bodyFormData.set('positions', position);
  bodyFormData.set('uploadedFiles', file);

  var positions = position;
  return await postRequest(
    constants.API_IMAGE_ADD,
    { assetCode, positions },
    bodyFormData,
    authHeader().Authorization,
    'multipart/form-data',
  );
}

async function updateImage(position, code, file) {
  var bodyFormData = new FormData();
  var assetCode = code;
  bodyFormData.append('assetCode', code);
  bodyFormData.append('positions', position);
  bodyFormData.append('uploadedFiles', file);

  const positions = [0];
  return await postRequest(
    constants.API_IMAGE_UPDATE,
    { assetCode, positions },
    bodyFormData,
    authHeader().Authorization,
    'multipart/form-data',
  );
}

async function deleteImage(position, code) {
  var bodyFormData = new FormData();
  bodyFormData.append('positions', [position]);
  return await postRequest(
    constants.API_IMAGE_DELETE + code,
    null,
    bodyFormData,
    authHeader().Authorization,
  );
}

async function getImage({ assetCode }) {
  return await getRequest(
    constants.API_IMAGE_GET + `${assetCode}`,
    null,
    authHeader().Authorization,
  );
}

function insertWarrantySync(warranty) {
  return postRequestSync(
    constants.API_WARRANTY_INSERT,
    { ...warranty },
    null,
    authHeader().Authorization,
  );
}

function insertLicenseSync(license) {
  return postRequestSync(
    constants.API_LICENSE_INSERT,
    { ...license },
    null,
    authHeader().Authorization,
  );
}
async function insertWarranty(item) {
  var warranty = createWarranty(item);
  return await postRequest(
    constants.API_WARRANTY_INSERT,
    { ...warranty },
    null,
    authHeader().Authorization,
  );
}
async function updateWarranty(item) {
  var warranty = createWarranty(item);
  console.log('updating warranty: ', warranty);

  return await postRequest(
    constants.API_WARRANTY_UPDATE,
    { ...warranty },
    null,
    authHeader().Authorization,
  );
}
async function insertLicense(item) {
  var license = createLicense(item);
  console.log('create license: ', license);

  const response = await postRequest(
    constants.API_LICENSE_INSERT,
    { ...license },
    null,
    authHeader().Authorization,
  );
  return response;
}
async function updateLicense(item) {
  var license = createLicense(item);
  console.log('update license: ', license);

  return await postRequest(
    constants.API_LICENSE_UPDATE,
    { ...license },
    null,
    authHeader().Authorization,
  );
}
async function getWarranty({ id }) {
  var link = constants.API_WARRANTY_GET;
  return await getRequest(
    link.replace('id', id),
    null,
    authHeader().Authorization,
  );
}

async function getLicense({ id }) {
  var link = constants.API_LICENSE_GET;
  return await getRequest(
    link.replace('id', id),
    null,
    authHeader().Authorization,
  );
}
const toApiWithDescription = (model) => {};
const toApi = (model) => {
  return {
    ...model,
    code: model.code,
    barcode: model.barcode,
    referenceCode: model.refCode,
    part: model.pn,
    serial: model.serial,
    description: model.des,
    shortDescription: model.shortDes,
    parent: model.parent,
    color: model.color,
    assetUnit: model.unit,
    assetCondition: model.condition,
    costCenter: model.cost,
    assetBrand: model.brand,
    assetClass: model.clss,
    assetSubClass: model.subClass,
    assetGroup: model.group,
    assetModel: model.model,
    serialized: model.serialized,
    erp: model.erp,
    assetSupplier: model.supplier,
    warrantyStartDate: model.warrantyStart,
    warrantyEndDate: model.warrantyEnd,
    licenseStartDate: model.licenseStart,
    licenseEndDate: model.licenseEnd,
    height: model.height,
    width: model.width,
    weight: model.weight,
    depth: model.depth,
    dimensionUomId: model.dimensionUOM,
    weightUomId: model.weightUOM,
    inventoryNumber: model.inventoryNo,
    active: model.active,
    warranty: model.warranty,
    propertyPlate: model.propertyPlate,
    size: model.size,
  };
};
const toModel = (api) => {
  return {
    code: api.Code,
    barcode: api.Barcode,
    refCode: api.ReferenceCode,
    pn: api.Part,
    des: api.Description,
    serial: api.Serial,
    shortDes: api.ShortDescription,
    parent: api.Parent,
    color: api.Color,
    unit: api.AssetUnit,
    condition: api.AssetCondition,
    cost: api.CostCenter,
    brand: api.AssetBrand,
    clss: api.AssetClass,
    subClass: api.AssetSubClass,
    group: api.AssetGroup,
    model: api.AssetModel,
    serialized: api.Serialized,
    erp: api.Erp,
    supplier: api.AssetSupplier,
    businessArea: api.BusinessArea,
    location: api.Location,
    zone: api.Zone,
    warrantyStart: api.WarrantyStartDate,
    warrantyEnd: api.WarrantyEndData,

    period: null,
    periodUOM: null,
    periodl: null,
    periodUOMl: null,
    isNotify: null,
    isNotifyl: null,
    licenseStart: api.LicenseStartDate,
    licenseEnd: api.LicenseEndDate,
    height: api.Height,
    width: api.Width,
    weight: api.Weight,
    depth: api.Depth,
    dimensionUOM: api.DimensionUomId,
    weightUOM: api.WeightUomId,
    inventoryNo: api.InventoryNumber,
    active: api.Active,
    ownerCode: api.Ownercode,
    ownerName: api.Ownername,
    ownerFirstName: api.Ownerfirstname,
    ownerLastName: api.Ownerlastname,
    ownerType: api.Ownertypename,
    ownerGroup: api.Ownergroupname,
    propertyPlate: api.PropertyPlate,
    size: api.Size,
  };
};

function createWarranty(item) {
  var moment_unit;
  switch (item.periodUOM) {
    case 'DAY':
      moment_unit = 'days';
      break;
    case 'MONTH':
      moment_unit = 'months';
      break;
    case 'YEAR':
      moment_unit = 'years';
      break;
    default:
      moment_unit = 'days';
  }
  var endDate;
  var period = item.period;
  if (item.warrantyEnd === false && !!item.period) {
    endDate = moment(item.warrantyStart)
      .add(item.period, moment_unit)
      // .add(moment_unit, item.period )
      .format('YYYY-MM-DD');
  } else if (item.warrantyEnd !== 'Invalid date') {
    endDate = moment(item.warrantyEnd).format('YYYY-MM-DD');
    period = moment(item.warrantyStart).diff(
      moment(item.warrantyEnd),
      moment_unit,
    );
    if (period < 0) period = -1 * period;
  }
  var code = item.warrantyCode;
  if (code === '' || code === null || code === undefined) code = uuid();
  var warranty = {
    // assetId: item.code,
    warrantyCode: code, // gen GUID
    startDate: moment(item.warrantyStart).format('YYYY-MM-DD'),
    endDate: endDate,
    warrantyBy: item.supplier,
    warrantyUnit: item.periodUOM,
    period: period,
    isNotify: item.isNotify,
  };
  return warranty;
}

function createLicense(item) {
  var moment_unit;
  switch (item.periodUOMl) {
    case 'DAY':
      moment_unit = 'days';
      break;
    case 'MONTH':
      moment_unit = 'months';
      break;
    case 'YEAR':
      moment_unit = 'years';
      break;
    default:
      moment_unit = 'days';
  }
  var endDate;
  var period = item.periodl;
  if (item.licenseEnd === 'Invalid date' && !!item.periodl) {
    endDate = moment(item.licenseStart)
      .add(item.periodl, moment_unit)
      .format('YYYY-MM-DD');
  } else if (item.licenseEnd !== 'Invalid date') {
    endDate = moment(item.licenseEnd).format('YYYY-MM-DD');
    period = moment(item.licenseStart).diff(
      moment(item.licenseEnd),
      moment_unit,
    );
    if (period < 0) period = -1 * period;
  }
  var code = item.licenseCode;
  if (code === '' || code === null || code === undefined) code = uuid();
  var license = {
    // assetId: item.code,
    licenseCode: code, // gen GUID
    startDate: moment(item.licenseStart).format('YYYY-MM-DD'),
    endDate: endDate,
    licenseBy: item.supplier,
    licenseUnit: item.periodUOMl === '' ? 'DAY' : item.periodUOMl,
    period: period,
    isNotify: item.isNotifyl,
  };
  return license;
}

async function uploadAssetFile(file) {
  var bodyFormData = new FormData();
  //console.log(file);

  bodyFormData.set('uploadedFile', file);
  return await postRequest(
    constants.API_UPLOAD_ASSET,
    bodyFormData,
    bodyFormData,
    authHeader().Authorization,
    'multipart/form-data',
  );
}

async function uploadOwnerChangeTxn(file) {
  var bodyFormData = new FormData();
  //console.log(file);

  bodyFormData.set('uploadedFile', file);
  return await postRequest(
    constants.API_UPLOAD_OWNERCHANGETXN,
    bodyFormData,
    bodyFormData,
    authHeader().Authorization,
    'multipart/form-data',
  );
}

async function syncToSmartMS() {
  return await getSyncAssetToSmartMS();
}
