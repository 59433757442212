import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from 'semantic-ui-react';
import { translate } from 'react-switch-lang';
import { Title } from '../../../../_fragments';
import { default as Header } from './flagments/writeoff.header.flagment';
import { default as Additem } from './flagments/writeoff.details.flagment';
// import { default as Footer } from './flagments/writeoff.footer.flagment'
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BeenhereIcon from '@material-ui/icons/Beenhere';

//****Redux & alert**** */
// import { commonAlert } from '../../../../labelConstant';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import {
  Save,
  Update,
  ResetItem,
} from '../components/redux/actions/writeoff.action';
import { WriteOffService as service } from '../../../../_services/transaction/asset.writeoff.service';

//****Redux & alert**** */

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  bg: {
    background: 'linear-gradient(45deg, #b2dfdb 30%, #1976d2 90%)',
    padding: theme.spacing(3, 3, 0),

    // background: '#bbdefb',
  },
  bgBodyCard: {
    background: '#cfd8dc',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const WriteOffForm = (props) => {
  // console.log('props', props);
  const { t, history } = props;

  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [submitstatus, setsubmitstatus] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const storage = useSelector((state) => state.returnStore);
  const {
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    getValues,
  } = useForm();

  let isReadOnly = false;

  if (!!props.location.state) {
    isReadOnly = props.location.state.isReadOnly;
    if (props.location.state.status == 'APPROVE') {
      isReadOnly = true;
    }
  } else {
    isReadOnly = false;
  }

  let myData = {
    code: '',
    date: '',
    writeOffDate: '',
    reason: '',
    remark: '',
    status: '',
    assetWriteOffDetail: [
      {
        assetCode: '',
        assetDescription: '',
        serial: '',
        quanitity: null,
        remark: '',
        status: '',
      },
    ],
  };

  const onsubmitAsync = async (data) => {
    setSubmitting(true);

    const storageSelector = (Obj) => {
      return Object.keys(Obj).map((storageKey) => Obj[storageKey]);
    };
    const storageArray = storageSelector(storage);

    myData = {
      code: data.code,
      date: data.date,
      writeOffDate: data.writeOffDate,
      reason: data.reason.value,
      remark: data.remark,
      status: data.status,
      remark: data.remark,
      assetWriteOffDetail: storageArray,
    };
    // alert.success(JSON.stringify(myData));
    const confirmSave = window.confirm('confirm to save ?');
    console.log('myData:', myData);
    if (confirmSave) {
      try {
        if (props.location.state.action == 'create') {
          console.log('create...');
          dispatch(Save(myData));
          dispatch(ResetItem);
          setSubmitting(false);
        }
        if (props.location.state.action == 'edit') {
          console.log('edit...');
          dispatch(Update(myData));
          dispatch(ResetItem);
          setSubmitting(false);
        }

        if (props.location.state.action == 'view') {
          console.log('view...');
        }
      } catch (error) {
        console.log('error-save', error);
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
    }
  };

  const approveEvent = async () => {
    const result = await service.Approve(props.match.params.id);
    if (result === 'OK') {
      alert.show('Approved', {
        timeout: 2000,
        type: 'success',
      });
      props.history.goBack();
    } else {
      alert.error(result);
    }
  };

  return (
    <>
      <form
        key="formWriteOff"
        onSubmit={handleSubmit(onsubmitAsync)}
        size="small"
        noValidate
      >
        <Grid divided="vertically" padded>
          <Grid.Column computer={10} tablet={11} mobile={16}>
            <Title
              icon="cubes"
              iconaction="exchange"
              content="Asset Write-Off"
              description="Create asset write-off document"
              props={props}
            />
          </Grid.Column>
          <Grid.Column textAlign="right" computer={6} tablet={5} mobile={16}>
            {!isReadOnly && (
              <>
                <Button
                  name="approveBtn"
                  size="large"
                  color="secondary"
                  variant="contained"
                  startIcon={<BeenhereIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    approveEvent();
                  }}
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  control={control}
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  color="primary"
                  name="submitBtn"
                >
                  Save
                </Button>
              </>
            )}

            <Button
              name="backBtn"
              size="large"
              color="default"
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={(e) => {
                e.preventDefault();
                props.history.goBack();
              }}
            >
              Back
            </Button>
          </Grid.Column>
        </Grid>

        <Header
          code={props.match.params.id}
          register={register}
          setValue={setValue}
          errors={errors}
          control={control}
          readOnly={isReadOnly}
        />
        <Additem
          code={props.match.params.id}
          register={register}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          control={control}
          readOnly={isReadOnly}
        />

        <Grid divided="vertically" columns={2} padded>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            {/* <Footer
              code={props.match.params.id}
              register={register}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
              control={control}
              readOnly={isReadOnly}
            /> */}
          </Grid.Column>
          <Grid.Column textAlign="right" computer={4} tablet={16} mobile={16}>
            {/* <ActionButton props={props} /> */}
          </Grid.Column>
        </Grid>
      </form>
    </>
  );
};

export default translate(WriteOffForm);
