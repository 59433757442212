import React, { useEffect, useState } from 'react';
import { translate } from 'react-switch-lang';
import Select from 'react-select';
import moment from 'moment';
import { Header, Icon, Divider, Grid, Form } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import {
  Typography,
  Paper,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { WriteOffService as service } from '../../../../../_services/transaction/asset.writeoff.service';
import BorrowDatatable from '../../../_InventoryFlagments/BorrowDatatable';
import { useSelector, useDispatch } from 'react-redux';
import { addItem } from '../redux/actions/writeoff.action';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  bg: {
    //background: 'linear-gradient(180deg, white 30%, grey 70%)',
    // background: '#bbdefb',
  },
}));

const WriteOffHeader = (props) => {
  const { t } = props;
  const classes = useStyles();
  const [valueDate, setvalueDate] = useState('');
  const [optionReason, setoptionReason] = useState([]);
  const [options, setOptions] = useState([]);

  const [ownerType, setownerTypeOption] = useState({
    ownertype_code: '1',
    ownertype_name: 'Company',
  });

  const [isDisable, setisDisable] = useState(false);

  const [assetBorrowCode, setassetBorrowCode] = useState('');
  const [borrowDocument, setborrowDocument] = useState('');
  const [openBorrowCode, setopenBorrowCode] = useState(false);
  const [code, setCode] = useState('');
  const [data, setdata] = useState([]);
  let loading = false;

  /// Redux ///
  const dispatch = useDispatch();
  const storage = useSelector((state) => state.ownerSearch);
  /// --- Redux  ----///

  const handleClickBorrowOpen = () => {
    setopenBorrowCode(true);
  };
  const handleClickBorrowClose = () => {
    setopenBorrowCode(false);
  };

  useEffect(() => {
    if (storage.closing) {
      props.setValue('assetBorrowCode', storage.code);
      props.setValue('OwnerType', storage.ownerType);
      props.setValue(
        'borrowDate',
        moment(storage.borrowDate).format('YYYY-MM-DD'),
      );
      props.setValue(
        'borrowDate',
        moment(storage.returnDate).format('YYYY-MM-DD'),
      );
      // console.log(storage);
      service
        .autoComplete({
          req_type: storage.ownerType,
          req_search: storage.borrowBy,
          recordTotal: '1',
        })
        .then((option) => {
          props.setValue('borrowBy', option);
        });

      ///add detail
      dispatch(addItem(storage.assetBorrowDetail));
      ///add detail

      setopenBorrowCode(false);
      setisDisable(true);
    }
  }, [storage]);

  //*** Initial get values ****/
  useEffect(() => {
    service.getReason().then((res) => {
      console.log(res.data.results);
      res.data.results
        .filter((option) => option.reasonType == 'WRITEOFF')
        .map((item) => {
          setoptionReason((option) => [
            ...option,
            {
              value: item.value,
              label: item.label,
            },
          ]);
        });
    });
  }, []);

  // +++++++++++++++++++ EDIT PART GET DATE ++++++++++++++++++++++++

  //*** Update get values ****/
  useEffect(() => {
    //check edit action
    if (!!props.code) {
      service
        .getItem(props.code)
        .then((res) => {
          if (!!res.data.result.reason)
            props.setValue('reason', {
              value: res.data.result.reason,
              label: res.data.result.reason,
            });
          setdata(res.data.result);
          //  console.log(res.data.result);
        })
        .catch((error) => {
          window.alert('error', JSON.stringify(error));
        });
    }
  }, []);

  const ownerOption = async (inputValue) => {
    try {
      loading = true;
      let res = await service.autoComplete({
        req_type: ownerType.ownertype_code,
        req_search: inputValue,
        recordTotal: '30',
      });
      res = Object.keys(res).map(
        (key) =>
          new Object({
            value: res[key].value,
            label: res[key].label,
          }),
      );
      loading = false;
      return res;
    } catch (error) {
      console.log('error:', error);
      loading = false;
    }
  };

  return (
    <>
      <Grid stackable>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Paper className={classes.root} elevation={1} variant="outlined">
              <div>
                <Header className={classes.bg} dividing>
                  <Typography variant="h6" color="textPrimary">
                    <Icon name="folder" />
                    {t('Description')}
                  </Typography>
                </Header>
                <FormControl
                  margin="dense"
                  fullWidth
                  required
                  error={!!props.errors.code}
                >
                  <FormLabel component="legend">{t('Code')}</FormLabel>
                  <TextField
                    size="small"
                    variant="outlined"
                    name="code"
                    value={[data.code]}
                    inputRef={props.register()}
                    onChange={(event) => {
                      setdata({ ...data, code: event.target.value });
                    }}
                    disabled
                  />
                  <FormHelperText>
                    {props.errors.code?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  margin="dense"
                  fullWidth
                  required
                  error={!!props.errors.dateValue}
                >
                  <FormLabel component="legend">{t('Date')}</FormLabel>
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    name="date"
                    value={[moment(data.date).format('YYYY-MM-DD')]}
                    inputRef={props.register({ required: true })}
                    onChange={(event) => {
                      setdata({ ...data, date: event.target.value });
                    }}
                    disabled={props.readOnly}
                  />
                  <FormHelperText>
                    {props.errors.date?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  margin="dense"
                  fullWidth
                  required
                  error={!!props.errors.status}
                >
                  <FormLabel component="legend">{t('status')}</FormLabel>

                  <TextField
                    size="small"
                    variant="outlined"
                    name="status"
                    value={data.status ? data.status : 'OPEN'}
                    inputRef={props.register()}
                    onChange={(event) => {
                      setdata({ ...data, status: event.target.value });
                    }}
                    defaultValue={data.status}
                    disabled
                  />
                  <FormHelperText>
                    {props.errors.status?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>
              </div>
            </Paper>
          </Grid.Column>
          <Grid.Column>
            <Paper className={classes.root} elevation={1} variant="outlined">
              <Header dividing>
                <Typography variant="h6" color="textPrimary">
                  <Icon name="user" />
                  {t('Write-Off')}
                </Typography>
              </Header>
              <section>
                <FormControl
                  margin="dense"
                  fullWidth
                  required
                  error={!!props.errors.writeOffDate}
                >
                  <FormLabel component="legend">{t('WriteOff-Date')}</FormLabel>
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    name="writeOffDate"
                    value={[moment(data.writeOffDate).format('YYYY-MM-DD')]}
                    inputRef={props.register({ required: true })}
                    onChange={(event) => {
                      setdata({ ...data, writeOffDate: event.target.value });
                    }}
                    disabled={props.readOnly}
                  />
                  <FormHelperText>
                    {props.errors.writeOffDate?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  margin="dense"
                  fullWidth
                  size="small"
                  required={true}
                  error={props.errors.Reason}
                >
                  <FormLabel component="legend">{t('Reason')}</FormLabel>
                  <Controller
                    {...props}
                    as={<Select />}
                    options={optionReason}
                    name="reason"
                    isClearable
                    control={props.control}
                    rules={{ required: 'Reason is Required' }}
                    isDisabled={props.readOnly}
                  />
                  <FormHelperText error>
                    {!!props.errors.reason && (
                      <>{props.errors.reason.message}</>
                    )}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  margin="dense"
                  fullWidth
                  error={!!props.errors.remark}
                >
                  <FormLabel component="legend">{t('Remark')}</FormLabel>
                  <TextField
                    size="small"
                    variant="outlined"
                    name="remark"
                    value={[data.remark]}
                    inputRef={props.register()}
                    onChange={(event) => {
                      setdata({ ...data, remark: event.target.value });
                    }}
                    disabled={props.readOnly}
                  />
                  <FormHelperText>
                    {props.errors.remark?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>
              </section>
            </Paper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Dialog
        open={openBorrowCode}
        fullWidth
        maxWidth={'lg'}
        onClose={handleClickBorrowClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Please select Borrow Document number'}
        </DialogTitle>
        <DialogContent>
          <div>
            <BorrowDatatable />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClickBorrowClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default translate(WriteOffHeader);
