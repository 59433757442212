import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';

import { Divider, Header, Icon } from 'semantic-ui-react';

import {
  InputSelect,
  InputSelect2,
} from '../../../../_fragments/formField/controllerFieldMUI';
import {
  assetBrandConstants as assetBrand_constants,
  assetGroupConstants as assetGroup_constants,
  assetModelConstants as assetModel_constants,
  assetClassConstants as assetClass_constants,
  assetSubClassConstants as assetSubClass_constants,
} from '../../../../_constants';
const Classification = (props) => {
  const { t, errors, control, readOnly } = props;

  const [assetClassCode, setAssetClassCode] = useState('');
  const [assetSubClassCode, setAssetSubClassCode] = useState('');
  const [assetGroupCode, setAssetGroupCode] = useState('');
  const [assetModelCode, setAssetModelCode] = useState('');
  useEffect(() => {
    console.log('refresh!!');
  }, [assetClassCode, assetSubClassCode, assetGroupCode, assetModelCode]);
  return (
    <>
      <InputSelect
        name="AssetBrand"
        control={control}
        label="brand"
        placeholder="brand"
        error={
          !!errors.assetBrand && {
            content: errors.assetBrand.message,
          }
        }
        service={assetBrand_constants.SERVICE}
        readOnly={readOnly}
      />
      <Divider horizontal>
        <Header as="h4">
          <Icon name="chain" />
          Division
        </Header>
      </Divider>

      <InputSelect
        name="AssetClass"
        control={control}
        label="class"
        placeholder="class"
        error={
          !!errors.AssetClass && {
            content: errors.AssetClass.message,
          }
        }
        service={assetClass_constants.SERVICE}
        onChange={([data, _]) => {
          if (!!data) setAssetClassCode(data.value);
          else {
            setAssetClassCode('');
            setAssetSubClassCode('');
          }
          return data;
        }}
        readOnly={readOnly}
      />
      <InputSelect
        name="AssetSubClass"
        key={assetClassCode}
        control={control}
        label="subclass"
        placeholder="subclass"
        error={
          !!errors.assetSubClass && {
            content: errors.assetSubClass.message,
          }
        }
        onChange={([data, _]) => {
          if (!!data) setAssetSubClassCode(data.value);
          else {
            setAssetSubClassCode('');
            setAssetGroupCode('');
          }
          return data;
        }}
        filter={(el) => {
          return el.assetClass === assetClassCode;
        }}
        service={assetSubClass_constants.SERVICE}
        readOnly={readOnly}
      />
      <InputSelect
        name="AssetGroup"
        key={assetSubClassCode || 'AssetSubClass'}
        control={control}
        label="group"
        placeholder="group"
        error={
          !!errors.assetGroup && {
            content: errors.assetGroup.message,
          }
        }
        onChange={([data, _]) => {
          if (!!data) setAssetGroupCode(data.value);
          else {
            setAssetGroupCode('');
            setAssetModelCode('');
          }
          return data;
        }}
        filter={(el) => {
          return el.assetSubClass === assetSubClassCode;
        }}
        service={assetGroup_constants.SERVICE}
        readOnly={readOnly}
      />
      <InputSelect
        name="AssetModel"
        key={assetGroupCode || 'AssetGroup'}
        control={control}
        label="model"
        placeholder="model"
        error={
          !!errors.assetModel && {
            content: errors.assetModel.message,
          }
        }
        onChange={([data, _]) => {
          console.log('current data: ', data.value);
          if (!!data) setAssetModelCode(data.value);
          else setAssetModelCode('');

          return data;
        }}
        filter={(el) => {
          return el.assetGroup === assetGroupCode;
        }}
        service={assetModel_constants.SERVICE}
        readOnly={readOnly}
      />
    </>
  );
};

export default translate(Classification);
