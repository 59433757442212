import * as yup from 'yup'; // for everything

export const schema = yup.object().shape({
  code: yup.string().required(),
  description: yup.string(),
  assetGroup: yup.object().shape({
    value: yup.string().required(),
    label: yup.string(),
  }),
});
