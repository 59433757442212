import React, { useEffect, useState, useMemo } from 'react';
import { translate } from 'react-switch-lang';
import { assetAction as actions } from '../../_action/asset.action';
import { assetConstants as constants, formType } from '../../_constants';
import { useSelector, useDispatch } from 'react-redux';
import { filenameXML } from '../../_constants';
import NewPageDatatable from '../../_fragments/ListingDatatable/NewPageDatatable';
import { Redirect } from 'react-router-dom';

const Asset = (props) => {
  const { t } = props;
  const header = useMemo(() => ({ name: 'Asset', icon: 'cubes' }), []);
  const reducer = useSelector((state) => state.assetOperation);
  const [item, setItem] = useState({});
  const [selectedCode, setSelected] = useState('');
  const [open, setOpen] = useState(false);
  const [type, setFormType] = useState('');
  const dispatch = useDispatch();
  const menuCode = 'M_ASSET';
  const columns = useMemo(
    () => [
      { title: t('Property Plate'), field: 'Code' },

      { title: t('Serial'), field: 'Serial' },
      { title: t('SAP Code'), field: 'PropertyPlate' },

      { title: t('Description'), field: 'Description' },
      { title: t('Inventory Number'), field: 'InventoryNumber' },
      {
        title: t('Brand'),
        render: (row) => {
          return `${row.AssetBrand} - ${row.AssetBrandDescription}`;
        },
      },
      {
        title: t('Model'),
        render: (row) => {
          return `${row.AssetModel} - ${row.AssetModelDescription}`;
        },
      },
      {
        title: t('Size'),
        field: 'Size',
      },
      // { title: t('brand'), field: 'AssetBrand' },
      // { title: t('model'), field: 'AssetModel' },
    ],
    [],
  );

  useEffect(() => {
    if (reducer.getSuccess) {
      setItem(reducer.data.data.info);
    }
  }, [reducer]);
  useEffect(() => {
    if (reducer.DELETE_SUCCESS) {
      setSelected('');
    }
  }, [reducer]);
  useEffect(() => {
    if (type === formType.DELETE) {
      console.log('remove: ', selectedCode);
      dispatch(actions.remove({ code: selectedCode }));
      setFormType('');
    }
  }, [type]);
  return (
    <>
      {selectedCode != '' && type === formType.VIEW && (
        <Redirect
          to={{
            pathname: props.history.location.pathname + '/view',
            state: {
              code: selectedCode,
              form: type,
              root: props.history.location.pathname,
            },
          }}
        />
      )}
      {selectedCode != '' && type === formType.UPDATE && (
        <Redirect
          to={{
            pathname: props.history.location.pathname + '/update',
            state: {
              code: selectedCode,
              form: type,
              root: props.history.location.pathname,
            },
          }}
        />
      )}
      {type === 'insert_form' && (
        <Redirect
          to={{
            pathname: props.history.location.pathname + '/insert',
            state: {
              code: '',
              form: type,
              root: props.history.location.pathname,
            },
          }}
        />
      )}

      <NewPageDatatable
        key={selectedCode}
        header={header}
        columns={columns}
        filenameXML={filenameXML.asset}
        setSelected={setSelected}
        setFormOpen={setOpen}
        setFormType={setFormType}
        title="Asset"
        menuCode={menuCode}
      />
    </>
  );
};

export default translate(Asset);
