import { jQGRidConstants } from '../_constants';
import { authHeader } from '../_helpers/auth-header';
import { postRequest, httpClient_Post } from '../_helpers';

export const jQGridService = {
  search,
  searchAssetByOwner,
  searchAutocomplete,
  searchAdvance,
  search2,
  AdvanceSearch,
  search_asset_by_owner,
  search_customer_filter,
};

// parameters oriented (for Location)
function search2(filename_config, page, rowperpage, searchInput = '') {
  var bodyFormData = new FormData();
  bodyFormData.set('req_search', searchInput);
  bodyFormData.set('filename_config', filename_config);
  bodyFormData.set('page', page);
  bodyFormData.set('rowperpage', rowperpage);

  return postRequest(
    jQGRidConstants.API,
    { filename_config, page, rowperpage }, // parameter
    bodyFormData,
    authHeader().Authorization,
  );
}
async function search_customer_filter(
  filename_config,
  page,
  rowperpage,
  { req_account, req_accountType, req_code, req_name },
) {
  var bodyFormData = new FormData();
  bodyFormData.set('filename_config', filename_config);
  bodyFormData.set('page', page);
  bodyFormData.set('rowperpage', rowperpage);
  bodyFormData.set('req_account', req_account);
  bodyFormData.set('req_accountType', req_accountType);
  bodyFormData.set('req_code', req_code);
  bodyFormData.set('req_name', req_name);
  return await postRequest(
    jQGRidConstants.API,
    null, // parameter
    bodyFormData,
    authHeader().Authorization,
  );
}
async function search_asset_by_owner(
  filename_config,
  page,
  rowperpage,
  customer,
  searchInput = '',
) {
  var bodyFormData = new FormData();
  bodyFormData.set('req_search', searchInput);
  bodyFormData.set('filename_config', filename_config);
  bodyFormData.set('page', page);
  bodyFormData.set('rowperpage', rowperpage);
  bodyFormData.set('req_customer_code', customer);
  return await postRequest(
    jQGRidConstants.API,
    null, // parameter
    bodyFormData,
    authHeader().Authorization,
  );
}
async function search(filename_config, page, rowperpage, searchInput = '') {
  var bodyFormData = new FormData();
  bodyFormData.set('req_search', searchInput);
  bodyFormData.set('filename_config', filename_config);
  bodyFormData.set('page', page);
  bodyFormData.set('rowperpage', rowperpage);
  //console.log('filename_config: ', filename_config);
  return await postRequest(
    jQGRidConstants.API,
    null, // parameter
    bodyFormData,
    authHeader().Authorization,
  );
}

async function searchAssetByOwner(
  filename_config,
  page,
  rowperpage,
  req_search = '',
  req_search2 = '',
  req_search3 = '',
) {
  var bodyFormData = new FormData();
  bodyFormData.set('req_search', req_search);
  bodyFormData.set('req_search2', req_search2);
  bodyFormData.set('req_search3', req_search3);
  bodyFormData.set('filename_config', filename_config);
  bodyFormData.set('page', page);
  bodyFormData.set('rowperpage', rowperpage);
  //console.log('filename_config: ', filename_config);
  return await postRequest(
    jQGRidConstants.API,
    null, // parameter
    bodyFormData,
    authHeader().Authorization,
  );
}

function searchAdvance(filename_config, page, rowperpage, filters) {
  var data = {
    filename_config,
    page,
    rowperpage,
    filters,
  };
  return postRequest(
    jQGRidConstants.API_ADVANCE,
    null, // parameter
    JSON.stringify(data),
    authHeader().Authorization,
  );
}

function searchAutocomplete(
  filename_config,
  recordTotal = 10,
  searchInput = '',
) {
  var bodyFormData = new FormData();

  bodyFormData.set('req_search', searchInput);
  return postRequest(
    jQGRidConstants.API_AUTOCOMPLETE, // url
    { filename_config, recordTotal }, // parameter
    bodyFormData, // body
    authHeader().Authorization, // authen
  );
}

async function AdvanceSearch(data) {
  return await httpClient_Post(
    jQGRidConstants.API_ASSET_ADV_SEARCH,
    data,
    authHeader().Authorization,
  )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}
