import React from 'react';
import { translate } from 'react-switch-lang';
import { StdTitle } from '../../_fragments';
import { getUserAuthen } from '../../_helpers/auth-header';

import { Segment, Checkbox, Card, Header, Icon } from 'semantic-ui-react';
import MaterialTable from 'material-table';
import { InputSelect } from '../../_fragments/formField/inputFields';
import { roleService as service } from '../../_services/role.service';
const Permission = (props) => {
  const { t } = props;
  const header = { name: 'Role', icon: 'cubes' };
  const [roles, setRoles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [currentRole, setCurrentRole] = React.useState({
    value: getUserAuthen().roleinfo.code,
    label: getUserAuthen().roleinfo.description,
  });
  const [currentData, setCurrentData] = React.useState();
  const [userMenus, setUserMenus] = React.useState([]);
  var tableRef = React.createRef();
  const [columns, setColumns] = React.useState([
    {
      title: 'name',
      field: 'name',
      customFilterAndSearch: (term, rowData) => term <= rowData.name.length,
    },
    { title: 'view', field: 'viewpermission', type: 'boolean' },
    { title: 'insert', field: 'newpermission', type: 'boolean' },
    { title: 'update', field: 'editpermission', type: 'boolean' },
    { title: 'delete', field: 'delpermission', type: 'boolean' },

    { title: 'import', field: 'importpermission', type: 'boolean' },
    { title: 'export', field: 'exportpermission', type: 'boolean' },
    { tiele: 'approve', field: 'approvepermission', type: 'boolean' },
  ]);

  React.useEffect(() => {
    setLoading(true);
    service.getAllItem().then((res) => {
      setRoles(
        res.data.results.map((el) => {
          return { value: el.code, label: el.description };
        }),
      );
      setLoading(false);
    });
  }, []);
  React.useEffect(() => {}, [currentData]);
  return (
    <>
      <Header
        icon="lock open"
        content={t('Role') + ' | ' + currentRole.label}
      />

      <InputSelect
        value={currentRole}
        name={'Role'}
        onChange={(e) => {
          setCurrentRole(e);
        }}
        options={roles}
        label={'Select Roles'}
        isClearable={false}
      />
      <Card fluid>
        {/* {JSON.stringify(currentData)} */}
        <MaterialTable
          tableRef={tableRef}
          isLoading={loading}
          key={JSON.stringify(currentRole)}
          title={
            <StdTitle
              icon="users"
              content={'User'}
              subHeader={t('Permission List')}
            />
          }
          columns={columns}
          data={(query) =>
            new Promise((resolve, reject) => {
              console.log(query);
              try {
                service.getRolePermissions(currentRole.value).then((res) => {
                  resolve({
                    data: res.data.results.map((menu) => {
                      console.log('menu: ', menu);

                      return {
                        code: currentRole.code,
                        menuid: menu.menuCode,
                        name: menu.name,
                        newpermission: menu.new_permission,
                        editpermission: menu.edit_permission,
                        delpermission: menu.delete_permission,
                        viewpermission: menu.view_permission,
                        importpermission: menu.import_permission,
                        exportpermission: menu.export_permission,
                      };
                    }),
                    page: 0,
                    totalCount: res.data.results.length,
                  });
                });
              } catch (error) {
                console.log('error: ', error);
                resolve({
                  data: [],
                  page: query.page,
                  totalCount: query.totalCount,
                });
              }
            })
          }
          editable={{
            // onRowAdd: (newData) =>
            //   new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //       setUserMenus([...userMenus, newData]);

            //       resolve();
            //     }, 1000);
            //   }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                service
                  .rolePermissionUpdate({
                    code: currentRole.value,
                    menucode: newData.menuid,
                    newpermissions: newData.newpermission,
                    editpermissions: newData.editpermission,
                    viewpermissions: newData.viewpermission,
                    delpermissions: newData.delpermission,
                    importpermissions: newData.importpermission,
                    exportpermissions: newData.exportpermission,
                  })
                  .then((res) => {
                    tableRef.current.onQueryChange();

                    console.log('res: ', res);
                  });
                resolve();
              }),
          }}
          options={{
            pageSize: 100,
            // filtering: true,
          }}
        />
      </Card>
    </>
  );
};

export default translate(Permission);
