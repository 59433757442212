import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Sidebar, Topbar, Footer } from './components';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { alertActions } from '../../_action/alert.action';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: '100%',
    padding: 20,
  },
}));

const Main = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alertState = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { children } = props;
  const alert = useAlert();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;
  React.useEffect(() => {
    if (alertState.success) {
      alert.success(alertState.message);
      dispatch(alertActions.clear(''));
      setError(null);
    } else if (alertState.error) {
      setError(alertState.message);
      alert.error(alertState.message);
      dispatch(alertActions.clear(''));
    } else {
    }
    // if (alertState.error) {
    // }
  }, [alertState]);
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <CssBaseline />
      <Topbar props={children} onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>
        {error && <Alert severity="error">{error}</Alert>}

        {children}
        <Footer />
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
