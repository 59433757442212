import { reasonConstants as constants } from '../_constants';

const initialState = {};
export function reasonOperation(state = initialState, action) {
  switch (action.type) {
    case constants.INSERT_REQUEST:
      return { ...state, insertRequest: true };
    case constants.INSERT_SUCCESS:
      return {
        ...state,
        insertSuccess: true,
        insertRequest: false,
        data: action.data,
      };
    case constants.INSERT_FAILURE:
      return {
        ...state,
        insertFailure: true,
        insertRequest: false,
        error: action.error,
      };

    case constants.UPDATE_REQUEST:
      return { ...state, updateRequest: true };
    case constants.UPDATE_SUCCESS:
      return {
        ...state,
        updateSuccess: true,
        updateRequest: false,
        data: action.data,
      };
    case constants.UPDATE_FAILURE:
      return {
        ...state,
        updateFailure: true,
        updateRequest: false,
        error: action.error,
      };

    case constants.DELETE_REQUEST:
      return { ...state, deleteRequest: true };
    case constants.DELETE_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        deleteRequest: false,
        data: action.data,
      };
    case constants.DELETE_FAILURE:
      return {
        ...state,
        deleteFailure: true,
        deleteRequest: false,
        error: action.error,
      };

    case constants.GET_REQUEST:
      return { ...state, getRequest: true };
    case constants.GET_SUCCESS:
      return {
        ...state,
        getSuccess: true,
        getRequest: false,
        data: action.data,
      };
    case constants.GET_FAILURE:
      return {
        ...state,
        getFailure: true,
        getRequest: false,
        error: action.error,
      };

    case constants.GETALL_REQUEST:
      return {
        ...state,
        getAllRequest: true,
        getAllSuccess: false,
        getAllFailure: false,
      };
    case constants.GETALL_SUCCESS:
      return {
        ...state,
        getAllRequest: false,
        getAllSuccess: true,
        getAllFailure: false,
        data: action.data,
      };
    case constants.GETALL_FAILURE:
      return {
        ...state,
        getAllRequest: false,
        getAllSuccess: false,
        getAllFailure: true,
        error: action.error,
      };

    default:
      return {}; // if return state, insertRequest, insertSuccess, etc., will remain the same value
  }
}
