export const ReturnConstants = {
  API_INSERT: '/api/asset/return/insert',
  API_UPDATE: '/api/asset/return/update',
  API_DELETE: '/api/asset/return/delete',
  API_APPROVE: '/api/asset/return/apporve',
  API_GET: '/api/asset/return/',
  API_GETALL: '/api/asset/return/getall',
  API_GET_BY_ID: '/api/asset/return/info?code=',

  API_GET_REASON: '/api/reason/getall',

  API_AUTOCOMPLETE: '/api/autocomplete',
  API_AUTOCOMPLETE_OWNER: '/api/autocomplete/owner',

  INSERT_REQUEST: 'RETURN_INSERT_REQUEST',
  INSERT_SUCCESS: 'RETURN_INSERT_SUCCESS',
  INSERT_FAILURE: 'RETURN_INSERT_FAILURE',

  UPDATE_REQUEST: 'RETURN_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'RETURN_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'RETURN_UPDATE_FAILURE',

  DELETE_REQUEST: 'RETURN_DELETE_REQUEST',
  DELETE_SUCCESS: 'RETURN_DELETE_SUCCESS',
  DELETE_FAILURE: 'RETURN_DELETE_FAILURE',

  GET_REQUEST: 'RETURN_GET_REQUEST',
  GET_SUCCESS: 'RETURN_GET_SUCCESS',
  GET_FAILURE: 'RETURN_GET_FAILURE',

  GETALL_REQUEST: 'RETURN_GETALL_REQUEST',
  GETALL_SUCCESS: 'RETURN_GETALL_SUCCESS',
  GETALL_FAILURE: 'RETURN_GETALL_FAILURE',

  RESET: 'RETURN_RESET',
  SERVICE: 'RETURN_SERVICE',
};
