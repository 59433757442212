import { LOGIN_URL } from '../components/login';
import axios from 'axios';
import { smtpConstants as constants } from '../_constants';
import { authHeader } from '../_helpers/auth-header';
import { history } from '../_helpers';
import { postRequest, getRequest } from '../_helpers';

export const smtpService = {
  
  insertItem,
  updateItem,
  deleteItem,
  getItem,
  getAllItem,
};
async function insertItem(item) {
    return await postRequest(
      constants.API_INSERT,
      item,
      null,
      authHeader().Authorization,
    );
  }
  
async function updateItem(item) {
  return await postRequest(
    constants.API_UPDATE,
    item,
    null,
    authHeader().Authorization,
  );
}

async function deleteItem(code) {
  return await postRequest(
    constants.API_DELETE,
    code,
    null,
    authHeader().Authorization,
  );
}
async function getItem(code) {
  return await getRequest(
    constants.API_GET + code,
    null,
    authHeader().Authorization,
  );
}

async function getAllItem() {
  return await getRequest(
    constants.API_GETALL,
    null,
    authHeader().Authorization,
  );
}
