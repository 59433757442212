export const OwnerChangeConstants = {
  API_INSERT: '/api/asset/ownerchange/insert',
  API_UPDATE: '/api/asset/ownerchange/update',
  API_DELETE: '/api/asset/ownerchange/delete',
  API_APPROVE: '/api/asset/ownerchange/apporve',
  API_GET: '/api/asset/ownerchange/',
  API_GETALL: '/api/asset/ownerchange/getall',
  API_GET_BY_ID: '/api/asset/ownerchange/info?code=',

  API_GET_REASON: '/api/reason/getall',
  API_GET_OWNER_TYPE: '/api/ownertype/getall',
  API_GET_ACCOUNT_TYPE: '/api/typeGFT/getall',
  API_GET_ACCOUNT: '/api/accountGFT/typeGFT/',
  API_GET_CUSTOMER_BY_ACCOUNT: '/api/customer/accountGFT/',
  API_GET_CUSTOMER_BY_ACCOUNT_AUTOCOMPLETE: '/api/autocomplete/customer',

  API_AUTOCOMPLETE: '/api/autocomplete',
  API_AUTOCOMPLETE_OWNER: '/api/autocomplete/owner',

  INSERT_REQUEST: 'OWNERCHANGE_INSERT_REQUEST',
  INSERT_SUCCESS: 'OWNERCHANGE_INSERT_SUCCESS',
  INSERT_FAILURE: 'OWNERCHANGE_INSERT_FAILURE',

  UPDATE_REQUEST: 'OWNERCHANGE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'OWNERCHANGE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'OWNERCHANGE_UPDATE_FAILURE',

  DELETE_REQUEST: 'OWNERCHANGE_DELETE_REQUEST',
  DELETE_SUCCESS: 'OWNERCHANGE_DELETE_SUCCESS',
  DELETE_FAILURE: 'OWNERCHANGE_DELETE_FAILURE',

  GET_REQUEST: 'OWNERCHANGE_GET_REQUEST',
  GET_SUCCESS: 'OWNERCHANGE_GET_SUCCESS',
  GET_FAILURE: 'OWNERCHANGE_GET_FAILURE',

  GETALL_REQUEST: 'OWNERCHANGE_GETALL_REQUEST',
  GETALL_SUCCESS: 'OWNERCHANGE_GETALL_SUCCESS',
  GETALL_FAILURE: 'OWNERCHANGE_GETALL_FAILURE',

  RESET: 'OWNERCHANGE_RESET',
  SERVICE: 'OWNERCHANGE_SERVICE',
};
