import { makeStyles } from '@material-ui/core/styles';

export const useMuiFormStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  btnSave: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnBack: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  container: { padding: '0px !important' },
  group: { padding: theme.spacing(1), margin: theme.spacing(0) },
  card: { padding: theme.spacing(2), margin: theme.spacing(1) },
}));
