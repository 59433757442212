export const assetGroupConstants = {
  API_INSERT: '/api/class/subclass/group/insert',
  API_UPDATE: '/api/class/subclass/group/update',
  API_DELETE: '/api/class/subclass/group/delete',
  API_GET: '/api/class/subclass/group/',
  API_GETALL: '/api/class/subclass/group/getall',

  INSERT_REQUEST: 'ASSET_GROUP_INSERT_REQUEST',
  INSERT_SUCCESS: 'ASSET_GROUP_INSERT_SUCCESS',
  INSERT_FAILURE: 'ASSET_GROUP_INSERT_FAILURE',

  UPDATE_REQUEST: 'ASSET_GROUP_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ASSET_GROUP_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ASSET_GROUP_UPDATE_FAILURE',

  DELETE_REQUEST: 'ASSET_GROUP_DELETE_REQUEST',
  DELETE_SUCCESS: 'ASSET_GROUP_DELETE_SUCCESS',
  DELETE_FAILURE: 'ASSET_GROUP_DELETE_FAILURE',

  GET_REQUEST: 'ASSET_GROUP_GET_REQUEST',
  GET_SUCCESS: 'ASSET_GROUP_GET_SUCCESS',
  GET_FAILURE: 'ASSET_GROUP_GET_FAILURE',

  GETALL_REQUEST: 'ASSET_GROUP_GETALL_REQUEST',
  GETALL_SUCCESS: 'ASSET_GROUP_GETALL_SUCCESS',
  GETALL_FAILURE: 'ASSET_GROUP_GETALL_FAILURE',

  RESET: 'ASSET_GROUP_RESET',
  SERVICE: 'ASSET_GROUP_SERVICE',
};
