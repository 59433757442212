import React from 'react';
import { translate } from 'react-switch-lang';
import { formType } from '../../../_constants';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Card,
  CardHeader,
  Avatar,
  Typography,
  Button,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';

// Icons
import SaveIcon from '@material-ui/icons/Save';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  header: {
    padding: theme.spacing(2),
  },
  icon: {
    textAlign: 'left',
  },
  action: {
    textAlign: 'right',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    overflow: 'scroll',
  },
  large_modal: {
    //display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    overflow: 'scroll',
  },
  button: {
    margin: theme.spacing(1),
  },
  btnSave: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnBack: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
}));

const FormModal = (props) => {
  const classes = useStyles();

  const {
    t,
    children,
    header,
    type,
    open,
    onClose,
    onSubmit,
    size = null,
  } = props;

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        className={size === 'large' ? classes.large_modal : classes.modal}
      >
        <Card>
          <CardHeader
            avatar={
              <Avatar>
                <AllInboxIcon />
              </Avatar>
            }
            action={
              <div className={classes.action}>
                <Button
                  variant="outlined"
                  onClick={onSubmit}
                  hidden={type === formType.VIEW}
                  className={classes.btnSave}
                  startIcon={<SaveIcon />}
                  color={'primary'}
                >
                  Save
                </Button>
                <Button
                  color="default"
                  variant="outlined"
                  className={classes.btnBack}
                  onClick={(e) => {
                    e.preventDefault();
                    onClose();
                  }}
                  startIcon={<KeyboardReturnIcon />}
                >
                  Back
                </Button>
              </div>
            }
            title={
              <Typography gutterBottom variant="h5" component="h2">
                {header.content}
              </Typography>
            }
            subheader={
              <Typography gutterBottom variant="h5" component="h2">
                {header.subheader}
              </Typography>
            }
          />
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <form>{children}</form>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default translate(FormModal);
