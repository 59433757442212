import { jQGRidConstants as constants } from '../_constants';

export const jQGridListData = (state = {}, action) => {
  switch (action.type) {
    case constants.REQUEST:
      return { isLoading: true, success: false, isError: false };
    case constants.SUCCESS:
      return {
        isLoading: false,
        success: true,
        isError: false,
        data: action.data.data,
      };
    case constants.FAILURE:
      return {
        isLoading: false,
        success: false,
        isError: true,
        error: action.error,
      };
    case constants.REQUEST_AUTOCOMPLETE:
      return {
        isLoadingAutocomplete: true,
        successAutocomplete: false,
        isError: false,
      };
    case constants.SUCCESS_AUTOCOMPLETE:
      return {
        isLoadingAutocomplete: false,
        successAutocomplete: true,
        isError: false,
        optionData: action.data.data,
      };
    case constants.FALURE_AUTOCOMPLETE:
      return {
        isLoadingAutocomplete: false,
        successAutocomplete: false,
        isError: true,
        error: action.error,
      };
    default:
      return { isLoading: false };
  }
};
