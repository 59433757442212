import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Card, Grid } from 'semantic-ui-react';
import { translate } from 'react-switch-lang';
import { Title, SaveButton, ClearButton } from '../../../../_fragments';
import { default as Header } from '../../_InventoryFlagments/_InventoryHeader';
import { default as Location } from '../../_InventoryFlagments/_inventoryLocation';
import { default as Additem } from '../../_InventoryFlagments/_InventoryAdditem';
import { default as ItemList } from '../../_InventoryFlagments/_InventoryItemList';
import { default as Footer } from '../../_InventoryFlagments/_InventoryFooter';

function Stocktransferedit(props) {
  // const { t } = props;
  const { register, handleSubmit, errors, control } = useForm();
  //const [submitting, setSubmitting] = useState(false);
  //const [submitstatus, setsubmitstatus] = useState(false);
  const onSubmit = (data) => {
    console.log('Form_data: ', data);
    //setSubmitting(false);
  };
  const id = props.match.params.id;
  const [isReadOnly, setisReadOnly] = useState(props.location.state.isReadOnly);
  useEffect(() => {
    setisReadOnly(props.location.state.isReadOnly)
  }, [setisReadOnly, props])
  // console.log('location: ', props.location);

  //====== Elements ========= //
  const buttonAction = (history, t) => {
    return (
      <>
        <SaveButton />
        <ClearButton history={history} />
      </>
    );
  };

  return (
    <>
      Stocktransferedit
      <p>{id}</p>
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
        // size="small"
        // loading={submitting || props.loading}
        // success={props.success}
        >
          <Card centered fluid>
            <Card.Content>
              <Card.Header>
                <Grid divided="vertically" padded>
                  <Grid.Column computer={12} tablet={12} mobile={16}>
                    <Title
                      icon="cubes"
                      iconaction="exchange"
                      content="Asset Transfer"
                      description="Create"
                      props={props}
                    />
                  </Grid.Column>
                  <Grid.Column
                    textAlign="right"
                    computer={4}
                    tablet={4}
                    mobile={16}
                  >
                    {buttonAction(props.history)}
                  </Grid.Column>
                </Grid>
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <Card.Description>
                {/* {submitstatus && (
                  <Message
                    {...submitstatus}
                    header="Action Forbidden"
                    content="You can only sign up for an account once with a given e-mail address."
                  />
                )} */}

                <Header
                  errors={errors}
                  register={register}
                  control={control}
                  readOnly={isReadOnly}
                />
              </Card.Description>
              <Card.Description>
                <Location
                  control={control}
                  register={register}
                  errors={errors}
                  readOnly={isReadOnly}
                />
              </Card.Description>
              <Card.Description>
                <Additem
                  control={control}
                  register={register}
                  errors={errors}
                  readOnly={isReadOnly}
                />
              </Card.Description>
              <Card.Description>
                <ItemList
                  control={control}
                  register={register}
                  errors={errors}
                  readOnly={isReadOnly}
                />
              </Card.Description>
              <Card.Description>
                <Grid divided="vertically" padded>
                  <Grid.Column computer={12} tablet={12} mobile={16}>
                    <Footer
                      control={control}
                      register={register}
                      errors={errors}
                      readOnly={isReadOnly}
                    />
                  </Grid.Column>
                  <Grid.Column
                    textAlign="right"
                    computer={4}
                    tablet={4}
                    mobile={16}
                  >
                    {buttonAction(props.history)}
                  </Grid.Column>
                </Grid>
              </Card.Description>
            </Card.Content>
          </Card>
        </form>
      </div>
    </>
  );
}

export default translate(Stocktransferedit);
