export const AssetTransferConstants = {
  API_ASSETTRANSFER_INSERT: '/api/asset/transfer/insert',
  API_ASSETTRANSFER_UPDATE: '/api/asset/transfer/update',
  API_ASSETTRANSFER_DELETE: '/api/asset/transfer/delete',
  API_ASSETTRANSFER_GET: '/api/asset/transfer/',
  API_ASSETTRANSFER_GETALL: '/api/asset/transfer/getall',

  INSERT_REQUEST: 'ASSETTRANSFER_INSERT_REQUEST',
  INSERT_SUCCESS: 'ASSETTRANSFER_INSERT_SUCCESS',
  INSERT_FAILURE: 'ASSETTRANSFER_INSERT_FAILURE',

  UPDATE_REQUEST: 'ASSETTRANSFER_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ASSETTRANSFER_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ASSETTRANSFER_UPDATE_FAILURE',

  DELETE_REQUEST: 'ASSETTRANSFER_DELETE_REQUEST',
  DELETE_SUCCESS: 'ASSETTRANSFER_DELETE_SUCCESS',
  DELETE_FAILURE: 'ASSETTRANSFER_DELETE_FAILURE',

  GET_REQUEST: 'ASSETTRANSFER_GET_REQUEST',
  GET_SUCCESS: 'ASSETTRANSFER_GET_SUCCESS',
  GET_FAILURE: 'ASSETTRANSFER_GET_FAILURE',

  GETALL_REQUEST: 'ASSETTRANSFER_GETALL_REQUEST',
  GETALL_SUCCESS: 'ASSETTRANSFER_GETALL_SUCCESS',
  GETALL_FAILURE: 'ASSETTRANSFER_GETALL_FAILURE',

  RESET: 'ASSETTRANSFER_RESET',
  SERVICE: 'ASSETTRANSFER_SERVICE',
};
