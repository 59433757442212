import React, { Component } from "react";
import { Segment, Message, Typography, Menu, Sidebar, Icon, Grid, Header, List, Image, Container, Divider } from 'semantic-ui-react'
import './footer.css';

class Footer extends Component {
  render() {
    return (
      <>

        <Segment vertical style={{ margin: '0em 0em 0em', padding: '1em 0em' }}>
          <Message>

            <Header as='h6'>
              Version 3.0.2  Copyright © 2019-2020 <a href="http://verismart.co.th/">Verismart Technologies Co., Ltd.</a> All rights reserved.

            </Header>


          </Message>
        </Segment>

        {/* <Container textAlign='center'>

            <List horizontal inverted divided link size='small'>
              <List.Item as='a' href='#'>
                Site Map
          </List.Item>
              <List.Item as='a' href='#'>
                Contact Us
          </List.Item>
              <List.Item as='a' href='#'>
                Terms and Conditions
          </List.Item>
              <List.Item as='a' href='#'>
                Privacy Policy
          </List.Item>
            </List>
          </Container> */}


        {/* <div>
        <footer className="main-footer">
          <div className="float-right d-none d-sm-block">
            <b>Version</b> 3.0.2
          </div>
          <strong>
            Copyright © 2019-2020 <a href="http://verismart.co.th/">Verismart Technologies Co., Ltd.</a>.
          </strong>{" "}
          All rights reserved.
        </footer>
      </div> */}
      </>


    );
  }
}

export default Footer;
