export const userConstants = {
  API_INSERT: '/api/user/insert',
  API_UPDATE: '/api/user/update',
  API_DELETE: '/api/user/delete',
  API_GET: '/api/user/',
  API_GETALL: '/api/user/getall',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  INSERT_REQUEST: 'USER_INSERT_REQUEST',
  INSERT_SUCCESS: 'USER_INSERT_SUCCESS',
  INSERT_FAILURE: 'USER_INSERT_FAILURE',

  UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  GET_REQUEST: 'USER_GET_REQUEST',
  GET_SUCCESS: 'USER_GET_SUCCESS',
  GET_FAILURE: 'USER_GET_FAILURE',

  RESET: 'USER_RESET',
  SERVICE: 'USER_SERVICE',
};
