import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useDispatch } from 'react-redux';
import {
  addItem,
  actionReadOnly,
} from '../_InventoryFlagments/redux/actions/assettable.action';
import palettle from '../../../theme/palette';

const AssetTable = (props) => {
  const { getItems, readOnly } = props;
  //props
  const dispatch = useDispatch();
  // const [submit, setsubmit] = useState(false);
  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: 'Asset Code',
      field: 'assetCode',
      editable: 'never',
    },
    { title: 'Description', field: 'assetDescription', editable: 'never' },
    { title: 'Serial', field: 'serial', editable: 'never' },
    {
      title: 'Quanitity',
      field: 'quanitity',
      type: 'numeric',
      editable: 'onUpdate',
    },

    { title: 'remark', field: 'remark', editable: 'onUpdate' },
  ]);

  useEffect(() => {
    // setItems(...items, getItems);
    setItems((prev) => [...prev, ...getItems]);
  }, [getItems]);

  useEffect(() => {
    dispatch(addItem(items));
    if (items.length > 0) {
      dispatch(actionReadOnly(true));
    } else {
      dispatch(actionReadOnly(false));
    }
  }, [items]);

  return (
    <>
      <div id="materialTable">
        <MaterialTable
          title="Asset List"
          columns={columns}
          data={items}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: palettle.background.default,
              color: palettle.primary.main,
            },
          }}
          editable={
            !readOnly
              ? {
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...items];
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        setItems([...dataUpdate]);
                        resolve(items);
                      }, 200);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataDelete = [...items];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        setItems([...dataDelete]);
                        resolve();
                      }, 200);
                    }),
                }
              : {}
          }
        />
      </div>
    </>
  );
};

export default AssetTable;
