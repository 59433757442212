
export const model = {
    // asset information
    code: '',
    roleCode: '',
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    bcc:[],
};