import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { roleService } from '../../_services';
import { userAction } from '../../_action/user.action';
import { useSelector, useDispatch } from 'react-redux';
import { Login } from '../login';

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;
  const user = JSON.parse(localStorage.getItem('user'));
  const [redirect, setRedirect] = React.useState(false);
  const loginState = useSelector((state) => state.userOperation);
  const [logout, setLogout] = React.useState(false);
  // React.useEffect(() => {
  //   if (loginState.logout === false) {
  //     setLogout(true);
  //   }
  // }, [loginState]);
  React.useEffect(() => {
    let isCancelled = false;
    try {
      const role = user.roleinfo; // {code, description}

      roleService.getRolePermissions(role.code).then((res) => {
        if (!isCancelled) {
          var menuPermission = res.data.results.find(
            (o) => o.menuCode === props.menuCode,
          );

          if (!!menuPermission && menuPermission.view_permission === false) {
            setRedirect(true);
          }
        }
      });
    } catch (error) {
      console.error('error: ', JSON.stringify(error));
      setRedirect(true);
    }

    return () => {
      isCancelled = true;
    };
  }, []);
  return (
    <>
      {logout && (
        <Route
          render={
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          }
        />
      )}
      {redirect && (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          )}
        />
      )}
      <Route
        {...rest}
        render={(props) =>
          !!localStorage.getItem('user') && !redirect ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    </>
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};
export default RouteWithLayout;
