import React from "react";
import {translate} from 'react-switch-lang';
import { Header, Icon } from "semantic-ui-react";

function AssetTitle(props) {
    const {t} = props;
    //console.log(props);
    return (
        <>
            <Header as={'h2'}>
                <Icon name={props.icon}/>
                <Header.Content>
                    {props.content}
                    <Header.Subheader>{props.subHeader}</Header.Subheader>
                </Header.Content>
            </Header>
        </>
    );
}

export default translate(AssetTitle);
