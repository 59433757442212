export const AssetCheckConstants = {
  API_INSERT: '/api/asset/check/insert',
  API_UPDATE: '/api/asset/check/update',
  API_DELETE: '/api/asset/check/delete',
  API_APPROVE: '/api/asset/check/approve',
  API_GET: '/api/asset/check/',
  API_GETALL: '/api/asset/check/getall',
  API_GET_BY_ID: '/api/sms1/asset/check/info/',
  API_OWNER_ASSET_VERIFY: '/api/asset/check/verify/owner',

  API_GET_REASON: '/api/reason/getall',

  API_AUTOCOMPLETE: '/api/autocomplete',
  API_AUTOCOMPLETE_OWNER: '/api/autocomplete/owner',

  INSERT_REQUEST: 'ASSETCHECK_INSERT_REQUEST',
  INSERT_SUCCESS: 'ASSETCHECK_INSERT_SUCCESS',
  INSERT_FAILURE: 'ASSETCHECK_INSERT_FAILURE',

  UPDATE_REQUEST: 'ASSETCHECK_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ASSETCHECK_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ASSETCHECK_UPDATE_FAILURE',

  DELETE_REQUEST: 'ASSETCHECK_DELETE_REQUEST',
  DELETE_SUCCESS: 'ASSETCHECK_DELETE_SUCCESS',
  DELETE_FAILURE: 'ASSETCHECK_DELETE_FAILURE',

  GET_REQUEST: 'ASSETCHECK_GET_REQUEST',
  GET_SUCCESS: 'ASSETCHECK_GET_SUCCESS',
  GET_FAILURE: 'ASSETCHECK_GET_FAILURE',

  GETALL_REQUEST: 'ASSETCHECK_GETALL_REQUEST',
  GETALL_SUCCESS: 'ASSETCHECK_GETALL_SUCCESS',
  GETALL_FAILURE: 'ASSETCHECK_GETALL_FAILURE',

  RESET: 'ASSETCHECK_RESET',
  SERVICE: 'ASSETCHECK_SERVICE',
};
