import React, { Component } from 'react';
import MaterialTable from 'material-table';
import Iframe from 'react-iframe';

const tableRef = React.createRef();

const TestReport = (props) => {
  return (
    <>
      {/* <Table /> */}
      <Iframe
        url="https://assetreport.verismartsoft.biz"
        width="100%"
        height={window.innerHeight}
        id="myId"
        className="myClassname"
        display="block"
        position="static"
      />
    </>
  );
};

export default TestReport;

const Table = () => {
  return (
    <MaterialTable
      title="Refresh Data Preview"
      tableRef={tableRef}
      options={{
        filtering: true,
        exportButton: true,
      }}
      columns={[
        {
          title: 'Avatar',
          field: 'avatar',
          render: (rowData) => (
            <img
              style={{ height: 36, borderRadius: '50%' }}
              src={rowData.avatar}
            />
          ),
        },
        { title: 'Id', field: 'id' },
        { title: 'First Name', field: 'first_name' },
        { title: 'Last Name', field: 'last_name' },
      ]}
      data={(query) =>
        new Promise((resolve, reject) => {
          console.log(query);
          //console.log('query: ', JSON.stringify(query));
          let url = 'https://reqres.in/api/users?';
          url += 'per_page=' + query.pageSize;
          url += '&page=' + (query.page + 1);
          fetch(url)
            .then((response) => response.json())
            .then((result) => {
              resolve({
                data: result.data,
                page: result.page - 1,
                totalCount: result.total,
              });
            });
        })
      }
      actions={[
        {
          icon: 'refresh',
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => tableRef.current && tableRef.current.onQueryChange(),
        },
      ]}
    />
  );
};
