import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Form, Divider } from 'semantic-ui-react';
import { translate } from 'react-switch-lang';
import { Title, SaveButton, ClearButton } from '../../../../_fragments';
import { default as Header } from './components/borrow.header.edit';
import { default as Additem } from './components/borrow.additem.edit';
import { default as Footer } from '../../_InventoryFlagments/_InventoryFooter';
import { Button } from '@material-ui/core';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//****Redux & alert**** */
import { commonAlert } from '../../../../labelConstant';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert, positions } from 'react-alert';
import {
  Update,
  ResetItem,
} from './components/redux/actions/borrow.additem.action';
import { BorrowService as service } from '../../../../_services/transaction/asset.borrow.service';
import { bool } from 'yup';

//****Redux & alert**** */

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  bg: {
    background: 'linear-gradient(45deg, #b2dfdb 30%, #1976d2 90%)',
    padding: theme.spacing(3, 3, 0),

    // background: '#bbdefb',
  },
  bgBodyCard: {
    background: '#cfd8dc',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const Assetborrowedit = (props) => {
  const { t, history } = props;

  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [submitstatus, setsubmitstatus] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const storage = useSelector((state) => state.borrowStore);

  const {
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    getValues,
  } = useForm();

  let theData = {
    code: null,
    dateValue: null,
    ownerType: null,
    borrowBy: null,
    remark: '',
    Reason: null,
    borrowDate: null,
    returnDate: null,
    borrowDetail: [],
  };
  let isReadOnly = false;
  if (!!props.location.state) {
    isReadOnly = props.location.state.isReadOnly;
    if (props.location.state.status == 'APPROVE') {
      isReadOnly = true;
    }
  } else {
    isReadOnly = false;
  }
  const onsubmitAsync = async (data) => {
    // console.log('data:', data);
    setSubmitting(true);

    try {
      const storageSelector = (Obj) => {
        return Object.keys(Obj).map((storageKey) => Obj[storageKey]);
      };

      const storageArray = storageSelector(storage);

      theData = {
        ...theData,
        code: data.code,
        dateValue: data.dateValue,
        ownerType: data.OwnerType,
        borrowBy: data.borrowby.value,
        remark: data.remark,
        Reason: data.Reason.value,
        borrowDate: data.borrowDate,
        returnDate: data.returnDate,
        borrowDetail: storageArray,
      };
      console.log('theData:', theData);

      dispatch(Update(theData));
      setSubmitting(false);
      dispatch(ResetItem);
    } catch (error) {
      console.log('Error message: ', error);
      setSubmitting(false);
      dispatch(ResetItem);
    }
  };

  const approveEvent = async () => {
    const result = await service.Approve(props.match.params.id);
    //window.alert(result);
    //console.log('result', result);

    if (result.status == 204) {
      alert.show('Approved - Successfull', {
        position: positions.TOP_CENTER,
        timeout: 2000,
        type: 'success',
      });
      props.history.goBack();
    } else {
      alert.error(result.message);
    }
  };

  return (
    <>
      <form
        key="formAssetBorrowInsert"
        onSubmit={handleSubmit(onsubmitAsync)}
        size="small"
        noValidate
        loading={submitting}
        //success={props.success}
      >
        <Grid divided="vertically" padded>
          <Grid.Column computer={10} tablet={11} mobile={16}>
            <Title
              icon="cubes"
              iconaction="exchange"
              content="Asset Borrow"
              description="Create"
              props={props}
            />
          </Grid.Column>
          <Grid.Column textAlign="right" computer={6} tablet={5} mobile={16}>
            {!isReadOnly && (
              <>
                <Button
                  name="approveBtn"
                  size="large"
                  color="secondary"
                  variant="contained"
                  startIcon={<BeenhereIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    approveEvent();
                  }}
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  control={control}
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  color="primary"
                  name="submitBtn"
                >
                  Update
                </Button>
              </>
            )}

            <Button
              name="backBtn"
              size="large"
              color="default"
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={(e) => {
                e.preventDefault();
                props.history.goBack();
              }}
            >
              Back
            </Button>
          </Grid.Column>
        </Grid>
        <Divider />
        {/* <pre>{JSON.stringify(assetData)}</pre> */}
        <Header
          code={props.match.params.id}
          register={register}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          control={control}
          readOnly={isReadOnly}
        />
        <Additem
          code={props.match.params.id}
          register={register}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          control={control}
          readOnly={isReadOnly}
        />

        <Grid divided="vertically" columns={2} padded>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            {/* <Footer
                    control={control}
                    errors={errors}
                    readOnly={props.readOnly}
                  /> */}
          </Grid.Column>
          <Grid.Column textAlign="right" computer={4} tablet={16} mobile={16}>
            {/* <ActionButton props={props} /> */}
          </Grid.Column>
        </Grid>
      </form>
    </>
  );
};

export default translate(Assetborrowedit);
