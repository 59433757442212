import { formType } from '../_constants';

export const BUTTON_SPINNER = 'spinner-border spinner-border-sm mr-1';

export function findPair(key, list) {
  let match = [];
  list.map((e) => {
    if (e[0] === key) match = e;
  });
  return match;
}

export function findKeyInObject(key, obj) {
  const objList = Object.entries(obj).map((pair) => {
    return pair;
  });
  const result = findPair(key, objList);
  return result;
}

export function extractTouchedInput(fieldName, touched) {
  const touchResult = findKeyInObject(fieldName, touched);
  const isTouched = touchResult === [] ? false : touchResult[1];
  return { isTouched };
}

export function extractErrorInput(fieldName, errors) {
  const errorResult = findKeyInObject(fieldName, errors);
  const isError = errorResult === [] ? false : errorResult[0];
  const errorMsg = errorResult === [] ? false : errorResult[1];

  return { isError, errorMsg };
}

export function mapRefToOptions(array) {
  if (array === undefined) return [];
  return array.map((data, index) => {
    return {
      key: data.code,
      text: data.code + ' - ' + data.description,
      // text: data.code,
      value: data.code,
    };
  });
}

export const replaceNullObject = (obj) => {
  let newObj = obj;
  Object.keys(newObj).forEach((key, i) => {
    if (obj[key] === null) {
      obj[key] = '';
    }
  });
  return newObj;
};

export const formHeader = (t, name, icon, type) => {
  switch (type) {
    case formType.VIEW:
      return {
        icon: icon,
        iconaction: 'search',
        content: t('View'),
        subHeader: t(name + 'Form'),
      };
    case formType.INSERT:
      return {
        icon: icon,
        iconaction: 'plus',
        content: t('Insert'),
        subHeader: t(name + 'Form'),
      };
    case formType.UPDATE:
      return {
        icon: icon,
        iconaction: 'pencil',
        content: t(name + 'Update'),
        subHeader: t(name + 'Form'),
      };
    case formType.DELETE:
      return {
        icon: icon,
        iconaction: 'trash',
        content: t(name + 'Delete'),
        subHeader: t(name + 'Form'),
      };
    default:
      return {
        icon: '',
        iconaction: '',
        content: '',
        subHeader: '',
      };
  }
};
