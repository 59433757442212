export const branchConstants = {
  API_INSERT: '/api/branch/insert',
  API_UPDATE: '/api/branch/update',
  API_DELETE: '/api/branch/delete',
  API_GET: '/api/branch/',
  API_GETALL: '/api/branch/getall',

  INSERT_REQUEST: 'BRANCH_INSERT_REQUEST',
  INSERT_SUCCESS: 'BRANCH_INSERT_SUCCESS',
  INSERT_FAILURE: 'BRANCH_INSERT_FAILURE',

  UPDATE_REQUEST: 'BRANCH_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'BRANCH_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'BRANCH_UPDATE_FAILURE',

  DELETE_REQUEST: 'BRANCH_DELETE_REQUEST',
  DELETE_SUCCESS: 'BRANCH_DELETE_SUCCESS',
  DELETE_FAILURE: 'BRANCH_DELETE_FAILURE',

  GET_REQUEST: 'BRANCH_GET_REQUEST',
  GET_SUCCESS: 'BRANCH_GET_SUCCESS',
  GET_FAILURE: 'BRANCH_GET_FAILURE',

  GETALL_REQUEST: 'BRANCH_GETALL_REQUEST',
  GETALL_SUCCESS: 'BRANCH_GETALL_SUCCESS',
  GETALL_FAILURE: 'BRANCH_GETALL_FAILURE',

  RESET: 'BRANCH_RESET',
  SERVICE: 'BRANCH_SERVICE',
};
