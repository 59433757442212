import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from 'react-switch-lang';
import { Grid, Button, Slide } from '@material-ui/core';
import { Divider } from 'semantic-ui-react';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import { Title } from '../../../../_fragments';
import { default as Header } from './components/return.header';
import { default as Additem } from './components/return.additem';
// import { default as Footer } from '../../_InventoryFlagments/_InventoryFooter';

//****Redux & alert**** */
// import { commonAlert } from '../../../../labelConstant';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import {
  Save,
  Update,
  ResetItem,
} from './components/redux/actions/return.additem.action';
import { data_return_insert } from './components/datatest';
import { ReturnService as service } from '../../../../_services/transaction/asset.return.service';

//****Redux & alert**** */

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  card: {
    // height: 100,
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
  },
  btnSave: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnArroved: {
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnBack: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
}));

// let isReadOnly = false;
let isCreate = false;

const AssetReturnForm = (props) => {
  //console.log('props', props);
  const { t, history } = props;

  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  // const [submitstatus, setsubmitstatus] = useState(false);
  const [direction, setDirection] = useState({
    slide: 'left',
    in: true,
    timeout: 650,
  });

  const dispatch = useDispatch();
  const alert = useAlert();
  const storage = useSelector((state) => state.returnStore);

  const {
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    getValues,
  } = useForm();

  let isReadOnly = false;

  if (!!props.location.state) {
    isReadOnly = props.location.state.isReadOnly;
    if (props.location.state.status === 'APPROVE') {
      isReadOnly = true;
    }
  } else {
    isReadOnly = false;
  }

  let myData = data_return_insert;

  const onsubmitAsync = async (data) => {
    setSubmitting(true);

    const storageSelector = (Obj) => {
      return Object.keys(Obj).map((storageKey) => Obj[storageKey]);
    };
    const storageArray = storageSelector(storage);
    let returnBy = '';

    //update action//
    if (data.borrowBy.value !== undefined) {
      returnBy = data.borrowBy.value;
    } else {
      returnBy = data.borrowBy[0].value;
    }
    myData = {
      assetReturnCode: data.code,
      assetBorrowCode: data.assetBorrowCode,
      dateValue: data.dateValue,
      borrowDate: data.borrowDate,
      returnDate: data.returnDate,
      ownerType: data.OwnerType.value,
      returnBy: returnBy,
      remark: data.remark,
      reason: data.Reason.value,
      returnDetail: storageArray,
    };

    //console.log('formdata_myData:', myData);
    if (props.location.state.action === 'create') {
      console.log('create');
      dispatch(Save(myData));
      dispatch(ResetItem);
      setSubmitting(false);
    }
    if (props.location.state.action === 'edit') {
      console.log('edit');
      dispatch(Update(myData));
      //dispatch(ResetItem);
      setSubmitting(false);
    }

    if (props.location.state.action === 'view') {
      console.log('view');
    }
  };

  const approveEvent = async () => {
    const result = await service.Approve(props.match.params.id);
    // console.log(result);
    if (result.status === 204) {
      alert.show('Approved', {
        timeout: 2000,
        type: 'success',
      });
      history.goBack();
    } else {
      alert.error('Error');
    }
  };
  return (
    <>
      <Slide
        timeout={direction.timeout}
        direction={direction.slide}
        in={direction.in}
        mountOnEnter
        unmountOnExit
      >
        <form onSubmit={handleSubmit(onsubmitAsync)} size="small" noValidate>
          <Grid container>
            <Grid item xs={12} sm={7}>
              <Title
                icon="cubes"
                iconaction="exchange"
                content="Asset Return"
                description={
                  !!props.location.state.action
                    ? props.location.state.action
                    : ''
                }
                props={props}
              />
            </Grid>
            <Grid item style={{ textAlign: 'end' }} xs={12} sm={5}>
              {!isReadOnly && (
                <>
                  {!isCreate && (
                    <Button
                      name="approveBtn"
                      className={classes.btnArroved}
                      size="large"
                      color="secondary"
                      variant="outlined"
                      startIcon={<BeenhereIcon />}
                      onClick={(e) => {
                        e.preventDefault();
                        approveEvent();
                      }}
                    >
                      Approve
                    </Button>
                  )}

                  <Button
                    className={classes.btnSave}
                    variant="outlined"
                    type="submit"
                    size="large"
                    startIcon={<SaveIcon />}
                    name="submitBtn"
                  >
                    Save
                  </Button>
                </>
              )}

              <Button
                className={classes.btnBack}
                name="backBtn"
                size="large"
                color="default"
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  setDirection({
                    ...direction,
                    slide: 'left',
                    in: false,
                  });
                  setTimeout(() => {
                    history.goBack();
                    dispatch(ResetItem);
                  }, direction.timeout);
                }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
          <Divider />

          <Header
            code={props.match.params.id}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            readOnly={isReadOnly}
          />
          <Additem
            code={props.match.params.id}
            register={register}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            control={control}
            readOnly={isReadOnly}
          />
        </form>
      </Slide>
    </>
  );
};

export default translate(AssetReturnForm);
