export { schema as assetSchema } from './asset.schema';
export { schema as assetBrandSchema } from './assetBrand.schema';
export { schema as assetClassSchema } from './assetClass.schema';
export { schema as assetConditionSchema } from './assetCondition.schema';
export { schema as assetGroupSchema } from './assetGroup.schema';
export { schema as assetModelSchema } from './assetModel.schema';
export { schema as assetSubClassSchema } from './assetSubClass.schema';
export { schema as assetSupplierSchema } from './assetSupplier.schema';
export { schema as businessAreaSchema } from './businessArea.schema';
export { schema as siteSchema } from './site.schema';
export { schema as locationSchema } from './location.schema';
export { schema as zoneSchema } from './zone.schema';
export { schema as roleSchema } from './role.schema';
export { schema as permissionSchema } from './permission.schema';
export { schema as userSchema } from './user.schema';
export { schema as costCenterSchema } from './costCenter.schema';
export { schema as customerSchema } from './customer.schema';
export { schema as smtpSchema } from './smtp.schema';
export { schema as assetAccountSchema } from './assetAccount.schema';
export { schema as assetAccountTypeSchema } from './assetAccountType.schema';
export { schema as reasonSchema } from './reason.schema';
