import React, { useState, useEffect } from 'react';
import { AssetForm, } from '../AssetForm';

import { assetAction } from '../../../_action/asset.action';
import { useSelector, useDispatch } from 'react-redux';

const AssetDescription = (props) => {
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const assetOp = useSelector((state) => state.assetOperation);
  const dispatch = useDispatch();
  const Code = props.match.params.item; // get code from url parameters
  const [assetItem, setAssetItem] = useState({});
  useEffect(() => {
    dispatch(assetAction.getAsset(Code));
  }, []); // component did mount
  useEffect(() => {
    if (assetOp.getSuccess === true) {
      setAssetItem(assetOp.data.data.info);
      setLoading(false);
      setFailure(false);
    }
    if (assetOp.getRequest === true) {
      setLoading(true);
    }
    if (assetOp.getFailure === true) {
      setFailure(true);
    }
  }, [assetOp.getSuccess, assetOp.getRequest, assetOp.getFailure]);
  const header = {
    icon: 'paragraph',
    content: 'Asset Description',
    subHeader: 'Current asset information',
  };
  return (
    <>

      <AssetForm
        readOnly={true}
        header={header}
        loading={assetOp.getRequest}
        error={failure}
        assetItem={assetItem}
      />

    </>
  );
};

export default AssetDescription;
