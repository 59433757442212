import { alertConstants as constants } from '../_constants';

export const alertActions = {
  success,
  error,
  clear,
};
function success(message) {
  return { type: constants.SUCCESS, message };
}

function error(message) {
  return { type: constants.ERROR, message };
}

function clear(message) {
  return { type: constants.CLEAR, message };
}
