

export const commonLabel = {
    SUBMIT: 'submit',
    YUP_REQUIRED_THIS_FIELD: 'requireThisField'

}
export const commonClass = {
    UPDATE_ICON_CLASS: 'fas fa-edit',
    INSERT_ICON_CLASS: 'fas fa-plus'
}
export const commonAlert = {
    ALERT_UPDATE_SUCCESS: 'updateSuccess',
    ALERT_UPDATE_FAILURE: 'updateFailure',
    ALERT_INSERT_SUCCESS: 'insertSuccess',
    ALERT_INSERT_FAILURE: 'insertFailure',
    ALERT_INVALID_INPUT: 'invalidInput',
    ALERT_DELETE_SUCCESS: 'deleteSuccess',
    ALERT_DELETE_FAILURE: 'deleteFailure',
    ALERT_GET_FAILURE: 'getFailure',
}