export const assetConditionConstants = {
  API_INSERT: '/api/condition/insert',
  API_UPDATE: '/api/condition/update',
  API_DELETE: '/api/condition/delete',
  API_GET: '/api/condition/',
  API_GETALL: '/api/condition/getall',

  INSERT_REQUEST: 'ASSET_CONDITION_INSERT_REQUEST',
  INSERT_SUCCESS: 'ASSET_CONDITION_INSERT_SUCCESS',
  INSERT_FAILURE: 'ASSET_CONDITION_INSERT_FAILURE',

  UPDATE_REQUEST: 'ASSET_CONDITION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ASSET_CONDITION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ASSET_CONDITION_UPDATE_FAILURE',

  DELETE_REQUEST: 'ASSET_CONDITION_DELETE_REQUEST',
  DELETE_SUCCESS: 'ASSET_CONDITION_DELETE_SUCCESS',
  DELETE_FAILURE: 'ASSET_CONDITION_DELETE_FAILURE',

  GET_REQUEST: 'ASSET_CONDITION_GET_REQUEST',
  GET_SUCCESS: 'ASSET_CONDITION_GET_SUCCESS',
  GET_FAILURE: 'ASSET_CONDITION_GET_FAILURE',

  GETALL_REQUEST: 'ASSET_CONDITION_GETALL_REQUEST',
  GETALL_SUCCESS: 'ASSET_CONDITION_GETALL_SUCCESS',
  GETALL_FAILURE: 'ASSET_CONDITION_GETALL_FAILURE',

  RESET: 'ASSET_CONDITION_RESET',
  SERVICE: 'ASSET_CONDITION_SERVICE',
};
