
export const model = {
    // asset information
    code: '',
    name: '',
    barcode: '',
    referenceCode: '',
    part: '',
    description: '',
    shortDescription: '',
    parent: '',
    color: '',
    serialized: false,
    erp: false,
    assetUnit: '',

    // classificaiton
    assetBrand: '',
    assetClass: '',
    assetSubClass: '',
    assetGroup: '',
    // assetModel: '',

    // manufacuring
    assetSupplier: '',

    // dimension
    height: '',
    width: '',
    depth: '',
    weight: '',
    dimensionUomId: '',
    weightUomId: '',

    // owner
    owner: '',
    company: '',
    branch: '',
    department: '',

    // cost center
    costCenter: '',

    // Warranty
    warrantyStartDate: '',
    warrantyEndDate: '',

    // location
    businessArea: '',
    site: '',
    location: '',
    zone: '',
};