import React, { useState, useEffect } from 'react';
import {
  Menu,
  Segment,
  Sidebar,
  Header,
  Image,
  Grid,
  Flag,
  Dropdown,
  Button as SemanticButton,
  Icon,
  Message,
} from 'semantic-ui-react';
import { setLanguage, translate, getLanguage } from 'react-switch-lang';

import { Link, Redirect, Route } from 'react-router-dom';
import * as ROUTES from '../../routes';
import StdSubtitle from '../../_fragments/Title/StdSubtitle';
///////////////////////////////
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarBorder from '@material-ui/icons/StarBorder';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Container from '@material-ui/core/Container';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import Icon from '@material-ui/core/Icon';

import moment from 'moment-timezone';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    //backgroundColor: theme.palette.background.paper,
    // color: theme.palette.text.secondary,
  },
  header: {
    // paddingLeft: theme.spacing(4),
    // backgroundColor: "#263238",
    background: 'linear-gradient(45deg, #b2dfdb 30%, #1976d2 90%)',
    color: 'black',
  },
  nested: {
    paddingLeft: theme.spacing(4),
    // backgroundColor: "#e1f5fe",
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      color: 'black',
      background: 'linear-gradient(45deg, #e1f5fe 30%, #fafafa 90%)',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: 'black',
    paddingLeft: theme.spacing(0),
  },
  menuNested: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: 'black',
    paddingLeft: theme.spacing(2),
    '&:hover': {
      color: 'black',
    },
  },

  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  headingExpansionText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: 'white',
  },
  headingExpansion: {
    // backgroundColor: '#e0f2f1',
  },
  signOut: {},
}));
const SideBar = ({ t, onHide, visible, user }) => {
  const classes = useStyles();
  const [time, setTime] = useState(moment().format('MMMM Do YYYY, h:mm:ss a'));

  // const [open, setOpen] = React.useState(true);
  useEffect(() => {
    setInterval(() => {
      setTime(moment().format('MMMM Do YYYY, h:mm a'));
    }, 1000);
  }, [time]);

  return (
    <>
      <Sidebar
        as={Segment}
        animation="overlay"
        direction="right"
        icon="labeled"
        onHide={onHide}
        visible={visible}
        width="wide"
      >
        <ExpansionPanel>
          <ExpansionPanelSummary
            className={classes.headingExpansion}
            expandIcon={<ExpandMoreIcon />}
          >
            {/* <AccountCircleIcon fontSize="large" />
            <Typography variant="h5"> User Account</Typography>
             */}
            <Header as='h4'>
              <Icon name='user' />
              <Header.Content>
                User Account
              </Header.Content>
            </Header>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              {user && user.userinfo.firstname + ' ' + user.userinfo.lastname}
            </Typography>
          </ExpansionPanelDetails>
          <ExpansionPanelDetails>
            <ListItem component={Link} to={'/login'}>
              <SemanticButton icon="user x" fluid content={t('signOut')} />
            </ListItem>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <List>
          <ListItem>
            <Dropdown
              button
              fluid
              icon="world"
              value={getLanguage()}
              className="icon"
              labeled
              onChange={(_, data) => {
                setLanguage(data.value);
              }}
              selection
              options={[
                {
                  key: 'en',
                  text: (
                    <span>
                      <Flag name="us" />
                      English
                    </span>
                  ),
                  value: 'en',
                },
                {
                  key: 'th',
                  text: (
                    <span>
                      <Flag name="th" />
                      Thai
                    </span>
                  ),
                  value: 'th',
                },
              ]}
            ></Dropdown>
          </ListItem>

          <ListItem>
            <Message
              icon="clock"
              header={time}
              // content={time}
              size="small"
            />
          </ListItem>
        </List>

        <List className={classes.root}>
          <ListItem button className={classes.header}>
            <ListItemIcon>
              <Icon name="user" />
            </ListItemIcon>
            <ListItemText>
              <Typography className={classes.heading}>User Master</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.USER_ROOT_ROUTE}
          >
            <Typography>User</Typography>
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.ROLE_ROOT_ROUTE}
          >
            User Role
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.PERMISSION_ROOT_ROUTE}
          >
            User Permission
          </ListItem>
        </List>

        <List className={classes.root}>

          <ListItem button className={classes.header}>
            <ListItemIcon>
              <Icon name="warehouse" />
            </ListItemIcon>
            <ListItemText>
              <Typography className={classes.heading}>Asset Master</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.ASSET_ROOT_ROUTE}
          >
            Asset
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.ASSET_BRAND_ROOT_ROUTE}
          >
            Brand
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.ASSET_CLASS_ROOT_ROUTE}
          >
            Class
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.ASSET_SUBCLASS_ROOT_ROUTE}
          >
            Subclass
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.ASSET_CONDITION_ROOT_ROUTE}
          >
            Condition
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.ASSET_GROUP_ROOT_ROUTE}
          >
            Group
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.ASSET_MODEL_ROOT_ROUTE}
          >
            Model
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.ASSET_SUPPLIER_ROOT_ROUTE}
          >
            Supplier
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.BUSINESS_AREA_ROOT_ROUTE}
          >
            Business Area
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.SITE_ROOT_ROUTE}
          >
            Site
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.LOCATION_ROOT_ROUTE}
          >
            Location
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.ZONE_ROOT_ROUTE}
          >
            Zone
          </ListItem>
        </List>

        <List className={classes.root}>
          <ListItem button className={classes.header}>
            <ListItemIcon>
              <Icon name="exchange" />
            </ListItemIcon>
            <ListItemText>
              <Typography className={classes.heading}>Transaction</Typography>
            </ListItemText>
          </ListItem>
          {/* <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.INVENTORY_STOCK_RECEIPT}
          >
            Receipt
          </ListItem> */}
          {/* <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.INVENTORY_STOCK_TRANSFER}
          >
            Transfer
          </ListItem> */}
          {/* <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.INVENTORY_REQUESITION}
          >
            Requisition
          </ListItem> */}
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.INVENTORY_BORROW}
          >
            Borrow
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.INVENTORY_RETURN}
          >
            Return
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.INVENTORY_STOCK_TRANSFER}
          >
            Ownership Transfer
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.INVENTORY_WRITEOFF}
          >
            WriteOff
          </ListItem>
        </List>

        <List className={classes.root}>
          <ListItem button className={classes.header}>
            <ListItemIcon>
              <Icon name="location arrow" />
            </ListItemIcon>
            <ListItemText>
              <Typography className={classes.heading}>Report</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={ROUTES.TEST_REPORT}
          >
            Report
          </ListItem>
        </List>
      </Sidebar>
    </>
  );
};

export default translate(SideBar);
