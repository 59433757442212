/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  Button,
  ListItemIcon,
  ListItemText,
  Collapse,
  Icon,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import SettingsIcon from '@material-ui/icons/Settings';
import StorageIcon from '@material-ui/icons/Storage';
import PieChartIcon from '@material-ui/icons/PieChart';
import ReceiptIcon from '@material-ui/icons/Receipt';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const MenuIcon = React.memo(({ menu }) => {
  var iconObject = <Icon className={menu.icon} />;
  const color = 'primary';
  const size = 'default';
  switch (menu.title) {
    case 'Asset Master':
      return <StorageIcon color={color} fontSize={size} />;
    case 'Reports':
      return <PieChartIcon color={color} fontSize={size} />;
    case 'Setting':
      return <SettingsIcon color={color} fontSize={size} />;
    case 'Transaction':
      return <ReceiptIcon color={color} fontSize={size} />;
    case 'Users':
      return <VerifiedUserIcon color={color} fontSize={size} />;
    default:
      return iconObject;
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.default,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.primary.main,
  },
  button: {
    padding: '5px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));
const SidebarNav = (props) => {
  const classes = useStyles(props);
  const { pages, className, ...rest } = props;

  const [open, setOpen] = useState(true);

  const handleClick = useCallback((param) => {
    setOpen((prevState) => {
      return { ...prevState, [param]: !prevState[param] };
    });
  }, []);
  return (
    <List
      key={0}
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={clsx(classes.root, className)}
      {...rest}
    >
      {pages.map((page) => (
        <div key={page.id}>
          <ListItem
            key={page.id}
            button
            onClick={() => {
              handleClick(page.id);
            }}
          >
            <ListItemIcon className={classes.icon}>
              <MenuIcon menu={page} />
            </ListItemIcon>
            <ListItemText
              className={classes.item}
              key={page.id}
              primary={page.title}
            />
            {!open[page.id] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={open[page.id]} timeout="auto" unmountOnExit>
            <List
              className={clsx(classes.root, className)}
              {...rest}
              component="div"
              disablePadding
            >
              {page.submenus
                .filter((sub) => sub.view_permission === true)
                .map((menu) => (
                  <div key={menu.menuCode}>
                    <ListItem
                      className={classes.nested}
                      disableGutters
                      key={menu.menuCode}
                    >
                      <Button
                        activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={menu.url}
                        startIcon={
                          // <div className={classes.icon}>
                          <Icon className={menu.icon} />
                          // </div>
                        }
                      >
                        {menu.name}
                      </Button>
                    </ListItem>
                  </div>
                ))}
            </List>
          </Collapse>
        </div>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
