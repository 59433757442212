import React, { useEffect, useState } from 'react';
import { translate } from 'react-switch-lang';
import Select from 'react-select';
import moment from 'moment';
import { Header, Icon } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import {
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Grid as MUIGrid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReturnService as service } from '../../../../../_services/transaction/asset.return.service';
import SearchIcon from '@material-ui/icons/Search';
import AsyncSelect from 'react-select/async';
import BorrowDatatable from '../../../_InventoryFlagments/BorrowDatatable';
import { useSelector, useDispatch } from 'react-redux';
// import { addItem, DeleteItem } from './redux/actions/return.additem.action';
// import { isMoment } from 'moment';
import { getCurrentOwner } from '../../../assetcheck/redux/actions/assetcheck.action';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  griditem: {
    padding: theme.spacing(1),
  },
  bg: {
    //background: 'linear-gradient(180deg, white 30%, grey 70%)',
    // background: '#bbdefb',
  },
  control: {
    paddingRight: theme.spacing(0.5),
  },
}));

const ReturnHeader = (props) => {
  const { t, setValue } = props;
  const classes = useStyles();
  const [valueDate] = useState('');
  const [optionReason, setoptionReason] = useState([]);
  const [options] = useState([]);
  const initialOwnerOption = [
    {
      value: 1,
      label: 'Company',
    },
    {
      value: 2,
      label: 'Branch',
    },
    {
      value: 3,
      label: 'Department',
    },
    {
      value: 4,
      label: 'Customer',
    },
    {
      value: 5,
      label: 'Employee',
    },
  ];
  let loading = false;
  //useStates
  const [ownerTypeOption] = useState(initialOwnerOption);
  const [onwerDescsOption, setOnwersDescOption] = useState([]);
  const [isDisable, setisDisable] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  // const [assetBorrowCode, setassetBorrowCode] = useState('');
  const [openBorrowCode, setopenBorrowCode] = useState(false);
  const [data, setdata] = useState([]);
  const [values, setValues] = useState({
    code: '',
    dateValue: '',
    borrowCode: '',
    OwnerType: '',
    borrowBy: '',
    Reason: '',
    remark: '',
    borrowDate: '',
    returnDate: '',
    status: 'OPEN',
  });

  /// Redux ///
  const dispatch = useDispatch();
  const storage = useSelector((state) => state.ownerSearch);
  const returnStore = useSelector((state) => state.assetTable);
  /// --- Redux  ----///

  //handles
  const handleClickBorrowOpen = () => {
    setopenBorrowCode(true);
  };
  const handleClickBorrowClose = () => {
    setopenBorrowCode(false);
  };

  const handleSelectChange = (event) => {
    if (event[0] != null) {
      setValues({
        ...values,
        [event[1].name]: { value: event[0].value, label: event[0].label },
      });
    } else {
      setValues({
        ...values,
        [event[1].name]: '',
      });
    }
  };

  //** UseEffects */
  useEffect(() => {
    if (storage.closing) {
      console.log(storage);
      props.setValue('assetBorrowCode', storage.code);
      //props.setValue('OwnerType', storage.ownerType);
      props.setValue('OwnerType', {
        value: storage.ownerType,
        label: initialOwnerOption.find((i) => i.value == storage.ownerType)
          .label,
      });

      setValues({
        ...values,
        borrowDate: storage.borrowDate,
        returnDate: storage.returnDate,

        remark: storage.remark,
      });

      // console.log(storage);
      service
        .autoComplete({
          req_type: storage.ownerType,
          req_search: storage.borrowBy,
          recordTotal: '1',
        })
        .then((option) => {
          props.setValue('borrowBy', option);
        });

      ///add detail

      // dispatch(addItem(storage.assetBorrowDetail));
      ///add detail

      setopenBorrowCode(false);
      setisDisable(true);
    }
  }, [storage]);

  useEffect(() => {
    service.getReason().then((res) => {
      // console.log(res.data.results);
      res.data.results
        .filter((option) => option.reasonType == 'RETURN')
        .map((item) => {
          setoptionReason((option) => [
            ...option,
            {
              value: item.value,
              label: item.label,
            },
          ]);
        });
    });
  }, []);

  useEffect(() => {
    console.log('returnStore ', returnStore);
    if (returnStore.is_readyonly) setIsReadOnly(true);
  }, [returnStore]);

  useEffect(() => {
    dispatch(
      getCurrentOwner({ owner: values.borrowBy, ownertype: values.OwnerType }),
    );
  }, [values['borrowBy']]);

  // +++++++++++++++++++ EDIT PART GET DATE ++++++++++++++++++++++++

  //*** Get Update action
  useEffect(() => {
    if (!!props.code) {
      service
        .getItem(props.code)
        .then((res) => {
          //  console.log('res;', res);
          setValues({
            code: res.data.result.code,
            date: moment(res.data.result.date).format('YYYY-MM-DD'),
            borrowCode: res.data.result.assetBorrowCode,
            OwnerType: res.data.result.ownerType,
            borrowby: res.data.result.returnBy,
            Reason: res.data.result.reason,
            remark: res.data.result.remark,
            borrowDate: moment(res.data.result.borrowDate).format('YYYY-MM-DD'),
            returnDate: moment(res.data.result.returnDate).format('YYYY-MM-DD'),
            status: res.data.result.status,
          });
          if (!!res.data.result.reason)
            props.setValue('Reason', {
              value: res.data.result.reason,
              label: res.data.result.reason,
            });

          props.setValue('OwnerType', {
            value: res.data.result.ownerType,
            label: initialOwnerOption.find(
              (i) => i.value == res.data.result.ownerType,
            ).label,
          });
          props.setValue('assetBorrowCode', res.data.result.assetBorrowCode);
          setdata(res.data.result);

          //  if (res.status === 200 && !!res.data) {
          service
            .autoComplete({
              req_type: res.data.result.ownerType,
              req_search: res.data.result.returnBy,
              recordTotal: '30',
            })
            .then((option) => {
              props.setValue('borrowBy', option);
            });
          // }
        })
        .catch((error) => {
          window.alert('error', error);
        });
    }
  }, []);

  useEffect(() => {
    ownersQuery(values.OwnerType.value, '').then((opts) => {
      setOnwersDescOption(opts);
    });
  }, [values['OwnerType']]);

  const ownersQuery = async (ownerType, inputValue) => {
    try {
      const res = await service.autoComplete({
        req_type: ownerType,
        req_search: inputValue,
        recordTotal: '30',
      });

      const resData = Object.keys(res).map(
        (key) =>
          new Object({
            value: res[key].value,
            label: res[key].label,
          }),
      );

      return resData;
    } catch (error) {
      console.log('error ownerQuery:', error);
    }
  };

  const handleChange = (event) => {
    //console.log(event.target.value);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <>
        <MUIGrid container className={classes.root}>
          <MUIGrid item className={classes.griditem} xs={12} md={6}>
            <Header className={classes.bg} dividing>
              <Typography variant="h6" color="textPrimary">
                <Icon name="folder" />
                {t('Description')}
              </Typography>
            </Header>
            <>
              <MUIGrid container>
                <MUIGrid item md={6} xs={12} className={classes.control}>
                  <FormControl
                    margin="dense"
                    fullWidth
                    required
                    error={!!props.errors.code}
                    className={classes.input}
                  >
                    <FormLabel component="legend">{t('Code')}</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="code"
                      value={[values.code]}
                      inputRef={props.register()}
                      onChange={handleChange}
                      disabled
                    />
                    <FormHelperText>
                      {props.errors.code?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </MUIGrid>
                <MUIGrid item md={6} xs={12} className={classes.control}>
                  <FormControl fullWidth margin="dense" required size="small">
                    <FormLabel component="legend">
                      {t('Borrow Document Reference')}
                    </FormLabel>
                    <Controller
                      name="assetBorrowCode"
                      control={props.control}
                      defaultValue={values.borrowCode}
                      placeholder="Search borrow code"
                      onChange={handleChange}
                      rules={{ required: 'Borrow Document is Required' }}
                      readOnly
                      as={
                        <OutlinedInput
                          type="text"
                          disabled={props.readOnly}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                disabled={props.readOnly}
                                aria-label="search"
                                size="small"
                                onClick={(e) => {
                                  handleClickBorrowOpen();
                                }}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={0}
                        />
                      }
                    />
                  </FormControl>
                  <FormHelperText error>
                    {!!props.errors.assetBorrowCode && (
                      <>{props.errors.assetBorrowCode.message}</>
                    )}
                  </FormHelperText>
                </MUIGrid>
                <MUIGrid item md={6} xs={12} className={classes.control}>
                  <FormControl
                    margin="dense"
                    fullWidth
                    required
                    // error={!!props.errors.dateValue}
                  >
                    <FormLabel component="legend">{t('Date')}</FormLabel>
                    <TextField
                      size="small"
                      type="date"
                      variant="outlined"
                      name="dateValue"
                      value={[moment(values.dateValue).format('YYYY-MM-DD')]}
                      inputRef={props.register({
                        required: 'Date is required',
                      })}
                      onChange={handleChange}
                      disabled={props.readOnly}
                    />
                    <FormHelperText error>
                      {!!props.errors.dateValue && (
                        <>{props.errors.dateValue.message}</>
                      )}
                    </FormHelperText>
                  </FormControl>
                </MUIGrid>
                <MUIGrid item md={6} xs={12} className={classes.control}>
                  <FormControl
                    margin="dense"
                    fullWidth
                    size="small"
                    required={true}
                    // error={props.errors.Reason}
                    className={classes.input}
                  >
                    <FormLabel component="legend">{t('Reason')}</FormLabel>
                    <Controller
                      {...props}
                      as={<Select />}
                      options={optionReason}
                      name="Reason"
                      isClearable
                      control={props.control}
                      rules={{ required: 'Reason is Required' }}
                      isDisabled={props.readOnly}
                    />
                    <FormHelperText error>
                      {!!props.errors.Reason && (
                        <>{props.errors.Reason.message}</>
                      )}
                    </FormHelperText>
                  </FormControl>
                </MUIGrid>
                <MUIGrid item md={6} xs={12} className={classes.control}>
                  <FormControl
                    className={classes.input}
                    margin="dense"
                    fullWidth
                    required
                    // error={!!props.errors.dateValue}
                  >
                    <FormLabel component="legend">{t('status')}</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="status"
                      value={[values.status]}
                      inputRef={props.register()}
                      onChange={handleChange}
                      disabled
                    />
                    <FormHelperText>
                      {props.errors.status?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </MUIGrid>
                <MUIGrid item md={6} xs={12} className={classes.control}>
                  <FormControl
                    margin="dense"
                    fullWidth
                    error={!!props.errors.remark}
                  >
                    <FormLabel component="legend">{t('Remark')}</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="remark"
                      value={[values.remark]}
                      inputRef={props.register()}
                      onChange={handleChange}
                      disabled={props.readOnly}
                    />
                    <FormHelperText>
                      {props.errors.remark?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </MUIGrid>
              </MUIGrid>
            </>
          </MUIGrid>
          <MUIGrid item className={classes.griditem} xs={12} md={6}>
            <>
              <Header dividing>
                <Typography variant="h6" color="textPrimary">
                  <Icon name="user" />
                  {t('Return By')}
                </Typography>
              </Header>
              <MUIGrid container>
                <MUIGrid item md={4} xs={12} className={classes.control}>
                  <FormControl
                    className={classes.input}
                    fullWidth
                    margin="dense"
                    size="small"
                    required={true}
                  >
                    <FormLabel component="legend">{t('Return Type')}</FormLabel>
                    <Controller
                      name="OwnerType"
                      as={<Select />}
                      options={ownerTypeOption}
                      defaultValue={values.OwnerType}
                      isClearable
                      control={props.control}
                      onChange={(value) => {
                        props.setValue('borrowBy', '');
                        handleSelectChange(value);
                        return value[0];
                      }}
                      rules={{ required: 'Return Type is Required' }}
                      isDisabled={isReadOnly}
                    />
                    <FormHelperText error>
                      {!!props.errors.OwnerType && (
                        <>{props.errors.OwnerType.message}</>
                      )}
                    </FormHelperText>
                  </FormControl>
                </MUIGrid>
                <MUIGrid item md={8} xs={12} className={classes.control}>
                  <FormControl margin="dense" fullWidth size="small" required>
                    <FormLabel component="legend">{t('Return name')}</FormLabel>
                    <Controller
                      as={<AsyncSelect />}
                      options={onwerDescsOption ? onwerDescsOption : []}
                      name="borrowBy"
                      isClearable
                      isDisabled={isReadOnly}
                      control={props.control}
                      rules={{ required: 'Return name Required' }}
                      defaultOptions={onwerDescsOption}
                      loadOptions={(value) => {
                        if (!values.OwnerType) {
                          window.alert(
                            'please select destination Return Type first',
                          );
                          return null;
                        }
                        return ownersQuery(values.OwnerType.value, value);
                      }}
                      placeholder={
                        values.OwnerType.label
                          ? 'Search by ' + values.OwnerType.label
                          : 'Select...'
                      }
                      onChange={(value) => {
                        //console.log(value);
                        handleSelectChange(value);
                        return value[0];
                      }}
                    />

                    <FormHelperText error>
                      {!!props.errors.borrowBy && (
                        <>{props.errors.borrowBy.message}</>
                      )}
                    </FormHelperText>
                  </FormControl>
                </MUIGrid>
                <MUIGrid item md={6} xs={12} className={classes.control}>
                  <FormControl
                    margin="dense"
                    fullWidth
                    required
                    // error={!!props.errors.borrowDate}
                  >
                    <FormLabel component="legend">{t('borrowDate')}</FormLabel>
                    <TextField
                      size="small"
                      type="date"
                      variant="outlined"
                      name="borrowDate"
                      value={[moment(values.borrowDate).format('YYYY-MM-DD')]}
                      inputRef={props.register({ required: true })}
                      onChange={handleChange}
                      disabled={props.readOnly}
                    />
                    <FormHelperText error>
                      {props.errors.borrowDate?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </MUIGrid>
                <MUIGrid item md={6} xs={12} className={classes.control}>
                  <FormControl
                    margin="dense"
                    fullWidth
                    required
                    // error={!!props.errors.returnDate}
                  >
                    <FormLabel component="legend">{t('returnDate')}</FormLabel>
                    <TextField
                      size="small"
                      type="date"
                      variant="outlined"
                      name="returnDate"
                      value={[moment(values.returnDate).format('YYYY-MM-DD')]}
                      inputRef={props.register({ required: true })}
                      onChange={handleChange}
                      disabled={props.readOnly}
                    />
                    <FormHelperText error>
                      {props.errors.returnDate?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </MUIGrid>
              </MUIGrid>
            </>
          </MUIGrid>
        </MUIGrid>
      </>

      <Dialog
        open={openBorrowCode}
        fullWidth
        maxWidth={'lg'}
        onClose={handleClickBorrowClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Please select Borrow Document number'}
        </DialogTitle>
        <DialogContent>
          <div>
            <BorrowDatatable />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClickBorrowClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default translate(ReturnHeader);
