import React from 'react';
import { translate } from 'react-switch-lang';
import ListingDatatable from '../../../_fragments/ListingDatatable';
import { TransactionDatatable } from '../../../_fragments/transactiondatatable';
import Select from 'react-select';
import { StdTitle } from '../../../_fragments';

const StockTransfer = (props) => {
  const { t } = props;
  const header = {
    icon: 'cubes',
    content: 'Asset Transfer',
    subHeader: 'Listing',
  };

  const insertBtn = {
    text: t('Add'),
    link: props.location.pathname + '/insert',
  };

  const actionPath = {
    add: `${props.location.pathname}/insert`,
    edit: `${props.location.pathname}/update`,
    delete: `${props.location.pathname}/delete`,
  };

  const headers = [
    { title: 'Code', field: 'Code' },
    { title: 'Date', field: 'Date' },
    { title: 'Source', field: 'SourceZoneDesc' },
    { title: 'Destination', field: 'DestinationZoneDesc' },
    { title: 'Status', field: 'TransferStatusDesc' },
    { title: 'Create By', field: 'CreateBy' },
    { title: 'Create Date', field: 'CreateDate', type: 'date' },
  ];

  const title = (
    <StdTitle
      icon={header.icon}
      content={header.content}
      subHeader={header.subHeader}
    />
  );
  const filenameXML = 'asset-transfer';
  return (
    <>
      <TransactionDatatable
        header={header}
        insertBtn={insertBtn}
        title={title}
        headers={headers}
        filenameXML={filenameXML}
        root={props.root}
        history={props.history}
        path={actionPath}
      />
    </>
  );
};

export default translate(StockTransfer);
