import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';
import { Controller } from 'react-hook-form';

import { ImageCard } from './ImageCard';
import {
  Header,
  Segment,
  Message,
  Icon,
  Button,
  Grid,
} from 'semantic-ui-react';
import { assetService as service } from '../../../../_services/asset.service';
import Alert from '../../../alert';

const sumArr = (arr) => {
  var total = 0;
  arr.map((el) => {
    total += el;
  });
  return total;
};

const ImageManager = (props) => {
  const { t, readOnly, code } = props;
  const [currentFiles, setFiles] = useState([]);
  const [fileExceeded, setFileExceeded] = useState(false);
  const maxFiles = 6;
  const [positions, setPositions] = useState([0, 0, 0, 0, 0, 0]); // tracking position
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');
  const getImages = async () => {
    if (!!code) {
      let assetCode = code;
      await service
        .getImage({ assetCode })
        .then((res) => {
          if (!!!res.data.results) return;
          var imgs = res.data.results.sort((a, b) =>
            a.position > b.position ? 1 : -1,
          );
          var all_position = [0, 0, 0, 0, 0, 0];
          imgs.map((el) => {
            all_position[el.position] = 1;
          });
          setPositions(all_position);
          setImages(imgs);
        })
        .catch((err) => {
          console.log('get image eerr: ', err);
          return err;
        });
    }
  };

  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled && !!code) getImages(isCancelled);
    return () => {
      isCancelled = true;
    };
  }, [code]);

  const onChange = async (e) => {
    var files = e.target.files;
    console.log('files: ', files);
    if (files.length + sumArr(positions) > maxFiles) {
      setFileExceeded(true);
      return;
    } else {
      for (let i = 0; i < files.length; i++) {
        var p = -1;
        for (let j = 0; j < positions.length; ++j) {
          if (!positions[j]) {
            p = j;
            break;
          }
        }
        service.updateImage(p + i, code, files[i]).then((res) => {
          getImages();
          console.log('res: ', res);
          if (res.data.status != 200) {
            setError(res.data.error);
          } else setError('');
        });
        // getImages();
      }
    }
  };
  const onDelete = (position) => {
    service.deleteImage(position, code).then((res) => {
      getImages();
      console.log('res: ', res);
      if (res.data.status != 200) {
        setError(res.data.error);
      } else setError('');
    });
  };
  const fileRef = React.createRef();
  return (
    <>
      <div>
        {fileExceeded && (
          <Message negative>
            <Message.Header>Maximum upload file exceeds!!</Message.Header>
          </Message>
        )}
        {/* <Button
          content="Choose File 2"
          labelPosition="left"
          icon="folder open"
          color="blue"
          onClick={(e) => {
            e.preventDefault();
            fileRef2.current.click();
          }}
          disabled={readOnly}
        /> */}
        {/* <input
          ref={fileRef2}
          type="file"
          hidden
          onChange={onChange}
          accept="image/*"
        /> */}

        <Button
          content="Choose File"
          labelPosition="left"
          icon="folder open"
          color="blue"
          onClick={(e) => {
            e.preventDefault();
            fileRef.current.click();
          }}
          disabled={readOnly}
        />

        <input
          ref={fileRef}
          type="file"
          hidden
          onChange={onChange}
          // multiple
          accept="image/*"
        />
        <Header as="h2" attached="top">
          <Icon name="images" />
          <Header.Content>
            Preview Images
            <Header.Subheader>Maximum upload limit: 6</Header.Subheader>
          </Header.Content>
        </Header>

        <Segment attached="bottom" placeholder>
          <Grid columns={6} centered>
            {!!error && <Alert severity="error">{error}</Alert>}

            {!!images &&
              images.length >= 0 &&
              images.map((item, index) => {
                return (
                  <Grid.Column
                    key={index}
                    computer={16}
                    tablet={16}
                    mobile={16}
                    key={currentFiles.length}
                  >
                    <ImageCard
                      src={item.resizeImagePath}
                      index={item.position}
                      name={'position: ' + item.position}
                      deleteImage={onDelete}
                      readOnly={readOnly}
                    />
                  </Grid.Column>
                );
              })}
          </Grid>
        </Segment>
      </div>
    </>
  );
};

export default translate(ImageManager);
