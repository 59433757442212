import React from 'react';
import {AssetForm} from '../AssetForm';
const AssetRegister = () => {
  console.log('AssetRegister loaded');
  const header = {
    icon:'write',
    content:'Asset Register',
    subHeader:'Register new asset'
  }
  return (<>
    
    
    <AssetForm readOnly={false} header={header}/>
  </>);
};

export default AssetRegister;
