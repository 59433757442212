import React, { useState, useEffect } from 'react';
// import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable, { MTableToolbar } from 'material-table';
import { jQGridService, roleService, assetService } from '../../_services';
import { tableIcons } from './tableIcons';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import { PresetColorTypes } from 'antd/lib/_util/colors';
import { useAlert, positions } from 'react-alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';
import palettle from '../../theme/palette';
import { CloudUpload } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ImportAlertModal from '../_modal/ImportAlertModal/ImportAlertModal';
// import { history } from '../../_helpers/history';

const useStyles = makeStyles((theme) => ({
  root: {},
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(3),
  },
}));
const tableRef = React.createRef();

const TransactionDatatable = (props) => {
  // const classes = useStyles();
  const { headers } = props;
  const initAlert = {
    open: false,
    type: 'success',
    message: 'Init Massage',
  };
  const alert = useAlert();
  const menuCode = props.menuCode;
  const user = JSON.parse(localStorage.getItem('user'));
  const [actions, setActions] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState(initAlert);
  const [errorMessage, setErrorMessage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);
  const role = user.roleinfo; // {code, description}

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification({ open: false });
  };

  var addAction = {
    icon: () => <AddIcon />,
    tooltip: 'Add new',
    isFreeAction: true,
    onClick: (e) => {
      e.preventDefault();
      props.history.push({
        pathname: `${props.path.add}`,
        state: { isReadOnly: false, action: 'create' },
      });
    },
  };
  var viewAction = {
    icon: () => <VisibilityIcon />,
    tooltip: 'View',
    onClick: (e, rowData) => {
      e.preventDefault();
      props.history.push({
        pathname: `${props.path.edit}/${
          rowData.Code || rowData.Asset_Check_Id
        }`,
        state: {
          isReadOnly: true,
          action: 'view',
          status: rowData.Status,
        },
      });
    },
  };
  var editAction = {
    icon: () => <EditIcon />,
    tooltip: 'edit',
    onClick: (e, rowData) => {
      e.preventDefault();
      props.history.push({
        pathname: `${props.path.edit}/${
          rowData.Code || rowData.Asset_Check_Id
        }`,
        state: {
          isReadOnly: false,
          action: 'edit',
          status: rowData.Status,
        },
      });
    },
  };
  var deleteAction = {
    icon: () => <DeleteIcon />,
    tooltip: 'Delete',
    onClick: (e, rowData) => {
      e.preventDefault();
      if (props.disableOnApprove && rowData.Status === 'APPROVE') {
        alert.error('Action not allowed on APPROVED item');
        return;
      }
      const confirm = window.confirm(
        `you want to delete this document no. ${rowData.Code} ?`,
      );

      if (confirm) {
        props.service
          .Delete(rowData.Code)
          .then((res) => {
            if (res === 'OK') {
              const options = {
                position: positions.BOTTOM_RIGHT,
                timeout: 2000,
              };
              alert.success(JSON.stringify(`Deleted Successfull`), options);
              tableRef.current && tableRef.current.onQueryChange();
            } else {
              //console.log(res);
              const options = {
                position: positions.BOTTOM_RIGHT,
                timeout: 2000,
              };
              alert.error(JSON.stringify(`Delete Not Successfull`), options);
              tableRef.current && tableRef.current.onQueryChange();
            }
          })
          .catch((err) => {
            const options = {
              position: positions.BOTTOM_RIGHT,
              type: 'error',
              timeout: 4000,
            };
            //alert.show(JSON.stringify(err), options);
          });
      }
    },
  };

  useEffect(() => {
    let isCancelled = false;
    roleService.getRolePermissions(role.code).then((res) => {
      if (!isCancelled) {
        // console.log('menu_code: ', menuCode);
        // console.log(res.data.results);
        // console.log(res.data.results.find((o) => o.menuCode === menuCode));
        var menuPermission = res.data.results.find(
          (o) => o.menuCode === menuCode,
        );

        //console.log(menuPermission);
        var actionList = [];
        if (menuPermission.new_permission) {
          actionList.push(addAction);
        }
        if (menuPermission.edit_permission) {
          actionList.push(editAction);
        }
        if (menuPermission.view_permission) {
          actionList.push(viewAction);
        }
        if (menuPermission.delete_permission) {
          actionList.push(deleteAction);
        }
        setActions(actionList);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  ///
  const onChangeInput = async (event) => {
    /* https://www.geeksforgeeks.org/file-uploading-in-react-js/ 
      https://bezkoder.com/react-hooks-file-upload/
      */
    //CALL API:
    setIsLoading(true);
    try {
      const result = await assetService.uploadOwnerChangeTxn(
        event.target.files[0],
      );
      if (result.status == 200) {
        setIsLoading(false);
        console.log('result: => ', result);
        setNotification({
          open: true,
          type: 'success',
          message: `OwnerChangeTxn - Imported total: ${result.data.total} records`,
        });
        setImportSuccess(true);
      } else {
        setErrorMessage(result);
        setOpenModal(true);
        console.log('result-error: => ', result.data);
        setIsLoading(false);
        setNotification({
          open: true,
          type: 'error',
          message: `ERROR: ${
            result.status
          } - OwnerChangeTxn can't import to database :${'\n'} ${JSON.stringify(
            result.data?.rowcount,
          )} rows`,
        });
      }
    } catch (error) {
      console.log('catching... => ', error);
      setNotification({
        open: true,
        type: 'error',
        message: `Error: S${error}`,
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (importSuccess === true) {
      console.log('refresh table');
      tableRef.current.onQueryChange();
      setImportSuccess(false);
    }
    return () => {};
  }, [importSuccess]);
  return (
    <>
      <MaterialTable
        icons={tableIcons}
        tableRef={tableRef}
        actions={actions}
        columns={headers}
        data={(query) =>
          new Promise((resolve, reject) => {
            console.log('query: ', query);

            try {
              jQGridService
                .search(
                  props.filenameXML + '.xml',
                  query.page + 1,
                  query.pageSize,
                  query.search,
                )
                .then((res) => {
                  console.log('table res => ', res.data);
                  if (!!res.data) {
                    resolve({
                      data: res.data.rows,
                      page: res.data.page - 1,
                      totalCount: res.data.records,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: query.page,
                      totalCount: query.totalCount,
                    });
                  }
                });
            } catch (error) {
              console.error(`Err: ${error}`);
              return reject({
                data: [],
                page: 1,
                totalCount: 1,
              });
            }
          })
        }
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: palettle.background.default,
            color: palettle.primary.main,
          },
        }}
        title={props.title}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />

              <div style={{ padding: 10, textAlign: 'end' }}>
                <input
                  accept=".xlsx"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  onChange={onChangeInput}
                  multiple
                  type="file"
                />
                <label htmlFor="contained-button-file">
                  {isLoading && <CircularProgress size="1.5rem" />}
                  <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<CloudUpload />}
                    component="span"
                    disabled={isLoading}
                  >
                    Import(.xlsx)
                  </Button>
                </label>
                <div>
                  <ImportAlertModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    errorMessage={errorMessage}
                  />
                  {errorMessage && (
                    <>
                      {/* <Alert severity="error">
                      {errorMessage.data.error_data.map((item, index) => {
                        var msgs = item.errors;
                        return (
                          <>
                            <ul>
                              <li key={index}>
                                <h4
                                  style={{ color: 'white' }}
                                >{`code: ${item.code}`}</h4>
                              </li>
                              {msgs.map((err, idx) => {
                                const [key, value] = Object.entries(err)[0];
                                return (
                                  <li key={`${index}-${idx}`}>
                                    {`${key} : ${value}`}
                                  </li>
                                );
                              })}
                            </ul>
                            <hr style={{ borderColor: 'white' }}></hr>
                          </>
                        );
                      })}
                    </Alert> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          ),
        }}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={notification.open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={notification.type}>
          <h3 style={{ color: 'white' }}>{notification.message}</h3>
        </Alert>
      </Snackbar>
    </>
  );
};

export default TransactionDatatable;
