import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { translate } from 'react-switch-lang';
import Select from 'react-select';
import { Header, Icon } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import {
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
  TextField,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BorrowService as service } from '../../../../../_services/transaction/asset.borrow.service';
import AsyncSelect from 'react-select/async';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentOwner } from '../../../assetcheck/redux/actions/assetcheck.action';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  griditem: {
    padding: theme.spacing(1),
  },
  bg: {
    //background: 'linear-gradient(180deg, white 30%, grey 70%)',
    // background: '#bbdefb',
  },
  control: {
    paddingRight: theme.spacing(0.5),
  },
}));

const BorrowHeader = (props) => {
  const { t, setValue } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialOwnerOption = [
    {
      value: 1,
      label: 'Company',
    },
    {
      value: 2,
      label: 'Branch',
    },
    {
      value: 3,
      label: 'Department',
    },
    {
      value: 4,
      label: 'Customer',
    },
    {
      value: 5,
      label: 'Employee',
    },
  ];

  //selector
  const borrowStore = useSelector((state) => state.assetTable);

  //useState
  const [optionReason, setoptionReason] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [ownerTypeOption] = useState(initialOwnerOption);
  const [onwerDescsOption, setOnwersDescOption] = useState([]);
  const [values, setValues] = useState({
    code: '',
    BorrowDate: '',
    ReturnDate: '',
    dateValue: moment().format('YYYY-MM-DD'),
    borrowby: '',
    OwnerType: '',
    Reason: '',
    remark: '',
    status: 'OPEN',
  });

  useEffect(() => {
    if (borrowStore.is_readyonly) setIsReadOnly(true);
  }, [borrowStore]);

  //Initialize
  useEffect(() => {
    service.getReason().then((res) => {
      // console.log(res.data.results);
      res.data.results
        .filter((option) => option.reasonType === 'BORROW')
        .map((item) => {
          setoptionReason((option) => [
            ...option,
            {
              value: item.value,
              label: item.label,
            },
          ]);
        });
    });
  }, []);

  //Edit
  useEffect(() => {
    //check edit action
    if (!!props.code) {
      service
        .getItem(props.code)
        .then((res) => {
          console.log('get_data:', res);
          setValues({
            code: res.data.result.code,
            BorrowDate: moment(res.data.result.borrowDate).format('YYYY-MM-DD'),
            ReturnDate: moment(res.data.result.returnDate).format('YYYY-MM-DD'),
            dateValue: moment(res.data.result.date).format('YYYY-MM-DD'),
            borrowby: res.data.result.borrowBy,
            OwnerType: res.data.result.ownerType,
            Reason: res.data.result.reason,
            status: res.data.result.status,
            remark: res.data.result.remark,
          });

          props.setValue('code', res.data.result.code);

          if (!!res.data.result.reason)
            props.setValue('reason', {
              value: res.data.result.reason,
              label: res.data.result.reason,
            });

          props.setValue('OwnerType', {
            value: res.data.result.ownerType,
            label: initialOwnerOption.find(
              (i) => i.value == res.data.result.ownerType,
            ).label
              ? initialOwnerOption.find(
                  (i) => i.value == res.data.result.ownerType,
                ).label
              : '',
          });

          props.setValue('borrowby', {
            value: res.data.result.borrowBy,
            label: res.data.result.borrowBy,
          });
          props.setValue('remark', res.data.result.remark);
        })

        .catch((error) => {
          window.alert(error);
        });
    }
  }, []);

  useEffect(() => {
    ownersQuery(values.OwnerType.value, '').then((opts) => {
      setOnwersDescOption(opts);
    });
  }, [values['OwnerType']]);

  useEffect(() => {
    dispatch(
      getCurrentOwner({ owner: values.borrowby, ownertype: values.OwnerType }),
    );
    // dispatch(getCurrentOwnerType(values.OwnerType));
  }, [values['borrowby']]);

  const ownersQuery = async (ownerType, inputValue) => {
    try {
      const res = await service.autoComplete({
        req_type: ownerType,
        req_search: inputValue,
        recordTotal: '30',
      });

      const resData = Object.keys(res).map(
        (key) =>
          new Object({
            value: res[key].value,
            label: res[key].label,
          }),
      );

      return resData;
    } catch (error) {
      console.log('error ownerQuery:', error);
    }
  };

  const handleSelectChange = (event) => {
    if (event[0] != null) {
      setValues({
        ...values,
        [event[1].name]: { value: event[0].value, label: event[0].label },
      });
    } else {
      setValues({
        ...values,
        [event[1].name]: '',
      });
    }
  };

  const handleChange = (event) => {
    //console.log(event.target.value);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <>
      <Grid container className={classes.root}>
        <Grid item className={classes.griditem} xs={12} md={6}>
          <Header className={classes.bg} dividing>
            <Typography variant="h6" color="textPrimary">
              <Icon name="folder" />
              {t('Asset Description')}
            </Typography>
          </Header>
          <div>
            <Grid container>
              <Grid item md={6} xs={12} className={classes.control}>
                <FormControl className={classes.input} fullWidth margin="dense">
                  <FormLabel component="legend">{t('Code')}</FormLabel>
                  <TextField
                    size="small"
                    variant="outlined"
                    name="code"
                    value={values.code}
                    inputRef={props.register()}
                    onChange={handleChange}
                    disabled
                  />
                  <FormHelperText>
                    {props.errors.code?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} className={classes.control}>
                <FormControl
                  margin="dense"
                  fullWidth
                  required
                  error={!!props.errors.dateValue}
                >
                  <FormLabel component="legend">{t('Date')}</FormLabel>
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    name="dateValue"
                    value={[moment(values.dateValue).format('YYYY-MM-DD')]}
                    inputRef={props.register({ required: true })}
                    onChange={handleChange}
                    //disabled={props.readOnly}
                    disabled={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormHelperText>
                    {props.errors.date?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={6} xs={12} className={classes.control}>
                <FormControl className={classes.input} fullWidth margin="dense">
                  <FormLabel component="legend">{t('Status')}</FormLabel>
                  <TextField
                    size="small"
                    variant="outlined"
                    name="status"
                    value={values.status}
                    inputRef={props.register()}
                    onChange={handleChange}
                    disabled
                  />
                  <FormHelperText>
                    {props.errors.status?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} className={classes.control}>
                <FormControl
                  className={classes.input}
                  fullWidth
                  margin="dense"
                  size="small"
                  required={true}
                  error={!!props.errors.Reason}
                >
                  <FormLabel component="legend">{t('Reason')}</FormLabel>
                  <Controller
                    name="reason"
                    as={<Select />}
                    options={optionReason}
                    defaultValue={values.reason}
                    isClearable
                    control={props.control}
                    rules={{ required: 'Reason is Required' }}
                    isDisabled={props.readOnly}
                  />
                  <FormHelperText error>
                    {!!props.errors.reason && (
                      <>{props.errors.reason.message}</>
                    )}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <FormControl
              className={classes.input}
              fullWidth
              margin="dense"
              error={!!props.errors.remark}
            >
              <FormLabel component="legend">{t('Remark')}</FormLabel>
              <TextField
                size="small"
                variant="outlined"
                name="remark"
                value={values.remark}
                inputRef={props.register}
                onChange={handleChange}
                disabled={props.readOnly}
              />
              <FormHelperText>
                {props.errors.remark?.type === 'required' &&
                  'Your input is required'}
              </FormHelperText>
            </FormControl>
          </div>
        </Grid>

        <Grid item className={classes.griditem} xs={12} md={6}>
          <Header dividing>
            <Typography variant="h6" color="textPrimary">
              <Icon name="user" />
              {t('Borrower')}
            </Typography>
          </Header>

          <section>
            <Grid container>
              <Grid item md={6} xs={12} className={classes.control}>
                <FormControl
                  margin="dense"
                  fullWidth
                  required
                  error={!!props.errors.BorrowDate}
                >
                  <FormLabel component="legend">{t('Borrow Date')}</FormLabel>
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    name="BorrowDate"
                    value={[moment(values.BorrowDate).format('YYYY-MM-DD')]}
                    inputRef={props.register({ required: true })}
                    onChange={handleChange}
                    disabled={props.readOnly}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormHelperText>
                    {props.errors.BorrowDate?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} className={classes.control}>
                <FormControl
                  margin="dense"
                  fullWidth
                  required
                  error={!!props.errors.ReturnDate}
                >
                  <FormLabel component="legend">{t('Date')}</FormLabel>
                  <TextField
                    size="small"
                    type="date"
                    variant="outlined"
                    name="ReturnDate"
                    value={[moment(values.ReturnDate).format('YYYY-MM-DD')]}
                    inputRef={props.register({ required: true })}
                    onChange={handleChange}
                    disabled={props.readOnly}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormHelperText>
                    {props.errors.ReturnDate?.type === 'required' &&
                      'Your input is required'}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </section>
          <Grid container>
            <Grid item md={4} xs={12} className={classes.control}>
              <FormControl
                className={classes.input}
                fullWidth
                margin="dense"
                size="small"
                required={true}
              >
                <FormLabel component="legend">{t('Borrow Type')}</FormLabel>
                <Controller
                  name="OwnerType"
                  as={<Select />}
                  options={ownerTypeOption}
                  defaultValue={values.OwnerType}
                  isClearable
                  control={props.control}
                  onChange={(value) => {
                    setValue('borrowby', '');
                    handleSelectChange(value);
                    return value[0];
                  }}
                  rules={{ required: 'Borrow type is Required' }}
                  isDisabled={isReadOnly}
                />
                <FormHelperText error>
                  {!!props.errors.OwnerType && (
                    <>{props.errors.OwnerType.message}</>
                  )}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={8} xs={12} className={classes.control}>
              <FormControl margin="dense" fullWidth size="small" required>
                <FormLabel component="legend">{t('Borrower name')}</FormLabel>
                <Controller
                  as={<AsyncSelect />}
                  options={onwerDescsOption ? onwerDescsOption : []}
                  name="borrowby"
                  isClearable
                  isDisabled={isReadOnly}
                  control={props.control}
                  rules={{ required: 'Borrower Required' }}
                  defaultOptions={onwerDescsOption}
                  loadOptions={(value) => {
                    if (!values.OwnerType) {
                      window.alert(
                        'please select destination Borrow Type first',
                      );
                      return null;
                    }
                    return ownersQuery(values.OwnerType.value, value);
                  }}
                  placeholder={
                    values.OwnerType.label
                      ? 'Search by ' + values.OwnerType.label
                      : 'Select...'
                  }
                  onChange={(value) => {
                    //console.log(value);
                    handleSelectChange(value);
                    return value[0];
                  }}
                />

                <FormHelperText error>
                  {!!props.errors.borrowby && (
                    <>{props.errors.borrowby.message}</>
                  )}
                </FormHelperText>

                {/* <Controller
              as={<AsyncSelect />}
              options={options ? options : []}
              name="borrowby"
              isClearable
              control={props.control}
              rules={{ required: 'Owner Required' }}
              defaultOptions={promiseOwnerOptions}
              loadOptions={promiseOwnerOptions}
            />

            

            <FormHelperText error>
              {props.errors.borrowby && <>{props.errors.borrowby.message}</>}
            </FormHelperText> */}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default translate(BorrowHeader);
