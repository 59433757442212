import React from 'react';
import { Label } from 'semantic-ui-react';

export default function _InventoryFooter(props) {
  return (
    <>
      <Label>_Footer</Label>
    </>
  );
}
