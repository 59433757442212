import { userConstants as constants } from '../_constants';
import { userService as services } from '../_services/user.service';
import { alertActions as alerts } from './alert.action';
import { alertConstants as message } from '../_constants';
import { user_model } from '../_model';
export const userAction = {
  login,
  logout,
  insert,
  update,
  remove,
  get,
  getAll,
  reset,
};

function request(t) {
  return { type: t };
}
function success(t, res) {
  return { type: t, data: res };
}
function failure(t, error) {
  return { type: t, error };
}
function reset(t) {
  return { type: t };
}
function login_request(user) {
  return { type: constants.LOGIN_REQUEST, user };
}
function login_success(user) {
  return { type: constants.LOGIN_SUCCESS, user };
}
function login_failure(error) {
  return { type: constants.LOGIN_FAILURE, error };
}
function login(username, password) {
  return (dispatch) => {
    dispatch(login_request({ username }));
    console.log('logging in');
    return services.login(username, password).then(
      (res) => {
        if (!!res.data.error) {
          dispatch(login_failure(res.data.error));
          dispatch(alerts.error(res.data.message));
          services.logout();
          return res.data.error;
        } else {
          dispatch(login_success(res.data));
          dispatch(alerts.success('login success!!!'));

          localStorage.setItem('user', JSON.stringify(res.data));
          return res.data;
        }
      },
      (error) => {
        dispatch(login_failure(JSON.stringify(error.message)));
        dispatch(alerts.error(JSON.stringify(error.message)));
      },
    );
  };
}
function logout() {
  return (dispatch) => {
    services.logout();
    dispatch(success());
    return { type: constants.LOGOUT };
    function success() {
      return { type: constants.LOGOUT };
    }
  };
}
// function logout() {
//   services.logout();
//   return { type: constants.LOGOUT };
// }

function insert(data) {
  console.log('user converted object: ', convert2Object(data));
  return (dispatch) => {
    dispatch(request(constants.INSERT_REQUEST));
    return services.insertItem(convert2Object(data)).then(
      (res) => {
        console.log('user insert response: ', res);

        dispatch(success(constants.INSERT_SUCCESS, res));
        dispatch(alerts.success(message.INSERT_SUCCESS));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.INSERT_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function update(data) {
  return (dispatch) => {
    dispatch(request(constants.UPDATE_REQUEST));
    return services.updateItem(convert2Object(data)).then(
      (res) => {
        dispatch(success(constants.UPDATE_SUCCESS, res));
        dispatch(alerts.success(message.UPDATE_SUCCESS));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.UPDATE_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function remove(data) {
  return (dispatch) => {
    dispatch(request(constants.DELETE_REQUEST));
    return services.deleteItem(data).then(
      (res) => {
        dispatch(success(constants.DELETE_SUCCESS, res));
        dispatch(alerts.success(message.DELETE_SUCCESS));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.DELETE_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function get(code) {
  return (dispatch) => {
    dispatch(request(constants.GET_REQUEST));

    return services.getItem(code).then(
      (res) => {
        if (res.data === '') return user_model;
        let obj = convert2Select(res.data.info);
        console.log('res: ', res);
        var converted = {
          ...res,
          data: {
            ...res.data,
            info: JSON.parse(JSON.stringify(obj)),
          },
        };
        dispatch(success(constants.GET_SUCCESS, converted));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.GET_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function getAll() {
  return (dispatch) => {
    dispatch(request(constants.GETALL_REQUEST));
    return services.getAllItem().then(
      (res) => {
        dispatch(success(constants.GETALL_SUCCESS, res));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.GETALL_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

const convert2Object = (obj) => {
  let new_obj = {
    ...obj,

    roleCode: !!obj.roleCode && !!obj.roleCode.value ? obj.roleCode.value : '',
  };
  return new_obj;
};
const convert2Select = (obj) => {
  let new_obj = {
    ...obj,
    roleCode: {
      value: obj.roleCode,
      label: obj.roleCode + ' - ' + obj.roleName,
    },
  };
  return new_obj;
};
