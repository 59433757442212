import React, { useCallback, useEffect, useState } from 'react';

export default function useFetch({ fetchFromApi, extract }, dependencies = []) {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchData = useCallback(async () => {
    const res = await fetchFromApi();
    setResponse(extract(res));
    setLoading(false);
  }, []);

  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      fetchData();
    }
    return () => {
      isCancelled = true;
    };
  }, [...dependencies]);
  return [response, loading];
}
