export function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token };
  } else {
    return {};
  }
}

export function getUserAuthen() {
  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    return user;
  } else {
    return {};
  }
}
