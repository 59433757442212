import {
  assetBrandConstants,
  assetClassConstants,
  assetConditionConstants,
  assetGroupConstants,
  assetModelConstants,
  assetSubClassConstants,
  assetSupplierConstants,
  branchConstants,
  businessAreaConstants,
  companyConstants,
  // jQGRidConstants,
  locationConstants,
  ownerConstants,
  siteConstants,
  uomConstants,
  zoneConstants,
  userConstants,
  costCenterConstants,
  roleConstants,
  permissionConstants,
  CustomerConstants as customerConstants,
  assetAccountConstants,
  assetAccountTypeConstants,
} from '../_constants';

import { assetBrandService } from './assetBrand.service';
import { assetClassService } from './assetClass.service';
import { assetConditionService } from './assetCondition.service';
import { assetGroupService } from './assetGroup.service';
import { assetModelService } from './assetModel.service';
import { assetSupplierService } from './assetSupplier.service';
import { assetSubClassService } from './assetSubClass.service';
import { branchService } from './branch.service';
import { businessAreaService } from './businessArea.service';
import { companyService } from './company.service';
import { locationService } from './location.service';
import { ownerService } from './owner.service';
import { siteService } from './site.service';
import { userService } from './user.service';
import { zoneService } from './zone.service';
import { uomService } from './uom.service';
import { costCenterService } from './costCenter.service';
import { roleService } from './role.service';
import { permissionService } from './permission.service';
import { CustomerService as customerService } from './customer.service';
import { assetAccountService } from './assetAccount.service';
import { assetAccountTypeService } from './assetAccountType.service';

export * from './assetCondition.service.js';
export * from './assetGroup.service.js';
export * from './assetSupplier.service.js';
export * from './businessArea.service.js';
export * from './assetClass.service.js';
export * from './assetSubClass.service.js';
export * from './assetBrand.service.js';
export * from './assetModel.service.js';
export * from './asset.service.js';
export * from './jqgrid.service.js';
export * from './location.service.js';
export * from './owner.service.js';
export * from './site.service.js';
export * from './uom.service.js';
export * from './user.service.js';
export * from './zone.service.js';
export * from './costCenter.service.js';
export * from './role.service.js';
export * from './permission.service.js';
export * from './customer.service.js';
export * from './assetAccount.service.js';
export * from './assetAccountType.service.js';
export const serviceMap = (service) => {
  switch (service) {
    case assetBrandConstants.SERVICE:
      return assetBrandService;
    case assetClassConstants.SERVICE:
      return assetClassService;
    case assetConditionConstants.SERVICE:
      return assetConditionService;
    case assetGroupConstants.SERVICE:
      return assetGroupService;
    case assetModelConstants.SERVICE:
      return assetModelService;
    case assetSubClassConstants.SERVICE:
      return assetSubClassService;
    case assetSupplierConstants.SERVICE:
      return assetSupplierService;
    case branchConstants.SERVICE:
      return branchService;
    case businessAreaConstants.SERVICE:
      return businessAreaService;
    case companyConstants.SERVICE:
      return companyService;
    case locationConstants.SERVICE:
      return locationService;
    case ownerConstants.SERVICE:
      return ownerService;
    case siteConstants.SERVICE:
      return siteService;
    case uomConstants.SERVICE:
      return uomService;
    case userConstants.SERVICE:
      return userService;
    case zoneConstants.SERVICE:
      return zoneService;
    case costCenterConstants.SERVICE:
      return costCenterService;
    case roleConstants.SERVICE:
      return roleService;
    case permissionConstants.SERVICE:
      return permissionService;
    case customerConstants.SERVICE:
      return customerService;
    case assetAccountConstants.SERVICE:
      return assetAccountService;
    case assetAccountTypeConstants.SERVICE:
      return assetAccountTypeService;
    default:
      break;
  }
};
