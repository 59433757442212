import { assetAccountConstants as constants } from '../_constants';

const initialState = {};
export function assetAccountOperation(state = initialState, action) {
  switch (action.type) {
    case constants.INSERT_REQUEST:
      return {
        ...state,
        insertRequest: true,
        insertSuccess: false,
        insertFailure: false,
      };
    case constants.INSERT_SUCCESS:
      return {
        ...state,
        insertRequest: false,
        insertSuccess: true,
        insertFailure: false,
        data: action.data,
      };
    case constants.INSERT_FAILURE:
      return {
        ...state,
        insertRequest: false,
        insertSuccess: false,
        insertFailure: true,
        error: action.error,
      };

    case constants.UPDATE_REQUEST:
      return {
        ...state,
        updateRequest: true,
        updateSuccess: false,
        updateFailure: false,
      };
    case constants.UPDATE_SUCCESS:
      return {
        ...state,
        updateRequest: false,
        updateSuccess: true,
        updateFailure: false,
        data: action.data,
      };
    case constants.UPDATE_FAILURE:
      return {
        ...state,
        updateRequest: false,
        updateSuccess: false,
        updateFailure: true,
        error: action.error,
      };

    case constants.DELETE_REQUEST:
      return {
        ...state,
        deleteRequest: true,
        deleteSuccess: false,
        deleteFailure: false,
      };
    case constants.DELETE_SUCCESS:
      return {
        ...state,
        deleteRequest: false,
        deleteSuccess: true,
        deleteFailure: false,
        data: action.data,
      };
    case constants.DELETE_FAILURE:
      return {
        ...state,
        deleteRequest: false,
        deleteSuccess: false,
        deleteFailure: true,
        error: action.error,
      };

    case constants.GET_REQUEST:
      return {
        ...state,
        getRequest: true,
        getSuccess: false,
        getFailure: false,
      };
    case constants.GET_SUCCESS:
      return {
        ...state,
        getRequest: false,
        getSuccess: true,
        getFailure: false,
        data: action.data,
      };
    case constants.GET_FAILURE:
      return {
        ...state,
        getRequest: false,
        getSuccess: false,
        getFailure: true,
        error: action.error,
      };

    case constants.GETALL_REQUEST:
      return {
        ...state,
        getAllRequest: true,
        getAllSuccess: false,
        getAllFailure: false,
      };
    case constants.GETALL_SUCCESS:
      return {
        ...state,
        getAllRequest: false,
        getAllSuccess: true,
        getAllFailure: false,
        data: action.data,
      };
    case constants.GETALL_FAILURE:
      return {
        ...state,
        getAllRequest: false,
        getAllSuccess: false,
        getAllFailure: true,
        error: action.error,
      };
    case constants.RESET:
      return {};
    default:
      return {}; // if return state, insertRequest, insertSuccess, etc., will remain the same value
  }
}
