import Swal from 'sweetalert2';
import '@sweetalert2/theme-material-ui/material-ui.scss';
import platte from '../../theme/palette';

const Error = (message) => {
  ///logic

  return Swal.fire({
    title: 'Error!',
    text: message,
    icon: 'error',
  });
};
const Success = (message) => {
  ///logic
  return Swal.fire({
    title: 'Success!',
    text: message,
    icon: 'success',
  });
};

const Info = (message) => {
  return Swal.fire({
    title: 'Info',
    text: message,
    icon: 'info',
  });
};

const Confirm = async (message) => {
  //

  return await Swal.fire({
    title: 'Are you sure?',
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: platte.primary.main,
    cancelButtonColor: platte.error.main,
    confirmButtonText: 'Yes, save it!',
  });
};

export { Error, Success, Confirm, Info };
