import * as yup from 'yup'; // for everything

export const schema = yup.object().shape({
  code: yup.string().required(),
  host: yup.string().required(),
  port: yup.string().required(),
  username: yup.string().required(),
  password: yup.string().required(),
//   bcc: yup.string().required(),
});
