import React from 'react';
// import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from 'material-table';
import { jQGridService, roleService } from '../../_services';
import { tableIcons } from './tableIcons';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import { PresetColorTypes } from 'antd/lib/_util/colors';
import { useAlert, positions } from 'react-alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';
import palettle from '../../theme/palette';
// import { history } from '../../_helpers/history';

const useStyles = makeStyles((theme) => ({
  root: {},
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(3),
  },
}));
const tableRef = React.createRef();

const TransactionDatatable = (props) => {
  // const classes = useStyles();
  const alert = useAlert();
  const menuCode = props.menuCode;
  const user = JSON.parse(localStorage.getItem('user'));
  const [actions, setActions] = React.useState([]);
  const role = user.roleinfo; // {code, description}
  var addAction = {
    icon: () => <AddIcon />,
    tooltip: 'Add new',
    isFreeAction: true,
    onClick: (e) => {
      e.preventDefault();
      props.history.push({
        pathname: `${props.path.add}`,
        state: { isReadOnly: false, action: 'create' },
      });
    },
  };
  var viewAction = {
    icon: () => <VisibilityIcon />,
    tooltip: 'View',
    onClick: (e, rowData) => {
      e.preventDefault();
      props.history.push({
        pathname: `${props.path.edit}/${
          rowData.Code || rowData.Asset_Check_Id
        }`,
        state: {
          isReadOnly: true,
          action: 'view',
          status: rowData.Status,
        },
      });
    },
  };
  var editAction = {
    icon: () => <EditIcon />,
    tooltip: 'edit',
    onClick: (e, rowData) => {
      e.preventDefault();
      props.history.push({
        pathname: `${props.path.edit}/${
          rowData.Code || rowData.Asset_Check_Id
        }`,
        state: {
          isReadOnly: false,
          action: 'edit',
          status: rowData.Status,
        },
      });
    },
  };
  var deleteAction = {
    icon: () => <DeleteIcon />,
    tooltip: 'Delete',
    onClick: (e, rowData) => {
      e.preventDefault();
      const confirm = window.confirm(
        `you want to delete this document no. ${rowData.Code} ?`,
      );

      if (confirm) {
        props.service
          .Delete(rowData.Code)
          .then((res) => {
            if (res === 'OK') {
              const options = {
                position: positions.BOTTOM_RIGHT,
                timeout: 2000,
              };
              alert.success(JSON.stringify(`Deleted Successfull`), options);
              tableRef.current && tableRef.current.onQueryChange();
            } else {
              //console.log(res);
              const options = {
                position: positions.BOTTOM_RIGHT,
                timeout: 2000,
              };
              alert.error(JSON.stringify(`Delete Not Successfull`), options);
              tableRef.current && tableRef.current.onQueryChange();
            }
          })
          .catch((err) => {
            const options = {
              position: positions.BOTTOM_RIGHT,
              type: 'error',
              timeout: 4000,
            };
            //alert.show(JSON.stringify(err), options);
          });
      }
    },
  };

  React.useEffect(() => {
    let isCancelled = false;
    roleService.getRolePermissions(role.code).then((res) => {
      if (!isCancelled) {
        // console.log('menu_code: ', menuCode);
        // console.log(res.data.results);
        // console.log(res.data.results.find((o) => o.menuCode === menuCode));
        var menuPermission = res.data.results.find(
          (o) => o.menuCode === menuCode,
        );

        //console.log(menuPermission);
        var actionList = [];
        if (menuPermission.new_permission) {
          actionList.push(addAction);
        }
        if (menuPermission.edit_permission) {
          actionList.push(editAction);
        }
        if (menuPermission.view_permission) {
          actionList.push(viewAction);
        }
        if (menuPermission.delete_permission) {
          actionList.push(deleteAction);
        }
        setActions(actionList);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        tableRef={tableRef}
        actions={actions}
        columns={props.headers}
        data={(query) =>
          new Promise((resolve, reject) => {
            // console.log('query: ', query);
            try {
              jQGridService
                .search(
                  props.filenameXML + '.xml',
                  query.page + 1,
                  query.pageSize,
                  query.search,
                )
                .then((res) => {
                  console.log('table res => ', res.data);
                  if (!!res.data) {
                    resolve({
                      data: res.data.rows,
                      page: res.data.page - 1,
                      totalCount: res.data.records,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: query.page,
                      totalCount: query.totalCount,
                    });
                  }
                });
            } catch (error) {
              console.error(`Err: ${error}`);
              return reject({
                data: [],
                page: 1,
                totalCount: 1,
              });
            }
          })
        }
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: palettle.background.default,
            color: palettle.primary.main,
          },
        }}
        title={props.title}
      />
    </>
  );
};

export default TransactionDatatable;
