import * as yup from 'yup'; // for everything

export const schema = yup.object().shape({
  code: yup.string().required(),
  roleCode: yup.object().shape({
    value: yup.string().required(),
    label: yup.string(),
  }),
  username: yup.string().required(),
  password: yup.string().required(),
  // email: yup.string().email(),
});
