import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';
import MaterialTable from 'material-table';
import { jQGridService } from '../../../_services';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch } from 'react-redux';
import { selectedItem } from './redux/actions/assetsearch.action';

const StdDatatable = (props) => {
  const { t, title, fromCustomer } = props;
  const [selected, setSelected] = useState(null);
  const [closing, setclosing] = useState(false);
  const dispatch = useDispatch();

  //selector
  const columns = [
    { title: t('code'), field: 'Code' },
    { title: t('serial'), field: 'Serial' },
    { title: t('description'), field: 'Description' },
    { title: t('brand'), field: 'AssetBrand' },
    { title: t('class'), field: 'AssetClass' },
    { title: t('subclass'), field: 'AssetSubClass' },
    { title: t('model'), field: 'AssetModel' },
  ];

  useEffect(() => {
    dispatch(selectedItem(selected, closing));
    return () => {};
  }, [selected]);

  return (
    <>
      <MaterialTable
        icons={<tableIcons />}
        actions={[
          {
            icon: () => <SearchIcon />,
            tooltip: t('Choose'),
            onClick: (e, rowData) => {
              e.preventDefault();
              setSelected(rowData);
              setclosing(true);
            },
          },
        ]}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            try {
              jQGridService
                .search_asset_by_owner(
                  'Asset-By-Customer.xml',
                  query.page + 1,
                  query.pageSize,
                  props.fromCustomer.fromcustomer.value,
                  query.search,
                )
                .then((res) => {
                  if (!!res.data) {
                    resolve({
                      data: res.data.rows,
                      page: res.data.page - 1,
                      totalCount: res.data.records,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: query.page,
                      totalCount: query.totalCount,
                    });
                  }
                });
            } catch (error) {
              console.log('error: ', error);
              resolve({
                data: [],
                page: query.page,
                totalCount: query.totalCount,
              });
            }
          })
        }
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#e1f5fe',
            // background: 'linear-gradient(45deg, #b2dfdb 30%, #1976d2 90%)',
            color: 'Black',
          },
          // rowStyle: {
          //   background: 'linear-gradient(45deg, #b2dfdb 30%, #1976d2 90%)',

          // }
        }}
        title={title}
      />
    </>
  );
};

export default translate(StdDatatable);
