export const BorrowConstants = {
  API_INSERT: '/api/asset/borrow/insert',
  API_UPDATE: '/api/asset/borrow/update',
  API_DELETE: '/api/asset/borrow/delete',
  API_APPROVE: '/api/asset/borrow/apporve',
  API_GET: '/api/asset/borrow/',
  API_GETALL: '/api/asset/borrow/getall',
  API_GET_BY_ID: '/api/asset/borrow/info?code=',
  API_GET_ASSET_BY_SERIAL: '/api/asset/search/serial/',

  API_GET_REASON: '/api/reason/getall',

  API_AUTOCOMPLETE: '/api/autocomplete',
  API_AUTOCOMPLETE_OWNER: '/api/autocomplete/owner',

  INSERT_REQUEST: 'BORROW_INSERT_REQUEST',
  INSERT_SUCCESS: 'BORROW_INSERT_SUCCESS',
  INSERT_FAILURE: 'BORROW_INSERT_FAILURE',

  UPDATE_REQUEST: 'BORROW_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'BORROW_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'BORROW_UPDATE_FAILURE',

  DELETE_REQUEST: 'BORROW_DELETE_REQUEST',
  DELETE_SUCCESS: 'BORROW_DELETE_SUCCESS',
  DELETE_FAILURE: 'BORROW_DELETE_FAILURE',

  GET_REQUEST: 'BORROW_GET_REQUEST',
  GET_SUCCESS: 'BORROW_GET_SUCCESS',
  GET_FAILURE: 'BORROW_GET_FAILURE',

  GETALL_REQUEST: 'BORROW_GETALL_REQUEST',
  GETALL_SUCCESS: 'BORROW_GETALL_SUCCESS',
  GETALL_FAILURE: 'ABORROW_GETALL_FAILURE',

  RESET: 'BORROW_RESET',
  SERVICE: 'BORROW_SERVICE',
};
