export default (state = [], { type, payload }) => {
  //console.log('payload ', payload);
  switch (type) {
    case 'ADD_ITEM':
      return { ...payload };
    case 'DELETE_ITEM':
      return { ...payload };
    case 'SUCESS':
      return { sucess: true };
    case 'RESET_ITEM':
      return { state: null, payload: null };
    default:
      return state;
  }
};
