import React from 'react';
import { translate } from 'react-switch-lang';
import TransactionDatatable from './TransactionDatatable';

const Transactionlisting = (props) => {
  const { t } = props;

  return (
    <div>
      <TransactionDatatable
        title={props.title}
        headers={props.headers}
        filenameXML={props.filenameXML}
        root={props.root}
        history={props.history}
      />
    </div>
  );
};

export default translate(Transactionlisting);
