import React from 'react';
import { translate } from 'react-switch-lang';

import { Header } from 'semantic-ui-react';
import { InputSelect, InputDate } from '../../../../_fragments/formField/controllerFieldMUI';
import {
  warrantyConstants as warranty_constants,
} from "../../../../_constants";

const Warranty = (props) => {
  const { t, errors, control, readOnly, } = props;
  return (
    <>
      <InputDate
        name="warrantyStartDate"
        control={control}
        label='warrantyStartDate'
        error={!!errors.warrantyStartDate && {
          content: errors.warrantyStartDate.message,
        }}
        readOnly={readOnly}

      />
      <InputDate
        name="warrantyEndDate"
        control={control}
        label='warrantyEndDate'
        error={!!errors.warrantyEndDate && {
          content: errors.warrantyEndDate.message,
        }}
        readOnly={readOnly}

      />
      {/* <InputSelect
        name="warranty"
        control={control}
        label='warranty'
        placeholder='warranty'
        error={!!errors.warranty && {
          content: errors.warranty.message,
        }}
        service={warranty_constants.SERVICE}
        readOnly={readOnly}
      /> */}

    </>
  );
};

export default translate(Warranty);
