import React from "react";
import { Input, Form } from "semantic-ui-react";

export function inputText2(props) {
  return (
    <>
      <Input {...props} />
    </>
  );
}

export function inputNumber2(props) {
  return (
    <>
      <Input {...props} />
    </>
  );
}

export function inputDate2(props) {
  return (
    <>

      <Form.Field
        required
        control={Input}
        type={"date"}
        label="Date"
        placeholder="Date"
      />
    </>
  );
}
