let data_return_insert = {
  assetBorrowCode: 'BW20200600143',
  dateValue: '2020-06-30',
  borrowDate: '2020-06-30',
  returnDate: '2020-06-30',
  ownerType: '1',
  returnBy: 'GLICO',
  remark: 'TEST',
  reason: 'BRFT',
  returnDetail: [
    {
      assetCode: '1000-001-0001-2017-0001',
      serial: 'USGMSG201701010001',
      brrowQuanitity: 1,
      returnQuanitity: 1,
      remark: 'item-remakr text',
    },
    {
      assetCode: '1000-001-0001-2017-0002',
      serial: 'SNTest0001',
      brrowQuanitity: 1,
      returnQuanitity: 1,
      remark: 'item-remakr text',
    },
  ],
};

let data_return_update = {
  assetReturnCode: 'RT20200600034',
  assetBorrowCode: 'BW20200600143',
  dateValue: '2020-06-29',
  borrowDate: '2020-06-29',
  returnDate: '2020-06-29',
  ownerType: '1',
  returnBy: 'ACC',
  remark: 'Return update test',
  reason: 'BRFT',
  returnDetail: [
    {
      assetReturnCode: 'RT20200600034',
      assetBorrowId: 'BW20200600143',
      assetCode: '1000-001-0001-2017-0001',
      assetDescription: '',
      serial: 'USGMSG201701010001',
      brrowQuanitity: 1,
      returnQuanitity: 1,
      uomId: '',
      locationId: '',
      zoneId: '',
      businessAreaId: '',
      inventoryId: '',
      remark: 'update return detail',
    },
  ],
};
export { data_return_insert, data_return_update };
