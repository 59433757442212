import React from 'react';
import { Header, Icon } from 'semantic-ui-react';

function title(props) {
  return (
    <>
      <Header
        as="h3"
        // color="blue"
        dividing={'dividing' in props ? props.dividing : false}
      >
        <Icon.Group size="big">
          <Icon name={props.icon} />
          <Icon corner name={props.iconaction} />
        </Icon.Group>
        <Header.Content>
          {props.content}
          <Header.Subheader>{props.description}</Header.Subheader>
        </Header.Content>
      </Header>
    </>
  );
}

export default title;
