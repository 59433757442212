import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from 'react-switch-lang';
import { Grid, Button, Slide } from '@material-ui/core';
import { Divider } from 'semantic-ui-react';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { Title } from '../../../_fragments';
import {
  AssetCheckHeader as Header,
  AssetCheckDetail as Additem,
} from './components';
import { MyAlert as Alert } from '../../../_fragments/alert';
import { Save, Update, ResetItem } from './redux/actions/assetcheck.action';
import { AssetCheckServices as service } from './services/assetcheck.service';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  card: {
    // height: 100,
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
  },
  btnSave: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnArroved: {
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnBack: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
}));

let isReadOnly = false;
let isCreate = false;

const AssetCheckForm = (props) => {
  const { t, history } = props;
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [submitstatus, setsubmitstatus] = useState(false);
  const [direction, setDirection] = useState({
    slide: 'left',
    in: true,
    timeout: 650,
  });
  const dispatch = useDispatch();
  const alert = useAlert();
  const {
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    getValues,
  } = useForm();

  //states
  let isReadOnly = false;
  let isCreate = false;
  if (!!props.location.state) {
    isReadOnly = props.location.state.isReadOnly;
    if (props.location.state.status == 'APPROVE') {
      isReadOnly = true;
    }
    if (props.location.state.action == 'create') {
      isCreate = true;
    }
  } else {
    isReadOnly = false;
  }

  const initialData = {
    code: '',
    owner: '',
    ownerType: '',
    checkBy: '',
    checkByType: '',
    checkDate: '',
    remark: '',
    details: [
      {
        assetCheckCode: '',
        assetCode: '',
        assetDescription: '',
        serial: '',
        isMatching: '',
        currentOwner: '',
        currentOwnerType: '',
        quanitity: '',
        remark: '',
      },
    ],
  };
  const [data, setData] = useState(initialData);
  const storage = useSelector((state) => state.assetTable);

  //** OnAction */
  const onsubmitAsync = async (formData) => {
    //**** */
    console.log('formData => ', formData);
    console.log('storage => ', storage);

    const storageSelector = (Obj) => {
      return Object.keys(Obj).map((storageKey) => ({
        assetCode: Obj[storageKey].assetCode,
        assetDescription: Obj[storageKey].assetDescription,
        serial: Obj[storageKey].serial,
        isMatching: Obj[storageKey].isMatching,
        currentOwner: formData.sourceOwner.value,
        currentOwnerType: formData.sourceOwnerType.value,
        quanitity: Obj[storageKey].quanitity,
        remark: Obj[storageKey].remark2,
      }));
    };

    const storageArray = storageSelector(storage);
    if (storageArray.length == 0) {
      window.alert('Please add item before save');
      return;
    }
    //Prepare data...
    let myData = {
      code: formData.code,
      owner: formData.sourceOwner.value,
      ownerType: formData.sourceOwnerType.value,
      checkBy: formData.sourceOwner.value,
      checkByType: formData.sourceOwnerType.value,
      checkDate: formData.date,
      Remark: formData.remark,
      details: storageArray,
    };

    //Saving...

    // console.log('mydata: ', myData);
    Alert.Confirm('Do you confirm to save ?').then((res) => {
      if (res.value) {
        confirmEvent();
      }
    });

    const confirmEvent = async () => {
      if (true) {
        try {
          if (props.location.state.action == 'create') {
            console.log('created...');
            dispatch(Save(myData));
            dispatch(ResetItem);
            setSubmitting(false);
          }
          if (props.location.state.action == 'edit') {
            console.log('edit...');
            dispatch(Update(myData));
            dispatch(ResetItem);
            setSubmitting(false);
          }

          if (props.location.state.action == 'view') {
            console.log('view...');
            dispatch(ResetItem);
            setSubmitting(false);
          }
        } catch (error) {
          console.log('error-save', error);
          setSubmitting(false);
        }
      } else {
        setSubmitting(false);
      }
    };
  };

  const approveEvent = async () => {
    const result = await service.Approve(props.match.params.id);
    console.log(result);
    if (result == 'OK') {
      alert.show('Approved', {
        timeout: 2000,
        type: 'success',
      });
      history.goBack();
    } else {
      alert.error('Error');
    }
  };
  return (
    <>
      <Slide
        timeout={direction.timeout}
        direction={direction.slide}
        in={direction.in}
        mountOnEnter
        unmountOnExit
      >
        <form onSubmit={handleSubmit(onsubmitAsync)} size="small" noValidate>
          <Grid container>
            <Grid item xs={12} sm={7}>
              <Title
                icon="cubes"
                iconaction="exchange"
                content="Asset Check"
                description="Description"
                props={props}
              />
            </Grid>
            <Grid item style={{ textAlign: 'end' }} xs={12} sm={5}>
              {!isReadOnly && (
                <>
                  {!isCreate && (
                    <Button
                      name="approveBtn"
                      className={classes.btnArroved}
                      size="large"
                      color="secondary"
                      variant="outlined"
                      startIcon={<BeenhereIcon />}
                      onClick={(e) => {
                        e.preventDefault();
                        approveEvent();
                      }}
                    >
                      Approve
                    </Button>
                  )}

                  <Button
                    className={classes.btnSave}
                    variant="outlined"
                    type="submit"
                    size="large"
                    startIcon={<SaveIcon />}
                    name="submitBtn"
                  >
                    Save
                  </Button>
                </>
              )}

              <Button
                className={classes.btnBack}
                name="backBtn"
                size="large"
                color="default"
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  setDirection({
                    ...direction,
                    slide: 'left',
                    in: false,
                  });
                  setTimeout(() => {
                    history.goBack();
                  }, direction.timeout);
                }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
          <Divider />

          <Header
            code={props.match.params.id}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            readOnly={isReadOnly}
          />
          <Additem
            code={props.match.params.id}
            register={register}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            control={control}
            readOnly={isReadOnly}
          />
        </form>
      </Slide>
    </>
  );
};

export default AssetCheckForm;
