import React, { useEffect, useState } from 'react';
import { translate } from 'react-switch-lang';
import { Grid, Divider, Header, Icon } from 'semantic-ui-react';
import {
  InputText,
  InputDate,
} from '../../../_fragments/formField/controllerFieldMUI';
import { Typography } from '@material-ui/core';
const _InventoryHeader = (props) => {
  const { t } = props;

  return (
    <>
      <Header dividing>
        <Typography variant="h6" color="textPrimary">
          <Icon name="folder" />
          {t('_asset_description')}
        </Typography>
      </Header>
      <Grid padded="horizontally">
        <Grid.Row>
          <Grid.Column mobile={16} tablet={4} computer={4}>
            <InputText
              name="code"
              control={props.control}
              label="code"
              placeholder="code"
              error={
                !!props.errors.code && {
                  content: props.errors.code.message,
                }
              }
              defaultValue=""
              required
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={4} computer={4}>
            <InputDate
              name="transferDate"
              control={props.control}
              label="_date"
              error={
                !!props.errors.transferDate && {
                  content: props.errors.transferDate.message,
                }
              }
              defaultValue={'2020-01-01'}
              type="date"
              readOnly={props.readOnly}
              content={props.errors.content}
              //required
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={4} computer={4}>
            <InputText
              name="_status"
              control={props.control}
              label="_status"
              placeholder="_status"
              error={
                !!props.errors._status && {
                  content: props.errors._status.message,
                }
              }
              defaultValue="Open"
              readOnly
            />
          </Grid.Column>
          <Grid.Column floated="right" mobile={16} tablet={4} computer={4}>
            <section>
              <InputText
                name="_createby"
                control={props.control}
                register={props.register}
                label="_createby"
                placeholder="_createby"
                error={
                  !!props.errors._createby && {
                    content: props.errors._createby.message,
                  }
                }
                defaultValue=""
                readOnly
              />
            </section>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={4} computer={4}>
            <section>
              <InputText
                name="reasonCode"
                control={props.control}
                label="reasonCode"
                placeholder="reasonCode"
                error={
                  !!props.errors.reasonCode && {
                    content: props.errors.reasonCode.message,
                  }
                }
                defaultValue="EOSL"
                required
              />
            </section>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={4} computer={4}>
            <section>
              <InputText
                name="remark"
                control={props.control}
                register={props.register}
                label="remark"
                placeholder="remark"
                error={
                  !!props.errors.remark && {
                    content: props.errors.remark.message,
                  }
                }
                defaultValue=""
              />
            </section>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
    </>
  );
};
export default translate(_InventoryHeader);
