import { LOGIN_URL } from '../components/login';
import axios from 'axios';
import { userConstants as constants } from '../_constants';
import { authHeader } from '../_helpers/auth-header';
import { history } from '../_helpers';
import { postRequest, getRequest } from '../_helpers';

export const userService = {
  login,
  logout,
  insertItem,
  updateItem,
  deleteItem,
  getItem,
  getAllItem,
};

async function login(username, password) {
  return await axios
    .post(LOGIN_URL, null, { params: { username, password } })
    .then((res) => {
      if (!!res.data.error) {
        console.log('error');
      } else {
        localStorage.setItem('user', JSON.stringify(res.data));
        history.push('/');
        window.location.reload();
      }
      if (res.data.error !== '99') {
        localStorage.setItem('user', JSON.stringify(res.data));
      } else {
      }

      console.log(JSON.stringify(res.data));
      return res;
    })
    .catch({});
}

function logout() {
  localStorage.removeItem('user');
}

async function insertItem(item) {
  return await postRequest(
    constants.API_INSERT,
    item,
    null,
    authHeader().Authorization,
  );
}

async function updateItem(item) {
  return await postRequest(
    constants.API_UPDATE,
    item,
    null,
    authHeader().Authorization,
  );
}

async function deleteItem(code) {
  return await postRequest(
    constants.API_DELETE,
    code,
    null,
    authHeader().Authorization,
  );
}
async function getItem(code) {
  return await getRequest(
    constants.API_GET + code,
    null,
    authHeader().Authorization,
  );
}

async function getAllItem() {
  return await getRequest(
    constants.API_GETALL,
    null,
    authHeader().Authorization,
  );
}
