import * as CONS from "../_constants";


const initialState = {}
export function deleteOperation (state = initialState, action) {
  switch (action.type) {
    case CONS.assetConditionConstants.DELETE_REQUEST: 
        return {
            ...state,
            assetConditionDeleteRequest:true
        }
    case CONS.assetConditionConstants.DELETE_SUCCESS:
        return {
            ...state,
            assetConditionDeleteSuccess:true,
            data:action.data
        }
    case CONS.assetConditionConstants.DELETE_FALURE: 
        return {
            ...state,
            assetConditionDeleteFailure:true,
            error:action.error
        }

    case CONS.assetGroupConstants.DELETE_REQUEST: return {...state,assetGroupDeleteRequest:true}
    case CONS.assetGroupConstants.DELETE_SUCCESS: return {...state,assetGroupDeleteSuccess:true,data:action.data}
    case CONS.assetGroupConstants.DELETE_FALURE: return {...state,assetGroupDeleteFailure:true,error:action.error}

    case CONS.assetSupplierConstants.DELETE_REQUEST: return {...state,assetSupplierDeleteRequest:true}
    case CONS.assetSupplierConstants.DELETE_SUCCESS: return {...state,assetSupplierDeleteSuccess:true,data:action.data}
    case CONS.assetSupplierConstants.DELETE_FALURE: return {...state,assetSupplierDeleteFailure:true,error:action.error}

    case CONS.businessAreaConstants.DELETE_REQUEST: 
        return {
            ...state,
            businessAreaDeleteRequest:true,
            businessAreaDeleteSuccess:false,
            businessAreaDeleteFailure:false
        }
    case CONS.businessAreaConstants.DELETE_SUCCESS: 
        return {
            ...state,
            businessAreaDeleteRequest:false,
            businessAreaDeleteSuccess:true,
            businessAreaDeleteFailure:false,
            data:action.data
        }
    case CONS.businessAreaConstants.DELETE_FALURE: 
        return {
            ...state,
            businessAreaDeleteRequest:false,
            businessAreaDeleteSuccess:false,
            businessAreaDeleteFailure:true,
            error:action.error
        }

    default:
        
      return {}; // if return state, insertRequest, insertSuccess, etc., will remain the same value
  }
}