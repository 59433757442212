import React, { useState, useEffect, useCallback } from 'react';
import './Form.css';
import { translate } from 'react-switch-lang';
import { Icon, Divider, Header } from 'semantic-ui-react';

import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ReactSelect from 'react-select';
import Select from 'react-select';
import { Title } from '../../../_fragments';
import { useSelector, useDispatch } from 'react-redux';
import View from './View';
import { assetAction as actions } from '../../../_action/asset.action';

import { assetConditionAction as conditionActions } from '../../../_action/assetCondition.action';
import { costCenterAction as costActions } from '../../../_action/costCenter.action';
import { assetBrandAction as brandActions } from '../../../_action/assetBrand.action';
import { assetSupplierAction as supplierActions } from '../../../_action/assetSupplier.action';
import { uomAction as uomActions } from '../../../_action/uom.action';
import CloseIcon from '@material-ui/icons/Close';

import { Display } from '../../Display';
import {
  InputSelectControlledRedux,
  InputDateControlled,
  InputSelectManualOption,
} from '../../../_fragments/formField/controllerFieldMUI';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Grid,
  Button,
  FormControlLabel,
  Switch,
  Box,
  Card,
  Typography,
  Slide,
  TextField,
  Collapse,
  IconButton,
} from '@material-ui/core';
import ImageManager from '../AssetForm/ImageManager';

import { useMuiFormStyles } from '../../../theme/MuiFormStyles';
import { Alert } from '@material-ui/lab';
import useAsset from '../../../hooks/useAsset';

const defaultVals = {
  code: '',
  propertyPlate: '',
  barcode: '',
  refCode: '',
  pn: '',
  serial: '',
  des: '',
  shortDes: '',
  parent: '',
  color: '',
  unit: '',
  condition: '',
  cost: '',
  brand: '',
  clss: '',
  subClass: '',
  group: '',
  model: '',
  serialized: true,
  erp: false,
  supplier: '',
  // warrantyStart: '',
  // warrantyEnd: '',
  warrantyStart: '2021-01-01',
  warrantyEnd: '2021-01-01',
  period: '',
  periodUOM: '',
  licenseStart: '2021-01-01',
  licenseEnd: '2021-01-01',
  periodl: '',
  periodlUOM: '',
  height: '',
  width: '',
  depth: '',
  weight: '',
  dimensionUOM: '',
  weightUOM: '',
  inventoryNo: '',
  active: true,
  size: '',
  isNotify: false,
  isNotifyl: false,
};

const Form = (props) => {
  const classes = useMuiFormStyles();
  const reducer = useSelector((asset) => asset.assetOperation);
  const rootReducer = useSelector((asset) => asset);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [showImageForm, setShowImage] = useState(true);
  const [formType, setFormType] = useState('');
  const [dateInputState, setDateInputState] = useState({
    disableEnd: true,
    disableP: true,
    disableEndl: true,
    disablePl: true,
  });
  const [direction, setDirection] = useState({
    slide: 'left',
    in: true,
    timeout: 650,
  });

  const {
    asset,
    setAsset,
    loading,
    title,
    makeSubmitFormat,
    options,
  } = useAsset({
    code: props.location.state.code,
    initial: defaultVals,
    form: props.location.state.form,
  });
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      let val = 0;
      setErrors({});
      var formatState = await makeSubmitFormat();

      try {
        console.log('val: ', val);
        if (formType.form === 'insert_form') {
          console.log('inserting: ');
          dispatch(actions.insert(formatState));
        } else if (formType.form === 'update_form') {
          console.log('updating: ');
          dispatch(actions.update(formatState));
        }
      } catch (err) {
        var tempErr = {};
        console.log('err: ', err.message);
        if (!!err.inner) {
          err.inner.map((info) => {
            tempErr = { ...tempErr, [info.params.path]: info.message };
            return null;
          });
          setErrors(tempErr);
        } else setErrors({ message: err.message });
      }
    },
    [makeSubmitFormat],
  );

  const clearLicense = (e) => {
    e.preventDefault();
    setDateInputState({
      disableEndl: true,
      disablePl: true,
    });
    setAsset({
      ...asset,
      licenseStart: '',
      licenseEnd: '',
      periodl: '',
      periodlUOM: '',
    });
  };
  const handleLicense = (e, data) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log('name: ', name);
    console.log('Data: ', data);

    if (name === 'licenseStart') {
      setAsset({ ...asset, [name]: value });
      setDateInputState({
        ...dateInputState,
        disableEndl: false,
        disablePl: false,
      });
    } else if (name === 'licenseEnd') {
      setDateInputState({
        ...dateInputState,
        disableEndl: false,
        disablePl: true,
      });

      setAsset({ ...asset, [name]: value, periodl: '' });
    } else if (name === 'periodl') {
      setDateInputState({
        ...dateInputState,
        disableEndl: true,
        disablePl: false,
      });
      if (value === '') {
        setDateInputState({
          ...dateInputState,
          disableEndl: false,
        });
      }
      setAsset({ ...asset, [name]: value, licenseEnd: '' });
    } else {
      setAsset({
        ...asset,
        [name]: value,
      });
    }
  };
  const clearWarranty = (e) => {
    e.preventDefault();
    setDateInputState({
      ...dateInputState,
      disableEnd: true,
      disableP: true,
    });
    setAsset({
      ...asset,
      warrantyStart: '',
      warrantyEnd: '',
      period: '',
      periodUOM: '',
    });
  };
  const handleWarranty = (e, data) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'warrantyStart') {
      setAsset({ ...asset, [name]: value });
      setDateInputState({
        ...dateInputState,
        disableEnd: false,
        disableP: false,
      });
    } else if (name === 'warrantyEnd') {
      setDateInputState({
        ...dateInputState,
        disableEnd: false,
        disableP: true,
      });

      setAsset({ ...asset, [name]: value, period: '' });
    } else if (name === 'period') {
      setDateInputState({
        ...dateInputState,
        disableEnd: true,
        disableP: false,
      });
      if (value === '') {
        setDateInputState({
          ...dateInputState,
          disableEnd: false,
        });
      }
      setAsset({ ...asset, [name]: value, warrantyEnd: '' });
    } else {
      setAsset({
        ...asset,
        [name]: value,
      });
    }
  };
  const handleChange = (e, data) => {
    const value = e.target.value;
    if (e.target.type === 'checkbox') {
      setAsset({ ...asset, [e.target.name]: data });
    } else if (e.target.type === 'date') {
      setAsset({ ...asset, [e.target.name]: value });
    } else {
      setAsset({
        ...asset,
        [e.target.name]: value,
      });
    }
  };
  const handleSelect = (value, target) => {
    setAsset({ ...asset, [target.name]: value });
  };

  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      if (reducer.insertSuccess || reducer.updateSuccess) {
        setErrors({});
        props.history.push(formType.root);
      } else if (reducer.insertFailure || reducer.updateFailure) {
        setErrors({ message: JSON.stringify(reducer.error) });
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [reducer]);

  useEffect(() => {
    var isCancelled = false;
    var asset = props.location.state;
    if (!isCancelled) {
      setFormType(asset);
      if (asset.form === 'update_form' || asset.form === 'view_form') {
        setDateInputState({
          disableEnd: false,
          disableP: false,
          disableEndl: false,
          disablePl: false,
        });
        setShowImage(true);
      } else {
        setDateInputState({
          disableEnd: true,
          disableP: true,
          disableEndl: true,
          disablePl: true,
        });
        setShowImage(false);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  if (loading) {
    return <>Loading...</>;
  } else if (!!formType && formType.form === 'view_form') {
    return (
      <>
        <View
          location={props.location}
          history={props.history}
          showImageForm={showImageForm}
          direction={direction}
          setDirection={setDirection}
          classes={classes}
          title={title}
          state={asset}
        />
      </>
    );
  } else
    return (
      <>
        <Slide
          timeout={direction.timeout}
          direction={direction.slide}
          in={direction.in}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <form noValidate>
              {/* title */}
              <Grid container>
                <Grid item xs={12} md={6} lg={6}>
                  <Title
                    icon="cubes"
                    iconaction={title.iconaction}
                    content={title.content}
                    description={title.description}
                    props={props}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box justifyContent="flex-end" display="flex">
                    <Button
                      variant="outlined"
                      size="large"
                      color="default"
                      onClick={onSubmit}
                      className={classes.btnSave}
                      startIcon={<SaveIcon />}
                      // color={'primary'}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      size="large"
                      color="default"
                      onClick={(e) => {
                        e.preventDefault();
                        setDirection({
                          ...direction,
                          slide: 'left',
                          in: false,
                        });
                        setTimeout(() => {
                          props.history.push(props.location.state.root);
                        }, direction.timeout);
                      }}
                      className={classes.btnBack}
                      startIcon={<ArrowBackIcon />}
                    >
                      Back
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Collapse in={!!errors.message}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setErrors({});
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {errors.message}
                </Alert>
              </Collapse>
              <Grid container direction="column">
                {/* Basic Info */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.container}
                >
                  {/* Basic Info 1 */}
                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="info circle" />
                        Basic Information Pt.1
                      </Typography>
                    </Header>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      {/* Code */}
                      <Grid item xs={12} md={2} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel required component="legend">
                            Property Plate no.
                          </FormLabel>
                          <TextField
                            name="code"
                            size="small"
                            placeholder="Property Plate no."
                            value={asset.code}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={
                              formType.form === 'update_form' ? true : false
                            }
                          />
                          <FormHelperText error={!!errors.code}>
                            {!!errors.code && errors.code}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Inventory Number */}
                      <Grid item xs={12} md={2} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            Inventory Number
                          </FormLabel>
                          <TextField
                            name="inventoryNo"
                            size="small"
                            placeholder="InventoryNumber"
                            value={asset.inventoryNo}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText error={!!errors.inventoryNo}>
                            {!!errors.inventoryNo && errors.inventoryNo}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Barcode */}
                      <Grid item xs={12} md={2} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Barcode</FormLabel>
                          <TextField
                            name="barcode"
                            size="small"
                            placeholder="barcode"
                            value={asset.barcode}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText error={!!errors.barcode}>
                            {!!errors.barcode && errors.barcode}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Ref Code */}
                      <Grid item xs={12} md={2} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Maintenance</FormLabel>
                          <TextField
                            name="refCode"
                            size="small"
                            placeholder="maintenance"
                            value={asset.refCode}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* PN */}
                      <Grid item xs={12} md={2} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Repair</FormLabel>
                          <TextField
                            name="pn"
                            size="small"
                            placeholder="Repair"
                            value={asset.pn}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Serial */}
                      <Grid item xs={12} md={2} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Serial</FormLabel>
                          <TextField
                            name="serial"
                            size="small"
                            placeholder="Serial no."
                            value={asset.serial}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      {/* Description */}
                      <Grid item xs={12} md={6} xl={6}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel required component="legend">
                            Description
                          </FormLabel>
                          <TextField
                            name="des"
                            size="small"
                            placeholder="Description"
                            value={asset.des}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText error={!!errors.des}>
                            {!!errors.des && errors.des}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Parent</FormLabel>
                          <TextField
                            name="parent"
                            size="small"
                            placeholder="Parent"
                            value={asset.parent}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Color</FormLabel>
                          <TextField
                            name="color"
                            size="small"
                            placeholder="Color"
                            value={asset.color}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      {/* ShortDescription */}
                      <Grid item xs={12} md={4} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            Short Description
                          </FormLabel>
                          <TextField
                            name="shortDes"
                            size="small"
                            placeholder="Short Description"
                            value={asset.shortDes}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel required component="legend">
                            SAP Code
                          </FormLabel>
                          <TextField
                            name="propertyPlate"
                            size="small"
                            placeholder="Code"
                            value={asset.propertyPlate}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText error={!!errors.propertyPlate}>
                            {!!errors.propertyPlate && errors.propertyPlate}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel required component="legend">
                            Size
                          </FormLabel>
                          <TextField
                            name="size"
                            size="small"
                            placeholder="Size"
                            value={asset.size}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText error={!!errors.size}>
                            {!!errors.size && errors.size}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                  {/* Basic Info 2 */}
                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="info circle" />
                        Basic Information Pt.2
                      </Typography>
                    </Header>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      {/* Unit */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Unit</FormLabel>

                          <InputSelectControlledRedux
                            name="unit"
                            value={asset.unit}
                            onChange={handleSelect}
                            action={uomActions}
                            reducer={rootReducer.uomOperation}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Condition */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Condition</FormLabel>
                          <InputSelectControlledRedux
                            name="condition"
                            value={asset.condition}
                            onChange={handleSelect}
                            action={conditionActions}
                            reducer={rootReducer.assetConditionOperation}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Cose Center */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Cost Center</FormLabel>
                          <InputSelectControlledRedux
                            name="cost"
                            value={asset.cost || ''}
                            onChange={handleSelect}
                            action={costActions}
                            reducer={rootReducer.costCenterOperation}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}></Grid>

                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={asset.serialized}
                                onChange={handleChange}
                                name="serialized"
                                color="primary"
                              />
                            }
                            label="isSerialized"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={1} xl={1}>
                        <FormControl margin="dense" fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={asset.erp}
                                onChange={handleChange}
                                name="erp"
                                color="primary"
                              />
                            }
                            label="ERP"
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={1} xl={1}>
                        <FormControl margin="dense" fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={asset.active}
                                onChange={handleChange}
                                name="active"
                                color="primary"
                              />
                            }
                            label="isActive"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                  {/* Owner Info*/}

                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="user circle outline" />
                        Owner
                      </Typography>
                    </Header>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          {formType.form === 'insert_form' ? (
                            <Display txt={'0000 - Not Define - ThaiGlico -'} />
                          ) : (
                            <Display
                              txt={
                                asset.ownerCode +
                                ' - ' +
                                asset.ownerName +
                                ' - ' +
                                asset.ownerType +
                                ' - '
                              }
                            />
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>

                  {/* Classification */}
                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="tag" />
                        Classification
                      </Typography>
                    </Header>

                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      {/* Supplier */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Supplier</FormLabel>

                          <InputSelectControlledRedux
                            name="supplier"
                            value={asset.supplier}
                            onChange={handleSelect}
                            action={supplierActions}
                            reducer={rootReducer.assetSupplierOperation}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Brand */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Brand</FormLabel>
                          <InputSelectControlledRedux
                            name="brand"
                            value={asset.brand}
                            onChange={handleSelect}
                            action={brandActions}
                            reducer={rootReducer.assetBrandOperation}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}></Grid>
                      {/* Class */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Class</FormLabel>
                          <Select
                            name={'clss'}
                            onChange={(value, target) => {
                              if (target.action === 'clear') {
                                setAsset({
                                  ...asset,
                                  clss: '',
                                  subClass: '',
                                  group: '',
                                  model: '',
                                });
                              } else setAsset({ ...asset, clss: value });
                            }}
                            isClearable
                            value={asset.clss}
                            getOptionLabel={(op) =>
                              op.code + ' - ' + op.description
                            }
                            getOptionValue={(op) => op.code}
                            options={options.clssOp}
                            menuPortalTarget={document.body}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* SubClass */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">SubClass</FormLabel>
                          <ReactSelect
                            key={asset.clss}
                            name="subClass"
                            onChange={(value) => {
                              setAsset({ ...asset, subClass: value });
                            }}
                            isClearable
                            value={asset.subClass}
                            getOptionLabel={(op) =>
                              op.code + ' - ' + op.description
                            }
                            getOptionValue={(op) => op.code}
                            filterOption={(value) => {
                              if (asset.clss === '')
                                return value.data.assetClass === asset.clss;
                              return value.data.assetClass === asset.clss.code;
                            }}
                            options={options.subClassOp}
                            menuPortalTarget={document.body}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Group */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Group</FormLabel>
                          <ReactSelect
                            key={asset.subClass}
                            name="group"
                            onChange={(value) => {
                              setAsset({ ...asset, group: value });
                            }}
                            isClearable
                            value={asset.group}
                            getOptionLabel={(op) =>
                              op.code + ' - ' + op.description
                            }
                            getOptionValue={(op) => op.code}
                            filterOption={(value) => {
                              if (asset.subClass === '')
                                return (
                                  value.data.assetSubClass === asset.subClass
                                );
                              return (
                                value.data.assetSubClass === asset.subClass.code
                              );
                            }}
                            options={options.groupOp}
                            menuPortalTarget={document.body}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Model */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Model</FormLabel>
                          <ReactSelect
                            key={asset.model}
                            name="model"
                            onChange={(value) => {
                              setAsset({ ...asset, model: value });
                            }}
                            isClearable
                            value={asset.model}
                            getOptionLabel={(op) =>
                              op.code + ' - ' + op.description
                            }
                            getOptionValue={(op) => op.code}
                            filterOption={(value) => {
                              if (asset.group === '')
                                return value.data.assetGroup === asset.group;
                              return value.data.assetGroup === asset.group.code;
                            }}
                            options={options.modelOp}
                            menuPortalTarget={document.body}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                  {/* Warranty_Liscense */}
                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="calendar check outline" />
                        Warranty
                      </Typography>
                    </Header>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      <Grid item xs={12} md={1} xl={1}>
                        <FormControl>
                          <Button
                            variant="outlined"
                            size="small"
                            color="default"
                            onClick={clearWarranty}
                            className={classes.btnSave}
                            // startIcon={<SaveIcon />}
                            // color={'primary'}
                          >
                            Clear
                          </Button>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={asset.isNotify}
                                onChange={handleChange}
                                name="isNotify"
                                color="primary"
                              />
                            }
                            label="Notification"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            Warranty Start Date
                          </FormLabel>
                          <InputDateControlled
                            name="warrantyStart"
                            value={asset.warrantyStart}
                            onChange={handleWarranty}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            Warranty End Date
                          </FormLabel>
                          <InputDateControlled
                            name="warrantyEnd"
                            value={asset.warrantyEnd}
                            onChange={handleWarranty}
                            disabled={dateInputState.disableEnd}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl fullWidth margin="dense">
                          <FormLabel component="legend">Period</FormLabel>
                          <TextField
                            name="period"
                            size="small"
                            type="number"
                            placeholder="period"
                            value={asset.period || ''}
                            onChange={handleWarranty}
                            disabled={dateInputState.disableP}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Period Unit</FormLabel>

                          <InputSelectManualOption
                            name="periodUOM"
                            value={asset.periodUOM}
                            onChange={handleSelect}
                            options={options.uomOp}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                  {/* License */}
                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="calendar check outline" />
                        License
                      </Typography>
                    </Header>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      <Grid item xs={12} md={1} xl={1}>
                        <FormControl>
                          <Button
                            variant="outlined"
                            size="small"
                            color="default"
                            onClick={clearLicense}
                            className={classes.btnSave}
                            // startIcon={<SaveIcon />}
                            // color={'primary'}
                          >
                            Clear
                          </Button>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={asset.isNotifyl}
                                onChange={handleChange}
                                name="isNotifyl"
                                color="primary"
                              />
                            }
                            label="Notification"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            License Start Date
                          </FormLabel>
                          <InputDateControlled
                            name="licenseStart"
                            value={asset.licenseStart}
                            onChange={handleLicense}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            License End Date
                          </FormLabel>
                          <InputDateControlled
                            name="licenseEnd"
                            value={asset.licenseEnd}
                            onChange={handleLicense}
                            disabled={dateInputState.disableEndl}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl fullWidth margin="dense">
                          <FormLabel component="legend">Period</FormLabel>
                          <TextField
                            name="periodl"
                            size="small"
                            type="number"
                            placeholder="period"
                            value={asset.periodl || ''}
                            onChange={handleWarranty}
                            disabled={dateInputState.disablePl}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Period Unit</FormLabel>

                          <InputSelectManualOption
                            name="periodUOMl"
                            value={asset.periodUOMl}
                            onChange={handleSelect}
                            options={options.uomOp}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                  {/* Dimension */}
                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="resize horizontal" />
                        Dimension
                      </Typography>
                    </Header>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl fullWidth margin="dense">
                          <FormLabel component="legend">Height</FormLabel>
                          <TextField
                            name="height"
                            size="small"
                            type="number"
                            placeholder="Height"
                            value={asset.height}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl fullWidth margin="dense">
                          <FormLabel component="legend">Width</FormLabel>
                          <TextField
                            name="width"
                            size="small"
                            type="number"
                            placeholder="Width"
                            value={asset.width}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl fullWidth margin="dense">
                          <FormLabel component="legend">Weight</FormLabel>
                          <TextField
                            name="weight"
                            size="small"
                            type="number"
                            placeholder="weight"
                            value={asset.weight}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl fullWidth margin="dense">
                          <FormLabel component="legend">Depth</FormLabel>
                          <TextField
                            name="depth"
                            size="small"
                            type="number"
                            placeholder="Depth"
                            value={asset.depth}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            Dimension Unit
                          </FormLabel>

                          <InputSelectManualOption
                            name="dimensionUOM"
                            value={asset.dimensionUOM}
                            onChange={handleSelect}
                            options={options.uomOp}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Weight Unit</FormLabel>

                          <InputSelectManualOption
                            name="weightUOM"
                            value={asset.weightUOM}
                            onChange={handleSelect}
                            options={options.uomOp}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                {showImageForm && (
                  <>
                    <Divider />

                    <Grid item xs={12} md={12} lg={12}>
                      <Card className={classes.group}>
                        <ImageManager
                          code={
                            !!props.location.state.code
                              ? props.location.state.code
                              : null
                          }
                        />
                      </Card>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </div>
        </Slide>
      </>
    );
};

export default translate(Form);
