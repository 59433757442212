import React, { useEffect, useState } from 'react';
import { translate } from 'react-switch-lang';

import { Form } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import { mapRefToOptions } from '../../_helpers';
import { serviceMap } from '../../_services';
// import { commonLabel } from '../../labelConstant';
// import moment from 'moment-timezone';
// import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import { Typography } from '@material-ui/core';

export const InputDate = translate((props) => {
  const { t } = props;

  return (
    <>
      {/* <DateInput
        label={'date'}
        name={props.naeme}
        value={dateValue}
        onChange={(_,{value})=>setDateValue(value)}
      /> */}

      {/* <Controller
      name={props.name}
      control={props.control}
      label={t(props.label)}
      placeholder={t(props.placeholder)}
      // defaultValue={moment().toDate().toString()}
      // value={dateValue}
      defaultValue=''
      // onChange={([_,data])=>data.value}
      errors={props.errors}
      valueName='value'
      as={DateInput}
      readOnly={props.readOnly}
      // required={props.required}
    /> */}
      {/* <Controller
      autoComplete="off"
      name={props.name}
      control={props.control}
      label={t(props.label)}
      error={props.error}
      defaultValue=''
      onChange={([_,data])=>data.value}
      as={SemanticDatepicker}
      readOnly={props.readOnly}
      required={props.required}
    /> */}

      {/* <Controller
      name={props.name}
      control={props.control}
      //label={t(props.label)}
      autoComplete="off"
      error={props.error}
      defaultValue={props.defaultValue}
      as={DatePicker}
      // bordered={false}
      allowClear	
      disabled={props.readOnly}
      required={props.required}
    /> */}
      <Controller
        name={props.name}
        control={props.control}
        label={<Typography color="textPrimary">{t(props.label)}</Typography>}
        error={props.error}
        defaultValue={props.defaultValue}
        as={Form.Input}
        type="date"
        readOnly={props.readOnly}
        required={props.required}
      />
    </>
  );
});

export const InputNumber = translate((props) => {
  const { t } = props;
  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        label={<Typography color="textPrimary">{t(props.label)}</Typography>}
        placeholder={t(props.placeholder)}
        error={props.error}
        as={Form.Input}
        type="number"
        //rules={getRules(props.rules, t)}
        readOnly={props.readOnly}
        // required={props.required}
      />
    </>
  );
});

export const InputText = translate((props) => {
  const { t } = props;
  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        label={<Typography color="textPrimary">{t(props.label)}</Typography>}
        placeholder={t(props.placeholder)}
        error={props.error}
        as={Form.Input}
        // rules={getRules(props.rules, t)}
        readOnly={props.readOnly}
        required={props.required}
      />
    </>
  );
});

export const InputToggle = translate((props) => {
  const { t } = props;
  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        label={<Typography color="textPrimary">{t(props.label)}</Typography>}
        // label={t(props.label)}
        as={Form.Checkbox}
        toggle
        valueName="checked"
        onChange={([_, data]) => data.checked}
        readOnly={props.readOnly}
      />
    </>
  );
});

export const InputCheckbox = translate((props) => {
  const { t } = props;
  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        label={<Typography color="textPrimary">{t(props.label)}</Typography>}
        as={Form.Checkbox}
        valueName="checked"
        onChange={([_, data]) => data.checked}
        readOnly={props.readOnly}
      />
    </>
  );
});

export const InputSelect = translate((props) => {
  const {
    t,
    name,
    control,
    label,
    placeholder,
    error,
    readOnly,
    required,
    onChange,
    service,
    isCascaded,
    filter,
  } = props;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    serviceMap(service)
      .getAllItem()
      .then(
        (res) => {
          setOptions(res.data.results);
          setLoading(false);
        },
        (error) => {
          return error;
        },
      );
  }, []);

  return (
    <>
      {props.readOnly ? (
        <Controller
          name={name}
          control={control}
          label={<Typography color="textPrimary">{t(label)}</Typography>}
          placeholder={t(placeholder)}
          error={error}
          as={Form.Input}
          readOnly={readOnly}
          required={required}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          label={<Typography color="textPrimary">{t(label)}</Typography>}
          placeholder={t(placeholder)}
          error={error}
          search
          selection
          onChange={!!onChange ? onChange : ([_, data]) => data.value}
          as={Form.Dropdown}
          options={
            isCascaded
              ? mapRefToOptions(options.filter(filter))
              : mapRefToOptions(options)
          }
          loading={loading}
          fluid
          required={required}
        />
      )}
    </>
  );
});
