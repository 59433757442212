import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';
import { useAlert } from 'react-alert';

import { assetSchema as schema } from '../../../../_validationSchema';
import { assetAction as actions } from '../../../../_action/asset.action';
import { asset_model as model } from '../../../../_model';
import { formType, assetConstants as constants } from '../../../../_constants';

import { useSelector, useDispatch } from 'react-redux';
import { StdTitle } from '../../../../_fragments';
import { Grid, Button, Modal, Segment, Header, Card } from 'semantic-ui-react';
import { commonAlert } from '../../../../labelConstant';
import { replaceNullObject } from '../../../../_helpers';
import StdSubtitle from '../../../../_fragments/Title/StdSubtitle';

import { FormControl, Divider } from '@material-ui/core';
import Info from '../Info';
import Classification from '../Classification';
import Manufacturing from '../Manufacturing';
import Dimension from '../Dimension';
import Owner from '../Owner';
import Location from '../Location';
import PreviewImage from '../PreviewImage';
import CostCenter from '../CostCenter';
import Warranty from '../Warranty/Warranty';

const AssetFormContainer = (props) => {
  const { t, type, code, open, setClose } = props;
  const alert = useAlert();
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state.assetOperation);
  const [readOnly, setReadOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [header, setHeader] = useState({
    icon: '',
    content: '',
    subHeader: '',
  });
  const onClose = (e = undefined) => {
    if (!!e) e.preventDefault();
    reset(model);
    setClose();
  };
  const onSubmit = (data) => {
    switch (type) {
      case formType.Update:
        dispatch(actions.update(convert2Object(data)));
      case formType.DELETE:
        dispatch(actions.remove({ code: data.code }));
      case formType.INSERT:
        dispatch(actions.insert(convert2Object(data)));
    }
  };
  const { handleSubmit, errors, control, reset, register } = useForm({
    defaultValues: model,
    validationSchema: schema,
  });
  useEffect(() => {
    if (!!type) setHeader(formHeader(type));
    if (type === formType.UPDATE) {
      dispatch(actions.get(code));
      setReadOnly(false);
    } else if (type === formType.INSERT) {
      reset(model);
      setReadOnly(false);
    } else if (type === formType.VIEW || type === formType.DELETE) {
      setReadOnly(true);
      dispatch(actions.get(code));
    }
  }, [type, code, dispatch, reset]);

  useEffect(() => {
    if (reducer.insertSuccess === true) {
      setLoading(false);
      alert.success(t(commonAlert.ALERT_INSERT_SUCCESS));
      onClose();
      reset(model);
    } else if (reducer.insertFailure === true) {
      setLoading(false);
    } else if (reducer.updateSuccess === true) {
      setLoading(false);
      alert.success(t(commonAlert.ALERT_UPDATE_SUCCESS));
      onClose();
      reset(model);
    } else if (reducer.updateFailure === true) {
      setLoading(false);
    } else if (reducer.getSuccess === true) {
      setLoading(false);
      let obj = replaceNullObject(reducer.data.data.info);

      reset(convert2Select(obj));
    } else if (reducer.deleteFailure === true) {
      setLoading(false);
    } else if (reducer.deleteSuccess === true) {
      alert.success(t(commonAlert.ALERT_DELETE_SUCCESS));
      onClose();
      reset(model);
    }

    if (
      reducer.insertRequest ||
      reducer.updateRequest ||
      reducer.deleteRequest ||
      reducer.getRequest
    )
      setLoading(true);
  }, [reducer, reset, onClose, t]);
  useEffect(() => {
    return () => {
      dispatch(actions.reset(constants.RESET));
    };
  }, []);
  useEffect(() => {
    if (open === false) {
      reset(model);
    }
  }, [open]);
  const inlineStyle = {
    modal: {
      position: 'relative',
      height: 'auto',
      margin: '5rem auto',
    },
  };
  return (
    <>
      <Modal
        key={open}
        open={open}
        // closeOnEscape={true}
        onClose={onClose}
        style={inlineStyle.modal}
        size="fullscreen"
      >
        <Modal.Content>
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <Grid stackable columns={2}>
              <Grid.Column mobile={16} tablet={9} computer={9}>
                <StdTitle
                  icon={header.icon}
                  content={header.content}
                  subHeader={header.subHeader}
                />
              </Grid.Column>

              <Grid.Column
                mobile={16}
                tablet={5}
                computer={5}
                floated="right"
                textAlign="right"
              >
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    onClose();
                  }}
                  size="medium"
                  content={t('cancel')}
                />
                <Button
                  type="submit"
                  hidden={type === formType.VIEW}
                  size="medium"
                  primary
                  content={t('submit')}
                />
              </Grid.Column>
            </Grid>
            <Segment loading={loading} placeholder>
              <Grid stackable columns={4} divided padded>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Card fluid raised>
                      <Header as="h3" attached="top" inverted>
                        Information
                      </Header>
                      <Segment raised attached>
                        <Info
                          errors={errors}
                          control={control}
                          readOnly={readOnly}
                          isUpdate={type === formType.UPDATE}
                          register={register}
                        />
                      </Segment>
                    </Card>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Card fluid raised>
                      <Header as="h3" attached="top" inverted>
                        Classification
                      </Header>
                      <Segment raised attached>
                        <Classification
                          errors={errors}
                          control={control}
                          readOnly={readOnly}
                        />
                      </Segment>
                    </Card>
                    <Divider />
                    <Card fluid raised>
                      <Header as="h3" attached="top" inverted>
                        Manufacturing
                      </Header>
                      <Segment raised attached>
                        <Manufacturing
                          errors={errors}
                          control={control}
                          readOnly={readOnly}
                        />
                      </Segment>
                    </Card>

                    {readOnly && (
                      <>
                        <Divider />
                        <Card fluid raised>
                          <Header as="h3" attached="top" inverted>
                            Owner
                          </Header>
                          <Segment raised attached>
                            <Owner
                              errors={errors}
                              control={control}
                              readOnly={readOnly}
                            />
                          </Segment>
                        </Card>
                      </>
                    )}
                  </Grid.Column>

                  <Grid.Column mobile={16} tablet={16} computer={5}>
                    <Card fluid raised>
                      <Header as="h3" attached="top" inverted>
                        Dimension
                      </Header>
                      <Segment raised attached>
                        <Dimension
                          errors={errors}
                          control={control}
                          readOnly={readOnly}
                        />
                      </Segment>
                    </Card>
                    <Divider />
                    <Card fluid raised>
                      <Header as="h3" attached="top" inverted>
                        Cost Center
                      </Header>
                      <Segment raised attached>
                        <CostCenter
                          errors={errors}
                          control={control}
                          readOnly={readOnly}
                        />
                      </Segment>
                    </Card>

                    {readOnly && (
                      <>
                        <Divider />
                        <Card fluid raised>
                          <Header as="h3" attached="top" inverted>
                            Location
                          </Header>
                          <Segment raised attached>
                            <Location
                              errors={errors}
                              control={control}
                              readOnly={readOnly}
                            />
                          </Segment>
                        </Card>
                      </>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </form>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default translate(AssetFormContainer);

const convert2Object = (obj) => {
  let new_obj = {
    ...obj,

    assetUnit: !!obj.assetUnit.value ? obj.assetUnit.value : '',
    assetBrand: !!obj.assetBrand.value ? obj.assetBrand.value : '',
    assetClass: !!obj.assetClass.value ? obj.assetClass.value : '',
    assetSubClass: !!obj.assetSubClass.value ? obj.assetSubClass.value : '',
    assetCondition: !!obj.assetCondition.value ? obj.assetCondition.value : '',
    assetGroup: !!obj.assetGroup.value ? obj.assetGroup.value : '',
    assetModel: !!obj.assetModel.value ? obj.assetModel.value : '',

    assetSupplier: !!obj.assetSupplier.value ? obj.assetSupplier.value : '',

    dimensionUomId: !!obj.dimensionUomId.value ? obj.dimensionUomId.value : '',
    weightUomId: !!obj.weightUomId.value ? obj.weightUomId.value : '',

    costCenter: !!obj.costCenter.value ? obj.costCenter.value : '',

    // businessArea: !!obj.businessArea.value ? obj.businessArea.value : '',
    // site: !!obj.site.value ? obj.site.value : '',
    // location: !!obj.location.value ? obj.location.value : '',
    // zone: !!obj.zone.value ? obj.zone.value : '',
  };
  return new_obj;
};
const convert2Select = (obj) => {
  let new_obj = {
    ...obj,
    assetUnit: {
      value: obj.assetUnit,
      label: obj.assetUnit + ' - ' + obj.assetUnitDescription,
    },
    assetBrand: {
      value: obj.assetBrand,
      label: obj.assetBrand + ' - ' + obj.assetBrandDescription,
    },
    assetClass: {
      value: obj.assetClass,
      label: obj.assetClass + ' - ' + obj.assetClassDescription,
    },
    assetSubClass: {
      value: obj.assetSubClass,
      label: obj.assetSubClass + ' - ' + obj.assetSubClassDescription,
    },
    assetCondition: {
      value: obj.assetCondition,
      label: obj.assetCondition + ' - ' + obj.assetConditionDescription,
    },
    assetGroup: {
      value: obj.assetGroup,
      label: obj.assetGroup + ' - ' + obj.assetGroupDescription,
    },
    assetModel: {
      value: obj.assetModel,
      label: obj.assetModel + ' - ' + obj.assetModelDescription,
    },

    assetSupplier: {
      value: obj.assetSupplier,
      label: obj.assetSupplier + ' - ' + obj.assetSupplierDescription,
    },

    dimensionUomId: {
      value: obj.dimensionUomId,
      label: obj.dimensionUomId + ' - ' + obj.dimensionUomDescription,
    },
    weightUomId: {
      value: obj.weightUomId,
      label: obj.weightUomId + ' - ' + obj.weightUomDescription,
    },

    costCenter: {
      value: obj.costCenter,
      label: obj.costCenter + ' - ' + obj.costCenterDescription,
    },

    // businessArea: {
    //   value: obj.businessArea,
    //   label: obj.businessArea + ' - ' + obj.businessAreaDescription
    // },
    // site: {
    //   value: obj.site,
    //   label: obj.site + ' - ' + obj.siteDescription
    // },
    // location: {
    //   value: obj.location,
    //   label: obj.location + ' - ' + obj.locationDescription
    // },
    // zone: {
    //   value: obj.zone,
    //   label: obj.zone + ' - ' + obj.zoneDescription
    // },
  };
  return new_obj;
};

const formHeader = (type) => {
  switch (type) {
    case formType.VIEW:
      return {
        icon: 'search',
        content: 'Asset Form| View',
        subHeader: '',
      };
    case formType.INSERT:
      return {
        icon: 'plus',
        content: 'Asset Form| Insert',
        subHeader: '',
      };
    case formType.UPDATE:
      return {
        icon: 'pencil',
        content: 'Asset Form| Update',
        subHeader: '',
      };
    case formType.DELETE:
      return {
        icon: 'trash',
        content: 'Asset Form| Delete',
        subHeader: '',
      };
  }
};
