import React, { useState } from 'react';
import { Link as RouterLink, Link, Route } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Icon,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import EcoIcon from '@material-ui/icons/Eco';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    display: 'flex',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
  const [notifications] = useState([]);

  return (
    <div className={classes.root}>
      <AppBar {...rest} position="fixed">
        <Toolbar>
          <RouterLink to="/">
            {/* <EcoIcon fontSize="large" style={{ color: '#FFF' }} /> */}
            <img height={50} alt="Logo" src="/imgs/fix_W2.png" />
          </RouterLink>
          {/* <Typography style={{ color: '#FFF' }} variant="h6" noWrap>
            Fixed Asset Portal
          </Typography> */}

          <div className={classes.flexGrow} />
          <Hidden mdDown>
            <IconButton color="inherit">
              <Badge
                badgeContent={notifications.length}
                color="primary"
                variant="dot"
              >
                {/* <NotificationsIcon /> */}
              </Badge>
            </IconButton>

            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={() => {
                // console.log(props.props.props);
                props.props.props.history.push('/login');
              }}
            >
              <InputIcon />
            </IconButton>
          </Hidden>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={onSidebarOpen}>
              <MenuIcon />
            </IconButton>
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={() => {
                // console.log(props.props.props);
                props.props.props.history.push('/login');
              }}
            >
              <InputIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
