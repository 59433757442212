export const locationConstants = {
  API_INSERT: '/api/site/location/insert',
  API_UPDATE: '/api/site/location/update',
  API_DELETE: '/api/site/location/delete',
  API_GET: '/api/site/location/',
  API_GETALL: '/api/site/location/getall',

  INSERT_REQUEST: 'LOCATION_INSERT_REQUEST',
  INSERT_SUCCESS: 'LOCATION_INSERT_SUCCESS',
  INSERT_FAILURE: 'LOCATION_INSERT_FAILURE',

  UPDATE_REQUEST: 'LOCATION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'LOCATION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'LOCATION_UPDATE_FAILURE',

  DELETE_REQUEST: 'LOCATION_DELETE_REQUEST',
  DELETE_SUCCESS: 'LOCATION_DELETE_SUCCESS',
  DELETE_FAILURE: 'LOCATION_DELETE_FAILURE',

  GET_REQUEST: 'LOCATION_GET_REQUEST',
  GET_SUCCESS: 'LOCATION_GET_SUCCESS',
  GET_FAILURE: 'LOCATION_GET_FAILURE',

  GETALL_REQUEST: 'LOCATION_GETALL_REQUEST',
  GETALL_SUCCESS: 'LOCATION_GETALL_SUCCESS',
  GETALL_FAILURE: 'LOCATION_GETALL_FAILURE',

  RESET: 'LOCATION_RESET',
  SERVICE: 'LOCATION_SERVICE',
};
