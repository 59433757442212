import React from 'react';
import { translate } from 'react-switch-lang';
import { TransactionDatatable } from '../../../_fragments/transactiondatatable';
import { WriteOffService as service } from '../../../_services/transaction/asset.writeoff.service';
import { Label } from 'semantic-ui-react';
import { useTheme } from '@material-ui/styles';
import { Chip } from '@material-ui/core';

const AssetWriteOff = (props) => {
  const theme = useTheme();
  const { t } = props;
  const header = {
    icon: 'cubes',
    content: 'Asset Write-Off',
    subHeader: 'Listing',
  };

  const insertBtn = {
    text: t('Add'),
    link: props.location.pathname + '/insert',
  };

  const actionPath = {
    add: `${props.location.pathname}/insert`,
    edit: `${props.location.pathname}/update`,
    delete: `${props.location.pathname}/delete`,
  };

  const headers = [
    { title: 'Code', field: 'Code' },
    { title: 'Date', field: 'Date' },

    {
      title: 'Status',
      field: 'Status',
      render: (rowData) => (
        <Label color={rowData.Status == 'APPROVE' ? 'green' : 'grey'}>
          {rowData.Status}
        </Label>
      ),
    },
    { title: 'Create By', field: 'CreateBy' },
  ];

  const title = 'Asset Write-Off';
  const filenameXML = 'asset-writeoff';
  const menuCode = 'M_ASSET_WRITEOFF';
  return (
    <>
      <TransactionDatatable
        header={header}
        insertBtn={insertBtn}
        title={title}
        headers={headers}
        filenameXML={filenameXML}
        root={props.root}
        history={props.history}
        path={actionPath}
        service={service}
        menuCode={menuCode}
      />
    </>
  );
};

export default translate(AssetWriteOff);
