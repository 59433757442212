export const roleConstants = {
  API_INSERT: '/api/role/insert',
  API_UPDATE: '/api/role/update',
  API_DELETE: '/api/role/delete',
  API_GET: '/api/role/',
  API_GETALL: '/api/role/getall',
  API_GETALL_MENUS: '/api/role/menu/getall',
  API_GET_PERMISSIONS: `/api/role/id/permission`,
  API_ROLE_PERMISSION_UPDATE: '/api/role/permission/update',

  INSERT_REQUEST: 'ROLE_INSERT_REQUEST',
  INSERT_SUCCESS: 'ROLE_INSERT_SUCCESS',
  INSERT_FAILURE: 'ROLE_INSERT_FAILURE',

  UPDATE_REQUEST: 'ROLE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ROLE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ROLE_UPDATE_FAILURE',

  DELETE_REQUEST: 'ROLE_DELETE_REQUEST',
  DELETE_SUCCESS: 'ROLE_DELETE_SUCCESS',
  DELETE_FAILURE: 'ROLE_DELETE_FAILURE',

  GET_REQUEST: 'ROLE_GET_REQUEST',
  GET_SUCCESS: 'ROLE_GET_SUCCESS',
  GET_FAILURE: 'ROLE_GET_FAILURE',

  GETALL_REQUEST: 'ROLE_GETALL_REQUEST',
  GETALL_SUCCESS: 'ROLE_GETALL_SUCCESS',
  GETALL_FAILURE: 'ROLE_GETALL_FAILURE',

  RESET: 'ROLE_RESET',
  SERVICE: 'ROLE_SERVICE',
};
