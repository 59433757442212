import React from 'react';
import { Title } from "../../../_fragments";

const Stockadjustment = (props) => {
  return (
    <div>
      <Title icon="warehouse" iconaction="edit" content="Stock Adjustment" props={props}/>
   </div>
 );
};

export default Stockadjustment;
