import React from 'react';
// import { Divider, Label } from 'semantic-ui-react';
// import { Image, Table } from 'semantic-ui-react';

export default function _InventoryItemList(props) {
  // const { t } = props;
  return (
    <>
      {/* <Table key="list" striped basic fixed singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>#No</Table.HeaderCell>
            <Table.HeaderCell width={1}>Image</Table.HeaderCell>
            <Table.HeaderCell>Code</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Qty</Table.HeaderCell>
            <Table.HeaderCell>Unit</Table.HeaderCell>
            <Table.HeaderCell>Serialized</Table.HeaderCell>
            <Table.HeaderCell>S/N</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Label>#1</Label>
            </Table.Cell>
            <Table.Cell>
              <Image
                src="https://react.semantic-ui.com/images/avatar/small/lena.png"
                rounded
                size="mini"
              />
            </Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>EDIT | DELETE</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Label>#2</Label>
            </Table.Cell>
            <Table.Cell>
              <Image
                src="https://react.semantic-ui.com/images/avatar/small/matthew.png"
                rounded
                size="mini"
              />
            </Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>EDIT | DELETE</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Label>#3</Label>
            </Table.Cell>
            <Table.Cell>
              <Image
                src="https://react.semantic-ui.com/images/avatar/small/lindsay.png"
                rounded
                size="mini"
              />
            </Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>ABCDEF</Table.Cell>
            <Table.Cell>EDIT | DELETE</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Divider /> */}
    </>
  );
}
