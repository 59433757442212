import { OwnerChangeServices as service } from '../../services/asset.ownerchange.service';
import { alertActions as alerts } from '../../../../../_action/alert.action';
import { alertConstants as message } from '../../../../../_constants';
import { history } from '../../../../../_helpers';

export const addItem = (payload) => ({
  type: 'ADD_ITEM',
  payload,
});
export const DeleteItem = (payload) => ({
  type: 'DELETE_ITEM',
  payload,
});

export const ResetItem = () => ({
  type: 'CLEAR_ITEM',
});

export const Sucess = () => ({
  type: 'SUCESS',
});
function reset(t) {
  return { type: t };
}
export function Save(data) {
  return async (dispatch) => {
    // dispatch(alerts.success(message.INSERT_SUCCESS));

    try {
      const res = await service.Save(data);
      console.log('res =>', res);
      if (res === 'OK') {
        dispatch(alerts.success(message.INSERT_SUCCESS));
        dispatch(ResetItem());
        history.goBack();
        // setTimeout(() => {
        //   history.goBack();
        // }, 1000);
      } else if (res === 'NOK') {
        dispatch(alerts.error(message.INSERT_FALIURE));
        dispatch(ResetItem());
      }
      return res;
    } catch (error) {
      dispatch(ResetItem());
      return error;
    }
  };
}

export function Update(data) {
  return async (dispatch) => {
    try {
      const res = await service.Update(data);
      //console.log('res =>', res);
      if (res === 'OK') {
        dispatch(alerts.success(message.UPDATE_SUCCESS));
        dispatch(ResetItem());
        // setTimeout(() => {
        history.goBack();
        // }, 1000);
      } else if (res === 'NOK') {
        dispatch(alerts.error(message.UPDATE_FAILURE));
        dispatch(ResetItem());
      }

      // dispatch(Sucess());

      return res;
    } catch (error) {
      //console.log('Error! ', error);
      dispatch(ResetItem());
      return error;
    }
  };
}
