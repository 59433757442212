import { CustomerService as service } from '../_services/customer.service';
import { CustomerConstants as constants } from '../_constants/customer.constants';
import { MyAlert as Alert } from '../_fragments/alert';
import { history } from '../_helpers/history';
import { DesktopWindows } from '@material-ui/icons';

//Call Reducer

const success = (payload) => ({
  type: 'SUCCESS',
  payload,
});

const failed = (payload) => ({
  type: 'FAILED',
  payload,
});

const clear = () => ({
  type: 'CLEAR',
});

const getCustomer = (payload) => ({
  type: 'GET_CUSTOMER',
  payload,
});

//Call UI
export const Save = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(clear());
      const result = await service.Save(data);
      if (result.status === 201) {
        // await dispatch(success('Insert Successfully!'));
        Alert.Success('Created Successful!').then(() => {
          history.goBack();
        });
      } else {
        Alert.Error(result.message);
        //await dispatch(failed(result));
      }
    } catch (error) {
      Alert.Error(JSON.stringify(error));
      //await dispatch(failed(error));
    }
  };
};

export const Update = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(clear());
      const result = await service.Update(data);
      if (result.status === 200) {
        Alert.Success('Update Successful!').then(() => {
          history.goBack();
        });
      } else {
        Alert.Error(result.message);
      }
    } catch (error) {
      Alert.Error(JSON.stringify(error));
      //wait dispatch(failed(error));
    }
  };
};

export const GetCustomer = (code) => {
  return async (dispatch) => {
    try {
      await dispatch(clear());
      const result = await service.getItem(code);
      //console.log(result);
      if (result.status === 200) {
        const data = result.data.info;
        await dispatch(getCustomer(data));
        // await dispatch(success('Update Successfully!'));
      } else {
        await dispatch(failed(result));
      }
    } catch (error) {
      await dispatch(failed(error));
    }
  };
};

export const ClearData = () => {
  return async (dispatch) => {
    await dispatch(clear());
  };
};
