import React from 'react';

import { Typography } from '@material-ui/core';

const Display = ({ txt, color = 'primary' }) => (
  <Typography color={color}>
    {txt === '' || txt === null ? '-' : txt}
  </Typography>
);

export default Display;
