import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import SideBar from './sidebar';
import { Segment, Sidebar, Grid } from 'semantic-ui-react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [visible, setVisible] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));
  return (
    <>
      <Header
        open={visible}
        toggleSidebar={() => {
          setVisible(!visible);
        }}
      />

      <Sidebar.Pushable>
        <Sidebar.Pusher>
          <Segment placeholder>
            <Route
              {...rest}
              render={(props) =>
                localStorage.getItem('user') ? (
                  <Component {...props} />
                ) : (
                  <Redirect
                    to={{ pathname: '/login', state: { from: props.location } }}
                  />
                )
              }
            />
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      <SideBar onHide={() => setVisible(false)} visible={visible} user={user} />
      <Footer />

      {/* <Grid style={{ height: '100vh' }}>
        <Grid.Column>
          <Header toggleSidebar={() => { setVisible(!visible) }} />
          <Sidebar.Pushable >
            <Sidebar.Pusher>
              <Segment placeholder >
                <Route {...rest} render={props => (
                  localStorage.getItem('user')
                    ? <Component {...props} />
                    : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )} />
              </Segment>
            </Sidebar.Pusher>


            <SideBar
              onHide={() => setVisible(false)}
              visible={visible}
              user={user}
            />

          </Sidebar.Pushable>
          <Footer />
        </Grid.Column>

      </Grid> */}
    </>
  );
};
