import { ReturnConstants as constants } from '../../../../../../../_constants/transaction';
import { ReturnService as service } from '../../../../../../../_services/transaction/asset.return.service';
import { alertActions as alerts } from '../../../../../../../_action/alert.action';
import { alertConstants as message } from '../../../../../../../_constants';
import { history } from '../../../../../../../_helpers';

export const addItem = (payload) => ({
  type: 'ADD_ITEM',
  payload,
});
export const DeleteItem = (payload) => ({
  type: 'DELETE_ITEM',
  payload,
});

export const ResetItem = () => ({
  type: 'RESET_ITEM',
});
export const Sucess = () => ({
  type: 'SUCESS',
});

export function Save(data) {
  return async (dispatch) => {
    try {
      const res = await service.Save(data);
      console.log('res =>', res);
      if (res.status == 201) {
        dispatch(alerts.success(message.INSERT_SUCCESS));
        dispatch(ResetItem());
        setTimeout(() => {
          history.goBack();
        }, 1000);
      } else if (res.status != 201) {
        dispatch(alerts.error(message.INSERT_FALIURE));
        dispatch(ResetItem());
      }
      return res;
    } catch (error) {
      console.log('Error ReturnSave! ', error);
      dispatch(ResetItem());
      return error;
    }
  };
}

export function Update(data) {
  return async (dispatch) => {
    try {
      const res = await service.Update(data);
      console.log('res =>', res);
      if (res.status == 204) {
        dispatch(alerts.success(message.UPDATE_SUCCESS));
        dispatch(ResetItem());
        setTimeout(() => {
          history.goBack();
        }, 1000);
      } else if (res.status != 204) {
        dispatch(alerts.error(message.UPDATE_FAILURE));
        //dispatch(ResetItem());
      }

      // dispatch(Sucess());

      return res;
    } catch (error) {
      console.log('Error! ', error);
      // dispatch(ResetItem());
      return error;
    }
  };
}
