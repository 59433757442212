import React, { useEffect } from 'react';
import {
  Grid,
  Paper,
  Card,
  CardHeader,
  Avatar,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import CodeIcon from '@material-ui/icons/Code';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { CardContent, Divider, Header } from 'semantic-ui-react';
import { Label } from '../../../components/Label';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardBody: {
    // flex: 1,
    // padding: theme.spacing(5),
    // justifyContent: 'center',
    // alignContent: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  header: {
    padding: theme.spacing(2),
  },
  icon: {
    textAlign: 'left',
  },
  action: {
    textAlign: 'right',
  },
  modal: {
    // display: 'block',
    flex: 1,
    // padding: theme.spacing(10),
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    width: '100%',
  },
  large_modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    overflow: 'scroll',
  },

  button: {
    margin: theme.spacing(1),
  },
  btnSave: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnBack: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
}));

const DisplayError = React.memo(({ errorMessage }) => {
  if (errorMessage === null) {
    return <></>;
  }

  if ('data' in errorMessage) {
    if (!!errorMessage.data.error_data)
      return (
        <>
          {/* total: {errorMessage.data.total} */}
          <Grid container justifyContent="flex-start">
            <Grid item style={{ margin: 10 }} xs={12} md={12} lg={12}>
              <Header>total: {errorMessage.data.total}</Header>
              {/* <Label txt={`total: ${errorMessage.data.total}`} /> */}
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {errorMessage.data.error_data.map((item, index) => {
                var msgs = item.errors;
                return (
                  <>
                    <List key={`${item.code}-${index}`}>
                      <ListItem key={`listItem-${item.code}-${index}`}>
                        <ListItemIcon
                          key={`listItemIcon-${item.code}-${index}`}
                        >
                          <CodeIcon
                            key={`listItemCodeIcon-${item.code}-${index}`}
                          />
                        </ListItemIcon>
                        <ListItemText
                          key={`listItemText-${item.code}-${index}`}
                          primary={`code: ${item.code}`}
                        />
                      </ListItem>
                      {msgs != null &&
                        msgs.map((err, idx) => {
                          const [key, value] = Object.entries(err)[0];
                          return (
                            <ListItem
                              key={`${item.code}-${index}-${idx}`}
                            >{`${key} : ${value}`}</ListItem>
                          );
                        })}
                    </List>
                    <Divider />
                  </>
                );
              })}
            </Grid>
          </Grid>
        </>
      );
    else
      return (
        <>{`Status: ${
          'status' in errorMessage ? errorMessage.status : ''
        } | data: ${errorMessage.data}`}</>
      );
  }

  return <>{JSON.stringify(errorMessage, null, 4)}</>;
}, []);
const ImportAlertModal = (props) => {
  const classes = useStyles();
  const { open, onClose, errorMessage, size = null } = props;
  useEffect(() => {
    // if (!!errorMessage) console.log('errorMessage: ', errorMessage);
    console.log('error: ', errorMessage);
    return () => {};
  }, [errorMessage]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={size === 'large' ? classes.large_modal : classes.modal}
    >
      <Card>
        <CardHeader
          avatar={
            <Avatar style={{ backgroundColor: 'red' }}>
              <ErrorOutlineIcon style={{ color: 'white' }} />
            </Avatar>
          }
          action={
            <div className={classes.action}>
              <Button
                color="default"
                variant="outlined"
                className={classes.btnBack}
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                }}
                startIcon={<KeyboardReturnIcon />}
              >
                Back
              </Button>
            </div>
          }
          title={
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              style={{ color: 'red' }}
            >
              Error encounters during import
            </Typography>
          }
        />
        {/* <CardContent classes={classes.cardBody}> */}
        <DisplayError errorMessage={errorMessage} />
        {/* {errorMessage != null && JSON.stringify(errorMessage, null, 4)} */}
        {/* {errorMessage != null && 
          errorMessage.data.error_data.map((item, index) => {
            var msgs = item.errors;
            return (
              <>
                <List>
                  <ListItem button>
                    <ListItemIcon>
                      <CodeIcon />
                    </ListItemIcon>
                    <ListItemText primary={`code: ${item.code}`} />
                  </ListItem>
                  {msgs != null &&
                    msgs.map((err, idx) => {
                      const [key, value] = Object.entries(err)[0];
                      return <ListItem>{`${key} : ${value}`}</ListItem>;
                    })}
                </List>
                <Divider />
              </>
            );
          })} */}
        {/* </CardContent> */}

        {/* <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <h1>{JSON.stringify(errorMessage)}</h1>
              </Paper>
            </Grid>
          </Grid> */}
      </Card>
    </Modal>
  );
};

export default ImportAlertModal;
