import { ReturnConstants as constant } from '../../_constants/transaction';
import { authHeader } from '../../_helpers/auth-header';

import {
  postRequest,
  getRequest,
  postJQGRid,
  httpClient_Post,
  httpClient_Post_with_Params,
  httpClient_borrow_approve,
  httpClient_borrow_delete,
} from '../../_helpers';

export const ReturnService = {
  updateItem,
  deleteItem,
  getItem,
  getAllItem,
  Save,
  Approve,
  Update,
  Delete,
  getReason,
  autoComplete,
};

async function Save(data) {
  return await httpClient_Post(
    constant.API_INSERT,
    data,
    authHeader().Authorization,
  )
    .then((res) => {
      // console.log('Hello then back', res);
      return res;
    })
    .catch((error) => {
      // console.log('Hello Error', error);
      return error;
    });
}

async function Update(data) {
  // console.log('dataUpdate', data);
  return await httpClient_Post(
    constant.API_UPDATE,
    data,
    authHeader().Authorization,
  )
    .then((res) => {
      // console.log('Hello then back', res);
      return res;
    })
    .catch((error) => {
      // console.log('Hello Error', error);
      return error;
    });
}

async function Approve(code) {
  return await httpClient_borrow_approve(
    constant.API_APPROVE,
    code,
    authHeader().Authorization,
  )
    .then((res) => {
      // console.log('Hello then back', res);
      return res;
    })
    .catch((error) => {
      // console.log('Hello Error', error);
      return error;
    });
}

async function Delete(code) {
  const result = await httpClient_borrow_delete(
    constant.API_DELETE,
    code,
    authHeader().Authorization,
  )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });

  return result;
}

async function updateItem(item) {
  return await postRequest(
    constant.API_UPDATE,
    item,
    null,
    authHeader().Authorization,
  );
}

async function deleteItem(code) {
  return await postRequest(
    constant.API_DELETE,
    code,
    null,
    authHeader().Authorization,
  );
}

async function getItem(code) {
  return await getRequest(
    constant.API_GET_BY_ID + code,
    null,
    authHeader().Authorization,
  );
}

async function getAllItem() {
  return await getRequest(
    constant.API_GET_REASON,
    null,
    authHeader().Authorization,
  );
}

async function autoComplete({
  req_type = -1,
  req_search = '',
  recordTotal = 0,
}) {
  return await httpClient_Post_with_Params(
    constant.API_AUTOCOMPLETE_OWNER,
    { req_type, req_search, recordTotal },
    authHeader().Authorization,
  );
}

async function getReason() {
  return await getRequest(
    constant.API_GET_REASON,
    null,
    authHeader().Authorization,
  );
}
