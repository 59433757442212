import React, { useState, useEffect } from 'react';

import { translate } from 'react-switch-lang';
import {
  Header,
  Icon,
  Grid,
  Button,
  Table,
  Label,
  Input,
} from 'semantic-ui-react';
import {
  Typography,
  Paper,
  FormControl,
  FormLabel,
  FormHelperText,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputText,
  InputNumber,
} from '../../../../../_fragments/formField/controllerFieldMUI';
import { useSelector, useDispatch } from 'react-redux';
import { addItem, DeleteItem } from './redux/actions/borrow.additem.action';
import { clearItem } from '../../../_InventoryFlagments/redux/actions/assetsearch.action';
import { BorrowService as service } from '../../../../../_services/transaction/asset.borrow.service';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import StdDatatable from '../../../_InventoryFlagments/StdDatatable';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AssetTable from '../../../_InventoryFlagments/assettable';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(),
    margin: theme.spacing(1),
  },
  bg: {
    //background: 'linear-gradient(180deg, white 30%, grey 70%)',
    // background: '#bbdefb',
  },
}));

const BorrowadditemEdit = (props) => {
  const classes = useStyles();
  const { t } = props;
  const [items, setItems] = useState([]);
  const [Adding, setAdding] = useState(false);
  const [AssetCode, setAssetCode] = useState('');
  const [Description, setDescription] = useState('');
  const [Serial, setSerial] = useState('');
  const [Quantity, setQuantity] = useState(1);
  const [Remark, setRemark] = useState('');
  const [isEdit, setisEdit] = useState(false);
  const [error, setError] = useState(false);
  const [submit, setsubmit] = useState(false);
  const storageAsset = useSelector((state) => state.assetSearch);

  const [open, setOpen] = React.useState(false);

  /// Redux ///
  const dispatch = useDispatch();
  const storage = useSelector((state) => state.assetSearch);
  /// --- Redux  ----///

  ///--- Dialog ---///

  const handleClose = () => {
    setOpen(false);
  };

  ///--- Dialog ---///
  let loading = false;
  useEffect(() => {
    //console.log('data-to-code:', props.code);
    loading = true;
    if (loading) {
      service
        .getItem(props.code)
        .then(async (res) => {
          loading = false;
          //console.log(res.data.result.assetBorrowDetail);

          const details = res.data.result.assetBorrowDetail;

          setItems(res.data.result.assetBorrowDetail);
          dispatch(addItem(res.data.result.assetBorrowDetail));
        })
        .catch((error) => {
          loading = false;
          window.alert('error', error);
        });
    }
  }, []);

  useEffect(() => {
    if (storageAsset.closing) {
      setAssetCode(storageAsset.Code);
      setSerial(storageAsset.Serial);
      setDescription(storageAsset.Description);
      setRemark(storageAsset.Remark);
      setOpen(false);
      setError(false);
    }
  }, [storageAsset]);

  useEffect(() => {
    setAdding(false);
    setAssetCode('');
    setSerial('');
    setDescription('');
    setQuantity(0);
    setRemark('');
    setsubmit(false);

    return () => {
      dispatch(clearItem());
      setsubmit(false);
    };
  }, [items]);

  const onSubmitAddItem = () => {
    setItems([
      {
        assetCode: AssetCode,
        assetDescription: Description,
        serial: Serial,
        quanitity: 1,
        remark: Remark,
      },
    ]);
  };

  return (
    <>
      {!props.readOnly && (
        <Paper className={classes.root} elevation={0} variant="outlined">
          <Header dividing>
            <Typography variant="h6" color="textPrimary">
              <Icon name="add" />
              {t('Assets')}
            </Typography>
          </Header>

          <Grid padded="horizontally">
            <Grid.Row>
              <Grid.Column computer={4} tablet={16} mobile={16}>
                <FormControl
                  margin="dense"
                  fullWidth
                  required={true}
                  error={null}
                  size="small"
                >
                  <FormLabel component="legend">Asset</FormLabel>
                  <Input
                    onClick={(e) => {
                      e.preventDefault();
                      if (!isEdit) {
                        setOpen(true);
                      }
                    }}
                    onChange={(e) => {
                      console.log(e.target.value);
                    }}
                    error={error}
                    icon
                    size="small"
                    placeholder="Search..."
                    value={AssetCode}
                    readOnly
                  >
                    <input size="small" name="assetCode" />
                    <Icon name="search" />
                  </Input>
                </FormControl>
              </Grid.Column>
              <Grid.Column computer={5} tablet={16} mobile={16}>
                <FormControl fullWidth margin="dense" size="small">
                  <FormLabel component="legend">Description</FormLabel>
                  <Input
                    size="small"
                    readOnly
                    defaultValue={storageAsset.Description}
                    value={Description}
                  />
                </FormControl>
              </Grid.Column>

              <Grid.Column computer={5} tablet={16} mobile={16}>
                <FormControl margin="dense" fullWidth size="small">
                  <FormLabel component="legend">Serial</FormLabel>
                  <Input
                    size="small"
                    placeholder="Serial"
                    defaultValue={storageAsset.Serial}
                    value={Serial}
                    readOnly
                  >
                    <input placeholder="Serial" size="small" name="serial" />
                  </Input>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </Grid.Column>
              <Grid.Column computer={2} tablet={16} mobile={16}>
                <InputNumber
                  name="quanitity"
                  control={props.control}
                  label="Quanitity"
                  placeholder="input "
                  error={
                    !!props.errors.quanitity && {
                      content: props.errors.quanitity.message,
                    }
                  }
                  defaultValue={Quantity}
                  value={Quantity}
                  onChange={([e, _]) => {
                    setQuantity(e.target.value);
                    return e;
                  }}
                />
              </Grid.Column>

              <Grid.Column computer={5} tablet={16} mobile={16}>
                <InputText
                  name="remark"
                  control={props.control}
                  label="Remark"
                  placeholder="Remark"
                  error={
                    !!props.errors.code && {
                      content: props.errors.code.message,
                    }
                  }
                  defaultValue={Remark}
                  value={Remark}
                  onChange={([e]) => {
                    setRemark(e.target.value);
                    return e;
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={1} tablet={12} mobile={16}>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">Add</FormLabel>
                  <Link
                    onClick={() => {
                      if (!!AssetCode) {
                        setAdding(true);
                        onSubmitAddItem();
                        setError(false);
                      } else {
                        setError(true);
                      }
                    }}
                  >
                    <AddBoxIcon style={{ fontSize: 42 }} color="primary" />
                  </Link>
                </FormControl>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Paper>
      )}

      <AssetTable getItems={items} readOnly={props.readOnly} />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Asset Master Search</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please find Asset Master as below:
          </DialogContentText>
          <div>
            <StdDatatable />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button primary onClick={handleClose}>
            Search
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default translate(BorrowadditemEdit);
