export const ownerConstants = {
  API_INSERT: '/api/asset/owner/insert',
  API_UPDATE: '/api/asset/owner/update',
  API_DELETE: '/api/asset/owner/delete',
  API_GET: '/api/asset/owner/',
  API_GETALL: '/api/asset/owner/getall',
  API_AUTOCOMPLETE_OWNER: '/api/autocomplete/owner',

  INSERT_REQUEST: 'OWNER_INSERT_REQUEST',
  INSERT_SUCCESS: 'OWNER_INSERT_SUCCESS',
  INSERT_FAILURE: 'OWNER_INSERT_FAILURE',

  UPDATE_REQUEST: 'OWNER_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'OWNER_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'OWNER_UPDATE_FAILURE',

  DELETE_REQUEST: 'OWNER_DELETE_REQUEST',
  DELETE_SUCCESS: 'OWNER_DELETE_SUCCESS',
  DELETE_FAILURE: 'OWNER_DELETE_FAILURE',

  GET_REQUEST: 'OWNER_GET_REQUEST',
  GET_SUCCESS: 'OWNER_GET_SUCCESS',
  GET_FAILURE: 'OWNER_GET_FAILURE',

  GETALL_REQUEST: 'OWNER_GETALL_REQUEST',
  GETALL_SUCCESS: 'OWNER_GETALL_SUCCESS',
  GETALL_FAILURE: 'OWNER_GETALL_FAILURE',

  RESET: 'OWNER_RESET',
  SERVICE: 'OWNER_SERVICE',
};
