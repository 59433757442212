export const assetConstants = {
  API_INSERT: '/api/asset/insert',
  API_UPDATE: '/api/asset/update',
  API_DELETE: '/api/asset/delete',
  API_GET: '/api/asset/',
  API_GETALL: '/api/asset/getall',
  API_EXISTS: '/api/asset/exists/',
  API_SEARCH_CODE: '/api/asset/search/code/',
  API_SEARCH_SERIAL: 'api/asset/get/serial/',
  API_EXISTS: 'api/asset/exists/',

  API_IMAGE_ADD: '/api/asset/add/image',
  API_IMAGE_UPDATE: '/api/asset/update/image',
  API_IMAGE_DELETE: '/api/asset/delete/image/',
  API_IMAGE_GET: '/api/asset/images/',
  API_EXPORT: '/api/asset/export',

  API_UPLOAD_ASSET: '/api/asset/upload',
  API_UPLOAD_OWNERCHANGETXN: '/api/asset/ownerchange/upload',

  API_WARRANTY_INSERT: 'api/asset/warranty/insert',
  API_WARRANTY_GET: `api/asset/id/warranty`,
  API_WARRANTY_UPDATE: 'api/asset/warranty/update',
  API_LICENSE_INSERT: 'api/asset/license/insert',
  API_LICENSE_GET: `api/asset/id/license`,
  API_LICENSE_UPDATE: 'api/asset/license/update',
  INSERT_REQUEST: 'ASSET_INSERT_REQUEST',
  INSERT_SUCCESS: 'ASSET_INSERT_SUCCESS',
  INSERT_FAILURE: 'ASSET_INSERT_FAILURE',

  UPDATE_REQUEST: 'ASSET_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ASSET_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ASSET_UPDATE_FAILURE',

  DELETE_REQUEST: 'ASSET_DELETE_REQUEST',
  DELETE_SUCCESS: 'ASSET_DELETE_SUCCESS',
  DELETE_FAILURE: 'ASSET_DELETE_FAILURE',

  GET_REQUEST: 'ASSET_GET_REQUEST',
  GET_SUCCESS: 'ASSET_GET_SUCCESS',
  GET_FAILURE: 'ASSET_GET_FAILURE',

  GETALL_REQUEST: 'ASSET_GETALL_REQUEST',
  GETALL_SUCCESS: 'ASSET_GETALL_SUCCESS',
  GETALL_FAILURE: 'ASSET_GETALL_FAILURE',

  INSERT_IMAGE_REQUEST: 'ASSET_INSERT_IMAGE_REQUEST',
  INSERT_IMAGE_SUCCESS: 'ASSET_INSERT_IMAGE_SUCCESS',
  INSERT_IMAGE_FAILURE: 'ASSET_INSERT_IMAGE_FAILURE',

  UPDATE_IMAGE_REQUEST: 'ASSET_UPDATE_IMAGE_REQUEST',
  UPDATE_IMAGE_SUCCESS: 'ASSET_UPDATE_IMAGE_SUCCESS',
  UPDATE_IMAGE_FAILURE: 'ASSET_UPDATE_IMAGE_FAILURE',

  DELETE_IMAGE_REQUEST: 'ASSET_DELETE_IMAGE_REQUEST',
  DELETE_IMAGE_SUCCESS: 'ASSET_DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_FAILURE: 'ASSET_DELETE_IMAGE_FAILURE',

  GET_IMAGE_REQUEST: 'ASSET_GET_IMAGE_REQUEST',
  GET_IMAGE_SUCCESS: 'ASSET_GET_IMAGE_SUCCESS',
  GET_IMAGE_FAILURE: 'ASSET_GET_IMAGE_FAILURE',

  SERVICE: 'ASSET_SERVICE',
  RESET: 'ASSET_RESET',
};
